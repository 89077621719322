import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../../../Layouts/FrontHeader';
import Footer from '../../../Layouts/FrontFooter';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { userForgetPassword } from '../../../slices/FrontView/authentication/thunk';

const ForgetPassword = (props) => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const { cmsData } = useSelector((state) => state.CMSF);

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            email: ''
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Please Enter Your Email"),
        }),
        onSubmit: (values) => {
            dispatch(userForgetPassword(values));


        }
    });
    document.title = `${cmsData?.websiteTitle ? cmsData?.websiteTitle : '1000 Ai Sites'} | Forgot Password`;


    return (
        <div>
            <Header />
            <section  >
                <div className="lg:px-4 py-20 px-md-5 text-center text-lg-start  login-forget"  >
                    <div className="container" >
                        <div className="row gx-lg-5 align-items-center justify-content-center">
                            <div className="col-lg-5 mb-5 mb-lg-0 ">
                                <div className="card ">
                                    <div className="card-body py-5 px-md-5">
                                        <h1 className="text-center font-bold mb-4">
                                            Forgot Password
                                        </h1>
                                        <form
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                validation.handleSubmit();
                                                return false;
                                            }}
                                            action="#">
                                            <div className="form-outline mb-4 relative">
                                                <label htmlFor="email" className="form-label " >Enter your email address</label>
                                                <input id="email" className="form-control input" type="email" name="email" placeholder="Enter your email address"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.email || ""}
                                                    invalid={
                                                        validation.touched.email && validation.errors.email ? true : false
                                                    }
                                                />

                                                <div className='error'>
                                                    <span >{validation.errors.email && validation.touched.email ? validation.errors.email : ''}</span>

                                                </div>
                                            </div>
                                            <div className=' mb-4'>Already have an account? <Link className='forget-link ' to='/login'>
                                                Log in
                                            </Link>
                                            </div>
                                            <button type="submit" className="btn btn-primary btn-block mb-4 primary-btn">
                                                Reset Password
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <Footer websiteList={'footerList'} />
        </div>
    );
}

export default ForgetPassword;