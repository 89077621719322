import 'react-toastify/dist/ReactToastify.css';
import { deleteRegisterWebsite, getPrice, registerWebsite as registerWebsiteApi } from "../../../helpers/backend_helper";
import { websitePriceSucccess } from "./reducer";
import { toast } from "react-toastify";


export const registerNewWebsites = (data) => async () => {
    try {

        const response = await registerWebsiteApi(data)
        if (response.success === true) {
            const linksObj = response.data?.links;
            const newObj = linksObj.find((item) => item.rel === 'approve' && item.method === 'GET')
            if (newObj.href) {
                window.open(newObj.href);
            } else {
                toast.error(response.message, { autoClose: 3000 });
            }
        }

    } catch (error) {
        toast.error(error, { autoClose: 3000 });
    }
}


export const getWebsitePrice = () => async (dispatch) => {
    try {
        const response = await getPrice();

        if (response.success === true) {
            dispatch(websitePriceSucccess(response?.data))
        }

    } catch (error) {

        toast.error(error, { autoClose: 3000 });
    }
}

export const deleteRegisterWebsites = (data, history) => async (dispatch) => {
    try {
        const response = await deleteRegisterWebsite(data);
        if (response.success === true) {
            toast.success(response.message, { autoClose: 3000 });
            history('/register');

        } else {
            toast.error(response.message, { autoClose: 3000 });
        }
    } catch (error) {
        toast.error(error, { autoClose: 3000 });
    }
}