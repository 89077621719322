import React, { useEffect, useState, } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Button, Offcanvas, OffcanvasHeader } from 'reactstrap';
import { api } from '../../config';

const FrontProfileDropdown = ({ isactive }) => {
    const navigate = useNavigate();
    const [active, setactive] = useState();
    const [isLoginUser, setIsLoginUser] = useState(null);
    const [isProfileDropdown, setIsProfileDropdown] = useState(false);
    const [isOpenDropdown, setIsOpenDropdown] = useState(false);
    const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);
    const { cmsData } = useSelector((state) => state.CMSF);

    const toggleOffcanvas = () => {
        setIsOffcanvasOpen(!isOffcanvasOpen);
    };

    const toggleProfileDropdown = () => {
        setIsProfileDropdown(!isProfileDropdown);
    };
    const toggleAccountDropdown = () => {
        setIsOpenDropdown(!isOpenDropdown);
    };

    useEffect(() => {
        setactive(isactive)
    })

    const authUser = sessionStorage.getItem('authUser');
    useEffect(() => {
        const loginUSer = JSON.parse(authUser)?.data;
        setIsLoginUser(loginUSer)
    }, [authUser])




    return (
        <React.Fragment>
            <div className='menubar'>
                <Button color="primary" onClick={toggleOffcanvas}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-justify" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M2 12.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5" />
                    </svg>
                </Button>
                <Offcanvas isOpen={isOffcanvasOpen} toggle={toggleOffcanvas} direction="end" >
                    <OffcanvasHeader className="flex items-center space-x-4">
                        <Link className="shrink-0" to='/' >
                            <img src={api.API_URL + '/' + cmsData?.websiteLogo} alt='logo' loading="lazy" style={{
                                width: '90%',
                                height: '60px'
                            }} />
                        </Link>
                    </OffcanvasHeader>
                    {!isLoginUser
                        ?
                        <>
                            <Link className={`text-sm text-gray-500 px-4 py-2 leading-none ${active === 'active-blogs' ? active : ''} `} onClick={() => {
                                setactive('active-blogs')
                            }} to='/blogs' >
                                Blog
                            </Link>
                            <Link className={`text-sm text-gray-500 px-4 py-2 leading-none ${active === 'active-register' ? active : ''} `} onClick={() => {
                                setactive('active-register')
                            }} to='/register'>
                                Submit
                            </Link>
                            <Link className={`text-sm text-gray-500 px-4 py-2 leading-none ${active === 'active-login' ? active : ''} `} onClick={() => {
                                setactive('active-login')
                            }} to='/login'>
                                Log in
                            </Link>

                        </>
                        :
                        <>
                            <Link className={`text-sm text-gray-500 px-4 py-2 leading-none ${active === 'active-blogs' ? active : ''} `} onClick={() => {
                                setactive('active-blogs')
                            }} to='/blogs' >
                                Blog
                            </Link>
                            <Link className={`text-sm text-gray-500 px-4 py-2 leading-none ${active === 'active-bloglist' ? active : ''} `} onClick={() => {
                                setactive('active-bloglist')
                            }} to='/bloglist' >
                                My Blog
                            </Link>
                            <Link className={`text-sm text-gray-500 px-4 py-2 leading-none ${active === 'active-Website' ? active : ''} `} onClick={() => {
                                setactive('active-Website')
                            }} to='/submitList' >
                                My Website
                            </Link>
                            <Link className={`text-sm text-gray-500 px-4 py-2 leading-none ${active === 'active-register' ? active : ''} `} onClick={() => {
                                setactive('active-register')
                            }} to='/register'>
                                Submit
                            </Link>
                            <Link className={`text-sm text-gray-500 px-4 py-2 leading-none ${active === 'active-supporttickets' ? active : ''}`} onClick={() => {
                                setactive('active-supporttickets')
                            }} to='/supporttickets' >
                                Support Ticket
                            </Link>
                            <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="ms-sm-3 header-item px-4 py-2 bg-white"  >
                                <DropdownToggle tag="button" type="button">
                                    <span className="d-flex align-items-center bg-white">
                                        <span className="text-start px-2 py-1 rounded-xl bg-gray-200">
                                            <span className="d-xl-inline-block ms-1 fw-semibold user-name-text text-gray-500 ">Account</span>
                                        </span>
                                    </span>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                    <DropdownItem href={"/changePassword"}>
                                        <i className="bi bi-key"></i> <span className="align-middle">Change Password</span></DropdownItem>
                                    <DropdownItem onClick={() => {
                                        sessionStorage.removeItem("authUser");
                                        navigate('/login')
                                    }}>
                                        <i className="bi bi-box-arrow-right"></i>  <span className="align-middle" data-key="t-logout">Logout</span></DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </>
                    }
                </Offcanvas>
            </div>


            {!isLoginUser
                ?
                <>
                    <Link className={`text-sm text-gray-500 headerlink leading-none ${active === 'active-blogs' ? active : ''} `} onClick={() => {
                        setactive('active-blogs')
                    }} to='/blogs' >
                        Blog
                    </Link>
                    <Link className={`text-sm text-gray-500 headerlink leading-none ${active === 'active-register' ? active : ''} `} onClick={() => {
                        setactive('active-register')
                    }} to='/register'>
                        Submit
                    </Link>
                    <Link className={`text-sm text-gray-500 headerlink leading-none ${active === 'active-login' ? active : ''} `} onClick={() => {
                        setactive('active-login')
                    }} to='/login'>
                        Log in
                    </Link>

                </>
                :
                <>
                    <Link className={`text-sm text-gray-500 headerlink leading-none ${active === 'active-blogs' ? active : ''} `} onClick={() => {
                        setactive('active-blogs')
                    }} to='/blogs' >
                        Blog
                    </Link>
                    <Link className={`text-sm text-gray-500 headerlink leading-none ${active === 'active-bloglist' ? active : ''} `} onClick={() => {
                        setactive('active-bloglist')
                    }} to='/bloglist' >
                        My Blog
                    </Link>
                    <Link className={`text-sm text-gray-500 headerlink leading-none ${active === 'active-Website' ? active : ''} `} onClick={() => {
                        setactive('active-Website')
                    }} to='/submitList' >
                        My Website
                    </Link>
                    <Link className={`text-sm text-gray-500 headerlink leading-none ${active === 'active-register' ? active : ''} `} onClick={() => {
                        setactive('active-register')
                    }} to='/register'>
                        Submit
                    </Link>
                    <Link className={`text-sm text-gray-500 headerlink leading-none ${active === 'active-supporttickets' ? active : ''}`} onClick={() => {
                        setactive('active-supporttickets')
                    }} to='/supporttickets' >
                        Support Ticket
                    </Link>
                    <Dropdown isOpen={isOpenDropdown} toggle={toggleAccountDropdown} className="ms-sm-3 header-item headerlink">
                        <DropdownToggle tag="button" type="button">
                            <span className="d-flex align-items-center bg-white">
                                <span className="text-start px-2 py-1 rounded-xl bg-gray-200">
                                    <span className="d-xl-inline-block ms-1 fw-semibold user-name-text text-gray-500 ">Account</span>
                                </span>
                            </span>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem href={"/changePassword"}>
                                <i className="bi bi-key"></i> <span className="align-middle">Change Password</span></DropdownItem>
                            <DropdownItem onClick={() => {
                                sessionStorage.removeItem("authUser");
                                navigate('/login')
                            }}>
                                <i className="bi bi-box-arrow-right"></i>  <span className="align-middle" data-key="t-logout">Logout</span></DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </>
            }

        </React.Fragment>
    );
};

export default FrontProfileDropdown;