
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Header from '../../../Layouts/FrontHeader';
import Footer from '../../../Layouts/FrontFooter';
import DOMPurify from 'dompurify';
import { useDispatch, useSelector } from 'react-redux';
import { pageSingleViewData } from '../../../slices/thunks';
import Loader from '../../../Components/Common/Loader';

const PrivacyPolicyPage = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const [receivedData, setReceivedData] = useState(null);

    const { pageLinkData } = useSelector((state) => state.Editors);
    const { cmsData } = useSelector((state) => state.CMSF);

    const currentPageURL = location.pathname?.replace('/pages/', '');

    useEffect(() => {
        setReceivedData(pageLinkData)
    }, [pageLinkData])

    useEffect(() => {
        if (currentPageURL) {
            dispatch(pageSingleViewData(currentPageURL))
        }
    }, [currentPageURL, dispatch])

    const myElementRef = useRef(null);
    const [footerHeight, setFooterHeight] = useState(700);

    useEffect(() => {
        if (myElementRef?.current) {
            const elementHeight = myElementRef.current.getBoundingClientRect().height;
            setFooterHeight(elementHeight)
        }
    });
    document.title = `${cmsData?.websiteTitle ? cmsData?.websiteTitle : '1000 Ai Sites'} | ${receivedData?.title ? receivedData?.title : ''}`;
    return (
        <div>

            <div ref={myElementRef}>
                {pageLinkData &&
                    <>
                        <Header />
                        <div className='detilspage'>
                            <div className="px-4 py-20 px-md-5 text-center text-lg-start " >
                                <div className="container">
                                    <div className="row gx-lg-5 align-items-center justify-content-center">
                                        <div className="col-lg-12 mb-lg-0  card">
                                            <div className="">
                                                <div className=" mx-auto px-4">
                                                    <div className='flex-grow'>
                                                        <h1 className="font-bold mt-3 mb-0" style={{ fontSize: '32px', textAlign: "center" }}>{receivedData?.title}</h1>
                                                    </div>
                                                    <div className="mb-6 mt-4">
                                                        <div className='mt-2' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(receivedData?.content) }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer footer_style={footerHeight > window.innerHeight ? 'App-footer-scroll' : ''} />
                    </>
                }
            </div>
        </div>
    );
}

export default PrivacyPolicyPage;