import { createSlice } from "@reduxjs/toolkit";
import { addNewBlog, deletedataBlog, getBlogAllData, getBlogStatusActiveandDeactiveData, updatedataBlog } from './thunk';

export const initialState = {
  blogdetails: [],
  error: {},
  blogTodayRevenue: 0,
  blogMonthRevenue: 0
};

const BlogSlice = createSlice({
  name: "blog",
  initialState,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(getBlogAllData.fulfilled, (state, action) => {
      // console.log("getBlogAllData ", action.payload);
      state.blogdetails = action.payload.data;
      // console.log('action.payload.data :',action.payload.data);
      let Monthlytotal = 0;
      let Todaytotal = 0;
      const today = new Date();

      action?.payload?.data?.map((item, index) => {
        if (new Date(item.createdAt).getMonth() + 1 === new Date().getMonth() + 1) {
          Monthlytotal += item?.BlogPayment_ID?.paymentAmount !== undefined ? item?.BlogPayment_ID?.paymentAmount : 0
        }
        if (new Date(item.createdAt).toDateString() === today.toDateString()) {
          Todaytotal += item?.BlogPayment_ID?.paymentAmount !== undefined ? item?.BlogPayment_ID?.paymentAmount : 0
        }
      })
      state.blogTodayRevenue = Todaytotal;
      state.blogMonthRevenue = Monthlytotal;
    });

    builder.addCase(getBlogAllData.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(getBlogStatusActiveandDeactiveData.fulfilled, (state, action) => {
      state.blogdetails = state.blogdetails.map((blog) =>
        blog._id.toString() === action?.payload?.blog._id.toString() ? { ...blog, ...action?.payload?.blog } : blog
      );
    });

    builder.addCase(getBlogStatusActiveandDeactiveData.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(addNewBlog.fulfilled, (state, action) => {
      state.blogdetails.push(action.payload.blog);
    });

    builder.addCase(addNewBlog.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(updatedataBlog.fulfilled, (state, action) => {
      state.blogdetails = state.blogdetails.map((BlogData) =>
        BlogData._id.toString() === action.payload.blog
          ._id.toString()
          ? {
            ...BlogData, ...action.payload.blog
          }
          : BlogData
      );
    });

    builder.addCase(updatedataBlog.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(deletedataBlog.fulfilled, (state, action) => {
      state.blogdetails = (state.blogdetails || []).filter((BlogData) => BlogData._id.toString() !== action.payload.blog.toString());
    });

    builder.addCase(deletedataBlog.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });
  },
});

export default BlogSlice.reducer;
