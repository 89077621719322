import React, { useState } from 'react';
import { Col, Row, TabContent, TabPane } from 'reactstrap';

import OverviewTab from './OverviewTab';

const Section = () => {

    return (
        <React.Fragment>
            <Row>
                <Col lg={12}>
                    <TabContent activeTab={'1'} className="text-muted">
                        <TabPane tabId="1">
                            <OverviewTab />
                        </TabPane>
                    </TabContent>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default Section;