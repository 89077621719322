import moment from 'moment';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Table } from 'reactstrap';



const TicketDetails = ({ state }) => {

    return (
        <React.Fragment>
            <Col xxl={3}>
                <Card>
                    <CardHeader>
                        <h5 className="card-title mb-0">Ticket Details</h5>
                    </CardHeader>
                    <CardBody>
                        <div className="table-responsive table-card">
                            <Table className="table-borderless align-middle mb-0">
                                <tbody>
                                    <tr>
                                        <td className="fw-medium">Ticket</td>
                                        <td>#{state?.Ticket_ID}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-medium">Client</td>
                                        <td id="t-client">{state?.User_ID?.Email ? state?.User_ID?.Email : <>-</>}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-medium">Ticket Name</td>
                                        <td>{state?.Title}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-medium">Status:</td>
                                        <td>
                                            {
                                                state.Priority === 'Medium' ? <span className="badge rounded-pill bg-success fs-12"> Medium </span>
                                                    : state.Priority === 'High' ? <span className="badge rounded-pill bg-danger fs-12"> High </span>
                                                        : <span className="badge rounded-pill bg-warning fs-12"> Low </span>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="fw-medium">Create Date</td>
                                        <td id="c-date">{moment(state?.createdAt).format("D MMM YYYY")}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
};

export default TicketDetails;