import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { userLoginWithURL } from '../../../slices/FrontView/authentication/thunk';
import { api } from '../../../config';
import { getcmsDetails } from '../../../slices/FrontView/cms/thunk';

const Ssologin = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [loginToken, setLoginToken] = useState(null)
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const loginData = (decodeURIComponent(urlParams.get('token')));

        setLoginToken(loginData)
    }, [])

    const { cmsData } = useSelector((state) => state.CMSF);

    useEffect(() => {
        dispatch(getcmsDetails())
    }, [dispatch])

    const handleGoToDashboard = () => {
        dispatch(userLoginWithURL(loginToken, navigate))
    }
    document.title = `${cmsData?.websiteTitle ? cmsData?.websiteTitle : '1000 Ai Sites'} | Login`;


    return (
        <React.Fragment>
            <div className="auth-page-wrapper">
                <div className="auth-page-content">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <Link to="/dashboard" className="d-inline-block auth-logo">
                                        <img src={api.API_URL + '/' + cmsData?.websiteLogo} alt='logo' loading="lazy" style={{ height: "60px", width: "250px" }} />
                                    </Link>
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">
                                    <CardBody className="p-4 text-center">
                                        <div className="avatar-lg mx-auto mt-2">
                                            <div className="avatar-title bg-light text-primary display-3 rounded-circle">
                                                <i className="ri-checkbox-circle-fill"></i>
                                            </div>
                                        </div>
                                        <div className="mt-4 pt-2">
                                            <h4>Well done !</h4>
                                            <p className="text-muted mx-4">Aww yeah, you successfully read this important message.</p>
                                            <div className="mt-4">
                                                <Button onClick={() => {
                                                    handleGoToDashboard()
                                                }} className="btn btn-primary w-full primary-btn">Back to Dashboard</Button>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </React.Fragment >
    );
};

export default Ssologin;