import image3 from "../../assets/images/companies/img-3.png";
import image4 from "../../assets/images/companies/img-4.png";
import image6 from "../../assets/images/companies/img-6.png";
import image8 from "../../assets/images/companies/img-8.png";

//User Images
import avatar1 from '../../assets/images/users/avatar-1.jpg';
import avatar2 from '../../assets/images/users/avatar-2.jpg';
import avatar3 from '../../assets/images/users/avatar-3.jpg';
import avatar5 from '../../assets/images/users/avatar-5.jpg';
import avatar4 from '../../assets/images/users/avatar-4.jpg';
import avatar6 from '../../assets/images/users/avatar-6.jpg';
import avatar7 from '../../assets/images/users/avatar-7.jpg';
import avatar8 from '../../assets/images/users/avatar-8.jpg';


const apiKey = [
  {
    id: "1",
    app_name: "Streamlab",
    platform: "Android",
    apikey: "fef67078-6fb6-4689-b342-6ddc24481728",
    status: "Deactive",
    create_date: "24 Sep 2022",
    api_request: "80/100",
  },
  {
    id: "2",
    app_name: "Streamlab",
    platform: "Web",
    apikey: "ed4c0d11-7d49-4c94-aae8-83fafb226ee9",
    status: "Active",
    create_date: "24 Sep 2022",
    api_request: "90/95",
  },
];

const projects = [
  {
    id: 1,
    title: "Chat App Update",
    updatedTime: "2 year Ago",
    badgeText: "Inprogress",
    badgeClass: "warning",
    member: [
      {
        id: 1,
        img: avatar1
      },
      {
        id: 2,
        img: avatar3
      }
    ],
    cardBorderColor: "warning",
    memberName: [
      {
        id: 1,
        memberText: "J"
      }
    ]
  },
  {
    id: 2,
    title: "ABC Project Customization",
    updatedTime: "2 month Ago",
    badgeText: "Progress",
    badgeClass: "primary",
    member: [
      {
        id: 1,
        img: avatar8
      },
      {
        id: 2,
        img: avatar7
      },
      {
        id: 3,
        img: avatar6
      },
    ],
    cardBorderColor: "success",
    memberName: [
      {
        id: 1,
        memberText: "2+"
      }
    ]
  },
  {
    id: 3,
    title: "Client - Frank Hook",
    updatedTime: "1 hr Ago",
    badgeText: "New",
    badgeClass: "info",
    member: [
      {
        id: 1,
        img: avatar4
      },
      {
        id: 2,
        img: avatar3
      },

    ],
    cardBorderColor: "info",
    memberName: [
      {
        id: 1,
        memberText: "M"
      }
    ]
  },
  {
    id: 4,
    title: "Velzon Project",
    updatedTime: "11 hr Ago",
    badgeText: "Completed",
    badgeClass: "success",
    member: [
      {
        id: 1,
        img: avatar7
      },
      {
        id: 2,
        img: avatar5
      },

    ],
    cardBorderColor: "primary",
  },
  {
    id: 5,
    title: "Brand Logo Design",
    updatedTime: "10 min Ago",
    badgeText: "New",
    badgeClass: "info",
    member: [
      {
        id: 1,
        img: avatar7
      },
      {
        id: 2,
        img: avatar6
      },

    ],
    cardBorderColor: "danger",
    memberName: [
      {
        id: 1,
        memberText: "E"
      }
    ]
  },
  {
    id: 6,
    title: "Chat App",
    updatedTime: "8 hr Ago",
    badgeText: "Inprogress",
    badgeClass: "warning",
    member: [
      {
        id: 1,
        img: avatar3
      },
      {
        id: 2,
        img: avatar8
      },
    ],
    memberName: [
      {
        id: 1,
        memberText: "R"
      }
    ],
    cardBorderColor: "primary"
  },
  {
    id: 7,
    title: "Project Update",
    updatedTime: "48 min Ago",
    badgeText: "Inprogress",
    badgeClass: "warning",
    member: [
      {
        id: 1,
        img: avatar6
      },
      {
        id: 2,
        img: avatar5
      },
      {
        id: 3,
        img: avatar4
      },
    ],
    cardBorderColor: "warning"
  },
  {
    id: 8,
    title: "Client - Jennifer",
    updatedTime: "30 min Ago",
    badgeText: "Process",
    badgeClass: "primary",
    member: [
      {
        id: 1,
        img: avatar1
      }
    ],
    cardBorderColor: "success"
  },
  {
    id: 9,
    title: "Business Template -UI/UX design",
    updatedTime: "7 month Ago",
    badgeText: "Completed",
    badgeClass: "success",
    member: [
      {
        id: 1,
        img: avatar2
      },
      {
        id: 2,
        img: avatar3
      },
      {
        id: 3,
        img: avatar4
      }
    ],
    cardBorderColor: "info",
    memberName: [
      {
        id: 1,
        memberText: "2+"
      }
    ]
  },
  {
    id: 10,
    title: "Update Project",
    updatedTime: "1 month Ago",
    badgeText: "New",
    badgeClass: "info",
    member: [
      {
        id: 1,
        img: avatar7
      }
    ],
    memberName: [
      {
        id: 1,
        memberText: "A"
      }
    ],
    cardBorderColor: "success"
  },
  {
    id: 11,
    title: "Bank Management System",
    updatedTime: "10 month Ago",
    badgeText: "Completed",
    badgeClass: "success",
    member: [
      {
        id: 1,
        img: avatar7
      },
      {
        id: 2,
        img: avatar6
      },
      {
        id: 3,
        img: avatar5
      }
    ],
    cardBorderColor: "danger",
    memberName: [
      {
        id: 1,
        memberText: "2+"
      }
    ]
  },
  {
    id: 12,
    title: "PSD to HTML Convert",
    updatedTime: "29 min Ago",
    badgeText: "New",
    badgeClass: "info",
    member: [
      {
        id: 1,
        img: avatar7
      }
    ],
    cardBorderColor: "primary"
  },

];

const document = [
  {
    id: 1,
    icon: "ri-file-zip-fill",
    iconBackgroundClass: "primary",
    fileName: "Artboard-documents.zip",
    fileType: "Zip File",
    fileSize: "4.57 MB",
    updatedDate: "12 Dec 2021"
  },
  {
    id: 2,
    icon: "ri-file-pdf-fill",
    iconBackgroundClass: "danger",
    fileName: "Bank Management System",
    fileType: "PDF File",
    fileSize: "8.89 MB",
    updatedDate: "24 Nov 2021"
  },
  {
    id: 3,
    icon: "ri-video-line",
    iconBackgroundClass: "secondary",
    fileName: "Tour-video.mp4",
    fileType: "MP4 File",
    fileSize: "14.62 MB",
    updatedDate: "19 Nov 2021"
  },
  {
    id: 4,
    icon: "ri-file-excel-fill",
    iconBackgroundClass: "secondary",
    fileName: "Tour-video.mp4",
    fileType: "XSL File",
    fileSize: "2.38 KB",
    updatedDate: "14 Nov 2021"
  },
  {
    id: 5,
    icon: "ri-folder-line",
    iconBackgroundClass: "info",
    fileName: "Project Screenshots Collection",
    fileType: "Folder File",
    fileSize: "87.24 MB",
    updatedDate: "08 Nov 2021"
  },
  {
    id: 6,
    icon: "ri-image-2-fill",
    iconBackgroundClass: "danger",
    fileName: "Velzon-logo.png",
    fileType: "PNG File",
    fileSize: "879 KB",
    updatedDate: "02 Nov 2021"
  },
];

const tileBoxes5 = [
  {
    id: 1,
    img: image3,
    title: "Jupyter Notebook ",
    class: "",
    year: "(2-4 Yrs Exp.)",
    subtitle: 'Notebook'
  },
  {
    id: 2,
    img: image4,
    class: "",
    title: "Google Colab",
    year: "(2+ Yrs Exp.)",
    subtitle: 'AI Model'
  },
  {
    id: 3,
    img: image6,
    class: "bg-soft-primary shadow-none bg-opacity-10",
    title: "jupyterLab",
    year: "(2+ Yrs Exp.)",
    subtitle: 'Custom App'
  },
  {
    id: 4,
    img: image8,
    class: "bg-soft-info shadow-none bg-opacity-10",
    title: "Deernote",
    year: "(2+ Yrs Exp.)",
    subtitle: 'AI Model '
  },
];
export { document, projects, apiKey, tileBoxes5 };
