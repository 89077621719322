import React, { useCallback, useEffect, useMemo, useState, } from 'react';
import { Card, CardBody, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Form, Input, Label, Row, UncontrolledDropdown } from 'reactstrap';

import { useDispatch, useSelector } from 'react-redux';
import { addNewEditor, deletedataEditor, getEditordata, updatedataEditor } from '../../../slices/thunks';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import TableContainer from '../../../Components/Common/TableContainer';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { isEmpty } from "lodash";
import DeleteModal from '../../../Components/Common/DeleteModal';
import { api } from '../../../config';


const EditorPages = () => {
    const dispatch = useDispatch();

    const [contentData, setContentData] = useState(null)
    const [Editoris, setEditoris] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const Editors = useSelector((state) => state.Editors.editordetails);
    // console.log('Editors :', Editors);

    useEffect(() => {
        setContentData(Editors)
    }, [Editors])

    useEffect(() => {
        if (!isEmpty(Editors)) {
            setContentData(Editors);
            setIsEdit(false);
        }
    }, [Editors]);


    useEffect(() => {
        dispatch(getEditordata());
    }, [dispatch]);


    const modules = {
        toolbar: [
            [{ header: '1' }, { header: '2' }, { font: [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [
                { list: 'ordered' },
                { list: 'bullet' },
                { indent: '-1' },
                { indent: '+1' },
            ],
            ['link', 'image', 'video'],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'align': [] }],
            ['clean'],
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        },
    }

    const formats = [
        'header',
        'font',
        'size',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
        'video',
        'color',
        'background',
        'align'
    ]

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            _id: (contentData && contentData._id) || null,
            title: (contentData && contentData.title) || '',
            content: (contentData && contentData.content) || '',
        },
        validationSchema: Yup.object({
            title: Yup.string().required("Please Enter Page Title"),
            content: Yup.string().required("Please Enter Page content"),
        }),
        onSubmit: (values) => {
            if (isEdit) {
                const updateData = new FormData();
                updateData.append('id', values._id);
                updateData.append('title', values.title);
                updateData.append('content', values.content);
                dispatch(updatedataEditor(updateData));
            } else {
                const formData = new FormData();
                formData.append('title', values.title);
                formData.append('content', values.content);
                dispatch(addNewEditor(formData));
            }
            validation.resetForm();
        }
    })

    const handleEditPages = useCallback((arg) => {
        const data = arg;
        setContentData({
            _id: data._id,
            title: data.title,
            content: data.content,
            pageLink: data.pageLink,
        });

        setIsEdit(true);

    }, []);

    const handleDeleteContact = () => {
        if (Editoris) {
            dispatch(deletedataEditor(Editoris._id));
            setDeleteModal(false);
        }
    };

    const onClickDelete = (data) => {
        setEditoris(data);
        setDeleteModal(true);
    };

    const handleClick = (dataToSend) => {
        const pageNameWithHyphens = dataToSend?.pageLink?.replace(/[^\w\s]/gi, ' ').replace(/\s+/g, '-').replace(/-+/g, '-').replace(/^-+|-+$/g, '');
        sessionStorage.setItem('myData', JSON.stringify(dataToSend));
        window.open(`/pages/${pageNameWithHyphens}`)
    };

    const columns = useMemo(
        () => [
            {
                Header: <input type="checkbox" className="form-check-input" id="checkBoxAll" onClick={() => { }} />,
                Cell: (cellProps) => {
                    return <input type="checkbox" className="contactCheckBox form-check-input" value={cellProps.row.original._id} onChange={() => { }} />;
                },
                id: '#',
            },
            {
                Header: "Page Title",
                accessor: "title",
                filterable: true,
            },
            {
                Header: "Page Link",
                accessor: "pageLink",
                filterable: true,
                Cell: (cellProps) => {
                    const pageData = cellProps.row.original
                    const pageLinkData = cellProps.value
                    const pageLink = cellProps.data
                    return (
                        // <a href={`http://localhost:3000/pages/${cellProps.value}`} target="_blank" className="link-secondary fw-bold">{`http://localhost:3000/pages/${cellProps.value}`}</a>
                        <div>
                            {
                                pageLink.map((item, index) => {
                                    if (pageLinkData === item.pageLink) {
                                        return (
                                            <div
                                                key={index}
                                                onClick={() => {
                                                    handleClick(item)
                                                }}
                                                className="link-secondary fw-bold"
                                                style={{ cursor: 'pointer' }}
                                            >
                                                {`${api.APP_URL}/pages/${item.pageLink}`}
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                    );
                }
            },
            {
                Header: "Action",
                Cell: (cellProps) => {
                    return (
                        <ul className="list-inline hstack gap-2">
                            <li className="list-inline-item">
                                <UncontrolledDropdown>
                                    <DropdownToggle
                                        href=""
                                        className="btn btn-soft-secondary btn-sm dropdown"
                                        tag="button"
                                    >
                                        <i className="ri-more-fill align-middle"></i>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-end">
                                        <DropdownItem
                                            className="dropdown-item edit-item-btn"
                                            href=""
                                            onClick={() => {
                                                const contactData = cellProps.row.original;
                                                handleEditPages(contactData)
                                            }}
                                        >
                                            <i className="ri-pencil-fill align-middle me-2 text-muted"></i>
                                            Edit
                                        </DropdownItem>
                                        <DropdownItem
                                            className="dropdown-item remove-item-btn"
                                            href=""
                                            onClick={() => { const contactData = cellProps.row.original; onClickDelete(contactData); }}
                                        >
                                            <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>
                                            Delete
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </li>
                        </ul>
                    );
                },
            },
        ],
        []
    );

    document.title = "Admin Panel | Pages";
    return (
        <React.Fragment>
            <div className="page-content">
                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={handleDeleteContact}
                    onCloseClick={() => setDeleteModal(false)}
                />
                <Container fluid>
                    <Row className='justify-content-center'>
                        <Col xxl={12}>
                            <Card>
                                <CardBody>
                                    <Form
                                        className="form-horizontal"
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}
                                    >
                                        <Row>
                                            <Col className="mb-3" lg={6}>
                                                <div>
                                                    <Label htmlFor="title" className="form-label">Page Title</Label>
                                                    <Input
                                                        name="title"
                                                        id="title"
                                                        className="form-control"
                                                        placeholder="Enter Page Title"
                                                        type="text"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.title || ""}
                                                        invalid={validation.touched.title && validation.errors.title ? true : false}
                                                    />
                                                    {validation.touched.title && validation.errors.title ? (
                                                        <p className="text-danger fw-medium fs-13 mt-1">{validation.errors.title}</p>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col className="mb-3" lg={12}>
                                                <div>
                                                    <Label htmlFor="content" className="form-label">Page Content</Label>
                                                    <ReactQuill modules={modules} theme="snow"
                                                        formats={formats}
                                                        value={validation.values.content}
                                                        onChange={(val) => {
                                                            validation.setFieldValue('content', val)
                                                        }}
                                                    />
                                                    {validation.touched.content && validation.errors.content ? (
                                                        <p className="text-danger fw-medium fs-13 mt-1">{validation.errors.content}</p>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col>
                                                <button type="submit" className="btn btn-primary" id="add-btn" > {!!isEdit ? "Update Pages" : "+ Add Pages"} </button>
                                            </Col>
                                        </Row>

                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xxl={12}>
                            <Card>
                                <CardBody>
                                    <div>
                                        {Editors.length ? (
                                            <TableContainer
                                                columns={columns}
                                                data={Editors}
                                                isGlobalFilter={true}
                                                isAddUserList={false}
                                                customPageSize={8}
                                                className="custom-header-css"
                                                divClass="table-responsive table-card mb-3"
                                                tableClass="align-middle table-nowrap"
                                                theadClass="table-light"
                                                isContactsFilter={false}
                                                SearchPlaceholder='Search for Editors...'
                                            />
                                        ) : (
                                            <div style={{ color: "rgb(135, 138, 153)" }} className="text-center fs-18 fw-bold">Page Not Available</div>
                                        )}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default EditorPages;