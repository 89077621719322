import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../../../Layouts/FrontHeader';
import Footer from '../../../Layouts/FrontFooter';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { changePassword } from '../../../slices/FrontView/authentication/thunk';

const FChangePassword = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { cmsData } = useSelector((state) => state.CMSF);

    const data = JSON.parse(sessionStorage.getItem("authUser"))?.data;
    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            oldpassword: '',
            newpassword: '',
            confirmpassword: '',
        },
        validationSchema: Yup.object({
            oldpassword: Yup.string().required("Please Enter Your Old password"),
            newpassword: Yup.string().required("Please Enter Your new password"),
            confirmpassword: Yup.string()
                .oneOf([Yup.ref('newpassword'), null], 'Passwords must match')
                .required("Confirm Password Required"),
        }),
        onSubmit: (values) => {
            const pwdObj = {
                id: data._id,
                oldPassword: values.oldpassword,
                newPassword: values.newpassword,
                confirmPassword: values.confirmpassword
            }
            dispatch(changePassword(pwdObj, navigate));


        }
    });
    document.title = `${cmsData?.websiteTitle ? cmsData?.websiteTitle : '1000 Ai Sites'} | Change Password`;

    return (
        <div>
            <Header />
            <section>
                <div className="lg:px-4 py-20 px-md-5 text-center text-lg-start  login-forget"  >
                    <div className="container">
                        <div className="row gx-lg-5 align-items-center justify-content-center">
                            <div className="col-lg-5 mb-5 mb-lg-0 ">
                                <div className="card ">
                                    <div className="card-body py-5 px-md-5">
                                        <h1 className="text-center font-bold mb-4">
                                            Change Password
                                        </h1>
                                        <form
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                validation.handleSubmit();
                                                return false;
                                            }}
                                            action="#">
                                            <div className="form-outline mb-4 relative">
                                                <label htmlFor="oldpassword" className="form-label" >Enter your Old Password</label>

                                                <input id="oldpassword" className="form-control input" type="password" name="oldpassword" placeholder="Enter your Old Password"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.oldpassword || ""}
                                                    invalid={
                                                        validation.touched.oldpassword && validation.errors.oldpassword ? true : false
                                                    }
                                                />
                                                <div className='error'>
                                                    <span >{validation.errors.oldpassword && validation.touched.oldpassword ? validation.errors.oldpassword : ''}</span>

                                                </div>
                                            </div>
                                            <div className="form-outline mb-4 relative">
                                                <label htmlFor="newpassword" className="form-label" >Enter your New Password</label>

                                                <input id="newpassword" className="form-control input" type="password" name="newpassword" placeholder="Enter your New Password"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.newpassword || ""}
                                                    invalid={
                                                        validation.touched.newpassword && validation.errors.newpassword ? true : false
                                                    }
                                                />
                                                <div className='error'>
                                                    <span >{validation.errors.newpassword && validation.touched.newpassword ? validation.errors.newpassword : ''}</span>

                                                </div>
                                            </div>
                                            <div className="form-outline mb-4 relative">
                                                <label htmlFor="confirmpassword" className="form-label" >Enter your Confirm Password</label>

                                                <input id="confirmpassword" className="form-control input" type="password" name="confirmpassword" placeholder="Enter your Confirm Password"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.confirmpassword || ""}
                                                    invalid={
                                                        validation.touched.confirmpassword && validation.errors.confirmpassword ? true : false
                                                    }
                                                />
                                                <div className='error'>
                                                    <span >{validation.errors.confirmpassword && validation.touched.confirmpassword ? validation.errors.confirmpassword : ''}</span>

                                                </div>
                                            </div>

                                            <button type="submit" className="btn btn-primary btn-block mb-4 primary-btn">
                                                Change Password
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <Footer websiteList={'footerList'} />
        </div>
    );
}

export default FChangePassword;