import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    user: {},
    error: "", // for error message
    websitePlan: []

};

const submitSitesSlice = createSlice({
    name: "submitSites",
    initialState,
    reducers: {

        addSubmitSites(state, action) {
            // state.forgetSuccessMsg = action.payload 
        },
        websitePriceSucccess(state, action) {
            state.websitePlan = action.payload
        }


    },
});

export const {
    addSubmitSites,
    websitePriceSucccess
} = submitSitesSlice.actions

export default submitSitesSlice.reducer;