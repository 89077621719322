import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";

import {
    Container,
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Button,
    Label,
    Input,
    FormFeedback,
    Form,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";
import { AdminChangePassword } from '../../../../slices/thunks';
import withRouter from '../../../../Components/Common/withRouter';

// actions

const ChangePassword = (props) => {
    const dispatch = useDispatch();

    const [id, setID] = useState("");

    // console.log(phonenumber, designation, website, city, country, zip_code, description);

    const obj = JSON.parse(sessionStorage.getItem("authAdmin"));

    useEffect(() => {
        if (sessionStorage.getItem("authAdmin")) {
            setID(obj.data._id);
        }
    }, [obj]);

    const formik = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed

        initialValues: {
            OldPassword: '',
            NewPassword: '',
            ConfirmPassword: ''
        },
        validationSchema: Yup.object({
            OldPassword: Yup.string().required("Please Enter Your old Password"),
            NewPassword: Yup.string().required("Please Enter Your New password"),
            ConfirmPassword: Yup.string().required("Please Enter Your Confirm Password").when("NewPassword", {
                is: val => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf(
                    [Yup.ref("NewPassword")],
                    "Confirm Password Isn't Match"
                )
            })
        }),
        onSubmit: (values, action) => {
            const changePWD = {
                id: id,
                OldPassword: values.OldPassword,
                NewPassword: values.NewPassword,
                ConfirmPassword: values.ConfirmPassword
            }
            dispatch(AdminChangePassword(changePWD, props.router.navigate));
            action.resetForm();
        }
    });

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = formik;

    document.title = "Admin Panel | Change Password";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row className='justify-content-center'>
                        <Col xxl={5}>
                            <Card>
                                <CardBody>
                                    <Form
                                        className="form-horizontal"
                                        action='#'
                                        method='post'
                                        onSubmit={handleSubmit}
                                    >
                                        <div className="form-group">
                                            <Row>
                                                <Col className="mb-3" lg={12}>
                                                    <div>
                                                        <Label htmlFor="OldPassword" className="form-label">Old
                                                            Password*</Label>
                                                        <Input
                                                            type="password"
                                                            name="OldPassword"
                                                            className="form-control"
                                                            id="OldPassword"
                                                            placeholder="Enter current password"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.OldPassword || ""}
                                                            invalid={
                                                                touched.OldPassword && errors.OldPassword ? true : false
                                                            }
                                                        />
                                                        {touched.OldPassword && errors.OldPassword ? (
                                                            <FormFeedback type="invalid"><div>{errors.OldPassword}</div></FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col className="mb-3" lg={12}>
                                                    <div>
                                                        <Label htmlFor="NewPassword" className="form-label">New
                                                            Password*</Label>
                                                        <Input
                                                            type="password"
                                                            name='NewPassword'
                                                            className="form-control"
                                                            id="NewPassword"
                                                            placeholder="Enter new password"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.NewPassword || ""}
                                                            invalid={
                                                                touched.NewPassword && errors.NewPassword ? true : false
                                                            }
                                                        />
                                                        {touched.NewPassword && errors.NewPassword ? (
                                                            <FormFeedback type="invalid"><div>{errors.NewPassword}</div></FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col className="mb-3" lg={12}>
                                                    <div>
                                                        <Label htmlFor="ConfirmPassword" className="form-label">Confirm Password*</Label>
                                                        <Input
                                                            type="password"
                                                            name='ConfirmPassword'
                                                            className="form-control"
                                                            id="ConfirmPassword"
                                                            placeholder="Enter your Confirm Password"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.ConfirmPassword || ""}
                                                            invalid={
                                                                touched.ConfirmPassword && errors.ConfirmPassword ? true : false
                                                            }
                                                        />
                                                        {touched.ConfirmPassword && errors.ConfirmPassword ? (
                                                            <FormFeedback type="invalid"><div>{errors.ConfirmPassword}</div></FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="mt-4">
                                            <Button type="submit" color="primary">
                                                Change Password
                                            </Button>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withRouter(ChangePassword);
