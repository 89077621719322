import { createSlice } from "@reduxjs/toolkit";
import { getDetailsAndProductdata, addNewDetailsAndProduct, updatedataDetailsAndProduct, deletedataDetailsAndProduct } from './thunk';

export const initialState = {
  detailandproductdetails: [],
  error: {}
};

const DetailsAndProductSlice = createSlice({
  name: "detailandproduct",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDetailsAndProductdata.fulfilled, (state, action) => {
      state.detailandproductdetails = action.payload.offer;
    });

    builder.addCase(getDetailsAndProductdata.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(addNewDetailsAndProduct.fulfilled, (state, action) => {
      state.detailandproductdetails.push(action.payload.newOffer);
    });

    builder.addCase(addNewDetailsAndProduct.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(updatedataDetailsAndProduct.fulfilled, (state, action) => {
      state.detailandproductdetails = state.detailandproductdetails.map((DetailAndProduct) =>
        DetailAndProduct._id.toString() === action.payload.offer
          ._id.toString()
          ? {
            ...DetailAndProduct, ...action.payload.offer
          }
          : DetailAndProduct
      );
    });

    builder.addCase(updatedataDetailsAndProduct.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(deletedataDetailsAndProduct.fulfilled, (state, action) => {
      state.detailandproductdetails = (state.detailandproductdetails || []).filter((DetailAndProduct) => DetailAndProduct._id.toString() !== action.payload.DetailsAndProduct.toString());
    });

    builder.addCase(deletedataDetailsAndProduct.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });
  },
});

export default DetailsAndProductSlice.reducer;
