import React, { useEffect, useState, useCallback, useMemo } from "react";
import { isEmpty, } from "lodash";

import {
    Col,
    Container,
    Row,
    Card,
    CardHeader,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,

} from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import TableContainer from "../../../Components/Common/TableContainer";
import avatar1 from '../../../assets/images/users/Profile Image.jpg';
import { getBlogAllData, getBlogStatusActiveandDeactiveData, deletedataBlog } from "../../../slices/thunks";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import moment from "moment";
import DeleteModal from "../../../Components/Common/DeleteModal";
import { Link, useNavigate } from "react-router-dom";
import { api } from "../../../config";
import { clearBlogsByTitleSuccess } from "../../../slices/FrontView/blogs/reducer";

const Blog = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const Blog = useSelector((state) => state.Blog.blogdetails);

    useEffect(() => {
        dispatch(getBlogAllData());
        dispatch(clearBlogsByTitleSuccess())
    }, [dispatch]);

    const [Blogis, setBlogis] = useState(null);

    useEffect(() => {
        setBlogis(Blog)
    }, [Blog])

    console.log('Blog', Blog);
    useEffect(() => {
        if (!isEmpty(Blog)) {
            setBlogis(Blog);
        }
    }, [Blog]);

    //delete Conatct
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteModalMulti, setDeleteModalMulti] = useState(false);

    // Checked All
    const checkedAll = useCallback(() => {
        const checkall = document.getElementById("checkBoxAll");
        const ele = document.querySelectorAll(".contactCheckBox");

        if (checkall.checked) {
            ele.forEach((ele) => {
                ele.checked = true;
            });
        } else {
            ele.forEach((ele) => {
                ele.checked = false;
            });
        }
        deleteCheckbox();
    }, []);

    // Delete Multiple
    const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
    const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

    const deleteMultiple = () => {
        const checkall = document.getElementById("checkBoxAll");
        selectedCheckBoxDelete.forEach((element) => {
            dispatch(deletedataBlog(element.value));
            setTimeout(() => { toast.clearWaitingQueue(); }, 3000);
        });
        setIsMultiDeleteButton(false);
        checkall.checked = false;
    };

    const deleteCheckbox = () => {
        const ele = document.querySelectorAll(".contactCheckBox:checked");
        ele.length > 0 ? setIsMultiDeleteButton(true) : setIsMultiDeleteButton(false);
        setSelectedCheckBoxDelete(ele);
    };

    // Delete Data
    const handleDeleteContact = () => {
        if (Blogis) {
            dispatch(deletedataBlog(Blogis._id));
            setDeleteModal(false);
        }
    };

    const onClickDelete = (data) => {
        setBlogis(data)
        setDeleteModal(true);
    };


    const handleValidDate = date => {
        const date1 = moment(new Date(date)).format("DD MMM Y");
        return date1;
    };

    const columns = useMemo(
        () => [
            {
                Header: <input type="checkbox" className="form-check-input" id="checkBoxAll" onClick={() => checkedAll()} />,
                Cell: (cellProps) => {
                    return <input type="checkbox" className="contactCheckBox form-check-input" value={cellProps.row.original._id} onChange={() => deleteCheckbox()} />;
                },
                id: '#',
            },
            {
                Header: "Date",
                accessor: "createdAt",
                filterable: true,
                Cell: (cellProps) => {
                    return (
                        <>
                            {
                                handleValidDate(cellProps.value)
                            }
                        </>
                    )
                }
            },
            {
                Header: "Blog Image",
                accessor: "blog_image",
                filterable: true,
                Cell: (cellProps) => {

                    const ProfileImage = `${cellProps.row.original?.blog_image}`
                    return ProfileImage ?
                        <>
                            <img
                                src={api.API_URL + '/' + ProfileImage}
                                alt=""
                                // height="45"
                                className="avatar-sm"
                            />
                        </>
                        :
                        <>
                            <img
                                src={avatar1}
                                alt=""
                                // height="45"
                                className="avatar-sm"
                            />
                        </>
                },
            },
            {
                Header: "Email",
                accessor: "Email",
                filterable: true,
                Cell: (cellProps) => {
                    const Email = cellProps.row.original?.user_ID?.Email;
                    return (
                        <div>{Email ? Email : <>-</>}</div>
                    )
                }
            },
            {
                Header: "Preview",
                Cell: (cellProps) => {
                    const pageData = cellProps.row.original;
                    const BlogTitleWithHyphens = pageData?.title?.replace(/[^\w\s]/gi, ' ').replace(/\s+/g, '-').replace(/-+/g, '-').replace(/^-+|-+$/g, '');
                    return (
                        <Link
                            to={`/blog/${BlogTitleWithHyphens}`}
                            target="_blank" >
                            <button className="btn btn-primary add-btn">
                                Preview
                            </button>
                        </Link>
                    );
                }
            },
            {
                Header: "Blog Title",
                accessor: "title",
                filterable: true,
                Cell: (cell) => {
                    return (
                        <div className="text-truncate">
                            {cell.value ?
                                cell.value?.length < 80
                                    ? cell.value
                                    : cell.value.substring(0, 80) + '...'
                                : <>-</>}
                        </div>
                    )
                }
            },
            {
                Header: "Short Description",
                accessor: "shortDetail",
                filterable: true,
                Cell: (cellProps) => {
                    return (
                        <div className="text-truncate">
                            {cellProps.value ?
                                cellProps.value?.length < 40
                                    ? cellProps.value
                                    : cellProps.value.substring(0, 40) + '...'
                                : <>-</>}
                        </div>
                    )
                }
            },
            {
                Header: "Category",
                accessor: "categories",
                Cell: (cellProps) => {
                    const categories = cellProps.value;

                    return (
                        <div>
                            {categories?.map((item, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <span className="badge badge-soft-primary fs-12 me-1 mb-1">
                                            {item}
                                        </span>
                                        {(index + 1) % 2 === 0 && <br />} {/* Break to new line every 2 items */}
                                    </React.Fragment>
                                )
                            })}
                        </div>
                    );
                },
            },
            {
                Header: "Payment Amount",
                accessor: "paymentAmount",
                filterable: true,
                Cell: (cellProps) => {
                    const amount = cellProps.row.original?.BlogPayment_ID?.paymentAmount
                    return (
                        amount ? <span className="text-uppercase">${amount}</span> : <span className="text-uppercase">$0</span>
                    )
                }
            },
            {
                Header: "Payment Id",
                accessor: "payment_id",
                filterable: true,
                Cell: (cellProps) => {
                    const payment_id = cellProps.row.original?.BlogPayment_ID?.payment_id
                    return (
                        <Link className="link-secondary"
                            to={`https://www.sandbox.paypal.com/activity/payment/${payment_id}`}
                            target="_blank"
                        >
                            <div>  {payment_id}</div>
                        </Link>
                    )
                }
            },
            {
                Header: "Payment Status",
                accessor: "paymentStatus",
                Cell: (cell) => {
                    switch (cell.value) {
                        case "Paid":
                        case "paid":
                            return <span className="badge text-uppercase fs-12 badge-soft-success"><i className="ri-checkbox-circle-line align-bottom"></i>  paid </span>;
                        case "Completed":
                        case "completed":
                            return <span className="badge text-uppercase fs-12 badge-soft-success"><i className="ri-checkbox-circle-line align-bottom"></i> Completed </span>;
                        case "Cancelled":
                        case "cancelled":
                            return <span className="badge text-uppercase fs-12 badge-soft-danger"><i className="ri-close-circle-line align-bottom"></i>  Cancelled </span>;
                        case "Failed":
                        case "failed":
                            return <span className="badge text-uppercase fs-12 badge-soft-danger"><i className="ri-close-circle-line align-bottom"></i> Failed </span>;
                        default:
                            return <span className="badge text-uppercase fs-12 badge-soft-warning"><i className="ri-time-line align-bottom"></i>  Pending </span>;
                    }
                }
            },
            {
                Header: "Status",
                accessor: "status",
                Cell: (cell) => {
                    switch (cell.value) {
                        case "Approved":
                            return <span className="badge text-uppercase badge-soft-success"><i className="ri-checkbox-circle-line align-bottom"></i> Approved </span>;
                        case "Rejected":
                            return <span className="badge text-uppercase badge-soft-danger"><i className="ri-close-circle-line align-bottom"></i> Rejected </span>;
                        default:
                            return <span className="badge text-uppercase badge-soft-warning">Pending </span>;
                    }
                }
            },
            {
                Header: "Action",
                Cell: (cellProps) => {
                    const statusdata = cellProps.row.original?.status
                    return (
                        <ul className="list-inline hstack gap-2">
                            <li className="list-inline-item">
                                <UncontrolledDropdown>
                                    <DropdownToggle
                                        href=""
                                        className="btn btn-soft-secondary btn-sm dropdown"
                                        tag="button"
                                    >
                                        <i className="ri-more-fill align-middle"></i>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-end">
                                        <DropdownItem
                                            className="dropdown-item edit-item-btn"
                                            href=""
                                            onClick={() => {
                                                const contactData = cellProps.row.original;
                                                navigate('/admin/blog-form', {
                                                    state: contactData,
                                                })
                                            }}
                                        >
                                            <i className="ri-pencil-fill align-middle me-2 text-muted"></i>
                                            Edit
                                        </DropdownItem>
                                        <DropdownItem
                                            className="dropdown-item edit-item-btn"
                                            href=""
                                            onClick={() => {
                                                const newStatus = statusdata === "Approved" ? "Rejected" : "Approved";
                                                dispatch(getBlogStatusActiveandDeactiveData({ id: cellProps.row.original?._id, newStatus }));
                                            }}
                                        >
                                            <i className={`ri-${statusdata === "Approved" ? "close-circle-line" : "checkbox-circle-line"} align-bottom me-2 text-muted`}></i>{" "}
                                            {statusdata === "Approved" ? "Rejected" : "Approved"}
                                        </DropdownItem>
                                        <DropdownItem
                                            className="dropdown-item remove-item-btn"
                                            href=""
                                            onClick={() => { const contactData = cellProps.row.original; onClickDelete(contactData); }}
                                        >
                                            <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>
                                            Delete
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </li>
                        </ul>
                    );
                },
            },
        ],
        []
    );

    document.title = "Admin Panel | Blog";
    return (
        <React.Fragment>
            <div className="page-content">
                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={handleDeleteContact}
                    onCloseClick={() => setDeleteModal(false)}
                />
                <DeleteModal
                    show={deleteModalMulti}
                    onDeleteClick={() => {
                        deleteMultiple();
                        setDeleteModalMulti(false);
                    }}
                    onCloseClick={() => setDeleteModalMulti(false)}
                />
                <Container fluid>
                    <BreadCrumb title="Blog" pageTitle="Dashboards" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <div className="d-flex align-items-center flex-wrap gap-2">
                                        <div className="flex-grow-1">
                                            <Link to="/admin/blog-form" >
                                                <button className="btn btn-primary add-btn">
                                                    <i className="ri-add-fill me-1 align-bottom"></i>Create New Blog Post
                                                </button>
                                            </Link>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <div className="hstack text-nowrap gap-2">
                                                {isMultiDeleteButton && <button className="btn btn-secondary" onClick={() => setDeleteModalMulti(true)}><i className="ri-delete-bin-2-line"></i></button>}
                                            </div>
                                        </div>
                                    </div>
                                </CardHeader>
                            </Card>
                        </Col>
                        <Col xxl={12}>
                            <Card id="Blog">
                                <CardBody>
                                    <div>
                                        {Blog.length ? (
                                            <TableContainer
                                                columns={columns}
                                                data={(Blog || [])}
                                                isGlobalFilter={true}
                                                isAddUserList={false}
                                                customPageSize={8}
                                                className="custom-header-css"
                                                divClass="table-responsive table-card mb-3"
                                                tableClass="align-middle table-nowrap"
                                                theadClass="table-light"
                                                isContactsFilter={false}
                                                SearchPlaceholder='Search for Blog...'
                                            />
                                        ) : <div style={{ color: "rgb(135, 138, 153)" }} className="text-center fs-18 fw-bold">Blog Not Available</div>
                                        }
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
};

export default Blog;