import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Navdata = () => {
    const history = useNavigate();

    //New data
    const [isDashboard2, setIsDashboard2] = useState(false);
    const [isPurchaseHistoryManagement, setIsPurchaseHistoryManagement] = useState(false);
    const [isManageApps, setIsManageApps] = useState(false);
    const [isManagebilling, setIsManageBilling] = useState(false);
    const [isManageprofile, setIsManageProfile] = useState(false);
    const [isSettings, setIsSettings] = useState(false);
    const [isUsers, setIsUsers] = useState(false);
    const [isSubmittedSites, setIsSubmittedSites] = useState(false);
    const [isPaymentHistory, setIsPaymentHistory] = useState(false);
    const [isCMS, setIsCMS] = useState(false);
    const [isPlanandPricing, setIsPlanandPricing] = useState(false);
    const [isCategory, setIsCategory] = useState(false);
    const [isSupportTickets, setIsSupportTickets] = useState(false);
    const [isPages, setIsPages] = useState(false);
    const [isBlog, setIsBlog] = useState(false);

    //state data
    const [isDashboard, setIsDashboard] = useState(false);
    const [isApps, setIsApps] = useState(false);
    const [isAuth, setIsAuth] = useState(false);
    const [isMultiLevel, setIsMultiLevel] = useState(false);

    const [iscurrentState, setIscurrentState] = useState('Dashboard');

    function updateIconSidebar(e) {
        if (e && e.target && e.target.getAttribute("subitems")) {
            const ul = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("subitems");
                if (document.getElementById(id))
                    document.getElementById(id).classList.remove("show");
            });
        }
    }

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
        if (iscurrentState !== 'Dashboard2') {
            setIsDashboard2(false);
        }
        if (iscurrentState !== 'Manage Apps') {
            setIsManageApps(false);
        }
        if (iscurrentState !== 'Users') {
            setIsUsers(false);
        }
        if (iscurrentState !== 'Submitted Sites') {
            setIsSubmittedSites(false);
        }
        if (iscurrentState !== 'Payment History') {
            setIsPaymentHistory(false);
        }
        if (iscurrentState !== 'CMS') {
            setIsCMS(false);
        }
        if (iscurrentState !== 'Blog') {
            setIsBlog(false);
        }
        if (iscurrentState !== 'Plan and Pricing') {
            setIsPlanandPricing(false);
        }
        if (iscurrentState !== 'Category') {
            setIsCategory(false);
        }
        if (iscurrentState !== 'Purchase History Management') {
            setIsPurchaseHistoryManagement(false);
        }

        if (iscurrentState !== 'Manage Billing') {
            setIsManageBilling(false);
        }

        if (iscurrentState !== 'Manage Profile') {
            setIsManageProfile(false);
        }
        if (iscurrentState !== 'Settings') {
            setIsSettings(false);
        }


        if (iscurrentState !== 'Dashboard') {
            setIsDashboard(false);
        }
        if (iscurrentState !== 'Apps') {
            setIsApps(false);
        }
        if (iscurrentState !== 'Auth') {
            setIsAuth(false);
        }
        if (iscurrentState !== 'Pages') {
            setIsPages(false);
        }


        if (iscurrentState === 'Widgets') {
            history("/widgets");
            document.body.classList.add('twocolumn-panel');
        }
    }, [
        history,
        iscurrentState,
        isManageApps,
        isUsers,
        isSubmittedSites,
        isPaymentHistory,
        isCMS,
        isBlog,
        isPlanandPricing,
        isDashboard2,
        isPurchaseHistoryManagement,

        isManagebilling,

        isManageprofile,
        isSettings,

        isDashboard,
        isApps,
        isAuth,
        isPages,

        isMultiLevel
    ]);

    const menuItems = [
        {
            label: "Menu",
            isHeader: true,
        },
        {
            id: "dashboard2",
            label: "Dashboards",
            icon: "ri-dashboard-2-line",
            link: "/admin/dashboard",
            stateVariables: isDashboard2,
            click: function (e) {
                e.preventDefault();
                setIsDashboard2(!isDashboard2);
                setIscurrentState('Dashboard2');
                updateIconSidebar(e);
            },
        },
        {
            id: "users",
            label: "Users",
            icon: "ri-user-line",
            link: "/admin/users",
            stateVariables: isManageApps,
            click: function (e) {
                e.preventDefault();
                setIsUsers(!isManageApps);
                setIscurrentState('Users');
                updateIconSidebar(e);
            },
        },
        {
            id: "ai/mlmodels",
            label: "Submitted Sites",
            icon: " ri-file-text-line",
            link: "/admin/submitted-sites",
            stateVariables: isPurchaseHistoryManagement,
            click: function (e) {
                e.preventDefault();
                setIsSubmittedSites(!isPurchaseHistoryManagement);
                setIscurrentState('Submitted Sites');
                updateIconSidebar(e);
            },
        },
        {
            id: "apikey",
            label: "Payment History",
            icon: "ri-secure-payment-line",
            link: "/admin/payment-history",
            stateVariables: isManageApps,
            click: function (e) {
                e.preventDefault();
                setIsPaymentHistory(!isManageApps);
                setIscurrentState('Payment History');
                updateIconSidebar(e);
            },
        },
        {
            id: "cms",
            label: "CMS",
            icon: "ri-home-gear-line",
            link: "/admin/cms",
            stateVariables: isCMS,
            click: function (e) {
                e.preventDefault();
                setIsCMS(!isCMS);
                setIscurrentState('CMS');
                updateIconSidebar(e);
            },
        },
        {
            id: "plan_and_pricing",
            label: "Plan and Pricing",
            icon: "ri-price-tag-3-line",
            link: "/admin/plan-and-pricing",
            stateVariables: isPlanandPricing,
            click: function (e) {
                e.preventDefault();
                setIsPlanandPricing(!isPlanandPricing);
                setIscurrentState('Plan and Pricing');
                updateIconSidebar(e);
            },
        },
        {
            id: "category",
            label: "Category",
            icon: "bx bx-category",
            link: "/admin/category",
            stateVariables: isCategory,
            click: function (e) {
                e.preventDefault();
                setIsCategory(!isCategory);
                setIscurrentState('Category');
                updateIconSidebar(e);
            },
        },
        {
            id: "supportTickets",
            label: "Support Tickets",
            icon: " ri-ticket-2-line",
            link: "/admin/support-tickets-list",
            click: function (e) {
                e.preventDefault();
                setIsSupportTickets(!isSupportTickets);
            },
            stateVariables: isSupportTickets,
        },
        {
            id: "pages",
            label: "Pages",
            icon: " ri-pages-line",
            link: "/admin/editor-page",
            click: function (e) {
                e.preventDefault();
                setIsPages(!isPages);
            },
            stateVariables: isPages,
        },
        {
            id: "blog",
            label: "Blog",
            icon: " las la-blog",
            link: "/admin/blog",
            click: function (e) {
                e.preventDefault();
                setIsBlog(!isBlog);
            },
            stateVariables: isBlog,
        },
    ];
    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;