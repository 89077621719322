import { createAsyncThunk } from "@reduxjs/toolkit";

//Include Both Helper File with needed methods
import { getSingleTransaction, getTransaction } from "../../../helpers/backend_helper";

export const getTransactionData = createAsyncThunk("submittedsites/getTransactionData", async () => {
  try {
    const response = await getTransaction();
    return response;
  } catch (error) {
    return error;
  }
})

export const getSingleTransactionData = createAsyncThunk("submittedsites/getSingleTransactionData", async ({ transactionId }) => {
  try {
    const response = await getSingleTransaction(transactionId);
    return response;
  } catch (error) {
    return error;
  }
});