import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import * as moment from "moment";

// Import Images
import dummyImg from "../../../assets/images/website_background.png";

import userimage1 from "../../../assets/images/product-development.png";

import {
    Col,
    Container,
    Row,
    Card,
    CardHeader,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Label,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    ModalFooter,
    Table,
    FormFeedback
} from "reactstrap";
import Select from "react-select";

import BreadCrumb from "../../../Components/Common/BreadCrumb";
import DeleteModal from "../../../Components/Common/DeleteModal";

import { useDispatch, useSelector } from "react-redux";
import TableContainer from "../../../Components/Common/TableContainer";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import avatar1 from '../../../assets/images/users/Placeholder_view_vector.svg.png';
import { deletedataSubmittedSites, getAdminUserdata, getAllSubmittedSitesdata, getCategorydata, getSiteStatusActiveandDeactiveData, getSubmittedSitesAllWebsite, updatedataSubmittedSites } from "../../../slices/thunks";
import { api } from "../../../config";
import { clearWebsiteByNamesSuccess } from "../../../slices/FrontView/dashboard/reducer";

const SubmittedSites = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;

    const SubmitSites = useSelector((state) => state.SubmitSites.allSubmittedSitesData);
    const AdminUser = useSelector((state) => state.AdminUser.adminusersdetails);
    const Category = useSelector((state) => state.Category.categorydetails);

    const [isEdit, setIsEdit] = useState(false);
    const [subitted_Sites, setSubitted_Sites] = useState(null);
    const [isImage, setIsImage] = useState(dummyImg);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteModalMulti, setDeleteModalMulti] = useState(false);
    const [modal, setModal] = useState(false);
    useEffect(() => {
        if (state === null) {
            dispatch(getAllSubmittedSitesdata());
        } else {
            dispatch(getSubmittedSitesAllWebsite({ Allid: state._id }));
        }
    }, [dispatch, state]);


    useEffect(() => {
        dispatch(getCategorydata());
        dispatch(clearWebsiteByNamesSuccess());
    }, [dispatch]);



    useEffect(() => {
        setSubitted_Sites(SubmitSites)
    }, [SubmitSites])

    useEffect(() => {
        if (!isEmpty(SubmitSites)) {
            setSubitted_Sites(SubmitSites);
            setIsEdit(false);
        }
    }, [SubmitSites]);

    const toggle = useCallback(() => {
        if (modal) {
            setModal(false);
            setIsEdit(false);
            setSubitted_Sites(null)
        } else {
            setModal(true);
        }
        setIsImage(dummyImg);
    }, [modal]);


    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            _id: (subitted_Sites && subitted_Sites._id) || null,
            Site_Name: (subitted_Sites && subitted_Sites.Site_Name) || '',
            Submitted_By: (subitted_Sites && subitted_Sites.Submitted_By) || (!isEdit && location?.state?._id),
            Site_Link: (subitted_Sites && subitted_Sites.Site_Link) || '',
            Logo: (subitted_Sites && subitted_Sites.Logo) || '',
            Short_Description: (subitted_Sites && subitted_Sites.Short_Description) || '',
            Long_Description: (subitted_Sites && subitted_Sites.Long_Description) || '',
            Category: (subitted_Sites && subitted_Sites.Category) || [],
            Payment_Status: (subitted_Sites && subitted_Sites.Payment_Status) || "completed",
            TOP_10: (subitted_Sites && subitted_Sites.TOP_10) || 'true',
            site_type: (subitted_Sites && subitted_Sites.site_type) || 'normal',
        },
        validationSchema: Yup.object({
            Site_Name: Yup.string().required("Please Enter Site Name"),
            Submitted_By: Yup.string().required("Please Select Your Email"),
            Site_Link: Yup.string().required("Please Enter Site Link"),
            Short_Description: Yup.string().required("Please Enter Short Description"),
            Long_Description: Yup.string().required("Please Enter Long Description")
        }).shape({
            Logo: Yup.string().required("Please Enter Website Logo"),

        }),
        onSubmit: (values) => {

            if (isEdit) {

                const formData = new FormData();

                formData.append('id', values._id);
                formData.append('Site_Name', values.Site_Name);
                formData.append('Submitted_By', values.Submitted_By);
                formData.append('Site_Link', values.Site_Link);
                formData.append('Logo', values.Logo);
                formData.append('Short_Description', values.Short_Description);
                formData.append('Long_Description', values.Long_Description);
                formData.append('Category', values.Category);
                formData.append('Payment_Status', values.Payment_Status);
                formData.append('TOP_10', values.TOP_10);
                formData.append('site_type', values.site_type);
                formData.append('websiteId', values._id);
                dispatch(updatedataSubmittedSites(formData))
                setModal(false);
            } else {
                const formData = new FormData();

                formData.append('Site_Name', values.Site_Name);
                formData.append('Submitted_By', values.Submitted_By);
                formData.append('Site_Link', values.Site_Link);
                formData.append('Logo', values.Logo);
                formData.append('Short_Description', values.Short_Description);
                formData.append('Long_Description', values.Long_Description);
                formData.append('Category', values.Category);
                formData.append('Payment_Status', values.Payment_Status);
                formData.append('TOP_10', values.TOP_10);
                formData.append('site_type', values.site_type);

                dispatch(updatedataSubmittedSites(formData));
            }
            validation.resetForm();
            setIsImage(dummyImg);
            setModal(false);
        },
    });



    // Update Data
    const handleContactClick = useCallback((arg) => {
        const contact = arg;
        setSubitted_Sites({
            _id: contact._id,
            Site_Name: contact.Site_Name,
            Submitted_By: contact.Submitted_By._id ? contact.Submitted_By._id : contact.Submitted_By,
            Logo: contact.Logo,
            Short_Description: contact.Short_Description,
            Long_Description: contact.Long_Description,
            Site_Link: contact.Site_Link,
            Category: contact.Category,
            Payment_Status: contact.Payment_Status,
            TOP_10: contact.TOP_10,
            site_type: contact.site_type,
        });
        toggle();
        setIsEdit(true);
        { contact?.Logo && setIsImage(api.API_URL + '/' + contact?.Logo) };
    }, [toggle]);

    // Checked All
    const checkedAll = useCallback(() => {
        const checkall = document.getElementById("checkBoxAll");
        const ele = document.querySelectorAll(".contactCheckBox");

        if (checkall.checked) {
            ele.forEach((ele) => {
                ele.checked = true;
            });
        } else {
            ele.forEach((ele) => {
                ele.checked = false;
            });
        }
        deleteCheckbox();
    }, []);

    // Delete Multiple
    const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
    const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

    const deleteMultiple = () => {
        const checkall = document.getElementById("checkBoxAll");
        selectedCheckBoxDelete.forEach((element) => {
            dispatch(deletedataSubmittedSites(element.value));
            setTimeout(() => { toast.clearWaitingQueue(); }, 3000);
        });
        setIsMultiDeleteButton(false);
        checkall.checked = false;
    };

    const deleteCheckbox = () => {
        const ele = document.querySelectorAll(".contactCheckBox:checked");
        ele.length > 0 ? setIsMultiDeleteButton(true) : setIsMultiDeleteButton(false);
        setSelectedCheckBoxDelete(ele);
    };

    // Delete Data
    const handleDeleteContact = () => {
        if (subitted_Sites) {
            dispatch(deletedataSubmittedSites(subitted_Sites._id));
            setDeleteModal(false);
        }
    };

    const onClickDelete = (data) => {
        setSubitted_Sites(data)
        setDeleteModal(true);
    };

    const handleValidDate = date => {
        const date1 = moment(new Date(date)).format("DD MMM Y");
        return date1;
    };

    useEffect(() => {
        dispatch(getAdminUserdata());
    }, [dispatch,]);



    const chunkArray = (array, chunkSize) => {
        const result = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            result.push(array.slice(i, i + chunkSize));
        }
        return result;
    };

    // Column
    const columns = useMemo(
        () => [
            {
                Header: <input type="checkbox" className="form-check-input" id="checkBoxAll" onClick={() => checkedAll()} />,
                Cell: (cellProps) => {
                    return <input type="checkbox" className="contactCheckBox form-check-input" value={cellProps.row.original._id} onChange={() => deleteCheckbox()} />;
                },
                id: '#',
            },
            {
                Header: "Logo",
                accessor: "Logo",
                filterable: true,
                Cell: (cellProps) => {

                    return (
                        <>
                            {
                                cellProps.row.original.Logo ?
                                    <>
                                        <img
                                            src={api.API_URL + '/' + cellProps.row.original.Logo}
                                            alt=""
                                            // height="45" 
                                            className="avatar-sm"
                                            style={{ maxWidth: "3rem" }}
                                        />
                                    </>
                                    :
                                    <>
                                        <img
                                            src={avatar1}
                                            alt=""
                                            // height="45"
                                            className="avatar-sm"
                                            style={{ maxWidth: "3rem" }}
                                        />
                                    </>
                            }
                        </>
                    )
                },
            },
            {
                Header: "Site Name",
                accessor: "Site_Name",
                filterable: true,
                Cell: (cellProps) => {
                    const siteName = cellProps.row.original.Site_Name;
                    const Site_LinkName = cellProps.row.original.Site_Link;
                    const words = siteName?.split(' ');

                    return (
                        <>
                            <div style={{ cursor: 'pointer' }} className="fw-bold d-flex text-secondary align-items-center">
                                <div
                                    onClick={() => {
                                        const UsersData = cellProps.row.original;
                                        navigate('/admin/website-details-overview', {
                                            state: UsersData,
                                        });
                                    }}
                                    className="flex-grow-1 ms-2 User_Name"
                                >
                                    {
                                        siteName ? words?.map((word, index) => (
                                            <React.Fragment key={index}>
                                                {word}
                                                {(index + 1) % 3 === 0 && <br />} {/* Break to new line every 3 words */}
                                                {' '}
                                            </React.Fragment>
                                        )) :
                                            `-`
                                    }
                                </div>
                            </div>
                        </>
                    );
                },
            },
            {
                Header: "Submitted By",
                accessor: "Submitted_By",
                filterable: true,
                Cell: (cellProps) => {
                    // Access the _id property within Submitted_By
                    const submittedBy = cellProps.row.original?.Submitted_By;
                    const submittedById = cellProps.row.original?.Submitted_By?._id;
                    const submittedByEmail = cellProps.row.original?.Submitted_By?.Email;

                    return (
                        <div>
                            {location?.state ? location?.state?.Email :
                                submittedBy ?
                                    submittedByEmail ?
                                        (submittedByEmail ? submittedByEmail : <div className="text-center">-</div>)
                                        : AdminUser?.map((item, index) => {
                                            if (item?._id === submittedBy) {
                                                return <option key={index}>{item?.Email}</option>;
                                            } else if (item?._id === submittedById) {
                                                return <option key={index}>{item?.Email}</option>;
                                            }
                                        })
                                    : '-'
                            }
                        </div>
                    );
                }
            },

            {
                Header: "Site Link",
                accessor: "Site_Link",
                filterable: true,
                Cell: (cell) => {
                    return (
                        cell.value ? <a href={cell.value} className="fw-bold link-secondary" target="_blank" rel="noopener noreferrer">{`${cell.value?.length < 80
                            ? cell.value
                            : cell.value.substring(0, 80) + '...'}`}</a>
                            : <div className="text-center">-</div>
                    )
                }
            },
            {
                Header: "Preview",
                Cell: (cellProps) => {
                    const pageData = cellProps.row.original
                    const pageLink = cellProps.data
                    return (
                        // <a href={`http://localhost:3000/pages/${cellProps.value}`} target="_blank" className="link-secondary fw-bold">{`http://localhost:3000/pages/${cellProps.value}`}</a>
                        <div>
                            {
                                pageLink.map((item, index) => {
                                    const SiteNameWithHyphens = item?.Site_Name?.replace(/[^\w\s]/gi, ' ').replace(/\s+/g, '-').replace(/-+/g, '-').replace(/^-+|-+$/g, '');
                                    const SiteLinkWithHyphens = item?.Site_Link?.replace(/[^\w\s]/gi, ' ').replace(/\s+/g, '-').replace(/-+/g, '-').replace(/^-+|-+$/g, '');

                                    if (pageData._id === item._id) {
                                        return (
                                            <Link key={index} className="link-secondary fw-bold"
                                                to={`/site/${item?.Site_Name ? SiteNameWithHyphens : SiteLinkWithHyphens}`}
                                                target="_blank"
                                            >
                                                <button className="btn btn-primary add-btn"> Preview</button>
                                            </Link>
                                        )
                                    }
                                })
                            }
                        </div>
                    );
                }
            },
            {
                Header: "Submitted Date",
                accessor: "Date_Submitted",
                filterable: true,
                Cell: (cell) => {
                    const formattedDate = moment(cell.value).format("D MMM YYYY");
                    return (
                        <div>
                            <div>{formattedDate}</div>
                        </div>
                    );
                },
            },
            {
                Header: "Next Payment Date",
                accessor: "Subscription_End_Date",
                filterable: true,
                Cell: (cell) => {
                    const formattedDate = moment(cell.value).format("D MMM YYYY");
                    return (
                        <div>
                            {cell.value ? <div>{formattedDate}</div> :
                                <div>- </div>
                            }
                        </div>
                    );
                },
            },
            {
                Header: "Category",
                accessor: "Category",
                Cell: (cellProps) => {
                    const categories = cellProps.value.slice(0, 5); // Get the first 5 items
                    const chunkedCategories = chunkArray(categories, 3); // Split into chunks of 3 items

                    return (
                        <div>
                            {chunkedCategories.map((chunk, chunkIndex) => (
                                <div key={chunkIndex}>
                                    {chunk.map((item, index) => (
                                        <span key={index} className="badge badge-soft-primary fs-12 me-1 mb-1">
                                            {item}
                                        </span>
                                    ))}
                                </div>
                            ))}
                        </div>
                    );
                },
            },
            {
                Header: "Billing Period",
                accessor: "billingPeriod",
                Cell: (cellProps) => {
                    switch (cellProps.row.original?.Plan?.billingPeriod) {
                        case "One-Time":
                            return <span className="badge text-uppercase badge-soft-warning"> One-Time </span>;
                        case "Daily":
                            return <span className="badge text-uppercase badge-soft-info"> Daily </span>;
                        case "Weekly":
                            return <span className="badge text-uppercase badge-soft-success"> Weekly </span>;
                        case "Monthly":
                            return <span className="badge text-uppercase badge-soft-secondary"> Monthly </span>;
                        case "Year":
                            return <span className="badge text-uppercase badge-soft-primary"> Yearly </span>;
                        default:
                            return <span className="badge text-uppercase badge-soft-warning"> One-Time </span>;
                    }
                }
            },
            {
                Header: "TOP 10",
                accessor: "TOP_10",
                Cell: (cell) => {
                    switch (cell.value) {
                        case "true":
                            return <span className="badge text-uppercase fs-12 bg-success"> Yes </span>;
                        default:
                            return <span className="badge text-uppercase fs-12 bg-danger"> No </span>;
                    }
                },
            },
            {
                Header: "Payment Status",
                accessor: "Payment_Status",
                Cell: (cell) => {
                    switch (cell?.row?.original?.Payment_Status || cell.row.original?.Subscription?.payment_status) {
                        case "Completed":
                        case "completed":
                            return <span className="badge text-uppercase fs-12 badge-soft-success"><i className="ri-checkbox-circle-line align-bottom"></i> Completed </span>;
                        case "Cancelled":
                        case "cancelled":
                            return <span className="badge text-uppercase fs-12 badge-soft-danger"><i className="ri-close-circle-line align-bottom"></i>  Cancelled </span>;
                        case "Failed":
                        case "failed":
                            return <span className="badge text-uppercase fs-12 badge-soft-danger"><i className="ri-close-circle-line align-bottom"></i> Failed </span>;
                        default:
                            return <span className="badge text-uppercase fs-12 badge-soft-warning"><i className="ri-time-line align-bottom"></i>  Pending </span>;
                    }
                }
            },
            {
                Header: "Subscription Status",
                accessor: "subscription_status",
                Cell: (cell) => {
                    switch (cell.value) {
                        case "Active":
                            return <span className="badge text-uppercase fs-12 badge-soft-success"><i className="ri-checkbox-circle-line align-bottom"></i> Active </span>;
                        case "active":
                            return <span className="badge text-uppercase fs-12 badge-soft-success"><i className="ri-checkbox-circle-line align-bottom"></i> Active </span>;
                        case "cancelled":
                            return <span className="badge text-uppercase fs-12 badge-soft-danger"><i className="ri-close-circle-line align-bottom"></i> Cancelled </span>;
                        default:
                            return <span className="badge text-uppercase fs-12 badge-soft-warning"><i className="ri-time-line align-bottom"></i> Pending </span>;
                    }
                }
            },
            {
                Header: "Approval Status",
                accessor: "Approval_Status",
                Cell: (cell) => {
                    switch (cell.value) {
                        case "Approved":
                            return <span className="badge text-uppercase fs-12 badge-soft-success"><i className="ri-checkbox-circle-line align-bottom"></i> Approved </span>;
                        case "Rejected":
                            return <span className="badge text-uppercase fs-12 badge-soft-danger"><i className="ri-close-circle-line align-bottom"></i> Rejected </span>;
                        case "Review":
                            return <span className="badge text-uppercase fs-12 badge-soft-info"><i className="ri-eye-line align-bottom"></i> Review </span>;
                        default:
                            return <span className="badge text-uppercase fs-12 badge-soft-warning"><i className="ri-time-line align-bottom"></i> Pending </span>;
                    }
                }
            },
            {
                Header: "Action",
                Cell: (cellProps) => {
                    const statusdata = cellProps.row.original?.Approval_Status;
                    return (
                        <ul className="list-inline hstack gap-2">
                            <li className="list-inline-item">
                                <UncontrolledDropdown>
                                    <DropdownToggle
                                        href=""
                                        className="btn btn-soft-secondary btn-sm dropdown"
                                        tag="button"
                                    >
                                        <i className="ri-more-fill align-middle"></i>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-end">
                                        <DropdownItem
                                            className="dropdown-item edit-item-btn"
                                            href=""
                                            onClick={() => { const contactData = cellProps.row.original; handleContactClick(contactData); }}
                                        >
                                            <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                                            Edit
                                        </DropdownItem>

                                        {statusdata === 'Pending' ?
                                            <>
                                                <DropdownItem
                                                    className="dropdown-item edit-item-btn"
                                                    href=""
                                                    onClick={() => {
                                                        const newStatus = "Approved";
                                                        dispatch(getSiteStatusActiveandDeactiveData({ id: cellProps.row.original?._id, newStatus }));
                                                    }}
                                                >
                                                    <i className={`ri-checkbox-circle-line align-bottom me-2 text-muted`}></i>{" "}
                                                    {"Approved"}
                                                </DropdownItem>
                                                <DropdownItem
                                                    className="dropdown-item edit-item-btn"
                                                    href=""
                                                    onClick={() => {
                                                        const newStatus = "Rejected";
                                                        dispatch(getSiteStatusActiveandDeactiveData({ id: cellProps.row.original?._id, newStatus }));
                                                    }}
                                                >
                                                    <i className={`ri-close-circle-line align-bottom me-2 text-muted`}></i>{" "}
                                                    {"Rejected"}
                                                </DropdownItem>
                                            </>
                                            :
                                            <DropdownItem
                                                className="dropdown-item edit-item-btn"
                                                href=""
                                                onClick={() => {
                                                    const newStatus = statusdata === "Approved" ? "Rejected" : "Approved";
                                                    dispatch(getSiteStatusActiveandDeactiveData({ id: cellProps.row.original?._id, newStatus }));
                                                }}
                                            >
                                                <i className={`ri-${statusdata === "Approved" ? "close-circle-line" : "checkbox-circle-line"} align-bottom me-2 text-muted`}></i>{" "}
                                                {statusdata === "Approved" ? "Rejected" : "Approved"}
                                            </DropdownItem>
                                        }

                                        <DropdownItem
                                            className="dropdown-item edit-item-btn"
                                            // href="/simplified-table-structure"
                                            onClick={() => {
                                                const UsersData = cellProps.row.original;
                                                navigate('/admin/details-and-product', {
                                                    state: UsersData,
                                                })
                                            }}
                                        >
                                            <i className=" ri-eye-line align-bottom me-2 text-muted"></i>{" "}
                                            View Offers/Product
                                        </DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem
                                            className="dropdown-item remove-item-btn"
                                            href=""
                                            onClick={() => { const contactData = cellProps.row.original; onClickDelete(contactData); }}
                                        >
                                            <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                                            Delete
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </li>
                        </ul>
                    );
                },
            },
        ],
        [handleContactClick, checkedAll]
    );

    const SelectCategory = Category.map((item) => (
        { label: item.CategoryName, value: item.CategoryName }
    ))

    document.title = "Admin Panel | Submitted Sites";
    return (
        <React.Fragment>
            <div className="page-content">

                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={handleDeleteContact}
                    onCloseClick={() => setDeleteModal(false)}
                />
                <DeleteModal
                    show={deleteModalMulti}
                    onDeleteClick={() => {
                        deleteMultiple();
                        setDeleteModalMulti(false);
                    }}
                    onCloseClick={() => setDeleteModalMulti(false)}
                />
                <Container fluid>
                    <BreadCrumb title="Submitted Sites" pageTitle="Dashboards" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <div className="d-flex align-items-center flex-wrap gap-2">
                                        <div className="flex-grow-1">
                                            <button style={{ backgroundImage: "inherit" }} className="btn btn-primary add-btn" onClick={() => { setModal(true); }}>
                                                <i className="ri-add-fill me-1 align-bottom"></i>Add Submitted Sites
                                            </button>
                                        </div>
                                        {
                                            location?.state &&
                                            <>
                                                <div className="flex-grow-1">
                                                    <h6 style={{ color: "black" }}>Date and Time</h6>
                                                    <p style={{ marginBottom: "0" }}>{handleValidDate(location?.state?.createdAt)}</p>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <h6 style={{ color: "black" }}>Email Address</h6>
                                                    <p style={{ marginBottom: "0" }}>{location?.state?.Email}</p>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <h6 style={{ color: "black" }}>List of Websites</h6>
                                                    <p style={{ marginBottom: "0" }}>{SubmitSites?.length}</p>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <h6 style={{ color: "black" }}>Last Login</h6>
                                                    <p style={{ marginBottom: "0" }}>{handleValidDate(location?.state?.updatedAt)}</p>
                                                </div>
                                            </>
                                        }
                                        <div className="flex-shrink-0">
                                            <div className="hstack text-nowrap gap-2">
                                                {isMultiDeleteButton && <button className="btn btn-secondary" onClick={() => setDeleteModalMulti(true)}><i className="ri-delete-bin-2-line"></i></button>}
                                            </div>
                                        </div>
                                    </div>
                                </CardHeader>
                            </Card>
                        </Col>
                        <Col xxl={12}>
                            <Card id="TeamMembersList">
                                <CardBody>
                                    <div>
                                        {SubmitSites?.length ? (
                                            <TableContainer
                                                columns={columns}
                                                data={(SubmitSites || [])}
                                                isGlobalFilter={true}
                                                isAddUserList={false}
                                                customPageSize={8}
                                                className="custom-header-css"
                                                divClass="table-responsive table-card mb-3"
                                                tableClass="align-middle table-nowrap"
                                                theadClass="table-light"
                                                isContactsFilter={false}
                                                SearchPlaceholder='Search for Submitted Sites...'
                                            // isShowPagination={false}
                                            />
                                        ) : <div style={{ color: "rgb(135, 138, 153)" }} className="text-center fs-18 fw-bold">Site Not Available</div>
                                        }
                                    </div>
                                    <Modal id="showModal" backdrop={false} isOpen={modal} centered size="lg">
                                        <ModalHeader className="bg-soft-info p-3" toggle={toggle}>
                                            {!!isEdit ? "Edit Submitted Sites" : "Add New Submitted Sites"}
                                        </ModalHeader>

                                        <Form
                                            className="tablelist-form"
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                validation.handleSubmit();
                                                return false;
                                            }}
                                        >
                                            <ModalBody>
                                                <Input type="hidden" id="id-field" />
                                                <Row className="g-3">
                                                    <Col lg={12}>
                                                        <div className="text-center">
                                                            <div className="position-relative d-inline-block">
                                                                <div className="position-absolute  bottom-0 end-0">
                                                                    <Label htmlFor="Logo" className="mb-0">
                                                                        <div className="avatar-xs cursor-pointer">
                                                                            <div className="avatar-title bg-light border rounded text-muted">
                                                                                <i className="ri-image-fill"></i>
                                                                            </div>
                                                                        </div>
                                                                    </Label>
                                                                    <Input className="form-control d-none"
                                                                        id="Logo" type="file"
                                                                        name="Logo"

                                                                        invalid={!isEdit &&
                                                                            validation.touched.Logo && validation.errors.Logo ? true : false
                                                                        }
                                                                        onChange={(event) => {
                                                                            const file = event.target.files[0];
                                                                            const reader = new FileReader();
                                                                            reader.onload = (event) => {
                                                                                validation.setFieldValue('Logo', file);
                                                                                setIsImage(event.target.result)
                                                                            };
                                                                            reader.readAsDataURL(file);
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="avatar-lg p-1">
                                                                    <div className="avatar-title bg-light rounded-circle">
                                                                        <img src={isImage} alt="img" id="customer-img" className="avatar-xl rounded object-cover img-thumbnail" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {validation.touched.Logo && validation.errors.Logo ? (
                                                                <FormFeedback type="invalid"><div>{validation.errors.Logo}</div></FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>

                                                    <Col lg={6}>
                                                        <div>
                                                            <Label
                                                                htmlFor="Site_Name"
                                                                className="form-label"
                                                            >
                                                                Site name
                                                            </Label>

                                                            <Input
                                                                name="Site_Name"
                                                                id="Site_Name"
                                                                className="form-control"
                                                                placeholder="Enter Site name"
                                                                type="text"
                                                                validate={{
                                                                    required: { value: true },
                                                                }}
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.Site_Name || ""}
                                                                invalid={
                                                                    validation.touched.Site_Name && validation.errors.Site_Name ? true : false
                                                                }
                                                            />
                                                            {validation.touched.Site_Name && validation.errors.Site_Name ? (
                                                                <FormFeedback type="invalid"><div>{validation.errors.Site_Name}</div></FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>

                                                    <Col lg={6}>
                                                        <div>
                                                            <Label
                                                                htmlFor="Submitted_By"
                                                                className="form-label"
                                                            >
                                                                Submitted By
                                                            </Label>

                                                            <Input
                                                                id="Submitted_By"
                                                                name="Submitted_By"
                                                                type="select"
                                                                className="form-select"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.Submitted_By || ""}
                                                                invalid={
                                                                    validation.touched.Submitted_By && validation.errors.Submitted_By ? true : false
                                                                }
                                                            >
                                                                <option value="" >Select Email</option> :
                                                                {
                                                                    location?.state === null ?
                                                                        AdminUser?.map((item, index) => (
                                                                            <option key={index} value={item._id} > {item.Email}</option>
                                                                        ))
                                                                        :
                                                                        <option value={location?.state?._id} > {location?.state?.Email}</option>
                                                                }
                                                            </Input>
                                                            {validation.touched.Submitted_By && validation.errors.Submitted_By ? (
                                                                <FormFeedback type="invalid"><div>{validation.errors.Submitted_By}</div></FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>

                                                    <Col lg={6}>
                                                        <div>
                                                            <Label
                                                                htmlFor="Site_Link"
                                                                className="form-label"
                                                            >
                                                                Site Link
                                                            </Label>

                                                            <Input
                                                                name="Site_Link"
                                                                id="Site_Link"
                                                                className="form-control"
                                                                placeholder="Enter Site Link"
                                                                type="text"
                                                                validate={{
                                                                    required: { value: true },
                                                                }}
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.Site_Link || ""}
                                                                invalid={
                                                                    validation.touched.Site_Link && validation.errors.Site_Link ? true : false
                                                                }
                                                            />
                                                            {validation.touched.Site_Link && validation.errors.Site_Link ? (
                                                                <FormFeedback type="invalid"><div>{validation.errors.Site_Link}</div></FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>

                                                    <Col lg={6}>
                                                        <div>
                                                            <Label
                                                                htmlFor="Short_Description-field"
                                                                className="form-label"
                                                            >
                                                                Short Description
                                                            </Label>

                                                            <textarea
                                                                name="Short_Description"
                                                                id="Short_Description-field"
                                                                className="form-control"
                                                                placeholder="Enter Short Description"
                                                                type="text"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.Short_Description || ""}
                                                            ></textarea>
                                                            {validation.touched.Short_Description && validation.errors.Short_Description ? (
                                                                <FormFeedback type="invalid"><div>{validation.errors.Short_Description}</div></FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>

                                                    <Col lg={12}>
                                                        <div>
                                                            <Label
                                                                htmlFor="Long_Description-field"
                                                                className="form-label"
                                                            >
                                                                Long Description
                                                            </Label>

                                                            <textarea
                                                                name="Long_Description"
                                                                id="Long_Description-field"
                                                                className="form-control"
                                                                placeholder="Enter Long Description"
                                                                type="text"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.Long_Description || ""}
                                                            ></textarea>
                                                            {validation.touched.Long_Description && validation.errors.Long_Description ? (
                                                                <FormFeedback type="invalid"><div>{validation.errors.Long_Description}</div></FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>

                                                    <Col lg={12}>
                                                        <div>
                                                            <Label
                                                                htmlFor="Category-field"
                                                                className="form-label"
                                                            >
                                                                Category
                                                            </Label>

                                                            <Select
                                                                isMulti
                                                                id="Category"
                                                                name="Category"
                                                                options={SelectCategory}
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                                value={SelectCategory.filter(option => validation.values.Category.includes(option.value))}
                                                                onBlur={validation.handleBlur}
                                                                onChange={(selectedOptions) => {
                                                                    const selectedValues = selectedOptions.map(option => option.value);
                                                                    validation.setFieldValue('Category', selectedValues)
                                                                }}
                                                            />
                                                            {validation.touched.Category && validation.errors.Category ? (
                                                                <FormFeedback type="invalid"><div>{validation.errors.Category}</div></FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>

                                                    <Col lg={4}>
                                                        <div>
                                                            <Label
                                                                htmlFor="Payment_Status-field"
                                                                className="form-label"
                                                            >
                                                                Payment Status
                                                            </Label>

                                                            <Input
                                                                id="Payment_Status-field"
                                                                name="Payment_Status"
                                                                type="select"
                                                                className="form-select"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.Payment_Status || ""}
                                                            >
                                                                <option value="completed">Completed</option>
                                                                <option value="failed">Failed</option>
                                                                <option value="pending">Pending</option>
                                                            </Input>
                                                            {validation.touched.Payment_Status && validation.errors.Payment_Status ? (
                                                                <FormFeedback type="invalid"><div>{validation.errors.Payment_Status}</div></FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <div>
                                                            <Label
                                                                htmlFor="TOP_10-field"
                                                                className="form-label"
                                                            >
                                                                TOP 10
                                                            </Label>

                                                            <Input
                                                                id="TOP_10-field"
                                                                name="TOP_10"
                                                                type="select"
                                                                className="form-select"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.TOP_10 || ""}
                                                            >
                                                                <option value="true">Yes</option>
                                                                <option value="false">No</option>
                                                            </Input>
                                                            {validation.touched.TOP_10 && validation.errors.TOP_10 ? (
                                                                <FormFeedback type="invalid"><div>{validation.errors.TOP_10}</div></FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <div>
                                                            <Label
                                                                htmlFor="site_type-field"
                                                                className="form-label"
                                                            >
                                                                Site Type
                                                            </Label>

                                                            <Input
                                                                id="site_type-field"
                                                                name="site_type"
                                                                type="select"
                                                                className="form-select"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.site_type || ""}
                                                            >
                                                                <option value="normal">normal</option>
                                                                <option value="editor">editor</option>
                                                                <option value="featured">featured</option>
                                                            </Input>
                                                            {validation.touched.site_type && validation.errors.site_type ? (
                                                                <FormFeedback type="invalid"><div>{validation.errors.site_type}</div></FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </ModalBody>
                                            <ModalFooter>
                                                <div className="hstack gap-2 justify-content-end">
                                                    <button type="button" className="btn btn-light" onClick={() => { toggle(); setIsImage(dummyImg); setModal(false); setIsEdit(false) }} > Close </button>
                                                    <button type="submit" className="btn btn-success" id="add-btn" > {!!isEdit ? "Update" : "Add Submitted Sites"} </button>
                                                </div>
                                            </ModalFooter>
                                        </Form>
                                    </Modal>
                                    <ToastContainer closeButton={false} limit={1} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment >
    );
};

export default SubmittedSites;