import React, { useEffect, useRef, useState } from 'react';
import Header from '../../../Layouts/FrontHeader';
import Footer from '../../../Layouts/FrontFooter';
import { useLocation, useNavigate, } from 'react-router-dom';
import { api } from '../../../config';
import DOMPurify from 'dompurify';
import { getBlogsByTitle } from '../../../slices/FrontView/blogs/thunk';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../Components/Common/Loader';
import website_background from "../../../assets/images/website_background.png";

const BlogView = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const { state } = location;
    const currentPageURL = location.pathname?.replace('/blog/', '');

    const { blogByTitle } = useSelector((state) => state.Blogs);
    const { cmsData } = useSelector((state) => state.CMSF);

    useEffect(() => {
        if (currentPageURL) {
            dispatch(getBlogsByTitle(currentPageURL))
        }
    }, [currentPageURL, dispatch])



    const myElementRef = useRef(null);
    const [footerHeight, setFooterHeight] = useState(700);
    useEffect(() => {
        if (myElementRef?.current) {
            const elementHeight = myElementRef.current.getBoundingClientRect().height;
            setFooterHeight(elementHeight)
        }
    });
    document.title = `${cmsData?.websiteTitle ? cmsData?.websiteTitle : '1000 Ai Sites'} | ${blogByTitle?.title ? blogByTitle?.title : ''}`;

    return (
        <div>
            {blogByTitle?.longDetail &&
                <>
                    <div ref={myElementRef}>
                        <Header />
                        <div className='detilspage'>
                            <div className="px-1 pt-20 px-md-5 text-center text-lg-start" >
                                <div className="container">
                                    <div className="row gx-lg-5 align-items-center justify-content-center">
                                        <div className="col-lg-7 mb-5 mb-lg-0  card">
                                            <div className="px-auto px-4 pb-4">
                                                <div className="mb-6 lg:mb-1 mt-4">
                                                    <div className='flex justify-between'>
                                                        <div className='container flex-grow'>
                                                            <h1 className="font-bold mb-0" style={{ fontSize: '32px' }}>{blogByTitle?.title}</h1>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='flex img justify-content-center align-items-center pb-3'>
                                                    <img src={blogByTitle?.blog_image ? api.API_URL + '/' + blogByTitle?.blog_image : website_background} style={{ borderRadius: '5px' }}
                                                        className='align-items-center '
                                                        alt="..." />
                                                </div>
                                                <div className=" prose-img:rounded-xl" >
                                                    <div dangerouslySetInnerHTML={{ __html: DOMPurify?.sanitize(blogByTitle?.longDetail) }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer footer_style={footerHeight > window.innerHeight ? 'App-footer-scroll' : ''} />
                </>
            }
        </div>
    );
}

export default BlogView;
