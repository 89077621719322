import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getcmsDetails } from '../slices/FrontView/cms/thunk';
import { api } from '../config';
import FrontProfileDropdown from '../Components/Common/FrontProfileDropdown';
import dummy_logo from '../assets/images/logo-dark.svg';

const FrontHeader = ({ children, isactive }) => {
    const location = useLocation();
    const navigate = useNavigate()

    const dispatch = useDispatch();

    const { cmsData } = useSelector((state) => state.CMSF);

    useEffect(() => {
        dispatch(getcmsDetails())
    }, [dispatch])

    const authorMeta = document.querySelector("meta[name='author']");
    const descriptionMeta = document.querySelector("meta[name='description']");
    const faviconLink = document.querySelector("link[rel~='icon']");

    useEffect(() => {
        document.title = cmsData?.websiteTitle || '1000 Ai Sites';
        descriptionMeta.content = cmsData?.websiteDescription;
        faviconLink.href = api.API_URL + '/' + cmsData?.favicon;

    }, [authorMeta, descriptionMeta, faviconLink, cmsData])

    const handleImageClick = () => {
        if (location.pathname === '/') {
            // Reload the page if already on the home page
            window.location.reload();
        } else {
            // Navigate to the home page
            navigate('/');
        }
    };

    return (
        <div>

            <div className="h-16 mx-auto flex items-center justify-between px-4 lg:px-6 main_header">
                <div className="flex items-center space-x-4">
                    <Link className="shrink-0" to='/index'>
                        <img onClick={handleImageClick} src={cmsData?.websiteLogo ? api.API_URL + '/' + cmsData?.websiteLogo : dummy_logo} alt='logo' loading="lazy" style={{ height: "50px", width: "200px" }} />
                    </Link>
                </div>
                <div className="flex items-center space-x-2 lg:space-x-3">

                    <FrontProfileDropdown isactive={isactive} />
                </div>
            </div>
        </div>
    );
}

export default FrontHeader;