import React, { useEffect, useRef, useState } from 'react';
import { Link, } from 'react-router-dom';

import Header from '../../../../Layouts/FrontHeader';
import Footer from '../../../../Layouts/FrontFooter';
import { delelteWebsite, getUserAllWebsite } from '../../../../slices/FrontView/dashboard/thunk';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '../../../../config';
import SetPasswordModal from '../../../../Components/Common/SetPasswordModal';
import { checkPasswordStatus } from '../../../../slices/FrontView/authentication/thunk';
import website_background from "../../../../assets/images/website_background.png";
import CryptoJS from "crypto-js";
import DeleteModal from '../../../../Components/Common/DeleteModal';
import { clearWebsiteByNamesSuccess } from '../../../../slices/FrontView/dashboard/reducer';
import { isEmpty, } from "lodash";
import moment from 'moment';

const SubmitList = (props) => {
    const dispatch = useDispatch();

    const { userWebsiteList } = useSelector((state) => state.Dashboard);
    const { isPassword } = useSelector((state) => state.Auth)
    const { cmsData } = useSelector((state) => state.CMSF);

    const [usersAllWebsite, setUsersAllWebsite] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteData, setDeleteData] = useState(null);
    const data = JSON.parse(sessionStorage.getItem("authUser"))?.data;


    useEffect(() => {
        if (data) {
            dispatch(getUserAllWebsite(data._id));
            dispatch(checkPasswordStatus(data.Email));
        }
        dispatch(clearWebsiteByNamesSuccess());
    }, [dispatch])


    useEffect(() => {
        const sortedWebsiteList = [...userWebsiteList].sort((a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf());

        setUsersAllWebsite(sortedWebsiteList)
    }, [userWebsiteList])

    useEffect(() => {
        if (!isEmpty(userWebsiteList)) {
            const sortedWebsiteList = [...userWebsiteList].sort((a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf());
            setUsersAllWebsite(sortedWebsiteList);
        }
    }, [userWebsiteList]);


    const handleDeleteWebsite = (item) => {
        setDeleteModal(true);
        setDeleteData(item);
    }

    const handleDeleteClick = () => {
        dispatch(delelteWebsite(deleteData?._id))
        setDeleteModal(false);
    }


    const myElementRef = useRef(null);
    const [footerHeight, setFooterHeight] = useState(700);
    useEffect(() => {
        if (myElementRef?.current) {
            const elementHeight = myElementRef.current.getBoundingClientRect().height;
            setFooterHeight(elementHeight)
        }
    });
    document.title = `${cmsData?.websiteTitle ? cmsData?.websiteTitle : '1000 Ai Sites'} | Websites`;

    return (
        <div>
            <div ref={myElementRef}>
                <Header isactive={'active-Website'} />
                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={handleDeleteClick}
                    onCloseClick={() => setDeleteModal(false)}
                />
                {isPassword === false && <SetPasswordModal user={data} />}
                <div className={`SubmitList`} >
                    <section>
                        <div className="lg:px-4 pt-20 px-md-5 text-center text-lg-start" >
                            <div className="flex items-center space-x-2" style={{ justifyContent: 'space-evenly', marginBottom: '2%' }}>
                                <div className='yourwebsite-text'>
                                    My Websites
                                </div>
                                <Link className="btn btn-labeled btn-primary" to='/register'>
                                    <span className="btn-label"><i className="bx bx-plus"></i></span>
                                    Submit New Website
                                </Link>
                            </div>
                            {userWebsiteList?.length !== 0 && usersAllWebsite?.map((item, index) => {
                                // console.log('item', item);
                                const SiteNameWithHyphens = item?.Site_Name?.replace(/[^\w\s]/gi, ' ').replace(/\s+/g, '-').replace(/-+/g, '-').replace(/^-+|-+$/g, '');
                                const SiteLinkWithHyphens = item?.Site_Link?.replace(/[^\w\s]/gi, ' ').replace(/\s+/g, '-').replace(/-+/g, '-').replace(/^-+|-+$/g, '');

                                return (
                                    <div key={index} className="container lg:g-5">
                                        <div className="row gx-lg-5 align-items-center justify-content-center">
                                            <div className="col-lg-8 mb-lg-0 ">
                                                <div className="card ">
                                                    <div className="card-body relative">
                                                        <div className="flex  justify-between ">
                                                            <div className="flex-none mr-3 lg:mr-4 rounded-[22.5%] w-20 h-20 overflow-hidden">
                                                                <img className="w-20 h-20" src={item?.Logo ? api.API_URL + '/' + item?.Logo : website_background} alt={`Logo of ${item?.Site_Name}`} loading="lazy" />
                                                            </div>
                                                            <div className='flex-grow'>
                                                                <h1 className="font-bold mb-0">{
                                                                    item?.Site_Name?.length > 50
                                                                        ? `${item?.Site_Name.substring(0, 50)}....`
                                                                        : item.Site_Name ? item?.Site_Name
                                                                            : ''
                                                                }</h1>
                                                                {!item?.Site_Name && <p className="text-sm text-gray-500">{item?.Site_Link?.length > 100 ? `${item?.Site_Link.substring(0, 100)}....` : item.Site_Link}</p>}
                                                                <p className="text-sm text-gray-400">{item?.Short_Description?.length > 50 ? `${item?.Short_Description.substring(0, 80)}....` : item.Short_Description}</p>

                                                            </div>

                                                            <div className='lg:block lg:col-span-3 '>

                                                                <div className='flex'>

                                                                    {item?.Approval_Status === 'Pending' && <div className=" lg:flex items-center space-x-1.5 mx-1">
                                                                        <span className="relative flex h-2 w-2">
                                                                            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-300 opacity-75" />
                                                                            <span className="relative inline-flex rounded-full h-2 w-2 bg-yellow-500" />
                                                                        </span>
                                                                        <p className="text-sm leading-none text-gray-400 text-website">Pending</p>
                                                                    </div>}

                                                                    {item?.Approval_Status === 'Approved' && <div className=" lg:flex items-center space-x-1.5 lg:mx-3">
                                                                        <span className="relative flex h-2 w-2">
                                                                            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-300 opacity-75" />
                                                                            <span className="relative inline-flex rounded-full h-2 w-2 bg-green-400" />
                                                                        </span>
                                                                        <p className="text-sm leading-none text-gray-400 text-website">Live</p>
                                                                    </div>}
                                                                    {item?.Approval_Status === 'Rejected' && <div className=" lg:flex items-center space-x-1.5 lg:mx-3">
                                                                        <span className="relative flex h-2 w-2">
                                                                            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red opacity-75" />
                                                                            <span className="relative inline-flex rounded-full h-2 w-2 bg-red" />
                                                                        </span>
                                                                        <p className="text-sm leading-none text-gray-400 text-website">Rejected</p>
                                                                    </div>}
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className='flex justify-end mt-4'>

                                                            <div className='flex more-btn-websitlist'>
                                                                <Link className='btn btn-labeled btn-primary Detils-btn-edit' to='/submit' state={{ website: item }}>
                                                                    <span className="btn-label-big"><i className="bx bxs-pencil"></i></span>
                                                                    <p className='text-website'>  Edit</p>
                                                                </Link>

                                                                <Link className='btn btn-labeled btn-primary Detils-btn-preview' to='/offerproduct' state={{ website: item }}>
                                                                    <span className="btn-label-big"><i className="bx bx-plus"></i></span>
                                                                    <p className='text-website'>
                                                                        Manage Offers/Product
                                                                    </p>
                                                                </Link>

                                                                <Link className='btn btn-labeled btn-primary Detils-btn-preview'
                                                                    to={`/site/${item?.Site_Name ? SiteNameWithHyphens : SiteLinkWithHyphens}`}
                                                                >
                                                                    <span className="btn-label-big"><i className="bx bx-show"></i></span>
                                                                    <p className='text-website'> Preview </p></Link>


                                                                <Link className='btn btn-labeled btn-primary Detils-btn-Delete' onClick={() => { handleDeleteWebsite(item) }}>
                                                                    <span className="btn-label-big"><i className="bx bx-trash"></i></span>
                                                                    <p className='text-website'> Delete</p></Link>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </div>
                    </section>
                </div>
            </div>
            <Footer footer_style={footerHeight > window.innerHeight ? 'App-footer-scroll' : ''} />
        </div >
    );
}

export default SubmitList;