import 'react-toastify/dist/ReactToastify.css';

import {
    addBlog as addBlogApi,
    createBlogpay as createBlogpayApi,
    deleteBlog as deleteBlogApi,
    editBlog as editBlogApi,
    getAllBlog as getAllBlogApi,
    getBlogByTitle as getBlogByTitleApi,
    getblogPlans as getblogPlansApi,
    getuserAllBlog as getuserAllBlogApi
} from '../../../helpers/backend_helper';
import {
    addBlogLoaderStop,
    addNewBlogSuccess, deleteBlogsSuccess,
    getAllBlogsSuccess, getBlogsByTitleSuccess, getBlogsPlanSuccess, getUserAllBlogsSuccess,
    updateBlogsSuccess
} from './reducer';
import { toast } from 'react-toastify';


export const getAllBlogs = () => async (dispatch) => {
    try {
        const response = await getAllBlogApi();
        if (response.success === true) {
            dispatch(getAllBlogsSuccess(response.data))

        }
    } catch (error) {
        toast.error(error, { autoClose: 3000 });
    }
}

export const getBlogsByTitle = (title) => async (dispatch) => {
    try {
        const response = await getBlogByTitleApi(title);
        if (response.success === true) {
            dispatch(getBlogsByTitleSuccess(response.blog))

        }
    } catch (error) {
        toast.error(error, { autoClose: 3000 });
    }
}

export const getUserAllBlogs = (id) => async (dispatch) => {
    try {
        const response = await getuserAllBlogApi(id);
        if (response.success === true) {
            dispatch(getUserAllBlogsSuccess(response.AllBlogData))

        }
    } catch (error) {
        toast.error(error, { autoClose: 3000 });
    }
}

export const addNewBlogs = (data, history) => async (dispatch) => {
    try {
        const response = await addBlogApi(data);
        if (response.success === true) {
            dispatch(addNewBlogSuccess(response.blog));
            dispatch(addBlogLoaderStop())
            toast.success(response.message, { autoClose: 3000 });
            history('/bloglist');
        } else {
            toast.error(response.message, { autoClose: 3000 });
            dispatch(addBlogLoaderStop())
        }
        // return response;
    } catch (error) {
        toast.error(error, { autoClose: 3000 });
        dispatch(addBlogLoaderStop())
    }
};


export const updateNewBlogs = (data, history) => async (dispatch) => {
    const id = data.get('id')
    try {
        const response = await editBlogApi(id, data);
        if (response.success === true) {
            dispatch(updateBlogsSuccess(response.blog))
            dispatch(addBlogLoaderStop())
            history('/bloglist');
        }
        toast.success(response.message, { autoClose: 3000 });

    } catch (error) {
        toast.error(error, { autoClose: 3000 });
        dispatch(addBlogLoaderStop())
    }
}

export const delelteBlogs = (id) => async (dispatch) => {
    try {
        const response = await deleteBlogApi(id);
        if (response.success === true) {
            dispatch(deleteBlogsSuccess(id))
        }
        toast.success(response.message, { autoClose: 3000 });
    } catch (error) {
        toast.error(error, { autoClose: 3000 });
    }
}


export const getBlogsPlan = () => async (dispatch) => {
    try {
        const response = await getblogPlansApi();
        if (response.success === true) {
            dispatch(getBlogsPlanSuccess(response.Data[0]))
        }
    } catch (error) {
        toast.error(error, { autoClose: 3000 });
    }
}

export const createBlogPayment = (data) => async (dispatch) => {
    try {
        const response = await createBlogpayApi(data);
        console.log('response', response);
        if (response.success === true) {
            const linksObj = response.data?.links;
            const newObj = linksObj.find((item) => item.rel === 'approve' && item.method === 'GET')
            if (newObj.href) {
                window.open(newObj.href);
            } else {
                toast.error(response.message, { autoClose: 3000 });
            }
            // dispatch(getBlogsPlanSuccess(response.Data[0]))
        }
    } catch (error) {
        toast.error(error, { autoClose: 3000 });
    }
}