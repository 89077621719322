import React from 'react';
import { Container, Row } from 'reactstrap';
import BreadCrumb from '../../../../Components/Common/BreadCrumb';
import TicketsData from './TicketsData';

const ListView = () => {
    document.title = "Admin Panel | Support Tickets";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Support Tickets" pageTitle="Dashboards" />
                    <TicketsData />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ListView;