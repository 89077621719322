import { createSlice } from "@reduxjs/toolkit";
import { getAdminSingleTicketsCommentsList, addNewAdminTicketComments, updateAdminTicketComments, deleteAdminTicketComments } from './thunk';

export const initialState = {
    adminticketscommentsList: []
};

const AdminTicketsCommentSlice = createSlice({
    name: 'ticketscomments',
    initialState,
    reducer: {},
    extraReducers: (builder) => {
        builder.addCase(getAdminSingleTicketsCommentsList.fulfilled, (state, action) => {
            state.adminticketscommentsList = action.payload.data;
        });

        builder.addCase(getAdminSingleTicketsCommentsList.rejected, (state, action) => {
            state.error = action.payload.error || null;
        });

        builder.addCase(addNewAdminTicketComments.fulfilled, (state, action) => {
            state.adminticketscommentsList.push(action.payload.data);
        });

        builder.addCase(addNewAdminTicketComments.rejected, (state, action) => {
            state.error = action.payload.error || null;
        });

        builder.addCase(updateAdminTicketComments.fulfilled, (state, action) => {
            state.adminticketscommentsList = state.adminticketscommentsList.map(ticket =>
                ticket._id.toString() === action.payload.data._id.toString()
                    ? { ...ticket, ...action.payload.data }
                    : ticket
            );
        });

        builder.addCase(updateAdminTicketComments.rejected, (state, action) => {
            state.error = action.payload.error || null;
        });

        builder.addCase(deleteAdminTicketComments.fulfilled, (state, action) => {
            state.adminticketscommentsList = state.adminticketscommentsList.filter(
                ticket => ticket._id.toString() !== action.payload.ticketcomments.toString()
            );
        });

        builder.addCase(deleteAdminTicketComments.rejected, (state, action) => {
            state.error = action.payload.error || null;
        });
    }
});

export default AdminTicketsCommentSlice.reducer;