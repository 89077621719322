
import { loginSuccess, logoutUserSuccess, apiError, reset_login_flag, loginBegin } from './reducer';

import { postAdminLogin } from "../../../../helpers/backend_helper";
import { toast } from "react-toastify";


export const loginUser = (user, history) => async (dispatch) => {

  try {
    dispatch(loginBegin())

    const response = await postAdminLogin({ Email: user.Email, Password: user.Password, });
    if (response.success === true) {
      toast.success(response.message, { autoClose: 3000 });

      sessionStorage.setItem("authAdmin", JSON.stringify(response));
      dispatch(loginSuccess(response));

      history('/admin/dashboard')
    } else {
      toast.error(response.message || 'Fail', { autoClose: 3000 });
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const logoutUser = () => async (dispatch) => {
  try {
    sessionStorage.removeItem("authAdmin");
    dispatch(logoutUserSuccess(true));
  } catch (error) {
    dispatch(apiError(error));
  }
};



export const resetLoginFlag = () => async (dispatch) => {
  try {
    const response = dispatch(reset_login_flag());
    return response;
  } catch (error) {
    dispatch(apiError(error));
  }
};