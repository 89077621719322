import 'react-toastify/dist/ReactToastify.css';

import { getcmsData as getcmsDataApi } from '../../../helpers/backend_helper';
import { getcmsDetailsSuccess } from './reducer';
import { toast } from 'react-toastify';


export const getcmsDetails = () => async (dispatch) => {
    try {
        const response = await getcmsDataApi();
        if (response.success === true) {
            dispatch(getcmsDetailsSuccess(response.data))

        }
    } catch (error) {
        toast.error(error, { autoClose: 3000 });
    }
}