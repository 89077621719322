import { createSlice } from "@reduxjs/toolkit";
import { getSubmittedSitesdata, updatedataSubmittedSites, deletedataSubmittedSites, getSubmittedSitesAllWebsite, getSubmittedSitesTransaction, getSiteStatusActiveandDeactiveData, getAllSubmittedSitesdata } from './thunk';

export const initialState = {
  allSubmittedSitesData: [],
  submittedsitesdetails: [],
  websiteSubscriptions: [],
  error: {}
};

const SubmittedSitesSlice = createSlice({
  name: "submittedsites",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSubmittedSitesdata.fulfilled, (state, action) => {
      state.submittedsitesdetails = action.payload.data;
    });

    builder.addCase(getSubmittedSitesdata.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(getAllSubmittedSitesdata.fulfilled, (state, action) => {
      state.allSubmittedSitesData = action.payload.data;
    });

    builder.addCase(getAllSubmittedSitesdata.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(getSubmittedSitesTransaction.fulfilled, (state, action) => {
      state.websiteSubscriptions = action.payload.allsubscriptions;
    });

    builder.addCase(getSubmittedSitesTransaction.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(getSubmittedSitesAllWebsite.fulfilled, (state, action) => {
      state.submittedsitesdetails = action.payload.websites;
    });

    builder.addCase(getSubmittedSitesAllWebsite.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(updatedataSubmittedSites.fulfilled, (state, action) => {
      const foundItem = state.allSubmittedSitesData.find(item => item._id === action.payload.website._id);

      if (foundItem) {
        state.allSubmittedSitesData = state.allSubmittedSitesData.map((website) =>
          website._id.toString() === action.payload.website
            ._id.toString()
            ? {
              ...website, ...action.payload.website
            }
            : website
        );
      } else {
        state.allSubmittedSitesData.push(action.payload.website);
      }
    });

    builder.addCase(updatedataSubmittedSites.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(deletedataSubmittedSites.fulfilled, (state, action) => {
      state.allSubmittedSitesData = (state.allSubmittedSitesData || []).filter((Submitted) => Submitted._id.toString() !== action.payload.SubmittedSites.toString());
    });

    builder.addCase(deletedataSubmittedSites.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(getSiteStatusActiveandDeactiveData.fulfilled, (state, action) => {
      state.allSubmittedSitesData = state.allSubmittedSitesData.map((website) =>
        website._id.toString() === action.payload.WebSite._id.toString() ? { ...website, ...action.payload.WebSite } : website
      );
    });

    builder.addCase(getSiteStatusActiveandDeactiveData.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });
  },
});

export default SubmittedSitesSlice.reducer;
