import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    websiteList: [],
    userWebsiteList: [],
    error: {},
    websiteByID: {},
    websiteOfferPorduct: [],
    websiteByName: {}
};




const DashboardSlice = createSlice({
    name: "DashboardSlice",
    initialState,
    reducers: {

        getAllWebsiteListSuccess(state, action) {
            state.websiteList = action?.payload || [];
        },

        addNewWebsiteSuccess(state, action) {
            // console.log('action L:', action);
        },

        getUserAllWebsiteSuccess(state, action) {
            state.userWebsiteList = action?.payload || [];
        },

        getWebsiteOfferProductSuccess(state, action) {
            state.websiteOfferPorduct = action?.payload || [];
        },

        getWebsiteByIDSuccess(state, action) {
            state.websiteByID = action?.payload || {};
        },
        getWebsiteByNameSuccess(state, action) {
            state.websiteByName = action?.payload || {};
        },
        clearWebsiteByNamesSuccess(state, action) {
            state.websiteByName = {};
        },

        addNewOfferProductSuccess(state, action) {
            // state.websiteOfferPorduct = state.websiteOfferPorduct.push(action?.payload?.newOffer);
            state.websiteOfferPorduct = [...state.websiteOfferPorduct, action?.payload?.newOffer];


        },

        updateOfferProductSuccess(state, action) {
            state.websiteOfferPorduct = state.websiteOfferPorduct.map(product =>
                product._id.toString() === action.payload._id.toString()
                    ? { ...product, ...action.payload }
                    : product
            );
        },

        deleteWebsiteOfferProductSuccess(state, action) {
            state.websiteOfferPorduct = state.websiteOfferPorduct.filter(
                product => product._id.toString() !== action.payload.toString()
            )
        },
        deleteWebsiteSuccess(state, action) {
            state.userWebsiteList = state.userWebsiteList.filter(
                site => site._id.toString() !== action.payload.toString()
            );
            state.websiteList = state.websiteList.filter(
                site => site._id.toString() !== action.payload.toString()
            )
        }
    },


});


export const {
    addNewWebsiteSuccess,
    getAllWebsiteListSuccess,
    getUserAllWebsiteSuccess,
    getWebsiteOfferProductSuccess,
    getWebsiteByIDSuccess,
    getWebsiteByNameSuccess,
    clearWebsiteByNamesSuccess,
    addNewOfferProductSuccess,
    updateOfferProductSuccess,
    deleteWebsiteOfferProductSuccess,
    deleteWebsiteSuccess
} = DashboardSlice.actions

export default DashboardSlice.reducer;