

import React, { useEffect, useRef, useState } from 'react';
import { Button, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown, } from 'reactstrap';

import Select from 'react-select';
import { useFormik } from 'formik';
import { object, string, } from 'yup';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Header from '../../../../Layouts/FrontHeader';
import Footer from '../../../../Layouts/FrontFooter';
import { useDispatch, useSelector } from 'react-redux';
import {
    addNewOfferProduct,
    deleteWebsiteOfferProduct,
    getWebsiteOfferProduct,
    updateOfferProduct
} from '../../../../slices/FrontView/dashboard/thunk';
import { api } from '../../../../config';
import website_background from "../../../../assets/images/website_background.png";
import { isEmpty } from "lodash";
import { useCallback } from 'react';


const OfferProductList = () => {
    const navigate = useNavigate()

    useEffect(() => {
        const obj = JSON.parse(sessionStorage.getItem('authUser'))?.data
        if (!obj) {
            navigate('/login')
        }
    }, [navigate])


    const dispatch = useDispatch();
    const location = useLocation();
    const { state } = location;
    const { websiteOfferPorduct } = useSelector((state) => state.Dashboard);
    const { cmsData } = useSelector((state) => state.CMSF);


    const [website, setWebsite] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const [offerProduct, setOfferProduct] = useState(null);



    useEffect(() => {
        setOfferProduct(websiteOfferPorduct)
    }, [websiteOfferPorduct])

    useEffect(() => {
        if (!isEmpty(websiteOfferPorduct)) {
            setOfferProduct(websiteOfferPorduct);
            setIsEdit(false);
        }
    }, [websiteOfferPorduct]);


    useEffect(() => {
        if (state === null) {
            setWebsite(null)

        } else {
            setWebsite(state.website)
            dispatch(getWebsiteOfferProduct(state.website._id))
        }
    }, [state, dispatch])


    const handleEdit = useCallback((arg) => {
        const data = arg;
        setOfferProduct({
            id: data._id,
            name: data.Offer_Name,
            OriginalPrice: data.OriginalPrice,
            Description: data.Description,
            SalePrice: data.SalePrice,
            BuyLink: data.BuyNowLink,
            categories: data.BillingPeriod,
            Website_ID: data.Website_ID
        })
        setIsEdit(true);
    }, []);


    const handleDelete = (data) => {
        dispatch(deleteWebsiteOfferProduct(data._id))
    };


    const userSchema = object({
        name: string().required('Please Enter Offer Name'),
        OriginalPrice: string().required('Please Enter Original Price'),
        SalePrice: string().required('Please Enter Sale Price'),
        Description: string().required('Please Enter Description'),
        BuyLink: string()
            .matches(
                /^(https?:\/\/|www\.)[a-zA-Z0-9@:%._\+~#?&//=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%._\+~#?&//=]*)$/,
                'Please Enter valid URL'
            )
            .required('Please Enter URL'),
        categories: string().required('Select Billing Period'),
    });

    const formik = useFormik({
        enableReinitialize: true,

        initialValues: {
            name: (offerProduct && offerProduct?.name) || '',
            OriginalPrice: (offerProduct && offerProduct?.OriginalPrice) || '',
            SalePrice: (offerProduct && offerProduct?.SalePrice) || '',
            Description: (offerProduct && offerProduct?.Description) || '',
            BuyLink: (offerProduct && offerProduct?.BuyLink) || '',
            categories: (offerProduct && offerProduct?.categories) || '',
        },
        validationSchema: userSchema,
        onSubmit: (values) => {

            if (isEdit) {
                const updateObj = {
                    id: offerProduct.id,
                    Website_ID: offerProduct.Website_ID,
                    Offer_Name: values.name,
                    OriginalPrice: values.OriginalPrice,
                    SalePrice: values.SalePrice,
                    Description: values.Description,
                    BuyNowLink: values.BuyLink,
                    BillingPeriod: values.categories
                }
                dispatch(updateOfferProduct(updateObj))

            } else {
                const productObj = {
                    Website_ID: state?.website?._id,
                    Offer_Name: values.name,
                    OriginalPrice: values.OriginalPrice,
                    SalePrice: values.SalePrice,
                    Description: values.Description,
                    BuyNowLink: values.BuyLink,
                    BillingPeriod: values.categories
                }

                dispatch(addNewOfferProduct(productObj))
            }
            formik.resetForm();
        },
    });
    const { values, touched, handleBlur, handleChange, handleSubmit, errors, setFieldValue } = formik

    const offerOptions = [
        { label: "Select..", value: "" },
        { label: "Daily", value: "Daily" },
        { label: "Weekly", value: "Weekly" },
        { label: "Monthly", value: "Monthly" },
        { label: "Annually", value: "Annually" },
        { label: "LifeTime", value: "One-time" },

    ];
    const myElementRef = useRef(null);
    const [footerHeight, setFooterHeight] = useState(700);
    useEffect(() => {
        if (myElementRef?.current) {
            const elementHeight = myElementRef.current.getBoundingClientRect().height;
            setFooterHeight(elementHeight)
        }
    });
    document.title = `${cmsData?.websiteTitle ? cmsData?.websiteTitle : '1000 Ai Sites'} | Offers/Product`;

    return (
        <div>
            <div ref={myElementRef}>
                <Header />
                <div className='offerpage mb-22' >
                    <div className="px-4 py-20 px-md-5 text-center text-lg-start" >
                        <section>
                            <div >
                                <div className="px-5 py-3 px-md-5 text-center text-lg-start" style={{ backgroundColor: 'hsl(0, 0%, 96%)' }}>
                                    <div className="flex items-center space-x-2" style={{ justifyContent: 'space-evenly', marginBottom: '1%' }}>
                                        <div className='yourwebsite-text'>
                                            Your Websites
                                        </div>
                                        <Link className="btn btn-labeled btn-primary " to='/register'>
                                            <span className="btn-label"><i className="bx bx-plus"></i></span>
                                            Submit New Website
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section>

                            <div className="lg:px-4 py-2 px-md-5 text-center text-lg-start " style={{ backgroundColor: 'hsl(0, 0%, 96%)' }} >
                                <div className="container">
                                    <div className="row gx-lg-5 align-items-center justify-content-center">
                                        <div className="col-lg-8 mb-5 mb-lg-0 relative">
                                            <div className="card ">
                                                <div className="card-body py-5 px-md-5">
                                                    <div className="flex  justify-between mt-4 mb-4">
                                                        <div className="flex-none mr-3 lg:mr-4 rounded-[22.5%] w-20 h-20 overflow-hidden">
                                                            <img className="w-20 h-20" src={website?.Logo ? api.API_URL + '/' + website?.Logo : website_background} alt={`Logo of ${website?.Site_Name}`} loading="lazy" />
                                                        </div>
                                                        <div className='flex-grow'>
                                                            <h1 className="font-bold mb-0">{website?.Site_Name || website?.Site_Link || 'website name'}</h1>
                                                            <p className="text-sm text-gray-400">
                                                                {website?.Short_Description?.length > 50 ? `${website?.Short_Description.substring(0, 80)}....` : website?.Short_Description}
                                                                {/* {website?.Short_Description} */}
                                                            </p>
                                                        </div>
                                                        <div>
                                                        </div>
                                                    </div>

                                                    <h3 className="text-center font-semibold mb-4">
                                                        Manage Offers/Product
                                                    </h3>
                                                    <form onSubmit={handleSubmit}>
                                                        <Row>
                                                            <div className='col-12 g-2 '>
                                                                <label className="form-label" htmlFor="form3Example3">Offer/Product Name</label>
                                                                <input
                                                                    className='form-control'
                                                                    margin="dense"
                                                                    id="name"
                                                                    name='name'
                                                                    value={values.name}
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    placeholder="Enter your Offers/Product Name"
                                                                    fullWidth
                                                                    variant="standard"
                                                                />
                                                                <div className='error'>
                                                                    <span>
                                                                        {errors.name && touched.name ? errors.name : ''}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className='col-12 g-2 col-lg-4'>
                                                                <label className="form-label" htmlFor="form3Example3">Original Price</label>
                                                                <input
                                                                    className='form-control'
                                                                    margin="dense"
                                                                    id="OriginalPrice"
                                                                    name='OriginalPrice'
                                                                    value={values.OriginalPrice}
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    placeholder="Enter  Original Price"
                                                                    type="number"
                                                                    fullWidth
                                                                    variant="standard"
                                                                />
                                                                <div className='error'>
                                                                    <span> {errors.OriginalPrice && touched.OriginalPrice ? errors.OriginalPrice : ''}</span>
                                                                </div>
                                                            </div>
                                                            <div className='col-12 g-2 col-lg-4'>
                                                                <label className="form-label" htmlFor="form3Example3">Sale Price</label>
                                                                <input
                                                                    className='form-control'
                                                                    margin="dense"
                                                                    id="SalePrice"
                                                                    name='SalePrice'
                                                                    value={values.SalePrice}
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    placeholder="Enter  Sale Price"
                                                                    type="number"
                                                                    fullWidth
                                                                    variant="standard"
                                                                />
                                                                <div className='error'>
                                                                    <span> {errors.SalePrice && touched.SalePrice ? errors.SalePrice : ''}</span>
                                                                </div>

                                                            </div>
                                                            <div className='col-12 g-2 col-lg-4'>
                                                                <label className="form-label" htmlFor="selectedOption">Billing Period</label>

                                                                <Select
                                                                    name="selectedOption"
                                                                    classNamePrefix="select"
                                                                    id='selectedOption'
                                                                    options={offerOptions}
                                                                    value={offerOptions.find((option) => option.value === values.categories)}
                                                                    onChange={(selectedOption) => setFieldValue('categories', selectedOption ? selectedOption.value : '')}
                                                                    onBlur={handleBlur}

                                                                />
                                                                <div className='error'>
                                                                    <span >{errors.categories && touched.categories ? errors.categories : ''}</span>
                                                                </div>

                                                            </div>
                                                            <div className='col-12 g-2'>
                                                                <label className="form-label" htmlFor="form3Example3">Offers/Product Details</label>
                                                                <textarea
                                                                    name="Description"
                                                                    value={values.Description}
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    placeholder='Enter Offers/Product Details'
                                                                    className='border' />
                                                                <div className='error'>
                                                                    <span >{errors.Description && touched.Description ? errors.Description : ''}</span>
                                                                </div>
                                                            </div>
                                                            <div className='col-12 g-2'>
                                                                <label className="form-label" htmlFor="form3Example3">Buy Now Link</label>
                                                                <input
                                                                    className='form-control'
                                                                    margin="dense"
                                                                    id="BuyLink"
                                                                    name='BuyLink'
                                                                    value={values.BuyLink}
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    placeholder="Enter Buy Now Link"
                                                                    type="text"
                                                                    fullWidth
                                                                    variant="standard"
                                                                />
                                                                <div className='error'>
                                                                    <span >{errors.BuyLink && touched.BuyLink ? errors.BuyLink : ''}</span>
                                                                </div>
                                                            </div>
                                                        </Row>
                                                        <Button className='mt-4 primary-btn'>Submit</Button>
                                                    </form>
                                                    <div className="absolute left-4 top-4">
                                                        <Link className=" flex " style={{ color: '#3d78e3' }} to='/submitList'>
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 mr-1">
                                                                <path fillRule="evenodd" d="M17 10a.75.75 0 01-.75.75H5.612l4.158 3.96a.75.75 0 11-1.04 1.08l-5.5-5.25a.75.75 0 010-1.08l5.5-5.25a.75.75 0 111.04 1.08L5.612 9.25H16.25A.75.75 0 0117 10z" clipRule="evenodd" />
                                                            </svg>
                                                            Back
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {websiteOfferPorduct?.length > 0 &&
                            <div className="text-center text-lg-start" style={{ backgroundColor: 'hsl(0, 0%, 96%)' }} >
                                <div className="row gx-lg-5 justify-content-center">
                                    <div className="col-lg-5">
                                        <div className="mx-4 lg:-mx-12 py-4 bg-gray-100 lg:rounded-xl ">
                                            <div className="relative w-full flex gap-4 snap-x overflow-x-auto scrollbar scrollbar-h-[0px] scrollbar-thumb-transparent scrollbar-track-transparent ">
                                                {
                                                    websiteOfferPorduct?.map((item, index) => {

                                                        return (
                                                            <div key={index} className="snap-start shrink-0">
                                                                <div className="card">
                                                                    <div className="card-body w-60 relative">

                                                                        <h3 className="text-sm font-bold mb-2">{item?.Offer_Name}</h3>
                                                                        <div className="text-sm mb-2">${item?.SalePrice}{" "}
                                                                            <span className="text-gray-500 line-through"> ${item?.OriginalPrice}</span>
                                                                            <span> / {item?.BillingPeriod}</span>
                                                                        </div>
                                                                        <div className='more-button'>

                                                                            <UncontrolledDropdown>
                                                                                <DropdownToggle className='offerDropdownItem'>

                                                                                    <i className="ri-more-2-fill"></i>
                                                                                </DropdownToggle>
                                                                                <DropdownMenu className='offerDropdownItem'>
                                                                                    <DropdownItem className='offerDropdownItem' onClick={() => { handleEdit(item) }}>
                                                                                        <i className="bi bi-pencil-square"> Edit</i>
                                                                                    </DropdownItem>
                                                                                    <DropdownItem className='offerDropdownItem' onClick={() => { handleDelete(item) }}>
                                                                                        <i className="bi bi-trash3"> Delete</i>
                                                                                    </DropdownItem>

                                                                                </DropdownMenu>
                                                                            </UncontrolledDropdown>
                                                                        </div>
                                                                        <div className="mb-4">
                                                                            <div className="inline-block px-1.5 py-0.5 border border-green-200 bg-green-50 text-green-500 text-xs rounded-full">
                                                                                You save ${item?.OriginalPrice - item?.SalePrice}</div>
                                                                        </div>
                                                                        <div data-type="url" className="group">
                                                                            <div className="hidden group-data-[type=url]:block ">
                                                                                <a className="btn btn-sm btn-primary w-full primary-btn" href={item?.BuyNowLink} target="__blank">
                                                                                    Buy now
                                                                                </a>
                                                                            </div>
                                                                            <div className="hidden group-data-[type=coupon]:block">
                                                                                <div className="btn btn-sm btn-gray w-full primary-btn" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <Footer footer_style={footerHeight > window.innerHeight ? 'App-footer-scroll' : ''} />
        </div>

    );
}

export default OfferProductList;