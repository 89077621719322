import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../../../Layouts/FrontHeader';
import Footer from '../../../Layouts/FrontFooter';

import { useFormik } from 'formik';
import { object, string, } from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { userLoginWithEmail, userloginWithPassword } from '../../../slices/FrontView/authentication/thunk';
import { Button, Spinner } from 'reactstrap';

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { isEmail } = useSelector((state) => state.Auth);
    const { cmsData } = useSelector((state) => state.CMSF);

    const [isloading, setIsLoading] = useState(false)


    const emailFormik = useFormik({
        enableReinitialize: true,
        initialValues: { email: '' },

        validationSchema: object({ email: string().email().required('Please Enter Email') }),

        onSubmit: (values,) => {
            dispatch(userLoginWithEmail(values))
            setIsLoading(true)

            // emailFormik.resetForm();
        },
    });

    useEffect(() => {
        if (isloading) {
            setTimeout(() => {
                setIsLoading(false)

            }, 5000)
        }
    }, [isloading])

    const passwordFormik = useFormik({
        enableReinitialize: true,
        initialValues: { password: '' },

        validationSchema: object().shape({
            password: string()
                .required('Password is required')
            // .min(8, 'Password must be at least 8 characters')
            // .matches(
            //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
            //     'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
            // ),
        }),

        onSubmit: (values) => {
            dispatch(userloginWithPassword({ email: emailFormik.values.email, password: passwordFormik.values.password }, navigate))
            emailFormik.resetForm();
            passwordFormik.resetForm();
        },
    });

    document.title = `${cmsData?.websiteTitle ? cmsData?.websiteTitle : '1000 Ai Sites'} | Login`;
    return (
        <div>

            <Header isactive={'active-login'} />
            <div className='detilspage'>
                <div className="lg:px-4 py-20 px-md-5 text-center text-lg-start login-forget"  >
                    <div className="container" >
                        <div className="row gx-lg-5 align-items-center justify-content-center">


                            <div className="col-lg-5 pt-20 mb-lg-0 ">
                                <div className="card m-mb-42">
                                    <div className="card-body py-5 px-md-5">
                                        <div className='text-center'>
                                            <h1 className="text-3xl font-bold mb-4">
                                                Log In
                                            </h1>
                                            <p className="text-lg login-subhading mb-4">Enter your details to continue to manage your websites.</p>
                                        </div>

                                        <form onSubmit={emailFormik.handleSubmit}>

                                            <div className="form-outline mb-4 relative">
                                                <label htmlFor="email" className="form-label" >Enter Email Address</label>
                                                <input
                                                    id="email"
                                                    className="form-control input"
                                                    type="email"
                                                    name="email"
                                                    placeholder="Enter Email Address"
                                                    value={emailFormik.values.email}
                                                    onBlur={emailFormik.handleBlur}
                                                    onChange={emailFormik.handleChange} />
                                                <div className='error'>
                                                    <span >{emailFormik.errors.email && emailFormik.touched.email ? emailFormik.errors.email : ''}</span>

                                                </div>
                                            </div>


                                            {!isEmail &&
                                                <Button disabled={isloading ? true : false} className="btn btn-primary primary-btn btn-block" type="submit">
                                                    {isloading ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}
                                                    Log In
                                                </Button>
                                                // <button type="submit" className="btn btn-primary btn-block primary-btn mb-4">Log In</button>
                                            }
                                        </form>
                                        {isEmail &&
                                            <form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    passwordFormik.handleSubmit();
                                                    return false;
                                                }}>

                                                <div className="form-outline mb-4 relative">

                                                    <label htmlFor="password" className="form-label " >Enter your password</label>
                                                    <input id="password" className="form-control input" type="password" name="password" placeholder='Enter your password' value={passwordFormik.values.password} onBlur={passwordFormik.handleBlur} onChange={passwordFormik.handleChange} />
                                                    <div className='error'>
                                                        <span >{passwordFormik.errors.password && passwordFormik.touched.password ? passwordFormik.errors.password : ''}</span>
                                                    </div>
                                                    <div className='text-end mt-1'>
                                                        <Link to='/forgetPassword'>Forget Password ?</Link>
                                                    </div>
                                                </div>
                                                <button type="submit" className="btn btn-primary primary-btn btn-block">
                                                    Log In
                                                </button>

                                            </form>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div >
            <Footer websiteList={'footerList'} />
        </div >
    );
}

export default Login;