import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    user: {},
    error: "", // for error message
    loading: false,
    isUserLogout: false,
    errorMsg: false, // for error,
    isEmail: false,
    isPassword: true
};

const authenticationSlice = createSlice({
    name: "authentication",
    initialState,
    reducers: {

        userloginEmailSuccess(state, action) {
            state.isEmail = action.payload
            state.loading=true
        },
        userforgetpasswordEmailSuccess(state, action) {
            state.isEmail = action.payload;
            state.loading=false
        },
        userloginSuccess(state, action) {
            state.user = action.payload
        },

        checkPasswordStatusSuccess(state, action) {
            state.isPassword = action.payload?.passwordStatus === 'true' ? true : false
        },

        adminForgetPasswordSuccess(state, action) {
            state.forgetSuccessMsg = action.payload
        },
        setPasswordSuccess(state, action) {
            state.isPassword = false
        }
    },
});

export const {
    adminForgetPasswordSuccess,
    userforgetpasswordEmailSuccess,
    userloginEmailSuccess,
    userloginSuccess,
    checkPasswordStatusSuccess,
    setPasswordSuccess,
} = authenticationSlice.actions

export default authenticationSlice.reducer;