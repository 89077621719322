import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { getEditordata } from '../slices/thunks';
import { api } from '../config';

const FrontFooter = ({ websiteList, footer_style }) => {
    const dispatch = useDispatch();

    const { editordetails } = useSelector((state) => state.Editors);

    useEffect(() => {
        dispatch(getEditordata());
    }, [dispatch]);



    return (
        <footer className={`App-footer ${footer_style}`} >

            <Row className='text-center'>
                <div>
                    <Link className='mx-1' to={`${api.APP_URL}/pages/privacy-policy`} target='_blank'>
                        <span className="py-0.5">Privacy Policy</span>
                    </Link>
                    <Link className='mx-1' to={`${api.APP_URL}/pages/cancellation-refund-policy`} target='_blank'>
                        <span className="py-0.5">Cancellation & Refund Policy</span>
                    </Link>
                    <Link className='mx-1' to={`${api.APP_URL}/pages/support`} target='_blank'>
                        <span className="py-0.5">Support</span>
                    </Link>
                    <Link className='mx-1' to={`${api.APP_URL}/pages/terms-of-service`} target='_blank'>
                        <span className="py-0.5">Terms Of Service</span>
                    </Link>

                </div>

                <Col sm={12} className='mb-1'>
                    © {new Date().getFullYear()} All rights reserved
                </Col>
            </Row>

        </footer>
    );
};

export default FrontFooter;