
import React, { useState } from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
    Input,
    Label,
    Form,
    Col,
    Row,
} from 'reactstrap';
import { useFormik } from 'formik';
import { object, string, ref } from 'yup';
import { useDispatch } from 'react-redux';
import { submitNewPassword } from '../../slices/FrontView/authentication/thunk';

const SetPasswordModal = ({ user }) => {
    const dispatch = useDispatch();

    const [modal, setModal] = useState(true);


    const toggle = () => setModal(!modal);

    let userSchema = object({
        password: string().max(8).required(),
        // .matches(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/, "please Enter strog password"),

        Conformpassword: string()
            .oneOf([ref('password'), null], 'Passwords must match')
            .required("Confirm Password Required"),
    })

    const formik = useFormik({
        initialValues: {
            password: '',
            Conformpassword: '',
        },
        validationSchema: userSchema,
        onSubmit: (values, action) => {
            const newObj = {
                id: user._id,
                newPassword: values.password
            }
            dispatch(submitNewPassword(newObj));

            action.resetForm();
            setModal(false)
        },
    });
    const { values, touched, handleBlur, handleChange, handleSubmit, errors, setValues, setFieldValue } = formik
    return (

        <div>
            <Modal isOpen={modal}
                backdrop={false}
                className='tablelist-1'
                centered
                size="lg"
            >
                <Form className="tablelist-form" onSubmit={handleSubmit} >
                    <ModalHeader toggle={toggle} >
                        Create a New Password
                    </ModalHeader>
                    <ModalBody >
                        <Row className="g-3">
                            <Col lg={12}>
                                <div>
                                    <Label htmlFor="password" className="form-label">Password</Label>
                                    <Input
                                        name="password"
                                        id="password"
                                        className="form-control"
                                        placeholder="Please Enter Your Password"
                                        type="password"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    />
                                    <span className='error'>{errors.password && touched.password ? errors.password : ''}</span>

                                </div>
                            </Col>
                            <Col lg={12}>
                                <div>
                                    <Label htmlFor="ConfirmPassword" className="form-label">Confirm Password</Label>
                                    <Input
                                        name='Conformpassword'
                                        className="form-control"
                                        id="ConfirmPassword"
                                        placeholder="Please Confirm Your Password"
                                        type='password'
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    />
                                    <span className='error'>{errors.Conformpassword && touched.Conformpassword ? errors.Conformpassword : ''}</span>

                                </div>
                            </Col>


                        </Row>

                    </ModalBody>
                    <div className="modal-footer">
                        <div className="hstack gap-2 justify-content-end">
                            {/* <button onClick={() => { setModal(false); }} className="btn btn-light m-2" data-bs-dismiss="modal">Cancel</button> */}
                            <button type="submit" className="btn btn-success" id="add-btn">Submit</button>
                        </div>
                    </div>
                </Form>
            </Modal>
        </div>
    );
}

export default SetPasswordModal;