import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Card, CardBody, Col, Row, } from 'reactstrap';


import moment from 'moment';
import avatar1 from '../../../../assets/images/users/Placeholder_view_vector.svg.png';
import { api } from '../../../../config';

const OverviewTab = (props) => {
    const location = useLocation();

    const handleValidDate = date => {
        const date1 = moment(new Date(date)).format("DD MMM Y");
        return date1;
    };
    const { state } = location;

    const payment_status = state?.Payment_Status || state?.Subscription?.payment_status;
    return (

        <React.Fragment>
            <Row className='justify-content-center'>
                <Col xl={12} lg={12}>
                    <Card>
                        <CardBody>
                            <div className="d-flex align-items-center flex-wrap gap-2">
                                <div className="text-muted flex-grow-1">
                                    <div className="col-md">
                                        <Row className="align-items-center g-3">
                                            <div className="col-md-auto">
                                                <div>
                                                    <div className="bg-white rounded-circle">
                                                        {
                                                            state?.Logo ?
                                                                <img src={api.API_URL + '/' + state?.Logo} alt="" className="avatar-sm" />
                                                                :
                                                                <img src={avatar1} alt="" className="avatar-sm" />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md">
                                                <div>
                                                    <h4 className="fw-bold">{state?.Site_Name}</h4>
                                                    <a href={state?.Site_Link} target='_blank' className="link-secondary">{state?.Site_Link}</a>
                                                </div>
                                            </div>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row className='justify-content-center'>
                <Col xl={6} lg={8}>
                    <Card>
                        <CardBody>
                            <div className="text-muted">
                                <ul className="ps-4 vstack gap-2">
                                    <li style={{ listStyle: "none", display: "flex" }}><span style={{ color: "#878a99", marginRight: "5px" }}>Submitted By: </span>
                                        {state.Submitted_By?.Email}
                                    </li>


                                    <li style={{ listStyle: "none" }}>
                                        <span style={{ color: "#878a99" }}>Top 10: </span>
                                        {
                                            state?.TOP_10 === 'true' ? <span className="badge text-uppercase text-white bg-success fs-14"> Yes </span>
                                                : <span className="badge text-uppercase bg-danger text-white fs-14"> No </span>
                                        }
                                    </li>
                                    <li style={{ listStyle: "none" }}>
                                        <span style={{ color: "#878a99" }}>Payment Status: </span>
                                        {
                                            payment_status === "Completed" || payment_status === "completed" ? <span className="badge text-success text-uppercase fs-14 badge-soft-success"><i className="ri-checkbox-circle-line align-bottom"></i> Completed </span>
                                                : payment_status === "Canceled" || payment_status === "canceled" ? <span className="badge text-danger text-uppercase fs-14 badge-soft-danger"><i className="ri-close-circle-line align-bottom"></i>  Canceled </span>
                                                    : payment_status === "Failed" || payment_status === "failed" ? <span className="badge text-danger text-uppercase fs-14 badge-soft-danger"><i className="ri-close-circle-line align-bottom"></i> Failed </span>
                                                        : payment_status === "Pending" || payment_status === "pending" ? <span className="badge text-uppercase text-warning fs-14 badge-soft-warning"><i className="ri-time-line align-bottom"></i>  Pending </span>
                                                            : <span className="badge text-uppercase text-warning fs-14 badge-soft-warning"><i className="ri-time-line align-bottom"></i>  Pending </span>
                                        }
                                    </li>
                                    <li style={{ listStyle: "none" }}>
                                        <span style={{ color: "#878a99" }}>Approval Status: </span>
                                        {
                                            state?.Approval_Status === 'Approved' ? <span className="badge text-success text-uppercase fs-14 badge-soft-success"><i className="ri-checkbox-circle-line align-bottom"></i> Approved </span>
                                                : state?.Approval_Status === 'Rejected' ? <span className="badge text-danger text-uppercase fs-14 badge-soft-danger"><i className="ri-close-circle-line align-bottom"></i> Rejected </span>
                                                    : <span className="badge text-warning text-uppercase fs-14 badge-soft-warning"><i className="ri-time-line align-bottom"></i> Pending </span>
                                        }
                                    </li>
                                    <li style={{ listStyle: "none" }}><span style={{ color: "#878a99" }}>Short Description: </span>{state?.Short_Description ? state?.Short_Description : <>no data available</>}</li>
                                </ul>
                            </div>
                        </CardBody>

                    </Card>
                </Col>
                <Col xl={6} lg={8}>
                    <Card>
                        <CardBody>
                            <div className="text-muted">
                                <ul className="ps-4 vstack gap-2">
                                    <li style={{ listStyle: "none" }}>
                                        <span style={{ color: "#878a99" }}>Category: </span>
                                        {
                                            state?.Category ? state?.Category.map((item, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <p className="badge badge-soft-primary fs-12 me-1 mb-1">
                                                            {item}
                                                        </p>
                                                        {(index + 1) % 5 === 0 && <br />} {/* Break to new line every 2 items */}
                                                    </React.Fragment>
                                                )
                                            }) : <>-</>
                                        }
                                    </li>
                                    <li style={{ listStyle: "none" }}>
                                        <span style={{ color: "#878a99" }}>Subscription ID : </span>
                                        <span className="badge text-uppercase text-info badge-soft-info fs-14"> {state?.Subscription?.subscription_id || state?.Subscription_ID?.subscription_id ? state?.Subscription?.subscription_id || state?.Subscription_ID?.subscription_id : '-'} </span>
                                    </li>
                                    <li style={{ listStyle: "none" }}>
                                        <span style={{ color: "#878a99" }}>Subscription Type: </span>
                                        <p className="badge mb-0 badge-soft-secondary fs-14">{state?.Plan?.billingPeriod || '-'}</p>
                                    </li>
                                    <li style={{ listStyle: "none" }}>
                                        <span style={{ color: "#878a99" }}>Start Date: </span>
                                        <p className="badge mb-0 badge-soft-success fs-14">{state?.Date_Submitted ? handleValidDate(state?.Date_Submitted) : '-'}</p>
                                    </li>
                                    {state.Subscription_End_Date && <li style={{ listStyle: "none" }}>
                                        <span style={{ color: "#878a99" }}>Next Payment Date: </span>
                                        <p className="badge mb-0 badge-soft-danger fs-14">{handleValidDate(state?.Subscription_End_Date)}</p>
                                    </li>}
                                </ul>
                            </div>
                        </CardBody>

                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default OverviewTab;