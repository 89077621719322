import React, { useCallback, useEffect, useMemo, useState, } from 'react';
import { Card, CardBody, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Form, FormFeedback, Input, Label, Modal, ModalBody, Row, UncontrolledDropdown } from 'reactstrap';

import { useDispatch, useSelector } from 'react-redux';
import { addNewBlog, deletedataEditor, getAdminUserdata, getBlogAllData, getCategorydata, getEditordata, updatedataBlog, updatedataEditor } from '../../../slices/thunks';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import TableContainer from '../../../Components/Common/TableContainer';
import Loader from '../../../Components/Common/Loader';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { isEmpty } from "lodash";
import DeleteModal from '../../../Components/Common/DeleteModal';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ProfileImage from "../../../assets/images/users/Placeholder_view_vector.svg.png";
import Select from "react-select";
import withRouter from '../../../Components/Common/withRouter';
import { api } from '../../../config';

const BlogForm = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();
    let navigate = useNavigate();
    const { state } = location;

    const [contentData, setContentData] = useState(null)
    const [faviconvalue, setFaviconValue] = useState(null);
    const [isImage, setIsImage] = useState(ProfileImage);

    const AdminUser = useSelector((state) => state.AdminUser.adminusersdetails);
    const Category = useSelector((state) => state.Category.categorydetails);
    const Blog = useSelector((state) => state.Blog.blogdetails);
    // console.log('Blog :', Blog);

    useEffect(() => {
        dispatch(getBlogAllData());
        dispatch(getAdminUserdata());
        dispatch(getCategorydata());
        dispatch(getEditordata());
    }, [dispatch]);

    useEffect(() => {
        setContentData(state)
        if (state === null) {
            setIsImage(ProfileImage)
        } else {
            setIsImage(api.API_URL + '/' + state?.blog_image)
        }
    }, [state])

    useEffect(() => {
        if (!isEmpty(state)) {
            setContentData(state);
            if (state === null) {
                setIsImage(ProfileImage)
            } else {
                setIsImage(api.API_URL + '/' + state?.blog_image)
            }
        }
    }, [state]);

    const modules = {
        toolbar: [
            [{ header: '1' }, { header: '2' }, { font: [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [
                { list: 'ordered' },
                { list: 'bullet' },
                { indent: '-1' },
                { indent: '+1' },
            ],
            ['link', 'image', 'video'],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'align': [] }],
            ['clean'],
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        },
    }

    const formats = [
        'header',
        'font',
        'size',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
        'video',
        'color',
        'background',
        'align'
    ]


    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            _id: (contentData && contentData._id) || null,
            user_ID: (contentData && contentData.user_ID._id) || '',
            blog_image: (contentData && contentData.blog_image) || '',
            title: (contentData && contentData.title) || '',
            longDetail: (contentData && contentData.longDetail) || '',
            shortDetail: (contentData && contentData.shortDetail) || '',
            categories: (contentData && contentData.categories) || []
        },
        validationSchema: Yup.object({
            title: Yup.string().required("Please Enter Blog Title"),
            longDetail: Yup.string().required("Please Enter Blog Description"),
            user_ID: Yup.string().required("Please Select Your Email"),
        }).shape({
            blog_image: Yup.string().required("Please Enter Blog Image"),
        }),
        onSubmit: (values) => {
            if (state !== null) {
                const updateData = new FormData();
                updateData.append('id', values._id);
                updateData.append('title', values.title);
                updateData.append('blog_image', values.blog_image);
                updateData.append('longDetail', values.longDetail);
                updateData.append('shortDetail', values.shortDetail);
                updateData.append('user_ID', values.user_ID);
                updateData.append('categories', values.categories);
                dispatch(updatedataBlog(updateData));
                setContentData(null)
                navigate('/admin/blog')
            } else {
                const formData = new FormData();
                formData.append('title', values.title);
                formData.append('blog_image', values.blog_image);
                formData.append('longDetail', values.longDetail);
                formData.append('shortDetail', values.shortDetail);
                formData.append('user_ID', values.user_ID);
                formData.append('categories', values.categories);
                dispatch(addNewBlog(formData));
                navigate('/admin/blog')
            }
            validation.resetForm();
            setIsImage(ProfileImage);
        }
    })


    const SelectCategory = Category.map((item) => (
        { label: item.CategoryName, value: item.CategoryName }
    ))
    // console.log("SelectCategory: ", SelectCategory);
    document.title = "Admin Panel | Blog Form";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row className='justify-content-center'>
                        <Col xxl={12}>
                            <Card>
                                <CardBody>
                                    <Form
                                        className="tablelist-form"
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}
                                    >
                                        <Row>
                                            <Col className='mb-3' lg={12}>
                                                <div>
                                                    <div className="position-relative d-inline-block">
                                                        <div className="position-absolute  bottom-0 end-0">
                                                            <Label htmlFor="blog_image" className="mb-0">
                                                                <div className="avatar-xs cursor-pointer">
                                                                    <div className="avatar-title bg-light border text-muted">
                                                                        <i className="ri-image-fill"></i>
                                                                    </div>
                                                                </div>
                                                            </Label>
                                                            <Input className="form-control d-none"
                                                                id="blog_image" type="file"
                                                                name="blog_image"

                                                                invalid={
                                                                    validation.touched.blog_image && validation.errors.blog_image ? true : false
                                                                }
                                                                onChange={(event) => {
                                                                    const file = event.target.files[0];
                                                                    const reader = new FileReader();
                                                                    reader.onload = (event) => {
                                                                        validation.setFieldValue('blog_image', file);
                                                                        setIsImage(event.target.result)
                                                                    };
                                                                    reader.readAsDataURL(file);
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="avatar-lg p-1">
                                                            <div className="avatar-title bg-light ">
                                                                <img src={isImage} alt="img" id="customer-img" className="avatar-md object-cover img-thumbnail" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {validation.touched.blog_image && validation.errors.blog_image ? (
                                                        <FormFeedback type="invalid"><div>{validation.errors.blog_image}</div></FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col className='mb-3' lg={6}>
                                                <div>
                                                    <Label
                                                        htmlFor="user_ID"
                                                        className="form-label"
                                                    >
                                                        User
                                                    </Label>

                                                    <Input
                                                        id='user_ID'
                                                        name="user_ID"
                                                        type="select"
                                                        className="form-select"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.user_ID || ""}
                                                        invalid={
                                                            validation.touched.user_ID && validation.errors.user_ID ? true : false
                                                        }
                                                    >
                                                        <option value="" >Select Email</option>
                                                        {
                                                            state === null ?
                                                                AdminUser?.map((item, index) => (
                                                                    <option key={index} value={item._id} > {item.Email}</option>
                                                                ))
                                                                :
                                                                <option value={state?.user_ID._id} > {state?.user_ID.Email}</option>
                                                        }
                                                    </Input>
                                                    {validation.touched.user_ID && validation.errors.user_ID ? (
                                                        <FormFeedback type="invalid"><div>{validation.errors.user_ID}</div></FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col className="mb-3" lg={6}>
                                                <div>
                                                    <Label htmlFor="title" className="form-label">Blog Title</Label>
                                                    <Input
                                                        name="title"
                                                        id="title"
                                                        className="form-control"
                                                        placeholder="Enter Blog Title"
                                                        type="text"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.title || ""}
                                                        invalid={validation.touched.title && validation.errors.title ? true : false}
                                                    />
                                                    {validation.touched.title && validation.errors.title ? (
                                                        <p className="text-danger fw-medium fs-13 mt-1">{validation.errors.title}</p>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col className="mb-3" lg={12}>
                                                <div>
                                                    <Label
                                                        htmlFor="categories"
                                                        className="form-label"
                                                    >
                                                        Category
                                                    </Label>

                                                    <Select
                                                        id='categories'
                                                        isMulti
                                                        name="categories"
                                                        options={SelectCategory}
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                        value={SelectCategory.filter(option => validation.values.categories.includes(option.value))}
                                                        onBlur={validation.handleBlur}
                                                        onChange={(selectedOptions) => {
                                                            const selectedValues = selectedOptions.map(option => option.value);
                                                            validation.setFieldValue('categories', selectedValues)
                                                        }}
                                                    />
                                                    {validation.touched.categories && validation.errors.categories ? (
                                                        <FormFeedback type="invalid"><div>{validation.errors.categories}</div></FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col className='mb-3' lg={12}>
                                                <div>
                                                    <Label
                                                        htmlFor="shortDetail"
                                                        className="form-label"
                                                    >
                                                        Short Description
                                                    </Label>

                                                    <Input
                                                        name="shortDetail"
                                                        id="shortDetail"
                                                        className="form-control"
                                                        placeholder="Enter Short Description"
                                                        type="textarea"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.shortDetail || ""}
                                                        invalid={
                                                            validation.touched.shortDetail && validation.errors.shortDetail ? true : false
                                                        }
                                                    />
                                                    {validation.touched.shortDetail && validation.errors.shortDetail ? (
                                                        <FormFeedback type="invalid"><div>{validation.errors.shortDetail}</div></FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div>
                                                    <Label htmlFor="longDetail" className="form-label">Long Description</Label>
                                                    <ReactQuill modules={modules} theme="snow"
                                                        formats={formats}
                                                        value={validation.values.longDetail}
                                                        onChange={(val) => {
                                                            validation.setFieldValue('longDetail', val)
                                                        }}
                                                    />
                                                    {validation.touched.longDetail && validation.errors.longDetail ? (
                                                        <p className="text-danger fw-medium fs-13 mt-1">{validation.errors.longDetail}</p>
                                                    ) : null}
                                                </div>
                                            </Col>

                                            <Col className="mt-3">
                                                <button type="submit" className="btn btn-primary" id="add-btn" >{state === null ? "+ Create New Blog Post" : "+ Update Blog Post"}</button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withRouter(BlogForm);