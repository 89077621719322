import 'react-toastify/dist/ReactToastify.css';
import {
    addProductOffer as addProductOfferApi,
    addWebsite as addWebsiteApi,
    deleteProductOffer as deleteProductOfferApi,
    deleteWebsite as deleteWebsiteApi,
    getUserWebsite as getUserWebsiteApi,
    getWebsiteById as getWebsiteByIdApi,
    getWebsiteByName as getWebsiteByNameApi,
    getWebsiteList as getWebsiteListApi,
    getWebsiteProductOffer as getWebsiteProductOfferApi,
    updateProductOffer as updateProductOfferApi
} from "../../../helpers/backend_helper";

import {
    addNewOfferProductSuccess,
    addNewWebsiteSuccess,
    deleteWebsiteOfferProductSuccess,
    deleteWebsiteSuccess,
    getAllWebsiteListSuccess,
    getUserAllWebsiteSuccess,
    getWebsiteByIDSuccess,
    getWebsiteByNameSuccess,
    getWebsiteOfferProductSuccess,
    updateOfferProductSuccess
} from "./reducer";

import { toast } from "react-toastify";


export const getAllWebsiteList = () => async (dispatch) => {
    try {
        const response = await getWebsiteListApi();
        if (response.success === true) {
            dispatch(getAllWebsiteListSuccess(response.data))
        } else {

            toast.error(response.message, { autoClose: 3000 });
        }

    } catch (error) {
        toast.error(error, { autoClose: 3000 });
    }
}

export const getWebsiteByNames = (name, history) => async (dispatch) => {
    try {
        const response = await getWebsiteByNameApi(name);
        if (response.success === true) {
            let obj = { website: response.website, offer: response.offers }
            dispatch(getWebsiteByNameSuccess(obj))
        } else {
            toast.error(response.message, { autoClose: 3000 });
            history('/')
        }
    } catch (error) {
        toast.error(error, { autoClose: 3000 })
    }
}

export const getWebsiteByID = (id) => async (dispatch) => {
    try {
        const response = await getWebsiteByIdApi(id);
        if (response.success === true) {
            const new_res = {
                site: response.Site,
                offer: response.offer,
            }

            dispatch(getWebsiteByIDSuccess(new_res))
        }

    } catch (error) {
        toast.error(error, { autoClose: 3000 });
    }
}


export const getUserAllWebsite = (id) => async (dispatch) => {
    try {
        const response = await getUserWebsiteApi(id);
        if (response.success === true) {
            dispatch(getUserAllWebsiteSuccess(response.websites))
        }

    } catch (error) {
        toast.error(error, { autoClose: 3000 });
    }
}

export const getWebsiteOfferProduct = (id) => async (dispatch) => {
    try {
        const response = await getWebsiteProductOfferApi(id);
        if (response.success === true) {
            dispatch(getWebsiteOfferProductSuccess(response.offer))
        }

    } catch (error) {
        toast.error(error, { autoClose: 3000 });
    }
}

export const addNewWebsite = (website, history) => async (dispatch) => {
    try {
        const response = await addWebsiteApi(website);

        if (response.success === true) {
            dispatch(addNewWebsiteSuccess(response.website));
            history('/submitList');
            toast.success(response.message, { autoClose: 3000 });
        } else {
            toast.error(response.message, { autoClose: 3000 });
        }
        // return response;
    } catch (error) {
        toast.error(error, { autoClose: 3000 });

    }
};
export const delelteWebsite = (id) => async (dispatch) => {
    try {
        const response = await deleteWebsiteApi(id);
        if (response.success === true) {
            dispatch(deleteWebsiteSuccess(id));

            toast.success(response.message, { autoClose: 3000 });
        } else {
            toast.error(response.message, { autoClose: 3000 });
        }
        // return response;
    } catch (error) {
        toast.error(error, { autoClose: 3000 });

    }
};



export const addNewOfferProduct = (product) => async (dispatch) => {
    try {
        const response = await addProductOfferApi(product);
        if (response.success === true) {
            dispatch(addNewOfferProductSuccess(response))
        }
        return response;
    } catch (error) {
        return error;
    }
}

export const updateOfferProduct = (product) => async (dispatch) => {
    try {
        const response = await updateProductOfferApi(product.id, product);
        if (response.success === true) {
            dispatch(updateOfferProductSuccess(response.offer))
        }
        return response.offer;
    } catch (error) {
        return error;
    }
}
export const deleteWebsiteOfferProduct = (product) => async (dispatch) => {
    try {
        const response = await deleteProductOfferApi(product);
        if (response.success === true) {
            dispatch(deleteWebsiteOfferProductSuccess(product))
        }
        return response.offer;
    } catch (error) {
        return error;
    }
}

