import React from 'react';
import { Container } from 'reactstrap';
import Section from './Section';
import PaymentHistory from './PaymentHistory';
import DetailsAndProduct from './DetailsAndProduct';


const ListOFWebsitesDetailsOverview = () => {
    document.title="Admin Panel | Website Details Overview";

    return (
        <React.Fragment>
            <div className="page-content">                
                <Container fluid>                    
                <Section />
                <PaymentHistory />
                <DetailsAndProduct/>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ListOFWebsitesDetailsOverview;