import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { Card, CardBody, CardHeader, Col, DropdownItem, DropdownMenu, DropdownToggle, Form, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Row, UncontrolledDropdown } from 'reactstrap';
//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from '../../../../Components/Common/TableContainer';
import { getAdminTicketsList, addNewAdminTicket, updateAdminTicket, deleteAdminTicket, getAdminUserdata } from "../../../../slices/thunks";

//Import Flatepicker
import Flatpickr from "react-flatpickr";

import { isEmpty } from "lodash";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import DeleteModal from "../../../../Components/Common/DeleteModal";
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';

const TicketsData = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const AdminTickets = useSelector((state) => state.AdminTickets.adminticketsList);
    const AdminUser = useSelector((state) => state.AdminUser.adminusersdetails);

    const [isAdminTickets, setIsAdminTickets] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const [ticket, setTicket] = useState([]);
    const [ticketID, setTicketID] = useState("");

    // Delete Tickets
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteModalMulti, setDeleteModalMulti] = useState(false);
    const [modal, setModal] = useState(false);

    useEffect(() => {
        setIsAdminTickets(AdminTickets)
    }, [AdminTickets])

    useEffect(() => {
        dispatch(getAdminUserdata());
    }, [dispatch,]);

    const toggle = useCallback(() => {
        if (modal) {
            setModal(false);
            setTicket(null);
        } else {
            setModal(true);
            setcreDate(dateFormat());
            setdueDate(dateFormat());
        }
    }, [modal]);


    // validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            _id: (ticket && ticket._id) || '',
            Title: (ticket && ticket.Title) || '',
            Description: (ticket && ticket.Description) || '',
            Priority: (ticket && ticket.Priority) || 'Medium',
            Status: (ticket && ticket.Status) || 'New',
        },
        validationSchema: Yup.object({
            Title: Yup.string().required("Please Enter Ticket Title"),
            Description: Yup.string().required("Please Enter Ticket Description"),
        }),
        onSubmit: (values) => {
            if (isEdit) {
                const UpdateformData = new FormData();

                UpdateformData.append('id', values._id);
                UpdateformData.append('Title', values.Title);
                UpdateformData.append('Description', values.Description);
                UpdateformData.append('Priority', values.Priority);
                UpdateformData.append('Status', values.Status);
                UpdateformData.append('Ticket_ID', ticketID);

                dispatch(updateAdminTicket(UpdateformData))
            } else {
                // console.log('values ::', values);
                const formData = new FormData();

                formData.append('Title', values.Title);
                formData.append('Description', values.Description);
                formData.append('Priority', values.Priority);
                formData.append('Status', values.Status);

                dispatch(addNewAdminTicket(formData));
            }
            toggle();
        },
    });

    // Delete Data
    const onClickDelete = (ticket) => {
        setTicket(ticket);
        setDeleteModal(true);
    };

    const handleDeleteTicket = () => {
        if (ticket) {
            dispatch(deleteAdminTicket(ticket.Ticket_ID));
            setDeleteModal(false);
        }
    };

    // Update Data
    const handleTicketsClick = useCallback((arg) => {
        const ticket = arg;

        setTicket({
            _id: ticket._id,
            Title: ticket.Title,
            Description: ticket.Description,
            Priority: ticket.Priority,
            Status: ticket.Status,
            User_ID: ticket?.User_ID?._id
        });
        setTicketID(ticket.Ticket_ID)
        setIsEdit(true);
        toggle();
    }, [toggle]);

    // Get Data

    useEffect(() => {
        if (AdminTickets && !AdminTickets.length) {
            dispatch(getAdminTicketsList());
        }
    }, [dispatch, AdminTickets]);


    useEffect(() => {
        setTicket(AdminTickets);
    }, [AdminTickets]);

    useEffect(() => {
        if (!isEmpty(AdminTickets)) {
            setTicket(AdminTickets);
            setIsEdit(false);
        }
    }, [AdminTickets]);

    // Add Data
    const handleTicketsClicks = () => {
        setTicket("");
        setIsEdit(false);
        toggle();
    };

    // Checked All
    const checkedAll = useCallback(() => {
        const checkall = document.getElementById("checkBoxAll");
        const ele = document.querySelectorAll(".ticketCheckBox");

        if (checkall.checked) {
            ele.forEach((ele) => {
                ele.checked = true;
            });
        } else {
            ele.forEach((ele) => {
                ele.checked = false;
            });
        }
        deleteCheckbox();
    }, []);

    // Delete Multiple
    const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
    const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

    const deleteMultiple = () => {
        const checkall = document.getElementById("checkBoxAll");
        selectedCheckBoxDelete.forEach((element) => {
            dispatch(deleteAdminTicket(element.value));
            setTimeout(() => { toast.clearWaitingQueue(); }, 3000);
        });
        setIsMultiDeleteButton(false);
        checkall.checked = false;
    };

    const deleteCheckbox = () => {
        const ele = document.querySelectorAll(".ticketCheckBox:checked");
        ele.length > 0 ? setIsMultiDeleteButton(true) : setIsMultiDeleteButton(false);
        setSelectedCheckBoxDelete(ele);
    };

    const columns = useMemo(
        () => [
            {
                Header: <input type="checkbox" id="checkBoxAll" className='form-check-input' onClick={() => checkedAll()} />,
                Cell: (cellProps) => {
                    return <input type="checkbox" className="ticketCheckBox form-check-input" value={cellProps.row.original.Ticket_ID} onChange={() => deleteCheckbox()} />;
                },
                id: '#',
            },
            {
                Header: "Ticket_ID",
                accessor: "Ticket_ID",
                filterable: true,
                Cell: (cellProps) => {
                    return (
                        <div className='link-secondary fw-bold' style={{ cursor: 'pointer' }}
                            onClick={() => {
                                const TicketData = cellProps.row.original;
                                navigate('/admin/support-tickets-details', {
                                    state: TicketData,
                                });
                            }}
                        >
                            #{cellProps.value}
                        </div>
                    )
                }
            },
            {
                Header: "Email",
                accessor: "Email",
                filterable: true,
                Cell: (cellProps) => {
                    const User_ID = cellProps.row.original?.User_ID;
                    const Email = cellProps.row.original?.User_ID?.Email;
                    return (
                        <>
                            {
                                User_ID?._id ?
                                    Email ? Email : <>-</>
                                    :
                                    AdminUser?.map((item, index) => {
                                        if (item?._id === User_ID) {
                                            return <option key={index}>{item?.Email}</option>;
                                        }
                                    })
                            }
                        </>
                    )
                }
            },
            {
                Header: "Title",
                accessor: "Title",
                filterable: true,
            },
            {
                Header: "Description",
                accessor: "Description",
                filterable: true,
                Cell: (cellProps) => {
                    return (
                        <div className="text-truncate">
                            {cellProps.value.length < 40
                                ? cellProps.value
                                : cellProps.value.substring(0, 40) + '...'}
                        </div>
                    )
                }
            },
            {
                Header: "Create Date",
                accessor: "createdAt",
                filterable: true,
                Cell: (cell) => {
                    const formattedDate = moment(cell.value).format("D MMM YYYY");
                    return (
                        <div>
                            <div>{formattedDate}</div>
                        </div>
                    );
                },
            },
            {
                Header: "Priority",
                accessor: "Priority",
                Cell: (cell) => {
                    switch (cell.value) {
                        case "Medium":
                            return <span className="badge text-uppercase badge-soft-success"><i className="ri-checkbox-circle-line align-bottom"></i> Medium </span>;
                        case "High":
                            return <span className="badge text-uppercase badge-soft-danger"><i className="ri-close-circle-line align-bottom"></i> High </span>;
                        default:
                            return <span className="badge text-uppercase badge-soft-warning">Low </span>;
                    }
                }
            },
            {
                Header: "Status",
                accessor: "Status",
                Cell: (cell) => {
                    switch (cell.value) {
                        case "Completed":
                            return <span className="badge text-uppercase badge-soft-success"> Completed </span>;
                        case "Closed":
                            return <span className="badge text-uppercase badge-soft-danger"> Closed </span>;
                        case "New":
                            return <span className="badge text-uppercase badge-soft-info"> New </span>;
                        default:
                            return <span className="badge text-uppercase badge-soft-warning">Inprogress </span>;
                    }
                }
            },
            {
                Header: "Actions",
                Cell: (cellProps) => {
                    return (
                        <UncontrolledDropdown>
                            <DropdownToggle tag="a" className="btn btn-soft-secondary btn-sm">
                                <i className="ri-more-fill align-middle"></i>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                                <li><DropdownItem className="edit-item-btn" data-bs-toggle="modal" onClick={() => { const TicketData = cellProps.row.original; handleTicketsClick(TicketData); }}><i className="ri-pencil-fill align-bottom me-2 text-muted"></i> Edit</DropdownItem></li>
                                <li>
                                    <DropdownItem className="remove-item-btn" data-bs-toggle="modal"
                                        onClick={() => {
                                            const ticketData = cellProps.row.original;
                                            onClickDelete(ticketData);
                                        }}>
                                        <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i> Delete
                                    </DropdownItem>
                                </li>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    );
                },
            },
        ],
        [handleTicketsClick, checkedAll]
    );

    const dateFormat = () => {
        let d = new Date(),
            months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return ((d.getDate() + ' ' + months[d.getMonth()] + ', ' + d.getFullYear()).toString());
    };

    const [credate, setcreDate] = useState(dateFormat());
    const [duedate, setdueDate] = useState(dateFormat());

    const credateformate = (e) => {
        const date = e.toString().split(" ");
        const joinDate = (date[2] + " " + date[1] + ", " + date[3]).toString();
        setcreDate(joinDate);
    };

    const duedateformate = (e) => {
        const date = e.toString().split(" ");
        const joinDate = (date[2] + " " + date[1] + ", " + date[3]).toString();
        setdueDate(joinDate);
    };

    return (
        <React.Fragment>
            <Row>
                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={handleDeleteTicket}
                    onCloseClick={() => setDeleteModal(false)}
                />
                <DeleteModal
                    show={deleteModalMulti}
                    onDeleteClick={() => {
                        deleteMultiple();
                        setDeleteModalMulti(false);
                    }}
                    onCloseClick={() => setDeleteModalMulti(false)}
                />
                <Col lg={12}>
                    <Card>
                        <CardHeader className="border-0">
                            <div className="d-flex align-items-center">
                                <h5 className="card-title mb-0 flex-grow-1">Tickets</h5>
                                <div className="flex-shrink-0">
                                    <div className="d-flex flex-wrap gap-2">
                                        {" "}{isMultiDeleteButton && <button className="btn btn-secondary"
                                            onClick={() => setDeleteModalMulti(true)}
                                        ><i className="ri-delete-bin-2-line"></i></button>}
                                    </div>
                                </div>
                            </div>
                        </CardHeader>
                        <CardBody className='pt-0'>
                            {AdminTickets?.length ? (
                                <TableContainer
                                    columns={columns}
                                    data={(AdminTickets || [])}
                                    isGlobalFilter={true}
                                    isAddUserList={false}
                                    customPageSize={8}
                                    className="custom-header-css"
                                    divClass="table-responsive table-card mb-3"
                                    tableClass="align-middle table-nowrap mb-0"
                                    theadClass="table-light"
                                    handleTicketClick={handleTicketsClicks}
                                    isTicketsListFilter={false}
                                    SearchPlaceholder='Search for ticket details or something...'
                                />
                            ) : <div style={{ color: "rgb(135, 138, 153)" }} className="text-center fs-18 fw-bold">Tickets Not Available</div>
                            }
                            <ToastContainer closeButton={false} limit={1} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Modal id="showModal" backdrop={false} isOpen={modal} centered size="lg"            >
                <ModalHeader toggle={toggle} className="p-3 bg-soft-info">
                    {!!isEdit ? "Edit Ticket" : "Add Ticket"}
                </ModalHeader>
                <Form className="tablelist-form"
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}>
                    <ModalBody>
                        <Row className="g-3">
                            <Col lg={12}>
                                <div>
                                    <Label htmlFor="Title-field" className="form-label">Title</Label>
                                    <Input
                                        name="Title"
                                        id="Title-field"
                                        className="form-control"
                                        placeholder="Enter Ticket Title"
                                        type="text"
                                        validate={{
                                            required: { value: true },
                                        }}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.Title || ""}
                                        invalid={
                                            validation.touched.Title && validation.errors.Title ? true : false
                                        }
                                    />
                                    {validation.touched.Title && validation.errors.Title ? (
                                        <FormFeedback type="invalid">{validation.errors.Title}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>

                            <Col lg={6}>
                                <Label htmlFor="Priority-field" className="form-label">Status</Label>
                                <Input
                                    name="Priority"
                                    type="select"
                                    className="form-select"
                                    id="Priority-field"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                        validation.values.Priority || ""
                                    }
                                >
                                    <option value="Medium">Medium</option>
                                    <option value="High">High</option>
                                    <option value="Low">Low</option>
                                </Input>
                                {validation.touched.Priority &&
                                    validation.errors.Priority ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.Priority}
                                    </FormFeedback>
                                ) : null}
                            </Col>

                            <Col lg={6}>
                                <Label htmlFor="Statusticket-field" className="form-label">Status</Label>
                                <Input
                                    name="Status"
                                    type="select"
                                    className="form-select"
                                    id="Status-field"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                        validation.values.Status || ""
                                    }
                                >
                                    <option value="New">New</option>
                                    <option value="Inprogress">Inprogress</option>
                                    <option value="Closed">Closed</option>
                                    <option value="Completed">Completed</option>
                                </Input>
                                {validation.touched.Status &&
                                    validation.errors.Status ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.Status}
                                    </FormFeedback>
                                ) : null}
                            </Col>

                            <Col lg={6}>
                                <div>
                                    <Label
                                        htmlFor="Description-field"
                                        className="form-label"
                                    >
                                        Description
                                    </Label>

                                    <textarea
                                        name="Description"
                                        id="Description-field"
                                        className="form-control"
                                        placeholder="Enter Ticket Description"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.Description || ""}
                                        invalid={
                                            validation.touched.Description && validation.errors.Description ? true : false
                                        }
                                    ></textarea>
                                    {validation.touched.Description && validation.errors.Description ? (
                                        <FormFeedback type="invalid"><div>{validation.errors.Description}</div></FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>

                    </ModalBody>
                    <div className="modal-footer">
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => { toggle(); setModal(false); setIsEdit(false) }} > Close </button>
                            <button type="submit" className="btn btn-success" id="add-btn">{!!isEdit ? "Update" : "Add Ticket"}</button>
                        </div>
                    </div>
                </Form>
            </Modal>
        </React.Fragment>
    );
};

export default TicketsData;