import { APIClient } from "./api_helper";



import * as url from "./url_helper";

const api = new APIClient();

// Login Method
export const postAdminLogin = data => api.create(url.ADMIN_LOGIN, data);

// Admin Login In User
export const postAdminLoginInUser = (data) => api.create(url.ADMIN_LOGIN_IN_USER, data);

// Change Password
export const updateChangePassword = data => api.create(url.ADMIN_CHANGEPASSWORD + '/' + data.id, data);

// Get CMS
export const getCMS = () => api.get(url.GET_CMS);

// Add CMS
export const postCMS = (data) => api.create(url.ADD_CMS, data);

// Update CMS
export const updateCMS = (id, data) => api.put(url.UPDATE_CMS + '/' + id, data);

// Get Category
export const getCategory = () => api.get(url.GET_CATEGORY);

// Add Category
export const postCategory = (data) => api.create(url.ADD_CATEGORY, data);

// Delete Category
export const deleteCategory = data => api.delete(url.DELETE_CATEGORY + '/' + data);

// Update Category
export const updateCategory = (id, data) => api.put(url.UPDATE_CATEGORY + '/' + id, data);

// Get PlanAndPricing
export const getPlanAndPricing = () => api.get(url.GET_PLAN_AND_PRICING);

// Add PlanAndPricing
export const postPlanAndPricing = (data) => api.create(url.ADD_PLAN_AND_PRICING, data);

// Delete PlanAndPricing
export const deletePlanAndPricing = data => api.delete(url.DELETE_PLAN_AND_PRICING + '/' + data);

// Update PlanAndPricing
export const updatePlanAndPricing = (id, data) => api.put(url.UPDATE_PLAN_AND_PRICING + '/' + id, data);

// Get Active And Deactive Plan
export const ActiveAndDeactivePlanAndPricing = (planId, newStatus) => api.get(url.GET_ACTIVE_AND_DEACTIVE + '/' + planId, newStatus);

// Get AdminUsers
export const getAdminUsers = () => api.get(url.GET_ADMIN_USERS);

// Get AdminUsers
export const getSingleUserAdminUsers = (userId) => api.get(url.GET_SINGLE_ADMIN_USERS + '/' + userId);

// Get Active AdminUsers
export const ActiveAdminUsers = (planId, newStatus) => api.get(url.GET_ADMIN_USERS_ACTIVE + '/' + planId, newStatus);

// Get Suspend AdminUsers
export const SuspendAdminUsers = (planId, newStatus) => api.get(url.GET_ADMIN_USERS_SUSPENDED + '/' + planId, newStatus);

// Update AdminUsers
export const updateAdminUsers = (id, data) => api.put(url.UPDATE_ADMIN_USERS + '/' + id, data);

// Delete AdminUsers
export const deleteAdminUsers = data => api.delete(url.DELETE_ADMIN_USERS + '/' + data);

// Get Submitted Users
export const getSubmittedUsers = () => api.get(url.GET_SUBMITTED_SITES);
export const getAllSubmittedSite = () => api.get(url.GET_ALL_SUBMITTED_SITES);

export const getSubmittedSiteTransaction = (id) => api.get(url.GET_SUBMITTED_SITES_TRANSACTION + '/' + id);

// Get AllWebsite Submitted Users
export const AllWebsiteSubmittedUsers = Allid => api.get(url.GET_ALL_WEBSITE_SUBMITTED_SITES + '/' + Allid);

// Update Submitted Users
export const updateSubmittedUsers = (data) => api.put(url.UPDATE_SUBMITTED_SITES, data);

// Delete Submitted Users
export const deleteSubmittedUsers = data => api.delete(url.DELETE_SUBMITTED_SITES + '/' + data);

// Get Details And Product
export const getDetailsAndProduct = id => api.get(url.GET_DETAILS_AND_PRODUCT + '/' + id);

// Get Approve
export const getApproveSubmitted = id => api.get(url.APPROVE_SUBMITTED_SITES + '/' + id);

// Get Reject
export const getRejectSubmitted = id => api.get(url.REJECT_SUBMITTED_SITES + '/' + id);

// Add Details And Product
export const postDetailsAndProduct = (data) => api.create(url.ADD_DETAILS_AND_PRODUCT, data);

// Update Details And Product
export const updateDetailsAndProduct = (id, data) => api.put(url.UPDATE_DETAILS_AND_PRODUCT + '/' + id, data);

// Delete Details And Product
export const deleteDetailsAndProduct = data => api.delete(url.DELETE_DETAILS_AND_PRODUCT + '/' + data);

// Get Editor
export const getEditor = () => api.get(url.GET_EDITOR);

// Get Single Data Editor
export const getSingleEditor = pagelink => api.get(url.GET_SINGLE_EDITOR + '/' + pagelink);

// Add Editor
export const postEditor = (data) => api.create(url.ADD_EDITOR, data);

// Delete Editor
export const deleteEditor = data => api.delete(url.DELETE_EDITOR + '/' + data);

// Update Editor
export const updateEditor = (id, data) => api.put(url.UPDATE_EDITOR + '/' + id, data);

// getSinglePage
export const getSinglePage = (name) => api.get(url.GET_PAGE_LINK + '/' + name);

// Get Transaction
export const getTransaction = () => api.get(url.GET_ALL_TRANSACTION);

// Get Single Transaction
export const getSingleTransaction = transactionId => api.get(url.GET_SINGLE_TRANSACTION + '/' + transactionId);

// Get Blog
export const getBlog = () => api.get(url.GET_ALL_BLOG);

// Get Blog Status Active and Deactive
export const getBlogStatusActiveandDeactive = (id, newStatus) => api.get(url.GET_ACTIVE_DEACTIVE + '/' + id, newStatus);

// Add Admin Blog
export const postAdminBlog = (data) => api.create(url.ADD_ADMIN_BLOGS, data);

// Delete Admin Blog
export const deleteAdminBlog = data => api.delete(url.DELETE_ADMIN_BLOGS + '/' + data);

// Update Admin Blog
export const updateAdminBlog = (id, data) => api.put(url.UPDATE_ADMIN_BLOGS + '/' + id, data);

// Get Blog Plan
export const getBlogPlan = () => api.get(url.GET_ALL_BLOG_PLAN);

// Update Admin Blog Plan
export const updateBlogPlan = (id, data) => api.put(url.UPDATE_ADMIN_BLOGS_PLAN + '/' + id, data);

// Get Admin Support Ticket
export const getAdminSupportTicket = () => api.get(url.GET_ALL_ADMIN_SUPPORT_TICKET);

// Get Admin Support Ticket
export const getAdminSingleSupportTicket = (id) => api.get(url.GET_ALL_ADMIN_SINGLE_SUPPORT_TICKET + '/' + id);

// Add Admin Support Ticket
export const postAdminSupportTicket = (data) => api.create(url.ADD_ADMIN_SUPPORT_TICKET, data);

// Delete Admin Support Ticket
export const deleteAdminSupportTicket = data => api.delete(url.DELETE_ADMIN_SUPPORT_TICKET + '/' + data);

// Update Admin Support Ticket
export const updateAdminSupportTicket = (id, data) => api.put(url.UPDATE_ADMIN_SUPPORT_TICKET + '/' + id, data);

// Get Admin Support Ticket
export const getAdminSupportTicketComments = (id) => api.get(url.GET_ALL_ADMIN_SUPPORT_TICKET_COMMENTS + '/' + id);

// Add Admin Support Ticket
export const postAdminSupportTicketComments = (id, data) => api.create(url.ADD_ADMIN_SUPPORT_TICKET_COMMENTS + '/' + id, data);

// Delete Admin Support Ticket
export const deleteAdminSupportTicketComments = data => api.delete(url.DELETE_ADMIN_SUPPORT_TICKET_COMMENTS + '/' + data);

// Update Admin Support Ticket
export const updateAdminSupportTicketComments = (id, data) => api.put(url.UPDATE_ADMIN_SUPPORT_TICKET_COMMENTS + '/' + id, data);



// //////////////////////////////////////////////////////////////////////////////////////


// get Task
export const getTaskList = () => api.get(url.GET_TASK_LIST);

// add Task
export const addNewTask = task => api.create(url.ADD_NEW_TASK, task);

// update Task
export const updateTask = task => api.update(url.UPDATE_TASK + '/' + task._id, task);

// delete Task
export const deleteTask = task => api.delete(url.DELETE_TASK + '/' + task);


// All Website

export const getWebsiteList = () => api.get(url.GET_WEBSIT_LIST);

export const getWebsiteById = (id) => api.get(url.GET_WEBSIT_BY_ID + '/' + id)
export const getWebsiteByName = (name) => api.get(url.GET_WEBSITE_BY_NAME + '/' + name)
export const addWebsite = (data) => api.put(url.ADD_WEBSITE, data);
export const getUserWebsite = (id) => api.get(url.GET_USER_WEBSITE + '/' + id)
export const deleteWebsite = (id) => api.delete(url.DELETE_WEBSITE + '/' + id)
export const deleteRegisterWebsite = (data) => api.create(url.DELETE_REGISTER_WEBSITE, data)


// Add Product/Offer
export const addProductOffer = (data) => api.create(url.ADD_WEBSITE_PORDUCT, data)
export const updateProductOffer = (id, data) => api.put(url.UPDATE_WEBSITE_PORDUCT + '/' + id, data)
export const getWebsiteProductOffer = (id) => api.get(url.GET_WEBSITE_PORDUCT + '/' + id)
export const deleteProductOffer = (id) => api.delete(url.DELETE_WEBSITE_PORDUCT + '/' + id)


// forgot pwd

export const forgotPWD = (data) => api.create(url.PASSWORD_FORGET, data);
export const login = (data) => api.create(url.PASSWORD_FORGET, data);

// product price

export const getPrice = () => api.get(url.GET_WEBSITE_PLAN)

// Login
export const loginWithEmail = (data) => api.create(url.LOGIN_WITH_EMAIL, data)
export const loginWithPWD = (data) => api.create(url.LOGIN_WITH_PWD, data)
export const loginWithurl = (token) => api.get(url.LOGIN_WITH_URL + '/' + token)

// user forget password
export const ForgetPasswordWithEmail = (data) => api.create(url.FORGET_PASSWORD_EMAIL, data)
export const ForgetPassworda = (data) => api.create(url.FORGET_PASSWORD, data)


// Register Website.


export const registerWebsite = (data) => api.create(url.CREATE_WEBSITE, data)


// change pwd
export const changePWD = (id, data) => api.put(url.CHANGE_PWD + '/' + id, data)
export const checkPwdStatus = (data) => api.get(url.CHEACK_PWD_STATUS + '/' + data);
export const setpassword = (id, data) => api.create(url.SET_PASSWORD + '/' + id, data);




// cms

export const getcmsData = () => api.get(url.GET_CMS);
// blog

export const getAllBlog = () => api.get(url.GET_BLOGS);
export const getBlogByTitle = (title) => api.get(url.GET_BLOGS_BY_TITLE + '/' + title);
export const getuserAllBlog = (id) => api.get(url.GET_USER_BLOGS + '/' + id);
export const addBlog = (data) => api.create(url.ADD_BLOGS, data);
export const editBlog = (id, data) => api.put(url.UPDATE_BLOGS + '/' + id, data);
export const deleteBlog = (id) => api.delete(url.DELETE_BLOGS + '/' + id);

// blog plan
export const getblogPlans = () => api.get(url.GET_BLOGS_PLAN);

export const createBlogpay = (data) => api.create(url.CREATE_BLOG_PAYMENT, data);


export const getUserTicket = (id) => api.get(url.GET_USER_SUPPORT_TICKET + '/' + id);
export const addUserTicket = (data) => api.create(url.ADD_USER_SUPPORT_TICKET, data)

export const getTicketComment = (id) => api.get(url.GET_TICKETS_COMMENTS + '/' + id);
export const addTicketComment = (id, data) => api.create(url.ADD_TICKETS_COMMENTS + '/' + id, data);