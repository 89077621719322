import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import { deleteCategory, getCategory, postCategory, updateCategory } from "../../../helpers/backend_helper";

export const getCategorydata = createAsyncThunk("category/getCategorydata", async () => {
  try {
    const response = getCategory()
    return response;
  } catch (error) {
    return error;
  }
})

export const addNewCategory = createAsyncThunk("category/addNewCategory", async (Category) => {
  try {
    const response = postCategory(Category)
    toast.success("Category Added Successfully", { autoClose: 3000 });
    return response;
  } catch (error) {
    toast.error("Category Added Failed", { autoClose: 3000 });
    return error;
  }
})

export const updatedataCategory = createAsyncThunk("category/updatedataCategory", async (Category) => {
  const category = Category.get('id')
  try {
    const response = updateCategory(category, Category)
    toast.success("Category Updated Successfully", { autoClose: 3000 });
    return response;
  } catch (error) {
    toast.error("Category Updated Failed", { autoClose: 3000 });
    return error;
  }
})

export const deletedataCategory = createAsyncThunk("category/deletedataCategory", async (Category) => {
  try {
    const response = deleteCategory(Category)
    toast.success("Category Deleted Successfully", { autoClose: 3000 });
    return { Category, ...response };
  } catch (error) {
    toast.error("Category Deleted Failed", { autoClose: 3000 });
    return error;
  }
})