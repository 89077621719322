import React from "react";
import { Navigate } from "react-router-dom";
import Dashboard from "../pages/Admin/Dashboard";
import Login from "../pages/Admin/Authentication/Login";
import Logout from "../pages/Admin/Authentication/Logout";
import UserProfile from "../pages/Admin/Authentication/user-profile";
import APIKey from "../pages/Admin/APIKey/index.js";
import ChangePassword from "../pages/Admin/Pages/ChangePassword/ChangePassword.js";
import Users from "../pages/Admin/Users/Users.js";
import SubmittedSites from "../pages/Admin/SubmittedSites/SubmittedSites.js";
import PaymentHistory from "../pages/Admin/PaymentHistory/PaymentHistory.js";
import DetailsAndProduct from "../pages/Admin/DetailsAndProduct/DetailsAndProduct.js";
import CMS from "../pages/Admin/CMS/CMS.js";
import PlanandPricing from "../pages/Admin/PlanandPricing/PlanandPricing.js";
import Category from "../pages/Admin/Category/Category.js";
import ListView from "../pages/Admin/SupportTickets/ListView/index.js";
import UserDetailsOverview from "../pages/Admin/Users/ProjectOverview/index.js";
import ListOFWebsitesDetailsOverview from "../pages/Admin/SubmittedSites/ProjectOverview/index.js";
import PaymentHistoryOverview from "../pages/Admin/PaymentHistory/ProjectOverview/index.js";
import TicketsDetails from "../pages/Admin/SupportTickets/TicketsDetails/index.js";
import Blog from "../pages/Admin/Blog/Blog.js";
import EditorPages from "../pages/Admin/DyanmicPages/index.js";

//      //      //      //      //      //      //      //      //


import FrontDashbaord from "../pages/FrontView/FrontDashbaord/index.js";
import FrontLogin from "../pages/FrontView/FrontAuth/Login.js";
import FrontRegister from "../pages/FrontView/FrontAuth/Register.js";
import FrontSubmitList from "../pages/FrontView/FrontDashbaord/SubmitList/SubmitList.js";
import Submit from "../pages/FrontView/FrontAuth/Submit.js";
import Detailspage from "../pages/FrontView/FrontDashbaord/Detailspage.js";
import AllBlogs from "../pages/FrontView/FrontBlog/AllBlogs.js";
import BlogView from "../pages/FrontView/FrontBlog/BlogView.js";
import BlogList from "../pages/FrontView/FrontBlog/BlogList.js";
import AddBlog from "../pages/FrontView/FrontBlog/AddBlog.js";
import OfferProductList from "../pages/FrontView/FrontDashbaord/SubmitList/OfferProductList.js";
import SupportTicket from "../pages/FrontView/SupportTicket/index.js";
import TicketView from "../pages/FrontView/SupportTicket/TicketView.js";
import FChangePassword from "../pages/FrontView/FrontAuth/ChangePassword.js";
import PrivacyPolicyPage from "../pages/FrontView/PrivacyPolicyPage/PrivacyPolicyPage.js";
import BlogForm from "../pages/Admin/Blog/BlogForm.js";
import Ssologin from "../pages/FrontView/FrontAuth/Ssologin.js";
import ForgetPassword from "../pages/FrontView/FrontAuth/ForgetPassword.js";
import SsoForgetPassword from "../pages/FrontView/FrontAuth/SsoForgetPassword.js";
import PaymentProcess from "../pages/FrontView/FrontAuth/PaymentProcess.js";

const authProtectedRoutes = [
  { path: "/", component: <FrontDashbaord /> },


  { path: "/admin/dashboard", component: <Dashboard /> },
  { path: "/admin/app-api-key", component: <APIKey /> },
  { path: "/admin/users", component: <Users /> },
  { path: "/admin/submitted-sites", component: <SubmittedSites /> },
  { path: "/admin/payment-history", component: <PaymentHistory /> },
  { path: "/admin/details-and-product", component: <DetailsAndProduct /> },
  { path: "/admin/cms", component: <CMS /> },
  { path: "/admin/plan-and-pricing", component: <PlanandPricing /> },
  { path: "/admin/category", component: <Category /> },
  { path: "/admin/support-tickets-list", component: <ListView /> },
  { path: "/admin/support-tickets-details", component: <TicketsDetails /> },
  { path: "/admin/user-details-overview", component: <UserDetailsOverview /> },
  { path: "/admin/website-details-overview", component: <ListOFWebsitesDetailsOverview /> },
  { path: "/admin/payment-history-details-overview", component: <PaymentHistoryOverview /> },
  { path: "/admin/editor-page", component: <EditorPages /> },
  { path: "/admin/blog", component: <Blog /> },
  { path: "/admin/blog-form", component: <BlogForm /> },
  { path: "/admin/change-password", component: <ChangePassword /> },
  { path: "/admin/profile", component: <UserProfile /> },
  { path: "/", exact: true, component: <Navigate to="/admin/dashboard" />, },
  { path: "*", component: <Navigate to="/admin/dashboard" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/admin/logout", component: <Logout /> },
  { path: "/admin", component: <Login /> },
  { path: "/admin/login", component: <Login /> },
  { path: "/pages/:pagename", component: <PrivacyPolicyPage /> },
  { path: "/", component: <FrontDashbaord /> },
  { path: "/register", component: <FrontRegister /> },
  { path: "/login", component: <FrontLogin /> },
  { path: '/submitList', component: <FrontSubmitList /> },
  { path: '/submit', component: <Submit /> },
  { path: '/sso-login', component: <Ssologin /> },

  { path: '/site/:pageName', component: <Detailspage /> },

  { path: '/blogs', component: <AllBlogs /> },
  // { path: '/blogview', component: <BlogView /> },
  { path: '/blog/:blogName', component: <BlogView /> },
  { path: '/bloglist', component: <BlogList /> },
  { path: '/addblog', component: <AddBlog /> },
  { path: '/changePassword', component: <FChangePassword /> },
  { path: '/forgetPassword', component: <ForgetPassword /> },
  { path: '/sso-ForgetPassword', component: <SsoForgetPassword /> },

  { path: '/offerproduct', component: <OfferProductList /> },
  { path: '/supporttickets', component: <SupportTicket /> },
  { path: '/ticketView', component: <TicketView /> },
  { path: '/payment-success', component: <PaymentProcess /> },

];

export { authProtectedRoutes, publicRoutes };