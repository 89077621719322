import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import { ActiveAndDeactivePlanAndPricing, deletePlanAndPricing, getPlanAndPricing, postPlanAndPricing, updatePlanAndPricing } from "../../../helpers/backend_helper";

export const getPlanAndPricingdata = createAsyncThunk("plan_and_pricing/getPlanAndPricingdata", async () => {
  try {
    const response = getPlanAndPricing()
    return response;
  } catch (error) {
    return error;
  }
})

export const ActiveAndDeactivedata = createAsyncThunk("plan_and_pricing/ActiveAndDeactivedata", async ({ planId, newStatus }) => {
  try {
    const response = await ActiveAndDeactivePlanAndPricing(planId, newStatus);
    return response;
  } catch (error) {
    return error;
  }
});

export const addNewPlanAndPricing = createAsyncThunk("plan_and_pricing/addNewPlanAndPricing", async (PlanAndPricing) => {
  try {
    const response = postPlanAndPricing(PlanAndPricing)
    toast.success("Plan And Pricing Added Successfully", { autoClose: 3000 });
    return response;
  } catch (error) {
    toast.error("Plan And Pricing Added Failed", { autoClose: 3000 });
    return error;
  }
})

export const updatedataPlanAndPricing = createAsyncThunk("plan_and_pricing/updatedataPlanAndPricing", async (PlanAndPricing) => {
  const plan_and_pricing = PlanAndPricing.get('id')
  try {
    const response = updatePlanAndPricing(plan_and_pricing, PlanAndPricing)
    toast.success("Plan And Pricing Updated Successfully", { autoClose: 3000 });
    return response;
  } catch (error) {
    toast.error("Plan And Pricing Updated Failed", { autoClose: 3000 });
    return error;
  }
})

export const deletedataPlanAndPricing = createAsyncThunk("plan_and_pricing/deletedataPlanAndPricing", async (PlanAndPricing) => {
  try {
    const response = deletePlanAndPricing(PlanAndPricing)
    toast.success("Plan And Pricing Deleted Successfully", { autoClose: 3000 });
    return { PlanAndPricing, ...response };
  } catch (error) {
    toast.error("Plan And Pricing Deleted Failed", { autoClose: 3000 });
    return error;
  }
})