import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import * as moment from "moment";

// Import Images
import { SlideshowLightbox } from 'lightbox.js-react';

import {
    Col,
    Container,
    Row,
    Card,
    CardHeader,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Label,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    ModalFooter,
    Table,
    FormFeedback
} from "reactstrap";
import Select from "react-select";

import BreadCrumb from "../../../Components/Common/BreadCrumb";
import DeleteModal from "../../../Components/Common/DeleteModal";

import { useDispatch, useSelector } from "react-redux";
import TableContainer from "../../../Components/Common/TableContainer";

import Loader from "../../../Components/Common/Loader";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Images

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import {
    ActiveAndDeactivedata, addNewPlanAndPricing,
    deletedataPlanAndPricing, getBlogPlandata, getCMSdata,
    getPlanAndPricingdata, updatedataBlogPlan, updatedataCMS,
    updatedataPlanAndPricing
} from "../../../slices/thunks";

const PlanandPricing = () => {
    const dispatch = useDispatch();

    const PlanAndPricing = useSelector((state) => state.PlanAndPricing.PlanAndPricingdetails);
    const CMS = useSelector(state => state.CMS.cmsdetails);
    const BlogPlan = useSelector((state) => state.BlogPlan.blogplandetails);

    useEffect(() => {
        dispatch(getPlanAndPricingdata());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getCMSdata());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getBlogPlandata());
    }, [dispatch]);

    const [isEdit, setIsEdit] = useState(false);
    const [PlanandPricingData, setPlanandPricingData] = useState(null);

    //delete Conatct
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteModalMulti, setDeleteModalMulti] = useState(false);
    const [modal, setModal] = useState(false);


    useEffect(() => {
        setPlanandPricingData(PlanAndPricing)
    }, [PlanAndPricing])

    useEffect(() => {
        if (!isEmpty(PlanAndPricing)) {
            setPlanandPricingData(PlanAndPricing);
            setIsEdit(false);
        }
    }, [PlanAndPricing]);

    const toggle = useCallback(() => {
        if (modal) {
            setModal(false);
            setIsEdit(false);
            setPlanandPricingData(null)

        } else {
            setModal(true);
        }
    }, [modal]);

    const KeyValidation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            _id: (CMS && CMS._id) || null,
            paypal_Secret_Key: (CMS && CMS.paypal_Secret_Key) || '',
            paypal_Client_ID: (CMS && CMS.paypal_Client_ID) || '',
        },
        validationSchema: Yup.object({
            paypal_Secret_Key: Yup.string().required("Please Enter Stripe Secret Key"),
            paypal_Client_ID: Yup.string().required("Please Enter Stripe Publishable Key"),
        }),
        onSubmit: (values) => {
            const formData = new FormData();

            formData.append('id', values._id);
            formData.append('paypal_Secret_Key', values.paypal_Secret_Key);
            formData.append('paypal_Client_ID', values.paypal_Client_ID);

            dispatch(updatedataCMS(formData))
        },
    });

    const BlogValidation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            _id: (BlogPlan && BlogPlan._id) || null,

            blog_price: (BlogPlan && BlogPlan.amount) || '',
        },
        validationSchema: Yup.object({

            blog_price: Yup.string().required("Please Enter Blog Price"),

        }),
        onSubmit: (values) => {
            const formData = new FormData();

            formData.append('id', values._id);

            formData.append('amount', values.blog_price);

            dispatch(updatedataBlogPlan(formData))
        },
    });

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            _id: (PlanandPricingData && PlanandPricingData._id) || null,
            planName: (PlanandPricingData && PlanandPricingData.planName) || '',
            originalPrice: (PlanandPricingData && PlanandPricingData.originalPrice) || '',
            salePrice: (PlanandPricingData && PlanandPricingData.salePrice) || '',
            billingPeriod: (PlanandPricingData && PlanandPricingData.billingPeriod) || '',
            description: (PlanandPricingData && PlanandPricingData.description) || '',
            status: (PlanandPricingData && PlanandPricingData.status) || 'Active',
            product_id: (PlanandPricingData && PlanandPricingData.product_id) || '',
            plan_id: (PlanandPricingData && PlanandPricingData.plan_id) || '',
        },
        validationSchema: Yup.object({
            planName: Yup.string().required("Please Enter Plan Name"),
            originalPrice: Yup.string().required("Please Enter Original Price"),
            salePrice: Yup.string().required("Please Enter Icon Sale Price"),
            billingPeriod: Yup.string().required("Please Enter Billing Period"),
            product_id: Yup.string().required("Please Enter Stripe & Plan ID"),
            plan_id: Yup.string().required("Please Enter Stripe Price ID"),
            status: Yup.string().required("Please Select Status"),
        }),
        onSubmit: (values) => {
            if (isEdit) {
                const formData = new FormData();

                formData.append('id', values._id);
                formData.append('planName', values.planName);
                formData.append('originalPrice', values.originalPrice);
                formData.append('salePrice', values.salePrice);
                formData.append('billingPeriod', values.billingPeriod);
                formData.append('description', values.description);
                formData.append('plan_id', values.plan_id);
                formData.append('mode', values.billingPeriod === 'One-Time' ? 'Order' : 'Subscription');
                formData.append('product_id', values.product_id);
                formData.append('status', values.status);

                dispatch(updatedataPlanAndPricing(formData))
                // setModal(false);
            } else {
                const formData = new FormData();

                formData.append('planName', values.planName);
                formData.append('originalPrice', values.originalPrice);
                formData.append('salePrice', values.salePrice);
                formData.append('billingPeriod', values.billingPeriod);
                formData.append('description', values.description);
                formData.append('plan_id', values.plan_id);
                formData.append('mode', values.billingPeriod === 'One-Time' ? 'Order' : 'Subscription');
                formData.append('product_id', values.product_id);
                formData.append('status', values.status);

                dispatch(addNewPlanAndPricing(formData));
            }
            validation.resetForm();
            setModal(false);
        },
    });



    // Update Data
    const handleContactClick = useCallback((arg) => {
        const contact = arg;
        setPlanandPricingData({
            _id: contact._id,
            planName: contact.planName,
            originalPrice: contact.originalPrice,
            salePrice: contact.salePrice,
            billingPeriod: contact.billingPeriod,
            description: contact.description,
            status: contact.status,
            product_id: contact.product_id,
            plan_id: contact.plan_id,
            mode: contact.mode,
        });
        toggle();
        setIsEdit(true);
    }, [toggle]);

    // Checked All
    const checkedAll = useCallback(() => {
        const checkall = document.getElementById("checkBoxAll");
        const ele = document.querySelectorAll(".contactCheckBox");

        if (checkall.checked) {
            ele.forEach((ele) => {
                ele.checked = true;
            });
        } else {
            ele.forEach((ele) => {
                ele.checked = false;
            });
        }
        deleteCheckbox();
    }, []);

    // Delete Multiple
    const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
    const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

    const deleteMultiple = () => {
        const checkall = document.getElementById("checkBoxAll");
        selectedCheckBoxDelete.forEach((element) => {
            dispatch(deletedataPlanAndPricing(element.value));
            setTimeout(() => { toast.clearWaitingQueue(); }, 3000);
        });
        setIsMultiDeleteButton(false);
        checkall.checked = false;
    };

    const deleteCheckbox = () => {
        const ele = document.querySelectorAll(".contactCheckBox:checked");
        ele.length > 0 ? setIsMultiDeleteButton(true) : setIsMultiDeleteButton(false);
        setSelectedCheckBoxDelete(ele);
    };

    // Delete Data
    const handleDeleteContact = () => {
        if (PlanandPricingData) {
            dispatch(deletedataPlanAndPricing(PlanandPricingData._id));
            setDeleteModal(false);
        }
    };

    const onClickDelete = (data) => {
        setPlanandPricingData(data)
        setDeleteModal(true);
    };

    const updateStatus = (id, newStatus) => {
        dispatch(ActiveAndDeactivedata(id, newStatus));
    };

    // Column
    const columns = useMemo(
        () => [
            {
                Header: <input type="checkbox" className="form-check-input" id="checkBoxAll" onClick={() => checkedAll()} />,
                Cell: (cellProps) => {
                    return <input type="checkbox" className="contactCheckBox form-check-input" value={cellProps.row.original._id} onChange={() => deleteCheckbox()} />;
                },
                id: '#',
            },
            {
                Header: "Plan Name",
                accessor: "planName",
                filterable: true,
            },
            {
                Header: "Original Price",
                accessor: "originalPrice",
                filterable: true,
                Cell: (cellProps) => {
                    const amount = cellProps.value
                    return (
                        amount ? <span className="text-uppercase">${amount}</span> : <span className="text-uppercase">$0</span>
                    )
                }
            },
            {
                Header: "Sale Price",
                accessor: "salePrice",
                filterable: true,
                Cell: (cellProps) => {
                    const amount = cellProps.value
                    return (
                        amount ? <span className="text-uppercase">${amount}</span> : <span className="text-uppercase">$0</span>
                    )
                }
            },
            {
                Header: "Billing Period",
                accessor: "billingPeriod",
                Cell: (cellProps) => {
                    switch (cellProps.value) {
                        case "One-Time":
                            return <span className="badge text-uppercase badge-soft-warning"> One-Time </span>;
                        case "Daily":
                            return <span className="badge text-uppercase badge-soft-info"> Daily </span>;
                        case "Weekly":
                            return <span className="badge text-uppercase badge-soft-success"> Weekly </span>;
                        case "Monthly":
                            return <span className="badge text-uppercase badge-soft-secondary"> Monthly </span>;
                        default:
                            return <span className="badge text-uppercase badge-soft-primary"> Yearly </span>;
                    }
                }
            },
            {
                Header: "Mode",
                accessor: "mode",
                filterable: true,
            },
            {
                Header: "Product ID",
                accessor: "product_id",
                filterable: true,
            },
            // {
            //     Header: "Stripe Id",
            //     accessor: "stripe_id",
            //     filterable: true,
            //     Cell: (cell) => {
            //         return (
            //             <a href={`https://dashboard.stripe.com/test/payments/${cell.value}`} target="_blank" className="fw-bold link-secondary" rel="noopener noreferrer">
            //                 {`${cell?.value?.slice(0, 20)}...${cell?.value?.slice(-5)}`}
            //             </a>
            //         )
            //     }    
            // },
            {
                Header: "Price ID",
                accessor: "plan_id",
                filterable: true,
            },
            {
                Header: "Status",
                accessor: "status",
                Cell: (cell) => {
                    switch (cell.value) {
                        case "Active":
                            return <span className="badge text-uppercase badge-soft-success"><i className="ri-checkbox-circle-line align-bottom"></i> Active </span>;
                        case "Inactive":
                            return <span className="badge text-uppercase badge-soft-danger"><i className="ri-close-circle-line align-bottom"></i> Inactive </span>;
                        default:
                            return <span className="badge text-uppercase badge-soft-warning">Default </span>;
                    }
                }
            },
            {
                Header: "Action",
                Cell: (cellProps) => {
                    const statusdata = cellProps.row.original.status

                    return (
                        <ul className="list-inline hstack gap-2">

                            <li className="list-inline-item">
                                <UncontrolledDropdown>
                                    <DropdownToggle
                                        href=""
                                        className="btn btn-soft-secondary btn-sm dropdown"
                                        tag="button"
                                    >
                                        <i className="ri-more-fill align-middle"></i>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-end">
                                        <DropdownItem
                                            className="dropdown-item edit-item-btn"
                                            href=""
                                            onClick={() => {
                                                const newStatus = statusdata === "Active" ? "Inactive" : "Active";
                                                dispatch(ActiveAndDeactivedata({ planId: cellProps.row.original._id, newStatus }));
                                            }}
                                        >
                                            <i className={`ri-${statusdata === "Active" ? "close-circle-line" : "checkbox-circle-line"} align-bottom me-2 text-muted`}></i>{" "}
                                            {statusdata === "Active" ? "Inactive" : "Active"}
                                        </DropdownItem>
                                        <DropdownItem
                                            className="dropdown-item edit-item-btn"
                                            href=""
                                            onClick={() => { const contactData = cellProps.row.original; handleContactClick(contactData); }}
                                        >
                                            <i className="ri-pencil-fill align-middle me-2 text-muted"></i>
                                            Edit
                                        </DropdownItem>
                                        <DropdownItem
                                            className="dropdown-item remove-item-btn"
                                            href=""
                                            onClick={() => { const contactData = cellProps.row.original; onClickDelete(contactData); }}
                                        >
                                            <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>
                                            Delete
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </li>
                        </ul>
                    );
                },
            },
        ],
        [handleContactClick, checkedAll]
    );

    document.title = "Admin Panel | Plan and Pricing";
    return (
        <React.Fragment>
            <div className="page-content">

                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={handleDeleteContact}
                    onCloseClick={() => setDeleteModal(false)}
                />
                <DeleteModal
                    show={deleteModalMulti}
                    onDeleteClick={() => {
                        deleteMultiple();
                        setDeleteModalMulti(false);
                    }}
                    onCloseClick={() => setDeleteModalMulti(false)}
                />
                <Container fluid>
                    <BreadCrumb title="Plan and Pricing" pageTitle="Dashboards" />
                    <Row>
                        <Col xxl={6}>
                            <Card >
                                <CardBody>
                                    <div className="live-preview">
                                        <Form
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                BlogValidation.handleSubmit();
                                                return false;
                                            }}
                                        >
                                            <Row>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="blog_price" className="form-label">Blog Price</Label>
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter Blog Price"
                                                            id="blog_price"
                                                            name="blog_price"
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            onChange={BlogValidation.handleChange}
                                                            onBlur={BlogValidation.handleBlur}
                                                            value={BlogValidation.values.blog_price || ""}
                                                            invalid={
                                                                BlogValidation.touched.blog_price && BlogValidation.errors.blog_price ? true : false
                                                            }
                                                        />
                                                        {BlogValidation.touched.blog_price && BlogValidation.errors.blog_price ? (
                                                            <FormFeedback type="invalid"><div>{BlogValidation.errors.blog_price}</div></FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>

                                                <Col md={12}>
                                                    <div className="text-end">
                                                        <button type="submit" className="btn btn-primary">Update Blog Plan</button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xxl={6}>
                            <Card >
                                <CardBody>
                                    <div className="live-preview">
                                        <Form
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                KeyValidation.handleSubmit();
                                                return false;
                                            }}
                                        >
                                            <Row>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="paypal_Secret_Key" className="form-label">  Secret Key</Label>
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter your Paypal Secret Key here"
                                                            id="paypal_Secret_Key"
                                                            name="paypal_Secret_Key"
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            onChange={KeyValidation.handleChange}
                                                            onBlur={KeyValidation.handleBlur}
                                                            value={KeyValidation.values.paypal_Secret_Key || ""}
                                                            invalid={
                                                                KeyValidation.touched.paypal_Secret_Key && KeyValidation.errors.paypal_Secret_Key ? true : false
                                                            }
                                                        />
                                                        {KeyValidation.touched.paypal_Secret_Key && KeyValidation.errors.paypal_Secret_Key ? (
                                                            <FormFeedback type="invalid"><div>{KeyValidation.errors.paypal_Secret_Key}</div></FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="paypal_Client_ID" className="form-label">Client ID</Label>
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter your Paypal  Client ID here"
                                                            id="paypal_Client_ID"
                                                            name="paypal_Client_ID"
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            onChange={KeyValidation.handleChange}
                                                            onBlur={KeyValidation.handleBlur}
                                                            value={KeyValidation.values.paypal_Client_ID || ""}
                                                            invalid={
                                                                KeyValidation.touched.paypal_Client_ID && KeyValidation.errors.paypal_Client_ID ? true : false
                                                            }
                                                        />
                                                        {KeyValidation.touched.paypal_Client_ID && KeyValidation.errors.paypal_Client_ID ? (
                                                            <FormFeedback type="invalid"><div>{KeyValidation.errors.paypal_Client_ID}</div></FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col md={12}>
                                                    <div className="text-end">
                                                        <button type="submit" className="btn btn-primary">Update</button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <div className="d-flex align-items-center flex-wrap gap-2">
                                        <div className="flex-grow-1">
                                            <button className="btn btn-primary add-btn" onClick={() => { setModal(true); }}>
                                                <i className="ri-add-fill me-1 align-bottom"></i>Add Plan and Pricing
                                            </button>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <div className="hstack text-nowrap gap-2">
                                                {isMultiDeleteButton && <button className="btn btn-secondary" onClick={() => setDeleteModalMulti(true)}><i className="ri-delete-bin-2-line"></i></button>}
                                            </div>
                                        </div>
                                    </div>
                                </CardHeader>
                            </Card>
                        </Col>
                        <Col xxl={12}>
                            <Card id="TeamMembersList">
                                <CardBody>
                                    <div>
                                        {PlanAndPricing.length ? (
                                            <TableContainer
                                                columns={columns}
                                                data={(PlanAndPricing || [])}
                                                isGlobalFilter={false}
                                                isAddUserList={false}
                                                customPageSize={8}
                                                className="custom-header-css"
                                                divClass="table-responsive table-card mb-3"
                                                tableClass="align-middle table-nowrap"
                                                theadClass="table-light"
                                                isContactsFilter={false}
                                                SearchPlaceholder='Search for Plan and Pricing...'
                                            />
                                        ) : <div style={{ color: "rgb(135, 138, 153)" }} className="text-center fs-18 fw-bold">Plan Not Available</div>
                                        }
                                    </div>
                                    <Modal backdrop={false} id="showModal" isOpen={modal} centered size="lg">
                                        <ModalHeader className="bg-soft-info p-3" toggle={toggle}>
                                            {!!isEdit ? "Edit Plan and Pricing" : "Add New Plan and Pricing"}
                                        </ModalHeader>

                                        <Form
                                            className="tablelist-form"
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                validation.handleSubmit();
                                                return false;
                                            }}
                                        >
                                            <ModalBody>
                                                <Input type="hidden" id="id-field" />
                                                <Row className="g-3">
                                                    <Col lg={6}>
                                                        <div>
                                                            <Label htmlFor="planName" className="form-label">
                                                                Plan Name
                                                            </Label>
                                                            <Input
                                                                name="planName"
                                                                id="planName"
                                                                className="form-control"
                                                                placeholder="Enter Plan Name"
                                                                type="text"
                                                                validate={{
                                                                    required: { value: true },
                                                                }}
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.planName || ""}
                                                                invalid={
                                                                    validation.touched.planName && validation.errors.planName ? true : false
                                                                }
                                                            />
                                                            {validation.touched.planName && validation.errors.planName ? (
                                                                <FormFeedback type="invalid"><div>{validation.errors.planName}</div></FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>

                                                    <Col lg={6}>
                                                        <div>
                                                            <Label
                                                                htmlFor="originalPrice"
                                                                className="form-label"
                                                            >
                                                                Original Price
                                                            </Label>

                                                            <Input
                                                                name="originalPrice"
                                                                id="originalPrice"
                                                                className="form-control"
                                                                placeholder="Enter Your Original Price"
                                                                type="number"
                                                                validate={{
                                                                    required: { value: true },
                                                                }}
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.originalPrice || ""}
                                                                invalid={
                                                                    validation.touched.originalPrice && validation.errors.originalPrice ? true : false
                                                                }
                                                            />
                                                            {validation.touched.originalPrice && validation.errors.originalPrice ? (
                                                                <FormFeedback type="invalid"><div>{validation.errors.originalPrice}</div></FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>

                                                    <Col lg={6}>
                                                        <div>
                                                            <Label
                                                                htmlFor="salePrice"
                                                                className="form-label"
                                                            >
                                                                Sale Price
                                                            </Label>

                                                            <Input
                                                                name="salePrice"
                                                                id="salePrice"
                                                                className="form-control"
                                                                placeholder="Enter Sale Price"
                                                                type="text"
                                                                validate={{
                                                                    required: { value: true },
                                                                }}
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.salePrice || ""}
                                                                invalid={
                                                                    validation.touched.salePrice && validation.errors.salePrice ? true : false
                                                                }
                                                            />
                                                            {validation.touched.salePrice && validation.errors.salePrice ? (
                                                                <FormFeedback type="invalid"><div>{validation.errors.salePrice}</div></FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>

                                                    <Col lg={6}>
                                                        <div>
                                                            <Label
                                                                htmlFor="billingPeriod"
                                                                className="form-label"
                                                            >
                                                                Billing Period
                                                            </Label>

                                                            <Input
                                                                id="billingPeriod"
                                                                name="billingPeriod"
                                                                type="select"
                                                                className="form-select"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.billingPeriod || ""}
                                                            >
                                                                {!isEdit && <option value="">Select Billing Period</option>}
                                                                <option value="Daily">Daily</option>
                                                                <option value="Weekly">Weekly</option>
                                                                <option value="Monthly">Monthly</option>
                                                                <option value="Year">Yearly</option>
                                                                <option value="One-Time">One-Time</option>
                                                            </Input>
                                                            {validation.touched.billingPeriod && validation.errors.billingPeriod ? (
                                                                <FormFeedback type="invalid"><div>{validation.errors.billingPeriod}</div></FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>

                                                    <Col lg={6}>
                                                        <div>
                                                            <Label
                                                                htmlFor="status"
                                                                className="form-label"
                                                            >
                                                                Status
                                                            </Label>

                                                            <Input
                                                                name="status"
                                                                type="select"
                                                                className="form-select"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.status || ""}
                                                            >
                                                                <option value="Active">Active</option>
                                                                <option value="Inactive">Inactive</option>
                                                            </Input>
                                                            {validation.touched.status && validation.errors.status ? (
                                                                <FormFeedback type="invalid"><div>{validation.errors.status}</div></FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>

                                                    <Col lg={6}>
                                                        <div>
                                                            <Label
                                                                htmlFor="product_id"
                                                                className="form-label"
                                                            >
                                                                Product ID
                                                            </Label>

                                                            <Input
                                                                name="product_id"
                                                                id="product_id"
                                                                className="form-control"
                                                                placeholder="Enter Product ID"
                                                                type="text"
                                                                validate={{
                                                                    required: { value: true },
                                                                }}
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.product_id || ""}
                                                                invalid={
                                                                    validation.touched.product_id && validation.errors.product_id ? true : false
                                                                }
                                                            />
                                                            {validation.touched.product_id && validation.errors.product_id ? (
                                                                <FormFeedback type="invalid"><div>{validation.errors.product_id}</div></FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>

                                                    <Col lg={6}>
                                                        <div>
                                                            <Label
                                                                htmlFor="plan_id"
                                                                className="form-label"
                                                            >
                                                                Price ID
                                                            </Label>

                                                            <Input
                                                                name="plan_id"
                                                                id="plan_id"
                                                                className="form-control"
                                                                placeholder="Enter Price ID"
                                                                type="text"
                                                                validate={{
                                                                    required: { value: true },
                                                                }}
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.plan_id || ""}
                                                                invalid={
                                                                    validation.touched.plan_id && validation.errors.plan_id ? true : false
                                                                }
                                                            />
                                                            {validation.touched.plan_id && validation.errors.plan_id ? (
                                                                <FormFeedback type="invalid"><div>{validation.errors.plan_id}</div></FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>

                                                    <Col lg={6}>
                                                        <div>
                                                            <Label
                                                                htmlFor="Plan_description"
                                                                className="form-label"
                                                            >
                                                                Plan Detail
                                                            </Label>

                                                            <Input
                                                                type='textarea'
                                                                name="description"
                                                                id="Plan_description"
                                                                className="form-control"
                                                                placeholder="Enter Plan Detail"
                                                                row={5}
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.description || ""}
                                                                invalid={
                                                                    validation.touched.description && validation.errors.description ? true : false
                                                                }
                                                            />
                                                            {validation.touched.description && validation.errors.description ? (
                                                                <FormFeedback type="invalid"><div>{validation.errors.description}</div></FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </ModalBody>
                                            <ModalFooter>
                                                <div className="hstack gap-2 justify-content-end">
                                                    <button type="button" className="btn btn-light" onClick={() => { toggle(); setModal(false); setIsEdit(false) }} > Close </button>
                                                    <button type="submit" className="btn btn-success" id="add-btn" > {!!isEdit ? "Update" : "Add Plan and Pricing"} </button>
                                                </div>
                                            </ModalFooter>
                                        </Form>
                                    </Modal>
                                    <ToastContainer closeButton={false} limit={1} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment >
    );
};

export default PlanandPricing;