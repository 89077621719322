import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { deleteAdminSupportTicket, getAdminSingleSupportTicket, getAdminSupportTicket, postAdminSupportTicket, updateAdminSupportTicket } from "../../../helpers/backend_helper";

//Include Both Helper File with needed methods

export const getAdminTicketsList = createAsyncThunk("tickets/getAdminTicketsList", async () => {
    try {
        const response = await getAdminSupportTicket();
        return response;
    } catch (error) {
        return error;
    }
});

export const getAdminSingleTicketsList = createAsyncThunk("tickets/getAdminSingleTicketsList", async (id) => {
    try {
        const response = await getAdminSingleSupportTicket(id);
        return response;
    } catch (error) {
        return error;
    }
});

export const addNewAdminTicket = createAsyncThunk("tickets/addNewAdminTicket", async (ticket) => {
    try {
        const response = await postAdminSupportTicket(ticket);
        const data = await response;
        toast.success("Ticket Added Successfully", { autoClose: 3000 });
        return data;
    } catch (error) {
        return error;
    }
});

export const updateAdminTicket = createAsyncThunk("tickets/updateTicket", async (ticket) => {
    const Ticket = ticket.get('Ticket_ID')
    try {
        const response = updateAdminSupportTicket(Ticket, ticket);
        const data = await response;
        toast.success("Ticket Updated Successfully", { autoClose: 3000 });
        return data;
    } catch (error) {
        toast.error("Ticket Updated Failed", { autoClose: 3000 });
        return error;
    }
});

export const deleteAdminTicket = createAsyncThunk("tickets/deleteAdminTicket", async (ticket) => {
    try {
        const response = await deleteAdminSupportTicket(ticket);
        if (response.success !== false) {
            toast.success("Ticket Delete Successfully", { autoClose: 3000 });
            return { ticket, ...response };
        } else {
            toast.error(response.message, { autoClose: 3000 });
        }
    } catch (error) {
        toast.error("Ticket Delete Failed", { autoClose: 3000 });
        return error;
    }
});