import { createSlice } from "@reduxjs/toolkit";
import { getBlogPlandata, updatedataBlogPlan } from './thunk';

export const initialState = {
  blogplandetails: {},
  error: {}
};

const blogplanSlice = createSlice({
  name: "blogplan",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBlogPlandata.fulfilled, (state, action) => {
      state.blogplandetails = action.payload?.Data[0];
    });

    builder.addCase(getBlogPlandata.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(updatedataBlogPlan.fulfilled, (state, action) => {
      state.blogplandetails = action.payload?.plan
    });

    builder.addCase(updatedataBlogPlan.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });
  },
});

export default blogplanSlice.reducer;
