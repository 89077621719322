import React, { useEffect } from 'react';
import { Routes, Route } from "react-router-dom";

//Layouts
import NonAuthLayout from "../Layouts/NonAuthLayout";
import VerticalLayout from "../Layouts/index";

//routes
import { authProtectedRoutes, publicRoutes } from "./allRoutes";
import { AuthProtected } from './AuthProtected';
import { getCMSdata } from '../slices/thunks';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { api } from '../config';
import { getcmsDetails } from '../slices/FrontView/cms/thunk';

const Index = () => {

    const dispatch = useDispatch();

    const CMS = useSelector(state => state.CMS.cmsdetails);
    const { cmsData } = useSelector((state) => state.CMSF);

    useEffect(() => {
        dispatch(getcmsDetails())
        dispatch(getCMSdata());
    }, [dispatch]);

    useEffect(() => {
        if (CMS) {
            const faviconLink = document.querySelector("link[rel='shortcut icon']");
            const metaDescriptionTag = document.querySelector('meta[name="description"]');
            const metaKeywordsTag = document.querySelector('meta[name="keywords"]');

            if (faviconLink || metaDescriptionTag || metaKeywordsTag) {
                !!CMS?.favicon && faviconLink.setAttribute('href', api.API_URL + '/' + CMS.favicon || '')
                metaDescriptionTag.setAttribute('content', CMS.websiteDescription || '');
                metaKeywordsTag.setAttribute('content', CMS.websiteKeywords || '');
            }
        }
    }, [CMS]);

    return (
        <React.Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />

                {/* <title>{cmsData?.websiteTitle}</title> */}
                <meta name="description" content={cmsData?.websiteDescription} />
                <meta property="og:title" content={cmsData?.websiteTitle} />
                <meta property="og:description" content={cmsData?.websiteDescription} />
                <meta property="og:site_name" content={cmsData?.websiteTitle} />
                <meta property="og:url" content={cmsData?.url} />
                <meta property="og:url" content={cmsData?.url} />
                <meta property="og:url" content={cmsData?.url} />
                <meta property="og:image" content={api.API_URL + cmsData?.websiteLogo} />
                <link rel="icon" type="image/*" href={api.API_URL + '/' + cmsData?.favicon} />



                <meta name="keywords" content={cmsData?.websiteKeywords?.join(', ')} />
                <meta name="platform-name" content={cmsData?.platformName} />
                <meta name="twitter:card" content={api.API_URL + cmsData?.websiteLogo} />
                <meta name="twitter:title" content={cmsData?.websiteTitle} />
                <meta name="twitter:description" content={cmsData?.websiteDescription} />
                <meta name="twitter:image" content={api.API_URL + cmsData?.websiteLogo} />

            </Helmet>

            <Routes>
                <Route>
                    {publicRoutes.map((route, idx) => (
                        <Route
                            path={route.path}
                            element={
                                <NonAuthLayout>
                                    {route.component}
                                </NonAuthLayout>
                            }
                            key={idx}
                            exact={true}
                        />
                    ))}
                </Route>

                <Route>
                    {authProtectedRoutes.map((route, idx) => (
                        <Route
                            path={route.path}
                            element={
                                <AuthProtected>
                                    <VerticalLayout>{route.component}</VerticalLayout>
                                </AuthProtected>}
                            key={idx}
                            exact={true}
                        />
                    ))}
                </Route>
            </Routes>
        </React.Fragment>
    );
};

export default Index;