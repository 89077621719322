import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    cmsData: {},
};




const CMSFSlice = createSlice({
    name: "CMSFSlice",
    initialState,
    reducers: {

        getcmsDetailsSuccess(state, action) {
            state.cmsData = action.payload || {}
        },
    },

});


export const {
    getcmsDetailsSuccess
} = CMSFSlice.actions

export default CMSFSlice.reducer;