import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { useFormik } from 'formik';
import { object, string, mixed, array } from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { addNewWebsite } from '../../../slices/FrontView/dashboard/thunk';
import { api } from '../../../config';
import Header from '../../../Layouts/FrontHeader';
import Footer from '../../../Layouts/FrontFooter';
import MyEditor from '../../../Components/Common/MyEditor';
import { getCategorydata } from '../../../slices/thunks';
import website_background from "../../../assets/images/website_background.png";

import { isEmpty } from "lodash";
import { Button, Spinner } from 'reactstrap';

const Submit = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;
    const { categorydetails } = useSelector((state) => state.Category);
    const { cmsData } = useSelector((state) => state.CMSF);

    const [categoryList, setCategoryList] = useState([]);
    const [isloading, setIsLoading] = useState(false)

    const [website, setWebsite] = useState(null);
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {

        if (categorydetails) {
            const transformedArray = categorydetails.map(item => ({
                label: item.CategoryName,
                value: item.CategoryName,
                cat_icon: item.Category_fileIcon,
                cat_bgColor: item.Category_backgroundColor,
                Status: item.Status
            }));
            setCategoryList(transformedArray);
        }

    }, [categorydetails])


    useEffect(() => {
        dispatch(getCategorydata());
    }, [dispatch]);

    useEffect(() => {
        if (state === null) {
            setWebsite(null)

        } else {
            setWebsite(state.website)
            setIsEdit(true)
        }
    }, [state])

    const userSchema = object({
        logo: mixed().required('Image is required'),
        Site_Name: string().required('Please Enter Website Name'),
        Brief: string().required('Please Enter Brief description'),
        description: string().required('Please Enter Long Description'),
    }).shape({
        categories: array().min(1, 'Select at least one category'),
    })
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            logo: (website && website?.Logo) || '',
            Site_Name: (website && website?.Site_Name) || '',
            Brief: (website && website.Short_Description) || '',
            description: (website && website.Long_Description) || '',
            categories: (website && website.Category) || [],
            Site_Link: (website && website.Site_Link) || '',

        },
        validationSchema: userSchema,
        onSubmit: (values,) => {

            const newProduct = new FormData();
            newProduct.append('websiteId', website?._id);
            newProduct.append('Site_Name', values?.Site_Name);
            newProduct.append('Submitted_By', state?.website?.Submitted_By);
            newProduct.append('Site_Link', values.Site_Link);
            newProduct.append('Logo', values.logo);
            newProduct.append('Short_Description', values.Brief);
            newProduct.append('Long_Description', values.description);
            newProduct.append('Category', values.categories);
            dispatch(addNewWebsite(newProduct, navigate));
            formik.resetForm();
            setIsLoading(true)
        },
    });

    useEffect(() => {
        if (isloading) {
            setTimeout(() => {
                setIsLoading(false)
            }, 5000)
        }
    }, [isloading])

    const { values, touched, handleBlur, handleChange, handleSubmit, errors, setValues, setFieldValue } = formik

    const myElementRef = useRef(null);
    const [footerHeight, setFooterHeight] = useState(700);

    useEffect(() => {
        if (myElementRef?.current) {
            const elementHeight = myElementRef.current.getBoundingClientRect().height;
            setFooterHeight(elementHeight)
        }
    });
    document.title = `${cmsData?.websiteTitle ? cmsData?.websiteTitle : '1000 Ai Sites'} | Submit Website`;

    return (
        <div >
            <div ref={myElementRef}>
                <Header />

                <div className='sumitpage'>
                    <div className="px-4 pt-20 px-md-5 text-center text-lg-start" >
                        <section>

                            <div className="lg:px-4 py-5 px-md-5 text-center text-lg-start" style={{ backgroundColor: 'hsl(0, 0%, 96%)' }}>
                                <div className="container">
                                    <div className="row gx-lg-5 align-items-center justify-content-center">


                                        <div className="col-lg-8 mb-lg-0 ">
                                            <div className="card">
                                                <div className="card-body py-5 px-md-5">
                                                    <h2 className="text-center font-semibold mb-4">
                                                        Submit Your Website Details
                                                    </h2>
                                                    <form onSubmit={handleSubmit}>
                                                        <div>
                                                            <div className="row">
                                                                <div className="col-md-6 mb-4">
                                                                    <div className="form-outline">
                                                                        <p>Website Featured Image</p>

                                                                        <div className='logo-upload' >
                                                                            <img src={typeof values.logo === "string"
                                                                                ?
                                                                                isEmpty(values.logo) ? website_background : api.API_URL + '/' + values.logo
                                                                                : URL.createObjectURL(values?.logo)}
                                                                                width={"100%"} height={"100%"} style={{ borderRadius: '10px' }} />

                                                                            <div className="input--file">
                                                                                <span>
                                                                                    <i className="bi bi-camera-fill"></i>
                                                                                </span>
                                                                                {/* <input name="file" type="file" value={values.file} onBlur={handleBlur} onChange={(event) => setFieldValue('file', event.currentTarget.files[0])} */}
                                                                                <input
                                                                                    name="logo"
                                                                                    type="file"
                                                                                    accept="image/png, image/gif, image/jpeg, image/jpg"
                                                                                    onBlur={handleBlur}
                                                                                    onChange={(event) => {
                                                                                        const file = event.target.files[0];
                                                                                        const reader = new FileReader();
                                                                                        reader.onload = () => { setFieldValue('logo', file); };
                                                                                        reader.readAsDataURL(file);
                                                                                    }

                                                                                    }
                                                                                />

                                                                            </div>

                                                                        </div>
                                                                        <p> Click to upload Image (PNG, JPG and JPEG up to 2MB) </p>
                                                                        <div className='error'>
                                                                            <span >{errors.logo && touched.logo ? errors.logo : ''}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <div className="form-outline mb-4 relative">

                                                                <label className="form-label" htmlFor="form3Example3">Website Name</label>
                                                                <input id="url" className="form-control" type="text" name="Site_Name" placeholder="Enter the name of your website" value={values.Site_Name} onBlur={handleBlur} onChange={handleChange} />
                                                                <div className='error'>
                                                                    <span >{errors.Site_Name && touched.Site_Name ? errors.Site_Name : ''}</span>
                                                                </div>
                                                            </div>

                                                            <div className="form-outline mb-4">
                                                                <label className="form-label" htmlFor="form3Example4">Website URL</label>
                                                                <input id="Site_Link" className="form-control" type="text" name="Site_Link" placeholder="https://www.example.com" value={formik.values.Site_Link} onBlur={handleBlur} onChange={handleChange} disabled />
                                                            </div>
                                                            <div className="form-outline mb-4 relative">
                                                                <label htmlFor="Brief" className="form-label" >Brief Description</label>
                                                                <input id="Brief" className="form-control" type="text" name="Brief" placeholder="Enter a brief one-liner about your website" value={values.Brief} onBlur={handleBlur} onChange={handleChange} />
                                                                <div className='error'>
                                                                    <span >{errors.Brief && touched.Brief ? errors.Brief : ''}</span>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <MyEditor
                                                                    label={'Website Long Description'}
                                                                    description={values.description}
                                                                    onchangeDescrition={(value) => {
                                                                        setFieldValue('description', value)
                                                                    }}
                                                                />
                                                                <div className="error">
                                                                    <span>{formik.errors.description && formik.touched.description ? formik.errors.description : ''}</span>
                                                                </div>
                                                            </div>
                                                            <div className="form-outline mb-4">
                                                                <label className="form-label" htmlFor="form3Example4">Categories</label>
                                                                <Select
                                                                    isMulti
                                                                    name="categories"
                                                                    options={categoryList}
                                                                    className="basic-multi-select"
                                                                    classNamePrefix="select"
                                                                    value={categoryList.filter(option => formik.values.categories.includes(option.value))}
                                                                    onBlur={handleBlur}
                                                                    onChange={(selectedOptions) => {
                                                                        const selectedValues = selectedOptions.map(option => option.value);
                                                                        setFieldValue('categories', selectedValues)
                                                                    }}

                                                                />
                                                                <div className='error'>
                                                                    <span >{errors.categories && touched.categories ? errors.categories : ''}</span>
                                                                </div>
                                                            </div>


                                                            <button disabled={isloading ? true : false} type="submit" className="btn btn-primary btn-block mb-4 primary-btn">
                                                                {isloading ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}
                                                                {isEdit ? 'Update Website' : 'Submit Website'}
                                                            </button>
                                                        </div>

                                                    </form>
                                                </div>
                                            </div>
                                            <div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/* Jumbotron */}

                        </section>
                    </div>
                </div>
                <Footer footer_style={footerHeight > window.innerHeight ? 'App-footer-scroll' : ''} />
            </div>
        </div>





    );
}

export default Submit;