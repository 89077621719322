import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    AdminLoginInUserDetails: {},
    error: "", // for error messag
};

const AdminLoginInUserSlice = createSlice({
    name: "adminlogininuser",
    initialState,
    reducers: {
        userpostAdminLogin(state, action) {
            state.AdminLoginInUserDetails = action.payload
        },
    },
});

export const {
    userpostAdminLogin,
} = AdminLoginInUserSlice.actions

export default AdminLoginInUserSlice.reducer;