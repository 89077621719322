import React, { useCallback, useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getAllSubmittedSitesdata } from '../../../slices/thunks';
import moment from 'moment';
import TableContainer from '../../../Components/Common/TableContainer';
import avatar1 from '../../../assets/images/users/Placeholder_view_vector.svg.png';
import { api } from '../../../config';

const SubmissionQueue = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const SubmitSites = useSelector((state) => state.SubmitSites.allSubmittedSitesData);
    useEffect(() => {
        dispatch(getAllSubmittedSitesdata());
    }, [dispatch]);

    const handleValidDate = date => {
        const date1 = moment(new Date(date)).format("DD MMM Y");
        return date1;
    };

    const columns = useMemo(
        () => [
            {
                Header: "Site Name",
                accessor: "Site_Name",
                filterable: true,
                Cell: (contact) => (
                    <>
                        <div className="d-flex align-items-center">
                            <div className="flex-shrink-0">
                                <img
                                    src={contact?.row?.original?.Logo ? api.API_URL + '/' + contact.row.original.Logo : avatar1}
                                    alt=""
                                    className="avatar-xxs rounded-circle"
                                />
                            </div>
                            <div onClick={() => {
                                const UsersData = contact.row.original;

                                navigate('/admin/website-details-overview', {
                                    state: UsersData,
                                })
                            }}
                                style={{ cursor: 'pointer' }} className="flex-grow-1 ms-2 name text-secondary fw-bold">
                                {
                                    contact.row.original?.Site_Name ? (contact.row.original.Site_Name?.split(' '))?.map((word, index) => (
                                        <React.Fragment key={index}>
                                            {word}  {(index + 1) % 3 === 0 && <br />} {/* Break to new line every 3 words */}{' '}
                                        </React.Fragment>
                                    )) : <>-</>
                                }
                            </div>
                        </div>
                    </>
                )
            },
            {
                Header: "Site Link",
                accessor: "Site_Link",
                filterable: true,
                Cell: (cell) => {
                    return (
                        cell?.value ? <a href={cell.value} className="fw-bold link-secondary" target="_blank" rel="noopener noreferrer">{`${cell.value}`}</a>
                            : <div className="text-center">-</div>
                    )
                }
            },
            {
                Header: "Submitted By",
                accessor: "ToolCount",
                filterable: true,
                Cell: (cellProps) => {
                    const submittedById = cellProps.row.original?.Submitted_By?.Email;

                    return (
                        <div>
                            {submittedById ? submittedById : <div className="text-center">-</div>}
                        </div>
                    );
                }
            },
            {
                Header: "Date",
                accessor: "Date_Submitted",
                Cell: (cell) => (
                    <>
                        {handleValidDate(cell.row.original?.Date_Submitted)}
                    </>
                ),
            },
            {
                Header: "Payment Status",
                accessor: "payment_status",
                Cell: (cell) => {
                    switch (cell.row.original?.Subscription?.payment_status) {
                        case "Paid":
                        case "paid":
                            return <span className="badge text-uppercase fs-12 badge-soft-success"><i className="ri-checkbox-circle-line align-bottom"></i> Paid </span>;
                        case "Completed":
                        case "completed":
                            return <span className="badge text-uppercase fs-12 badge-soft-success"><i className="ri-checkbox-circle-line align-bottom"></i> Completed </span>;
                        case "cancelled":
                            return <span className="badge text-uppercase fs-12 badge-soft-danger"><i className="ri-close-circle-line align-bottom"></i> Cancelled </span>;
                        default:
                            return <span className="badge text-uppercase fs-12 badge-soft-warning"><i className="ri-time-line align-bottom"></i> Pending </span>;
                    }
                }
            },
            {
                Header: "Approval Status",
                accessor: "Approval_Status",
                Cell: (cell) => {
                    switch (cell.value) {
                        case "Approved":
                            return <span className="badge text-uppercase fs-12 badge-soft-success"><i className="ri-checkbox-circle-line align-bottom"></i> Approved </span>;
                        case "Rejected":
                            return <span className="badge text-uppercase fs-12 badge-soft-danger"><i className="ri-close-circle-line align-bottom"></i> Rejected </span>;
                        case "Review":
                            return <span className="badge text-uppercase fs-12 badge-soft-info"><i className="ri-eye-line align-bottom"></i> Review </span>;
                        default:
                            return <span className="badge text-uppercase fs-12 badge-soft-warning"><i className="ri-time-line align-bottom"></i> Pending </span>;
                    }
                }
            },
        ],
        []
    );

    // Conditionally render the component based on Approval_Status
    if (SubmitSites?.some(site => site.Approval_Status === 'Pending')) {
        return (
            <Col xxl={12}>
                <Card id="TeamMembersList">
                    <CardHeader className="align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">Submission Queue</h4>
                    </CardHeader>
                    <CardBody>
                        <div>
                            {SubmitSites?.length ? (
                                <TableContainer
                                    columns={columns}
                                    data={SubmitSites?.filter(site => site.Approval_Status === 'Pending')}
                                    isGlobalFilter={false}
                                    isAddUserList={false}
                                    isShowPagination={false}
                                    customPageSize={8}
                                    className="custom-header-css"
                                    divClass="table-responsive table-card"
                                    tableClass="align-middle"
                                    theadClass="table-light"
                                    isContactsFilter={false}
                                    SearchPlaceholder='Search for Users...'
                                />
                            ) : <div style={{ color: "rgb(135, 138, 153)" }} className="text-center fs-18 fw-bold">Site Not Available</div>
                            }
                        </div>
                    </CardBody>
                </Card>
            </Col>
        );
    } else {
        // Render something else or nothing when there are no sites with Approval_Status === 'Pending'
        return null;
    }
};

export default SubmissionQueue;