import React from 'react';

//import Scss
import './assets/scss/themes.scss';
import './assets/css/Dashboard.css';

//imoprt Route
import Route from './Routes';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function App() {
  return (
    <React.Fragment>
      <Route />
      <ToastContainer />
    </React.Fragment>
  );
}

export default App;
