import React, { useEffect } from 'react';
import { Col, Row, } from "reactstrap";
// import { useQuill } from "react-quilljs";
// import "quill/dist/quill.snow.css";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';



// You can choose a different theme if you prefer

const MyEditor = ({ label = 'Description', description, onchangeDescrition }) => {

    const modules = {
        toolbar: [
            [{ header: '1' }, { header: '2' }, { font: [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [
                { list: 'ordered' },
                { list: 'bullet' },
                { indent: '-1' },
                { indent: '+1' },
            ],
            ['link', 'image', 'video'],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'align': [] }],
            ['clean'],
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        },
    }

    const formats = [
        'header',
        'font',
        'size',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
        'video',
        'color',
        'background',
        'align'
    ]


    return (
        <div className="form-outline mb-4">
            <label className="form-label" htmlFor="description ">{label}</label>
            <Row >
                <Col lg={12}>
                    <ReactQuill modules={modules} theme="snow"
                        formats={formats}
                        value={description}
                        onChange={(val) => onchangeDescrition(val)}
                    />
                </Col>
            </Row>
        </div>

    );
};

export default MyEditor;