import { toast } from 'react-toastify';
import { updateChangePassword } from "../../../../helpers/backend_helper";

export const AdminChangePassword = (user, history) => async (dispatch) => {

    try {

        let response;

        response = updateChangePassword(user);

        const data = await response;

        if (data.success === true) {
            toast.success(data.message, { autoClose: 3000 });
            history('/login')
        } else {
            toast.error(data.message || 'Fail', { autoClose: 3000 });
        }
    } catch (error) {
        console.log("Error", error);
        toast.error(error, { autoClose: 3000 });
    }
}