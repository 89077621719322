import 'react-toastify/dist/ReactToastify.css';
import {
    postAdminLoginInUser as postAdminLoginInUserApi,
} from "../../../helpers/backend_helper";
import { userpostAdminLogin } from "./reducer";
import { toast } from "react-toastify";
import { userloginSuccess } from '../../FrontView/authentication/reducer';


export const AdminLoginInUserData = (data, history) => async (dispatch) => {
    try {

        const response = await postAdminLoginInUserApi(data);
        if (response.success === true) {
            dispatch(userloginSuccess(response?.data || {}))

            sessionStorage.setItem("authUser", JSON.stringify(response || {}));
            toast.success(response.message, { autoClose: 3000 });

            window.open('/submitList', '_blank')
        } else {
            toast.error(response.message, { autoClose: 3000 });
        }
    } catch (forgetError) {
        toast.error(forgetError, { autoClose: 3000 });
    }
}