import 'react-toastify/dist/ReactToastify.css';
import {
    ForgetPasswordWithEmail,
    ForgetPassworda,
    changePWD as changePWDApi,
    checkPwdStatus as checkPwdStatusApi,
    forgotPWD,
    loginWithEmail as loginWithEmailApi,
    loginWithPWD as loginWithPWDApi,
    loginWithurl as loginWithurlApi,
    setpassword as setpasswordApi,
} from "../../../helpers/backend_helper";
import { checkPasswordStatusSuccess, setPasswordSuccess, userforgetpasswordEmailSuccess, userloginEmailSuccess, userloginSuccess } from "./reducer";
import { toast } from "react-toastify";


export const userLoginWithEmail = (data) => async (dispatch) => {
    try {

        const response = await loginWithEmailApi(data);
        if (response.success === true) {
            if (response.passwordStatus) {
                dispatch(userloginEmailSuccess(response.passwordStatus))
            } else {
                toast.success(response.message, { autoClose: 3000 });
            }
        } else {
            toast.error(response.message, { autoClose: 3000 });
        }
    } catch (error) {
        console.log('forgetError.response', error.response && error.response.status === 404);
        toast.error(error, { autoClose: 3000 });
    }
}

export const userloginWithPassword = (data, history) => async (dispatch) => {
    try {

        const response = await loginWithPWDApi(data);

        if (response.success === true) {

            sessionStorage.setItem("authUser", JSON.stringify(response || {}));

            dispatch(userloginSuccess(response?.data || {}))

            toast.success(response.message, { autoClose: 3000 });
            history('/submitList');
        } else {
            toast.error(response.message, { autoClose: 3000 });
        }
    } catch (forgetError) {
        toast.error(forgetError, { autoClose: 3000 });
    }
}


export const userForgetPassword = (data) => async (dispatch) => {
    try {

        const response = await ForgetPasswordWithEmail(data);
        if (response.success === true) {

            dispatch(userforgetpasswordEmailSuccess(response))

            toast.success(response.message, { autoClose: 3000 });
        } else {
            toast.error(response.message, { autoClose: 3000 });
        }
    } catch (forgetError) {
        toast.error(forgetError, { autoClose: 3000 });
    }

}
export const ForgetPassword = (data, history) => async (dispatch) => {
    try {

        const response = await ForgetPassworda(data);
        if (response.success === true) {
            sessionStorage.removeItem("authUser");

            history('/login')
        }
    } catch (forgetError) {
        toast.error(forgetError, { autoClose: 3000 });
    }

}

export const changePassword = (data, history) => async (dispatch) => {
    try {
        const response = await changePWDApi(data.id, data);
        if (response.success === true) {
            sessionStorage.removeItem("authUser");
            toast.success(response.message, { autoClose: 3000 });
            history('/login')
        } else {

            toast.error(response.message, { autoClose: 3000 });
        }
    } catch (error) {
        toast.error(error, { autoClose: 3000 });
    }
}


export const checkPasswordStatus = (data) => async (dispatch) => {
    try {

        const response = await checkPwdStatusApi(data);
        if (response.success === true) {
            dispatch(checkPasswordStatusSuccess(response))
        }

    } catch (error) {
        toast.error(error, { autoClose: 3000 });
    }
}

export const submitNewPassword = (data) => async (dispatch) => {
    try {

        const response = await setpasswordApi(data.id, data);

        if (response.success === true) {
            sessionStorage.removeItem("authUser");
            sessionStorage.setItem("authUser", JSON.stringify(response || {}));
            dispatch(setPasswordSuccess(response))
        }

    } catch (error) {
        toast.error(error, { autoClose: 3000 });
    }
}


export const userLoginWithURL = (data, history) => async (dispatch) => {
    try {
        const response = await loginWithurlApi(data);
        if (response.success === true) {

            sessionStorage.setItem("authUser", JSON.stringify(response || {}));

            dispatch(userloginSuccess(response?.data || {}))

            toast.success(response.message, { autoClose: 3000 });
            history('/submitList');
        } else {
            toast.error(response.message, { autoClose: 3000 });
        }
    } catch (forgetError) {
        toast.error(forgetError, { autoClose: 3000 });
    }
}