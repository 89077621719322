import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button, Card, CardBody, Col, Form, FormFeedback, Input, Label, Row } from 'reactstrap';
import img1 from '../../../assets/images/Register/Register-1.jpg'
import img2 from '../../../assets/images/Register/Register-2.jpg'
import Header from '../../../Layouts/FrontHeader';
import Footer from '../../../Layouts/FrontFooter';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import { addTicketComments, getTicketComments } from '../../../slices/thunks';
import { isEmpty } from 'lodash';

const TicketView = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const { state } = location;

    const [ticketData, setTicketData] = useState(null);
    const [commentsData, setCommentsData] = useState([]);


    const { ticketsUserCommnet } = useSelector((state) => state.TicketsF);
    const { cmsData } = useSelector((state) => state.CMSF);


    const obj = JSON.parse(sessionStorage.getItem('authUser'))?.data




    useEffect(() => {
        setCommentsData(ticketsUserCommnet);
    }, [ticketsUserCommnet]);

    useEffect(() => {
        if (!isEmpty(ticketsUserCommnet)) {
            setCommentsData(ticketsUserCommnet);

        }
    }, [ticketsUserCommnet]);



    useEffect(() => {

        if (state === null) {
            setTicketData(null)

        } else {
            setTicketData(state.tickets);
            dispatch(getTicketComments(state.tickets._id))
            // setCommentsData(state.tickets?.comments)
        }
    }, [state])



    // validation
    const validation = useFormik({
        enableReinitialize: true,

        initialValues: { Description: '', },

        validationSchema: Yup.object({
            Description: Yup.string().required("Please Enter Description"),
        }),
        onSubmit: (values) => {


            const newcmt = {
                user: obj?._id,
                text: values.Description,
                createdAt: new Date()
            }
            dispatch(addTicketComments(ticketData?._id, newcmt))
            setCommentsData([...commentsData, newcmt])
            validation.resetForm();


        },
    });
    document.title = `${cmsData?.websiteTitle ? cmsData?.websiteTitle : '1000 Ai Sites'} | Ticket`;


    return (
        <>
            <Header />
            <div className='ticketviewpage ' >
                <div className="px-4 py-20 px-md-5 text-center text-lg-start " >
                    <div className='d-flex justify-content-center Ticketbody ' >
                        <Col xxl={6} lg={9} sm={10}>
                            <Card className='mt-5 mb-5'>
                                <CardBody className="p-4 ">
                                    <div className='chatVeiw'>
                                        <div className='d-flex mb-4 items-center'>
                                            <div>
                                                <Link className="flex" style={{ color: '#3d78e3' }} to='/Supporttickets'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 mr-1">
                                                        <path fillRule="evenodd" d="M17 10a.75.75 0 01-.75.75H5.612l4.158 3.96a.75.75 0 11-1.04 1.08l-5.5-5.25a.75.75 0 010-1.08l5.5-5.25a.75.75 0 111.04 1.08L5.612 9.25H16.25A.75.75 0 0117 10z" clipRule="evenodd" />
                                                    </svg>
                                                    Back
                                                </Link>
                                            </div>
                                            <div className='profile mx-4'>
                                                <h3>{ticketData?.Title}</h3>
                                                <h5 className="fs-15 text-center">Alexis Clarke <small className="text-muted text-center">{moment(new Date(ticketData?.createdAt)).format("DD MMM Y hh:mm")}</small> </h5>
                                            </div>
                                        </div>
                                        {/* <h5 className="card-title mb-4">Comments</h5> */}
                                        <div style={{ height: "300px" }}>
                                            {commentsData?.length !== 0 && commentsData.map((item, index) => {

                                                return (
                                                    <div key={index} className="px-3 mx-n3">
                                                        {item.user &&
                                                            <div className="d-flex m-2">
                                                                <div className="flex-shrink-0">
                                                                    <img src={img1} alt="" className="avatar-xs rounded-circle" />
                                                                </div>
                                                                <div className="flex-grow-1 ms-3">
                                                                    <h5 className="fs-15">{obj?.Email?.split('@')[0]}{". "}<small className="text-muted">{moment(new Date(item?.createdAt)).format("DD MMM Y hh:mm")} </small></h5>
                                                                    <p className="text-muted ">{item.text}</p>
                                                                </div>
                                                            </div>}

                                                        {item.admin &&
                                                            <div className="d-flex ms-4 m-4 flex-grow-1">
                                                                <div className="flex-shrink-0">
                                                                    <img src={img2} alt="" className="avatar-xs rounded-circle" />
                                                                </div>
                                                                <div className="flex-grow-1 ms-3">
                                                                    <h5 className="fs-15">Admin <small className="text-muted">{moment(new Date(item?.createdAt)).format("DD MMM Y hh:mm")}</small></h5>
                                                                    <p className="text-muted">{item.text}</p>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>

                                                )
                                            })

                                            }
                                        </div>

                                    </div>
                                    <Form className="tablelist-form" onSubmit={(e) => {
                                        e.preventDefault();
                                        validation.handleSubmit();
                                        return false;
                                    }}>
                                        {/* <form action="#" className="mt-3"> */}
                                        <Row className="g-3 mt-2">
                                            <Col lg={12}>
                                                <Label htmlFor="exampleFormControlTextarea1" className="form-label">Leave a Comments reply</Label>
                                                {/* <textarea className="form-control bg-light border-light" id="exampleFormControlTextarea1" rows="3" placeholder=" Message"></textarea> */}

                                                <Input
                                                    type="textarea"
                                                    name='Description'
                                                    className="form-control"
                                                    id="addaddress-textarea"
                                                    placeholder="Enter Message"
                                                    rows="4"
                                                    validate={{ required: { value: true }, }}
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values?.Description || ""}
                                                    invalid={validation.touched.Description && validation.errors.Description ? true : false}
                                                />
                                                {validation.touched.Description && validation.errors.Description ? (
                                                    <FormFeedback type="invalid">{validation.errors.Description}</FormFeedback>
                                                ) : null}

                                            </Col>
                                            <Col lg={12} className="text-end">
                                                <Button type='submit' className="btn btn-labeled btn-primary primary-btn">Send Message</Button>
                                            </Col>
                                        </Row>
                                        {/* </form> */}
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default TicketView;