import React, { useEffect, useRef, useState } from 'react';
import { Link, } from 'react-router-dom';
import { isEmpty } from "lodash";

import Header from '../../../Layouts/FrontHeader';
import Footer from '../../../Layouts/FrontFooter';

import { useDispatch, useSelector } from 'react-redux';
import { createBlogPayment, delelteBlogs, getAllBlogs, getBlogsPlan, getUserAllBlogs } from '../../../slices/FrontView/blogs/thunk';
import { api } from '../../../config';
import CryptoJS from 'crypto-js';
import DeleteModal from '../../../Components/Common/DeleteModal';
import { clearBlogsByTitleSuccess } from '../../../slices/FrontView/blogs/reducer';
import moment from 'moment';
import { Modal, ModalBody, Spinner } from 'reactstrap';
import { getcmsDetails } from '../../../slices/FrontView/cms/thunk';

const BlogList = () => {
    const dispatch = useDispatch();

    const { userAllBlogs, blogPlan, isBlogLoading } = useSelector((state) => state.Blogs);
    const { cmsData } = useSelector((state) => state.CMSF);
    const [userData, setUserData] = useState(null);
    const [userAllBLogs, setUserAllBLogs] = useState([])
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteData, setDeleteData] = useState(null);

    const userObj = JSON.parse(sessionStorage.getItem('authUser'))?.data;

    useEffect(() => {
        const sortedAllBlogs = [...userAllBlogs].sort((a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf());
        setUserAllBLogs(sortedAllBlogs);
    }, [userAllBlogs])

    useEffect(() => {
        if (!isEmpty(userAllBlogs)) {
            const sortedAllBlogs = [...userAllBlogs].sort((a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf());
            setUserAllBLogs(sortedAllBlogs);
        }
    }, [userAllBlogs]);

    useEffect(() => {

        const urlParams = new URLSearchParams(window.location.search);
        const blogData = decodeURIComponent(urlParams.get('blog'));

        if (blogData !== 'null') {
            const hashedText = CryptoJS.AES.decrypt(blogData, 'NODE');
            var originalText = hashedText.toString(CryptoJS.enc.Utf8);
            const successUpdatePlans = JSON.parse(originalText);
            setUserData(successUpdatePlans?.user)
        }

    }, [])


    useEffect(() => {
        setUserData(userObj)
    }, [])

    useEffect(() => {
        dispatch(getAllBlogs());
        dispatch(getBlogsPlan());
        dispatch(getcmsDetails())

    }, [dispatch])


    useEffect(() => {
        if (userData) {
            dispatch(getUserAllBlogs(userData._id));
        }
        dispatch(clearBlogsByTitleSuccess())
    }, [userData])

    const handleDeleteWebsite = (item) => {
        setDeleteModal(true);
        setDeleteData(item);
    }

    const handleDeleteClick = (item) => {
        dispatch(delelteBlogs(deleteData?._id))
        setDeleteModal(false);
    }
    const handlePayNow = (item) => {
        console.log('fewkfwejfoiweoifjoi');
        if (blogPlan) {
            let payobj = {
                blog_ID: item._id,
                blogPlan_ID: blogPlan._id,
                user_ID: userData._id,
            }
            dispatch(createBlogPayment(payobj))

        }
    }
    const myElementRef = useRef(null);
    const [footerHeight, setFooterHeight] = useState(700);
    useEffect(() => {
        if (myElementRef?.current) {
            const elementHeight = myElementRef.current.getBoundingClientRect().height;
            setFooterHeight(elementHeight)
        }
    });
    document.title = `${cmsData?.websiteTitle ? cmsData?.websiteTitle : '1000 Ai Sites'} | Blogs`;
    return (
        <div>
            {isBlogLoading &&
                <Modal isOpen={isBlogLoading} backdrop={false} centered={true} >
                    <ModalBody className="py-3 px-5" >
                        <div className="d-flex justify-content-center">
                            <Spinner color="primary"> Loading... </Spinner>
                        </div>
                    </ModalBody>
                </Modal>}
            <div ref={myElementRef}>
                <Header isactive={'active-bloglist'} />
                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={handleDeleteClick}
                    onCloseClick={() => setDeleteModal(false)}
                />
                <div className={`SubmitList`} >
                    <section>
                        <div className="lg:px-4 pt-20 px-md-5 text-center text-lg-start" >
                            <div className="flex items-center space-x-2" style={{ justifyContent: 'space-evenly', marginBottom: '2%' }}>
                                <div className='yourwebsite-text'>
                                    My Blog
                                </div>
                                <Link className="btn btn-labeled btn-primary" to='/addblog'>
                                    <span className="btn-label"><i className="bx bx-plus"></i></span>
                                    Submit New Blog Post
                                </Link>
                            </div>
                            {userAllBLogs?.length !== 0 && userAllBLogs?.map((item, index) => {
                                const BlogTitleWithHyphens = item?.title?.replace(/[^\w\s]/gi, ' ').replace(/\s+/g, '-').replace(/-+/g, '-').replace(/^-+|-+$/g, '');

                                return (
                                    <div key={index} className="container lg:g-5">
                                        <div className="row gx-lg-5 align-items-center justify-content-center">
                                            <div className="col-lg-8 mb-lg-0 ">
                                                <div className="card">
                                                    <div className="card-body relative">

                                                        <div className="row flex justify-content-between">
                                                            <div className="col-4 flex-none mr-3 lg:mr-4 overflow-hidden">
                                                                <div className='blogList-img'>
                                                                    <img className='blog-img' src={api.API_URL + '/' + item.blog_image} alt='logo' style={{ backgroundSize: 'cover' }} loading="lazy" />
                                                                </div>
                                                            </div>

                                                            <div className='col-7  lg:block lg:col-span-3' >
                                                                <div className='flex'>
                                                                    <div className='flex-grow'>
                                                                        <h6 className="text-2xl font-bold mb-0 blogtitle">{item?.title?.length > 33 ? `${item?.title.substring(0, 33)}...` : item.title}</h6>
                                                                    </div>
                                                                    <div className='flex justify-end  mx-3'>
                                                                        {item?.status === 'Pending' && <div className=" lg:flex items-center space-x-1.5 mx-3">
                                                                            <span className="relative flex h-2 w-2">
                                                                                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-300 opacity-75" />
                                                                                <span className="relative inline-flex rounded-full h-2 w-2 bg-yellow-500" />
                                                                            </span>
                                                                            <p className="text-sm leading-none text-gray-400 text-website">Pending</p>
                                                                        </div>}

                                                                        {item?.status === 'Approved' && <div className=" lg:flex items-center space-x-1.5 lg:mx-3">
                                                                            <span className="relative flex h-2 w-2">
                                                                                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-300 opacity-75" />
                                                                                <span className="relative inline-flex rounded-full h-2 w-2 bg-green-400" />
                                                                            </span>
                                                                            <p className="text-sm leading-none text-gray-400 text-website">Live</p>
                                                                        </div>}
                                                                        {item?.status === 'Rejected' && <div className=" lg:flex items-center space-x-1.5 lg:mx-3">
                                                                            <span className="relative flex h-2 w-2">
                                                                                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red opacity-75" />
                                                                                <span className="relative inline-flex rounded-full h-2 w-2 bg-red" />


                                                                            </span>
                                                                            <p className="text-sm leading-none text-gray-400 text-website">Rejected</p>
                                                                        </div>}
                                                                    </div>

                                                                </div>
                                                                <div className='mt-3 mb-5'>
                                                                    <p>
                                                                        {item?.shortDetail?.length > 80 ? `${item?.shortDetail?.substring(0, 80)}....` : item?.shortDetail}</p>
                                                                </div>

                                                                <div className='flex justify-end mt-4 position-absolute bottom-0 end-0' >
                                                                    <div className='flex more-btn-bloglist'>
                                                                        <Link className={`btn btn-labeled btn-primary Detils-btn-edit ${item?.paymentStatus !== 'paid' ? 'Detils-btnpaymentStatus' : ''}`} to='/addblog' state={{ blogs: item }}>
                                                                            <span className="btn-label-big"><i className="bx bxs-pencil"></i></span>
                                                                            <p className='text-website'>  Edit</p>
                                                                        </Link>

                                                                        {item?.paymentStatus !== 'completed' && <Link className='btn btn-labeled btn-primary Detils-btn-pay' onClick={() => { handlePayNow(item) }}>
                                                                            <span className="btn-label-big"><i className="lab la-amazon-pay"></i></span>
                                                                            <p className='text-website'> Pay Now </p>
                                                                        </Link>
                                                                        }
                                                                        <Link className={`btn btn-labeled btn-primary Detils-btn-preview ${item?.paymentStatus !== 'paid' ? 'Detils-btnpaymentStatus' : ''}`} to={`/blog/${BlogTitleWithHyphens}`} >
                                                                            <span className="btn-label-big"><i className="bx bx-show"></i></span>
                                                                            <p className='text-website'>  Preview </p>
                                                                        </Link>

                                                                        <Link className={`btn btn-labeled btn-primary Detils-btn-Delete ${item?.paymentStatus !== 'paid' ? 'Detils-btnpaymentStatus' : ''}`} onClick={() => { handleDeleteWebsite(item) }}>
                                                                            <span className="btn-label-big"><i className="bx bx-trash"></i></span>
                                                                            <p className='text-website'> Delete</p>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}


                        </div>
                    </section>
                </div >
            </div>
            <Footer footer_style={footerHeight > window.innerHeight ? 'App-footer-scroll' : ''} />
        </div >
    );
}

export default BlogList;