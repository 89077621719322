import React, { useEffect, useRef, useState } from 'react';

import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";

import { getAllWebsiteList } from '../../../slices/FrontView/dashboard/thunk';
import { api } from '../../../config';
import { getcmsDetails } from '../../../slices/FrontView/cms/thunk';
import FrontHeader from '../../../Layouts/FrontHeader';
import FrontFooter from '../../../Layouts/FrontFooter';
import website_background from "../../../assets/images/website_background.png";
import { getCategorydata } from '../../../slices/thunks';
import "../../../assets/css/Dashboard.css";

import Select from 'react-select';
import { clearWebsiteByNamesSuccess } from '../../../slices/FrontView/dashboard/reducer';
import moment from 'moment';


const FrontDashbaord = (props) => {
    const dispatch = useDispatch();
    const { websiteList } = useSelector((state) => state.Dashboard);
    const { categorydetails } = useSelector((state) => state.Category);
    const [categoryList, setCategoryList] = useState([]);
    const [MywebsiteList, setMywebsiteList] = useState()
    const [top10Sites, setTop10Sites] = useState([])
    const [approvedSites, setApprovedSites] = useState([])

    useEffect(() => {
        setMywebsiteList(websiteList)
        handleFilterData(websiteList)
    }, [websiteList])


    const handleFilterData = (websiteList) => {
        const isTop10 = websiteList.filter((item) => item.TOP_10 === 'true' && item.Approval_Status === 'Approved')
        const sortedTop10Data = [...isTop10].sort((a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf());
        setTop10Sites(sortedTop10Data)

        const isAprovedSites = websiteList.filter((item) => item.TOP_10 === 'false' && item.Approval_Status === 'Approved')
        const sortedAprovedData = [...isAprovedSites].sort((a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf());
        setApprovedSites(sortedAprovedData)
    }

    const handlechangeSearch = (value) => {
        const SitesList = websiteList.filter((item) => {
            const siteName = item?.Site_Name?.toLowerCase() || '';
            const siteLink = item?.Site_Link?.toLowerCase() || '';
            const shortDescription = item?.Short_Description?.toLowerCase() || '';
            const searchFields = [siteName, siteLink, shortDescription];
            return searchFields.some((field) => field.includes(value.toLowerCase()));
        })
        setMywebsiteList(SitesList)
        handleFilterData(SitesList)
    }

    const handlechangecategor = (value) => {
        if (value === 'All') {
            setMywebsiteList(websiteList)
            handleFilterData(websiteList)

        } else {
            const result = websiteList.filter(item => item?.Category?.find(person => person === value));
            handleFilterData(result)
            setMywebsiteList(result)
        }
    }


    useEffect(() => {
        dispatch(getAllWebsiteList());
        dispatch(getcmsDetails())
        dispatch(clearWebsiteByNamesSuccess());
    }, [dispatch])
    // const { offer } = useSelector((state) => state.Dashboard.websiteByID);

    useEffect(() => {

        if (categorydetails) {
            const transformedArray = categorydetails.map(item => ({
                label: item.CategoryName,
                value: item.CategoryName,
                cat_icon: item.Category_fileIcon,
                cat_bgColor: item.Category_backgroundColor,
                Status: item.Status
            }));
            const firstArray = [{ label: 'All', value: 'All', Status: 'Active', }]
            setCategoryList([...firstArray, ...transformedArray]);
        }

    }, [categorydetails])


    useEffect(() => {
        dispatch(getCategorydata());
    }, [dispatch]);


    const myElementRef = useRef(null);
    const [footerHeight, setFooterHeight] = useState(700);
    useEffect(() => {
        if (myElementRef?.current) {
            const elementHeight = myElementRef.current.getBoundingClientRect().height;
            setFooterHeight(elementHeight)
        }
    });
    return (
        <div className='App'>
            <div ref={myElementRef}>
                <FrontHeader />
                <div className='homepage pb-4'>
                    <div className="px-4 pt-20 px-md-5 text-center text-lg-start">

                        <div className="max-w-7xl mx-auto px-4 lg:px-6 mb-6 d-flex justify-content-center ">
                            <div >
                                <div className="mt-5">
                                    <h3 className="font-bold  text-ellipsis overflow-hidden mb-3">Find the Best AI Tools That Make Your Life Easy!</h3>
                                </div>

                                <div className="flex items-center space-x-2">
                                    <Link className="btn btn-labeled btn-primary" to='/register'>
                                        <span className="btn-label"> <span className="bx bx-globe"> </span>  </span>
                                        Submit Your Website
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="max-w-7xl mx-auto lg:px-4 lg:px-6 mb-6" >
                            <form autoComplete="off" method="GET" action="#" data-turbo-frame="results" >
                                <div className="bg-gray-100 rounded-full flex m-flex-wrap items-center pr-4">
                                    <input
                                        name="query"
                                        className="form-control-Search h-12 px-6 rounded-l-full"
                                        type="text"
                                        placeholder="Search..."
                                        // value={searchQuery}
                                        onChange={(e) => handlechangeSearch(e.target.value)}
                                    />

                                    <div className="form-outline form-control-dropdown mt-1 m-w-full">
                                        <Select
                                            name="categories"
                                            options={categoryList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            onChange={(e) => {
                                                handlechangecategor(e.value)
                                            }}
                                        />

                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="max-w-7xl mx-auto lg:px-6 mb-10">
                            {top10Sites?.length !== 0 && <h3 className='detil-titel-home border-bottom font-bold line-clamp-1 text-ellipsis overflow-hidden'>Most Popular</h3>}
                            <turbo-frame id="results">
                                <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-10 md:gap-4">
                                    {
                                        top10Sites?.length !== 0 && top10Sites?.map((item, index) => {
                                            const SiteNameWithHyphens = item?.Site_Name?.replace(/[^\w\s]/gi, ' ').replace(/\s+/g, '-').replace(/-+/g, '-').replace(/^-+|-+$/g, '');
                                            const SiteLinkWithHyphens = item?.Site_Link?.replace(/[^\w\s]/gi, ' ').replace(/\s+/g, '-').replace(/-+/g, '-').replace(/^-+|-+$/g, '');

                                            if ((item.TOP_10 === 'true' && item.Approval_Status === 'Approved')) {
                                                return (
                                                    <Link key={index} className="relative p-2 items-center md:p-3 md:bg-gray-50 border-shadow md:rounded-lg"
                                                        to={`/site/${item?.Site_Name ? SiteNameWithHyphens : SiteLinkWithHyphens}`}
                                                        style={{ border: item?.site_type === 'featured' ? '2px solid orange' : item?.site_type === 'editor' ? '2px solid #22c55e' : '0px' }}

                                                        data-turbo="false" >
                                                        <div className='flex'>
                                                            <div className="flex-none mr-3 rounded-[22.5%] w-16 h-16 overflow-hidden">
                                                                <img className="w-16 h-16" src={item?.Logo ? api.API_URL + '/' + item?.Logo : website_background} alt={`Logo of ${item?.Site_Name}`} loading="lazy" />
                                                            </div>
                                                            <div className="flex-grow">
                                                                <div className="flex items-center justify-between mb-0.5">
                                                                    <h6 className="text-lg font-bold line-clamp-1 text-ellipsis overflow-hidden">
                                                                        {item?.Site_Name ? item?.Site_Name :
                                                                            item.Site_Link?.length > 40 ? `${item?.Site_Link?.substring(0, 40)}` : item?.Site_Link
                                                                        }
                                                                    </h6>
                                                                </div>
                                                                <p className="text-sm md:text-xs text-gray-600 line-clamp-2 text-start">{item.Short_Description}</p>
                                                            </div>
                                                        </div>
                                                        <div className='flex border-top mt-2 justify-content-between '>
                                                            <div className='flex mt-2'>
                                                                <span>
                                                                    {
                                                                        item.offers?.length > 0 &&
                                                                        <div >
                                                                            <div class="shrink-0 flex  items-center space-x-1">
                                                                                <div
                                                                                    class="mt-2 shrink-0 flex items-center space-x-1 px-1.5 py-0.5 animate-pulse bg-green-200 text-green-600 rounded-full">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                                                        stroke-width="1.5" stroke="currentColor" class="w-3 h-3">
                                                                                        <path stroke-linecap="round" stroke-linejoin="round"
                                                                                            d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                                                                                    </svg>
                                                                                    <div class="font-medium text-xs">{item.offers.length} deal</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </span>

                                                            </div>
                                                            <div className='text-center mt-2 '>
                                                                {item?.site_type === 'editor' && <div className='text-center shrink-0 flex items-center space-x-1 px-2 py-1.5 bg-green-500 text-white rounded-full'>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                                    </svg>
                                                                    <p> Editor's Choice</p>
                                                                </div>
                                                                }
                                                                {item?.site_type === 'featured' && <div className='text-center shrink-0 flex items-center space-x-1 px-2 py-1.5 bg-orange-500 text-white rounded-full'>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                                    </svg>
                                                                    <p> Featured</p>
                                                                </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </Link>
                                                )
                                            }

                                        })
                                    }
                                </div>

                            </turbo-frame>
                        </div>
                        <div className="max-w-7xl mx-auto lg:px-6 mb-10">
                            {approvedSites?.length !== 0 && <h3 className='detil-titel-home border-bottom font-bold line-clamh3-1 text-ellipsis overflow-hidden'>Newest Launched</h3>}
                            <turbo-frame >
                                <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-10 md:gap-4">
                                    {
                                        approvedSites?.length !== 0 && approvedSites?.map((item, index) => {
                                            const SiteNameWithHyphens = item?.Site_Name?.replace(/[^\w\s]/gi, ' ').replace(/\s+/g, '-').replace(/-+/g, '-').replace(/^-+|-+$/g, '');
                                            const SiteLinkWithHyphens = item?.Site_Link?.replace(/[^\w\s]/gi, ' ').replace(/\s+/g, '-').replace(/-+/g, '-').replace(/^-+|-+$/g, '');

                                            if (item.TOP_10 === 'false' && item.Approval_Status === 'Approved') {
                                                return (
                                                    <Link key={index} className="relative p-2 items-center md:p-3 md:bg-gray-50 border-shadow md:rounded-lg"
                                                        to={`/site/${item?.Site_Name ? SiteNameWithHyphens : SiteLinkWithHyphens}`}
                                                        style={{ border: item?.site_type === 'featured' ? '2px solid orange' : item?.site_type === 'editor' ? '2px solid #22c55e' : '0px' }}
                                                        data-turbo="false" >
                                                        <div className='flex'>
                                                            <div className="flex-none mr-3 rounded-[22.5%] w-16 h-16 overflow-hidden">
                                                                <img className="w-16 h-16" src={item?.Logo ? api.API_URL + '/' + item?.Logo : website_background} alt={`Logo of ${item?.Site_Name}`} loading="lazy" />

                                                            </div>
                                                            <div className="flex-grow">
                                                                <div className="flex items-center justify-between mb-0.5">
                                                                    <h6 className="text-lg font-bold line-clamp-1 text-ellipsis overflow-hidden">
                                                                        {item?.Site_Name ? item?.Site_Name : item.Site_Link?.length > 40 ? `${item?.Site_Link?.substring(0, 40)}` : item?.Site_Link}
                                                                    </h6>
                                                                </div>
                                                                <p className="text-sm md:text-xs text-gray-600 line-clamp-2 text-start">{item.Short_Description}</p>
                                                            </div>
                                                        </div>
                                                        <div className='flex border-top mt-2 justify-content-between '>
                                                            <div className='flex mt-2'>
                                                                <span>
                                                                    {item.offers?.length > 0 &&
                                                                        <div>
                                                                            <div class="shrink-0 flex  items-center space-x-1">
                                                                                <div
                                                                                    class="mt-2 shrink-0 flex items-center space-x-1 px-1.5 py-0.5 animate-pulse bg-green-200 text-green-600 rounded-full">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                                                        stroke-width="1.5" stroke="currentColor" class="w-3 h-3">
                                                                                        <path stroke-linecap="round" stroke-linejoin="round"
                                                                                            d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                                                                                    </svg>
                                                                                    <div class="font-medium text-xs">{item.offers.length} deal</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </span>
                                                            </div>
                                                            <div className='text-center mt-2 '>
                                                                {/* {item?.site_type === 'normal' && <div className='text-center'>     <p>Total Views : {item?.Gpt_view}</p></div>} */}

                                                                {item?.site_type === 'editor' && <div className='text-center shrink-0 flex items-center space-x-1 px-2 py-1.5 bg-green-500 text-white rounded-full'>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                                    </svg>
                                                                    <p> Editor's Choice</p>
                                                                </div>
                                                                }
                                                                {item?.site_type === 'featured' && <div className='text-center shrink-0 flex items-center space-x-1 px-2 py-1.5 bg-orange-500 text-white rounded-full'>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                                    </svg>
                                                                    <p> Featured</p>
                                                                </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </Link>
                                                )
                                            }
                                        })
                                    }
                                </div>
                            </turbo-frame>
                        </div>
                    </div>
                </div>
            </div>
            <FrontFooter footer_style={footerHeight > window.innerHeight ? 'App-footer-scroll' : ''} />
        </div>
    );
}

export default FrontDashbaord;