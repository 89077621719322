import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { isEmpty } from "lodash";
import * as moment from "moment";

// Import Images
import { SlideshowLightbox } from 'lightbox.js-react';
import 'lightbox.js-react/dist/index.css'


import {
    Col,
    Container,
    Row,
    Card,
    CardHeader,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Label,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    ModalFooter,
    Table,
    FormFeedback
} from "reactstrap";
import Select from "react-select";

import BreadCrumb from "../../../Components/Common/BreadCrumb";
import DeleteModal from "../../../Components/Common/DeleteModal";

//Import actions
// import {
//     addAdminTeamData as onAddAdminTeamData,
//     getAdminTeamData as onGetAdminTeamData,
//     updateAdminTeamData as onUpdateAdminTeamData,
// deleteAdminTeamData as onDeleteAdminTeamData,
// } from "../../../slices/thunks";
//redux
import { useDispatch, useSelector } from "react-redux";
import TableContainer from "../../../Components/Common/TableContainer";

import Loader from "../../../Components/Common/Loader";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import avatar1 from '../../../assets/images/users/Placeholder_view_vector.svg.png';

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import { addNewDetailsAndProduct, deletedataDetailsAndProduct, getDetailsAndProductdata, updatedataDetailsAndProduct } from "../../../slices/thunks";
import { api } from "../../../config";

const DetailsAndProduct = () => {
    const location = useLocation();
    // console.log("location: ", location);
    const dispatch = useDispatch();
    const { state } = location;
    const DetailandProduct = useSelector((state) => state.DetailandProduct.detailandproductdetails);

    useEffect(() => {
        dispatch(getDetailsAndProductdata({ id: state._id }));
    }, [dispatch]);

    const [isEdit, setIsEdit] = useState(false);
    const [DetailandProductis, setDetailandProductis] = useState(null);

    //delete Conatct
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteModalMulti, setDeleteModalMulti] = useState(false);
    const [modal, setModal] = useState(false);

    useEffect(() => {
        setDetailandProductis(DetailandProduct)
    }, [DetailandProduct])

    useEffect(() => {
        if (!isEmpty(DetailandProduct)) {
            setDetailandProductis(DetailandProduct);
            setIsEdit(false);
        }
    }, [DetailandProduct]);

    const toggle = useCallback(() => {
        if (modal) {
            setModal(false);
            setIsEdit(false);
            setDetailandProductis(null)

        } else {
            setModal(true);
        }
    }, [modal]);

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            _id: (DetailandProductis && DetailandProductis._id) || null,
            Offer_Name: (DetailandProductis && DetailandProductis.Offer_Name) || '',
            OriginalPrice: (DetailandProductis && DetailandProductis.OriginalPrice) || '',
            SalePrice: (DetailandProductis && DetailandProductis.SalePrice) || '',
            BillingPeriod: (DetailandProductis && DetailandProductis.BillingPeriod) || 'One-time',
            BuyNowLink: (DetailandProductis && DetailandProductis.BuyNowLink) || '',
            Description: (DetailandProductis && DetailandProductis.Description) || '',
        },
        validationSchema: Yup.object({
            Offer_Name: Yup.string().required("Please Enter Offer Name"),
            OriginalPrice: Yup.string().required("Please Enter Original Price"),
            SalePrice: Yup.string().required("Please Enter Sale Price"),
            BillingPeriod: Yup.string().required("Please Select Billing Period"),
            BuyNowLink: Yup.string().required("Please Enter Buy Now Link"),
            Description: Yup.string().required("Please Enter Description"),
        }),
        onSubmit: (values) => {
            // console.log('values ::', values);
            if (isEdit) {
                const formData = new FormData();

                formData.append('id', values._id);
                formData.append('Offer_Name', values.Offer_Name);
                formData.append('OriginalPrice', values.OriginalPrice);
                formData.append('SalePrice', values.SalePrice);
                formData.append('BillingPeriod', values.BillingPeriod);
                formData.append('BuyNowLink', values.BuyNowLink);
                formData.append('Description', values.Description);
                formData.append('Website_ID', location?.state._id);

                dispatch(updatedataDetailsAndProduct(formData))
            } else {
                const formData = new FormData();

                formData.append('Website_ID', location?.state._id);
                formData.append('Offer_Name', values.Offer_Name);
                formData.append('OriginalPrice', values.OriginalPrice);
                formData.append('SalePrice', values.SalePrice);
                formData.append('BillingPeriod', values.BillingPeriod);
                formData.append('BuyNowLink', values.BuyNowLink);
                formData.append('Description', values.Description);

                dispatch(addNewDetailsAndProduct(formData));
            }
            validation.resetForm();
            setModal(false);
        },
    });

    // Update Data
    const handleContactClick = useCallback((arg) => {
        const contact = arg;
        setDetailandProductis({
            _id: contact._id,
            Offer_Name: contact.Offer_Name,
            OriginalPrice: contact.OriginalPrice,
            SalePrice: contact.SalePrice,
            BillingPeriod: contact.BillingPeriod,
            BuyNowLink: contact.BuyNowLink,
            Description: contact.Description,
        });
        toggle();
        setIsEdit(true);
    }, [toggle]);



    // Checked All
    const checkedAll = useCallback(() => {
        const checkall = document.getElementById("checkBoxAll");
        const ele = document.querySelectorAll(".contactCheckBox");

        if (checkall.checked) {
            ele.forEach((ele) => {
                ele.checked = true;
            });
        } else {
            ele.forEach((ele) => {
                ele.checked = false;
            });
        }
        deleteCheckbox();
    }, []);

    // Delete Multiple
    const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
    const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

    const deleteMultiple = () => {
        const checkall = document.getElementById("checkBoxAll");
        selectedCheckBoxDelete.forEach((element) => {
            dispatch(deletedataDetailsAndProduct(element.value));
            setTimeout(() => { toast.clearWaitingQueue(); }, 3000);
        });
        setIsMultiDeleteButton(false);
        checkall.checked = false;
    };

    const deleteCheckbox = () => {
        const ele = document.querySelectorAll(".contactCheckBox:checked");
        ele.length > 0 ? setIsMultiDeleteButton(true) : setIsMultiDeleteButton(false);
        setSelectedCheckBoxDelete(ele);
    };

    // Delete Data
    const handleDeleteContact = () => {
        if (DetailandProductis) {
            dispatch(deletedataDetailsAndProduct(DetailandProductis._id));
            setDeleteModal(false);
        }
    };

    const onClickDelete = (data) => {
        setDetailandProductis(data)
        setDeleteModal(true);
    };

    const handleValidDate = date => {
        const date1 = moment(new Date(date)).format("DD MMM Y");
        return date1;
    };

    // Column
    const columns = useMemo(
        () => [
            {
                Header: <input type="checkbox" className="form-check-input" id="checkBoxAll" onClick={() => checkedAll()} />,
                Cell: (cellProps) => {
                    return <input type="checkbox" className="contactCheckBox form-check-input" value={cellProps.row.original._id} onChange={() => deleteCheckbox()} />;
                },
                id: '#',
            },
            {
                Header: "Offers/Product Date",
                accessor: "createdAt",
                filterable: true,
                Cell: (cellProps) => {
                    return (
                        <>
                            {
                                handleValidDate(cellProps.value)
                            }
                        </>
                    )
                }
            },
            {
                Header: "Offers/Product Name",
                accessor: "Offer_Name",
                filterable: true,
            },
            {
                Header: "Original Price",
                accessor: "OriginalPrice",
                filterable: true,
                Cell: (cellProps) => {
                    const amount = cellProps.value
                    return (
                        amount ? <span className="text-uppercase">${amount}</span> : <span className="text-uppercase">$0</span>
                    )
                }
            },
            {
                Header: "Sale Price",
                accessor: "SalePrice",
                filterable: true,
                Cell: (cellProps) => {
                    const amount = cellProps.value
                    return (
                        amount ? <span className="text-uppercase">${amount}</span> : <span className="text-uppercase text-warning">$0</span>
                    )
                }
            },
            {
                Header: "Billing Period",
                accessor: "BillingPeriod",
                filterable: true,
            },
            {
                Header: "Product Description",
                accessor: "Description",
                filterable: true,
                Cell: (cellProps) => {
                    return (
                        <div className="text-truncate">
                            {cellProps.value ?
                                cellProps.value.length < 40
                                    ? cellProps.value
                                    : cellProps.value.substring(0, 40) + '...'
                                : <>-</>}
                        </div>
                    )
                }
            },
            {
                Header: "Buy Now Link",
                accessor: "BuyNowLink",
                filterable: true,
                Cell: (cell) => {
                    return (
                        cell.value ?
                            <a target='_blank' className="link-secondary" href={cell.value}>
                                {cell?.value}
                            </a>
                            :
                            <div className="text-center">-</div>
                    )
                }
            },
            {
                Header: "Action",
                Cell: (cellProps) => {
                    return (
                        <ul className="list-inline hstack gap-2">

                            <li className="list-inline-item">
                                <UncontrolledDropdown>
                                    <DropdownToggle
                                        href=""
                                        className="btn btn-soft-secondary btn-sm dropdown"
                                        tag="button"
                                    >
                                        <i className="ri-more-fill align-middle"></i>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-end">

                                        <DropdownItem
                                            className="dropdown-item edit-item-btn"
                                            href=""
                                            onClick={() => { const contactData = cellProps.row.original; handleContactClick(contactData); }}
                                        >
                                            <i className="ri-pencil-fill align-middle me-2 text-muted"></i>
                                            Edit
                                        </DropdownItem>
                                        <DropdownItem
                                            className="dropdown-item remove-item-btn"
                                            href=""
                                            onClick={() => { const contactData = cellProps.row.original; onClickDelete(contactData); }}
                                        >
                                            <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>
                                            Delete
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </li>
                        </ul>
                    );
                },
            },
        ],
        [handleContactClick, checkedAll]
    );

    document.title = "Admin Panel | Offers/Product";
    return (
        <React.Fragment>
            <div className="page-content">

                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={handleDeleteContact}
                    onCloseClick={() => setDeleteModal(false)}
                />
                <DeleteModal
                    show={deleteModalMulti}
                    onDeleteClick={() => {
                        deleteMultiple();
                        setDeleteModalMulti(false);
                    }}
                    onCloseClick={() => setDeleteModalMulti(false)}
                />
                <Container fluid>
                    <BreadCrumb title="Offers/Product" pageTitle="Dashboards" />
                    <Row>
                        <Col lg={12}>
                            <Card>

                                <CardHeader>
                                    <div className="d-flex align-items-center flex-wrap gap-2">
                                        <div className="flex-grow-1">
                                            <button className="btn btn-primary add-btn" onClick={() => { setModal(true); }}>
                                                <i className="ri-add-fill me-1 align-bottom"></i>Add Offers/Product
                                            </button>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h6 style={{ color: "black", marginBottom: "0" }}>Logo</h6>
                                            {
                                                location?.state.Logo ?
                                                    <img
                                                        src={api.API_URL + '/' + location?.state.Logo}
                                                        alt=""
                                                        className="avatar-xxs rounded-circle"
                                                    />
                                                    :
                                                    <img
                                                        src={avatar1}
                                                        alt=""
                                                        className="avatar-xxs rounded-circle"
                                                    />
                                            }
                                        </div>
                                        <div className="flex-grow-1">
                                            <h6 style={{ color: "black" }}>Site Name</h6>
                                            <p style={{ marginBottom: "0" }}>{location?.state.Site_Name || location?.state.Site_Link}</p>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h6 style={{ color: "black" }}>Submitted Date</h6>
                                            <p style={{ marginBottom: "0" }}>{handleValidDate(location?.state.Date_Submitted)}</p>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h6 style={{ color: "black" }}>Category</h6>
                                            <p style={{ marginBottom: "0" }}>{location?.state.Category}</p>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h6 style={{ color: "black" }}>Approval Status</h6>
                                            <li style={{ listStyle: "none" }}>
                                                {
                                                    location?.state.Approval_Status === 'Approved' ? <span className="badge text-success text-uppercase fs-14 badge-soft-success"><i className="ri-checkbox-circle-line align-bottom"></i> Approved </span>
                                                        : location?.state.Approval_Status === 'Rejected' ? <span className="badge text-danger text-uppercase fs-14 badge-soft-danger"><i className="ri-close-circle-line align-bottom"></i> Rejected </span>
                                                            : location?.state.Approval_Status === 'Review' ? <span className="badge text-info text-uppercase fs-14 badge-soft-info"><i className="ri-eye-line align-bottom"></i> Review </span>
                                                                : <span className="badge text-warning text-uppercase fs-12 badge-soft-warning"><i className="ri-time-line align-bottom"></i> Pending </span>
                                                }
                                            </li>
                                        </div>
                                    </div>
                                </CardHeader>
                            </Card>
                        </Col>
                        <Col xxl={12}>
                            <Card id="TeamMembersList">
                                <CardBody>
                                    <div>
                                        {DetailandProduct?.length ? (
                                            <TableContainer
                                                columns={columns}
                                                data={(DetailandProduct || [])}
                                                isGlobalFilter={true}
                                                isAddUserList={false}
                                                customPageSize={8}
                                                className="custom-header-css"
                                                divClass="table-responsive table-card mb-3"
                                                tableClass="align-middle table-nowrap"
                                                theadClass="table-light"
                                                isContactsFilter={false}
                                                SearchPlaceholder='Search for Offers/Product...'
                                            />
                                        ) : <div style={{ color: "rgb(135, 138, 153)" }} className="text-center fs-18 fw-bold">Offers Not Available</div>
                                        }
                                    </div>
                                    <Modal id="showModal" backdrop={false} isOpen={modal} centered size="lg">
                                        <ModalHeader className="bg-soft-info p-3" toggle={toggle}>
                                            {!!isEdit ? "Edit Offers/Product" : "Add New Offers/Product"}
                                        </ModalHeader>

                                        <Form
                                            className="tablelist-form"
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                validation.handleSubmit();
                                                return false;
                                            }}
                                        >
                                            <ModalBody>
                                                <Input type="hidden" id="id-field" />
                                                <Row className="g-3">

                                                    <Col lg={6}>
                                                        <div>
                                                            <Label
                                                                htmlFor="Offer_Name"
                                                                className="form-label"
                                                            >
                                                                Offers/Product Name
                                                            </Label>

                                                            <Input
                                                                name="Offer_Name"
                                                                id="Offer_Name"
                                                                className="form-control"
                                                                placeholder="Enter Offers/Product Name"
                                                                type="text" validate={{
                                                                    required: { value: true },
                                                                }}
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.Offer_Name || ""}
                                                                invalid={
                                                                    validation.touched.Offer_Name && validation.errors.Offer_Name ? true : false
                                                                }
                                                            />
                                                            {validation.touched.Offer_Name && validation.errors.Offer_Name ? (
                                                                <FormFeedback type="invalid"><div>{validation.errors.Offer_Name}</div></FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>

                                                    <Col lg={6}>
                                                        <div>
                                                            <Label
                                                                htmlFor="OriginalPrice"
                                                                className="form-label"
                                                            >
                                                                Original Price
                                                            </Label>

                                                            <Input
                                                                name="OriginalPrice"
                                                                id="OriginalPrice"
                                                                className="form-control"
                                                                placeholder="Enter Original Price"
                                                                type="text" validate={{
                                                                    required: { value: true },
                                                                }}
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.OriginalPrice || ""}
                                                                invalid={
                                                                    validation.touched.OriginalPrice && validation.errors.OriginalPrice ? true : false
                                                                }
                                                            />
                                                            {validation.touched.OriginalPrice && validation.errors.OriginalPrice ? (
                                                                <FormFeedback type="invalid"><div>{validation.errors.OriginalPrice}</div></FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>

                                                    <Col lg={6}>
                                                        <div>
                                                            <Label
                                                                htmlFor="SalePrice"
                                                                className="form-label"
                                                            >
                                                                Sale Price
                                                            </Label>

                                                            <Input
                                                                name="SalePrice"
                                                                id="SalePrice"
                                                                className="form-control"
                                                                placeholder="Enter Original Price"
                                                                type="text" validate={{
                                                                    required: { value: true },
                                                                }}
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.SalePrice || ""}
                                                                invalid={
                                                                    validation.touched.SalePrice && validation.errors.SalePrice ? true : false
                                                                }
                                                            />
                                                            {validation.touched.SalePrice && validation.errors.SalePrice ? (
                                                                <FormFeedback type="invalid"><div>{validation.errors.SalePrice}</div></FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>

                                                    <Col lg={6}>
                                                        <div>
                                                            <Label
                                                                htmlFor="BillingPeriod"
                                                                className="form-label"
                                                            >
                                                                Billing Period
                                                            </Label>

                                                            <Input
                                                                id="BillingPeriod"
                                                                name="BillingPeriod"
                                                                type="select"
                                                                className="form-select"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.BillingPeriod || ""}
                                                            >
                                                                <option value="One-time">One-time</option>
                                                                <option value="Daily">Daily</option>
                                                                <option value="Monthly">Monthly</option>
                                                                <option value="Weekly">Weekly</option>
                                                                <option value="Annually">Annually</option>
                                                            </Input>
                                                            {validation.touched.BillingPeriod && validation.errors.BillingPeriod ? (
                                                                <FormFeedback type="invalid"><div>{validation.errors.BillingPeriod}</div></FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>

                                                    <Col lg={6}>
                                                        <div>
                                                            <Label
                                                                htmlFor="BuyNowLink"
                                                                className="form-label"
                                                            >
                                                                Buy Now Link
                                                            </Label>

                                                            <Input
                                                                name="BuyNowLink"
                                                                id="BuyNowLink"
                                                                className="form-control"
                                                                placeholder="Enter Buy Now Link"
                                                                type="text" validate={{
                                                                    required: { value: true },
                                                                }}
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.BuyNowLink || ""}
                                                                invalid={
                                                                    validation.touched.BuyNowLink && validation.errors.BuyNowLink ? true : false
                                                                }
                                                            />
                                                            {validation.touched.BuyNowLink && validation.errors.BuyNowLink ? (
                                                                <FormFeedback type="invalid"><div>{validation.errors.BuyNowLink}</div></FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>

                                                    <Col lg={6}>
                                                        <div>
                                                            <Label
                                                                htmlFor="Description"
                                                                className="form-label"
                                                            >
                                                                Product Description
                                                            </Label>

                                                            <textarea
                                                                name="Description"
                                                                id="Description"
                                                                className="form-control"
                                                                placeholder="Enter Product Description"
                                                                type="text"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.Description || ""}
                                                            ></textarea>
                                                            {validation.touched.Description && validation.errors.Description ? (
                                                                <FormFeedback type="invalid"><div>{validation.errors.Description}</div></FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </ModalBody>
                                            <ModalFooter>
                                                <div className="hstack gap-2 justify-content-end">
                                                    <button type="button" className="btn btn-light" onClick={() => { toggle(); setModal(false); setIsEdit(false) }} > Close </button>
                                                    <button type="submit" className="btn btn-success" id="add-btn" > {!!isEdit ? "Update" : "Add Offers/Product"} </button>
                                                </div>
                                            </ModalFooter>
                                        </Form>
                                    </Modal>
                                    <ToastContainer closeButton={false} limit={1} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment >
    );
};

export default DetailsAndProduct;