import React, { useState, useEffect, useRef } from 'react';
import Header from '../../../Layouts/FrontHeader';
import Footer from '../../../Layouts/FrontFooter';
import { useLocation, useNavigate } from 'react-router-dom/dist';
import { useDispatch, useSelector } from 'react-redux';
import { getWebsiteByNames } from '../../../slices/FrontView/dashboard/thunk';
import DOMPurify from 'dompurify';
import { api } from '../../../config';
import website_background from "../../../assets/images/website_background.png";
import Loader from '../../../Components/Common/Loader';

const Detailspage = (props) => {
    const location = useLocation();
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { website, offer } = useSelector((state) => state?.Dashboard?.websiteByName);
    const { cmsData } = useSelector((state) => state.CMSF);
    const currentPageURL = location.pathname?.replace('/site/', '');

    useEffect(() => {
        if (currentPageURL) {
            dispatch(getWebsiteByNames(currentPageURL, navigate))
        }
    }, [currentPageURL, dispatch])

    const myElementRef = useRef(null);
    const [footerHeight, setFooterHeight] = useState(700);
    useEffect(() => {
        if (myElementRef?.current) {
            const elementHeight = myElementRef.current.getBoundingClientRect().height;
            setFooterHeight(elementHeight)
        }
    });

    document.title = `${cmsData?.websiteTitle ? cmsData?.websiteTitle : '1000 Ai Sites'} | ${website?.Site_Name ? website?.Site_Name : ''}`;
    return (
        <div >
            {website &&
                <>
                    <div ref={myElementRef}>
                        <Header />
                        <div className='detilspage pb-4' >

                            <div className="px-4 pt-20 pb-5 px-md-5 text-center text-lg-start">
                                <div className="container">
                                    <div className="row gx-lg-5 align-items-center justify-content-center">
                                        <div className="col-lg-7 mb-lg-0 card ">
                                            <div className="">
                                                <div className="mx-auto px-4 pb-4">
                                                    <div className="mb-6 lg:mb-12">
                                                        <div className="flex items-center justify-between mb-4 mt-4">
                                                            <div className="flex-none mr-3 lg:mr-4 rounded-[22.5%] w-20 h-20 overflow-hidden">
                                                                <img className="w-20 h-20" src={website?.Logo ? api.API_URL + '/' + website?.Logo : website_background} alt={`Logo of ${website?.Site_Name}`} loading="lazy" />
                                                            </div>
                                                            <div>
                                                                <a className="btn btn-sm btn-primary" href={website?.Site_Link} target="_blank">
                                                                    <span className="btn-label-view"> <span className="bx bx-globe"> </span></span>
                                                                    <span className='text-website' >Visit Website</span>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="ml-1 w-4 h-4">
                                                                        <path fillRule="evenodd" d="M5.22 14.78a.75.75 0 001.06 0l7.22-7.22v5.69a.75.75 0 001.5 0v-7.5a.75.75 0 00-.75-.75h-7.5a.75.75 0 000 1.5h5.69l-7.22 7.22a.75.75 0 000 1.06z" clipRule="evenodd" />
                                                                    </svg>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className='flex justify-between'>
                                                            <div className='flex-grow'>
                                                                <h1 className="font-bold mb-0">{website?.Site_Name || website?.Site_Link}</h1>
                                                                <p className="text-sm text-gray-400">{website?.Short_Description}</p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    {offer?.length > 0 && <div className="mx-4 lg:-mx-12 py-4 bg-gray-100 lg:rounded-xl mb-6 lg:mb-12">
                                                        <h3 className="text-sm ml-4 mb-2">Deals</h3>
                                                        <div className="relative w-full flex gap-4 snap-x overflow-x-auto scrollbar scrollbar-h-[0px] scrollbar-thumb-transparent scrollbar-track-transparent">
                                                            <div className="snap-start shrink-0" />

                                                            {offer?.map((item, index) => {
                                                                return (
                                                                    <div key={index} className="snap-start shrink-0">
                                                                        <div className="w-60 bg-white p-4 rounded-xl">
                                                                            <h3 className="text-sm font-bold mb-2">{item.Offer_Name}</h3>
                                                                            <div className="text-sm mb-2">${item.SalePrice} <span className="text-gray-500 line-through">${item.OriginalPrice}</span> <span>/
                                                                                {" "} {item.BillingPeriod}</span></div>
                                                                            <div className="mb-4">
                                                                                <div className="inline-block px-1.5 py-0.5 border border-green-200 bg-green-50 text-green-500 text-xs rounded-full">
                                                                                    You save ${Number(item.OriginalPrice) - Number(item.SalePrice)}</div>
                                                                            </div>
                                                                            <div data-type="url" className="group">
                                                                                <div className="hidden group-data-[type=url]:block">
                                                                                    <a className="btn btn-sm btn-primary w-full" href={item.BuyNowLink} target="__blank">
                                                                                        Buy now
                                                                                    </a>
                                                                                </div>
                                                                                <div className="hidden group-data-[type=coupon]:block">
                                                                                    <div className="btn btn-sm btn-gray w-full" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                            <div className="snap-start shrink-0">
                                                                <div className="w-0" />
                                                            </div>
                                                        </div>
                                                    </div>}




                                                    <div className=" prose-img:rounded-xl" >
                                                        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(website?.Long_Description) }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer footer_style={footerHeight > window.innerHeight ? 'App-footer-scroll' : ''} />
                </>
            }
        </div>
    );
}

export default Detailspage;
// large contest   App-footer1
// small contest   App-footer