import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Row, Spinner } from 'reactstrap';
import { ForgetPassword, userLoginWithURL } from '../../../slices/FrontView/authentication/thunk';
import { api } from '../../../config';
import { getcmsDetails } from '../../../slices/FrontView/cms/thunk';
import * as Yup from 'yup';
import { useFormik } from 'formik';


import CryptoJS from 'crypto-js';


function SsoForgetPassword(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isloading, setIsLoading] = useState(false)

    const [loginToken, setLoginToken] = useState(null)

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);

        const loginData = (decodeURIComponent(urlParams.get('data')));

        if (loginData !== 'null') {
            const hashedText = CryptoJS.AES.decrypt(loginData, 'NODE');

            var originalText = hashedText.toString(CryptoJS.enc.Utf8);
            const successData = JSON.parse(originalText);

            setLoginToken(successData)
        }
    }, [])
    const { cmsData } = useSelector((state) => state.CMSF);

    useEffect(() => {
        dispatch(getcmsDetails())
    }, [dispatch])


    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            newpassword: '',
            confirmPassword: '',
        },
        validationSchema: Yup.object({
            newpassword: Yup.string().required("Please Enter Your new password"),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('newpassword'), null], 'Passwords must match')
                .required("Confirm Password Required"),
        }),
        onSubmit: (values) => {
            // console.log("values", values);
            const pwdObj = {
                Email: loginToken?.email,
                newPassword: values.newpassword,
                confirmPassword: values.confirmPassword,
                Token: loginToken?.token
            }
            dispatch(ForgetPassword(pwdObj, navigate));
            setIsLoading(true)


        }
    });
    useEffect(() => {
        if (isloading) {
            setTimeout(() => {
                setIsLoading(false)
            }, 5000)
        }
    }, [isloading])

    document.title = `${cmsData?.websiteTitle ? cmsData?.websiteTitle : '1000 Ai Sites'} | Forgot Password`;

    return (
        <React.Fragment>
            <div className="auth-page-wrapper">
                <div className="auth-page-content">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <Link to="/dashboard" className="d-inline-block auth-logo">
                                        <img src={api.API_URL + '/' + cmsData?.websiteLogo} alt='logo' loading="lazy" style={{ height: "60px", width: "250px" }} />
                                    </Link>
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">
                                    <CardBody className="p-4 text-center">
                                        <h1 className="text-center font-bold mb-4">
                                            Forget Password
                                        </h1>
                                        <form
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                validation.handleSubmit();
                                                return false;
                                            }}
                                            action="#">

                                            <div className="form-outline mb-4 text-start relative">
                                                <label htmlFor="newpassword" className="form-label" >Enter your New Password</label>

                                                <input id="newpassword" className="form-control input" type="password" name="newpassword" placeholder="Enter your New Password"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.newpassword || ""}
                                                    invalid={
                                                        validation.touched.newpassword && validation.errors.newpassword ? true : false
                                                    }
                                                />
                                                <div className='error'>
                                                    <span >{validation.errors.newpassword && validation.touched.newpassword ? validation.errors.newpassword : ''}</span>

                                                </div>
                                            </div>
                                            <div className="form-outline mb-4 relative text-start">
                                                <label htmlFor="confirmPassword" className="form-label " >Enter your Confirm Password</label>

                                                <input id="confirmPassword" className="form-control input" type="password" name="confirmPassword" placeholder="Enter your Confirm Password"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.confirmPassword || ""}
                                                    invalid={
                                                        validation.touched.confirmPassword && validation.errors.confirmPassword ? true : false
                                                    }
                                                />
                                                <div className='error'>
                                                    <span >{validation.errors.confirmPassword && validation.touched.confirmPassword ? validation.errors.confirmPassword : ''}</span>

                                                </div>
                                            </div>

                                            <Button disabled={isloading ? true : false} className="btn btn-primary primary-btn btn-block mb-4" type="submit">
                                                {isloading ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}
                                                Change Password
                                            </Button>
                                            {/* <button type="submit" className="flex justify-content-start btn btn-primary btn-block mb-4 primary-btn">
                                            Change Password
                                            </button> */}
                                        </form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </React.Fragment >
    );
}

export default SsoForgetPassword;