import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";

import * as moment from "moment";


import 'lightbox.js-react/dist/index.css'


import {
    Col,
    Row,
    Card,
    CardBody,
} from "reactstrap";


import { useDispatch, useSelector } from "react-redux";
import TableContainer from "../../../../Components/Common/TableContainer";
import { getSubmittedSitesTransaction } from "../../../../slices/thunks";

const PaymentHistory = () => {

    const location = useLocation();
    const dispatch = useDispatch();

    const { state } = location;
    const { websiteSubscriptions } = useSelector((state) => state.SubmitSites);

    useEffect(() => {
        if (state?._id) {
            dispatch(getSubmittedSitesTransaction(state?._id))
        }
    }, [state, dispatch])


    // Column
    const columns = useMemo(
        () => [
            {
                Header: <input type="checkbox" className="form-check-input" id="checkBoxAll" />,
                Cell: () => {
                    return <input type="checkbox" className="contactCheckBox form-check-input" />;
                },
                id: '#',
            },
            {
                Header: "Transaction Date",
                accessor: "createdAt",
                filterable: true,
                Cell: (cell) => {
                    const formattedDate = moment(cell.value).format("D MMM YYYY");
                    return (
                        <div>
                            {formattedDate}
                        </div>
                    );
                },
            },
            {
                Header: "Plan Type",
                accessor: "billingPeriod",
                Cell: (cellProps) => {
                    switch (cellProps.row.original?.plan_ID?.billingPeriod) {
                        case "One-Time":
                            return <span className="badge text-uppercase badge-soft-warning"> One-Time </span>;
                        case "Daily":
                            return <span className="badge text-uppercase badge-soft-info"> Daily </span>;
                        case "Weekly":
                            return <span className="badge text-uppercase badge-soft-success"> Weekly </span>;
                        case "Monthly":
                            return <span className="badge text-uppercase badge-soft-secondary"> Monthly </span>;
                        default:
                            return <span className="badge text-uppercase badge-soft-primary"> Yearly </span>;
                    }
                }
            },
            {
                Header: "Amount ($)",
                accessor: "amount",
                filterable: true,
                Cell: (cellProps) => {
                    return <div> ${cellProps.row.original?.plan_ID?.salePrice}</div>
                }
            },
            {
                Header: "Start Date",
                accessor: "current_period_start",
                filterable: true,
                Cell: (cell) => {
                    const formattedDate = moment(cell.value).format("D MMM YYYY");
                    return (
                        <div>{formattedDate}</div>
                    );
                },
            },
            {
                Header: "Next Payment Date",
                accessor: "current_period_end",
                filterable: true,
                Cell: (cell) => {
                    const formattedDate = moment(cell.value).format("D MMM YYYY");
                    return (
                        <div>{formattedDate}</div>
                    );
                },
            },
            {
                Header: "Status",
                accessor: "payment_status",
                Cell: (cell) => {
                    switch (cell.value) {
                        case "Completed":
                        case "completed":
                            return <span className="badge text-uppercase badge-soft-success"><i className="ri-checkbox-circle-line align-bottom"></i> Completed </span>;
                        case "Active":
                        case "active":
                            return <span className="badge text-uppercase badge-soft-success"><i className="ri-checkbox-circle-line align-bottom"></i> Active </span>;
                        case "Inactive":
                        case "inactive":
                            return <span className="badge text-uppercase badge-soft-danger"><i className="ri-close-circle-line align-bottom"></i> Failed </span>;
                        case "canceled":
                        case "Canceled":
                            return <span className="badge text-uppercase badge-soft-danger"><i className="ri-close-circle-line align-bottom"></i> Failed </span>;
                        default:
                            return <span className="badge text-uppercase badge-soft-warning"><i className="ri-time-line align-bottom"></i> Pending </span>;
                    }
                }
            },
            {
                Header: "Payment ID",
                accessor: "payment_id",
                filterable: true,
                Cell: (cell) => {
                    return (
                        <>
                            <Link className="link-secondary" to={`https://www.sandbox.paypal.com/activity/payment/${cell.row.original.payment_id}`} target="_blank" >
                                <div> {cell.row.original.invoice_id ? cell.row.original.invoice_id : cell.row.original.payment_id}</div>
                            </Link>
                        </>

                    )
                }
            },
        ],
        []
    );

    return (
        <div>

            <Row>
                <Col lg={12}>
                    <div className="d-flex align-items-center mb-4">
                        <div className="flex-grow-1">
                            <h5 className="mb-0">Payment History</h5>
                        </div>
                    </div>
                </Col>
                <Col xxl={12}>
                    <Card id="TeamMembersList">
                        <CardBody>
                            <div>
                                {websiteSubscriptions?.length ? (
                                    <TableContainer
                                        columns={columns}
                                        data={(websiteSubscriptions || [])}
                                        isAddUserList={false}
                                        customPageSize={8}
                                        className="custom-header-css"
                                        divClass="table-responsive table-card mb-3"
                                        tableClass="align-middle table-nowrap"
                                        theadClass="table-light"
                                        isContactsFilter={false}
                                        SearchPlaceholder='Search for Payment History...'
                                    />
                                ) : <div style={{ color: "rgb(135, 138, 153)" }} className="text-center fs-18 fw-bold">Transaction Not Available</div>
                                }
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default PaymentHistory;