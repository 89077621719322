import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import { deleteDetailsAndProduct, getDetailsAndProduct, postDetailsAndProduct, updateDetailsAndProduct } from "../../../helpers/backend_helper";

export const getDetailsAndProductdata = createAsyncThunk("detailandproduct/getDetailsAndProductdata", async ({ id }) => {
  try {
    const response = await getDetailsAndProduct(id)
    return response;
  } catch (error) {
    return error;
  }
})

export const addNewDetailsAndProduct = createAsyncThunk("detailandproduct/addNewDetailsAndProduct", async (DetailsAndProduct) => {
  try {
    const response = await postDetailsAndProduct(DetailsAndProduct)
    toast.success("Detail/Product Added Successfully", { autoClose: 3000 });
    return response;
  } catch (error) {
    toast.error("Detail/Product Added Failed", { autoClose: 3000 });
    return error;
  }
})

export const updatedataDetailsAndProduct = createAsyncThunk("detailandproduct/updatedataDetailsAndProduct", async (DetailsAndProduct) => {
  const detailandproduct = DetailsAndProduct.get('id')
  try {
    const response = await updateDetailsAndProduct(detailandproduct, DetailsAndProduct)
    toast.success("Detail/Product Updated Successfully", { autoClose: 3000 });
    return response;
  } catch (error) {
    toast.error("Detail/Product Updated Failed", { autoClose: 3000 });
    return error;
  }
})

export const deletedataDetailsAndProduct = createAsyncThunk("detailandproduct/deletedataDetailsAndProduct", async (DetailsAndProduct) => {
  try {
    const response = await deleteDetailsAndProduct(DetailsAndProduct)
    toast.success("Detail/Product Deleted Successfully", { autoClose: 3000 });
    return { DetailsAndProduct, ...response };
  } catch (error) {
    toast.error("Detail/Product Deleted Failed", { autoClose: 3000 });
    return error;
  }
})