import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    allPages: [],
};




const PagesSlice = createSlice({
    name: "PagesSlice",
    initialState,
    reducers: {
        getAllPagesSuccess(state, action) {
            state.allPages = action.payload || {}
        },
    },

});


export const {
    getAllPagesSuccess
} = PagesSlice.actions

export default PagesSlice.reducer;