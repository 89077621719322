import React, { useEffect, useRef, useState } from 'react';
import { Container, } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Header from '../../../Layouts/FrontHeader'
import Footer from '../../../Layouts/FrontFooter'

import TicketsData from './TicketsData';
import { useSelector } from 'react-redux';

const SupportTicket = () => {

    const { ticketsUserList } = useSelector((state) => state?.TicketsF);
    const { cmsData } = useSelector((state) => state.CMSF);

    const myElementRef = useRef(null);
    const [footerHeight, setFooterHeight] = useState(700);
    useEffect(() => {
        if (myElementRef?.current) {
            const elementHeight = myElementRef.current.getBoundingClientRect().height;
            setFooterHeight(elementHeight)
        }
    });
    document.title = `${cmsData?.websiteTitle ? cmsData?.websiteTitle : '1000 Ai Sites'} | Support Ticket`;

    return (
        <React.Fragment>
            <div ref={myElementRef}>
                <Header isactive={'active-supporttickets'} />
                <div className='ticketspage mb-8' >
                    <div className="px-4 py-20 px-md-5 text-center text-lg-start" >
                        <Container className='ticket' >
                            <div className='mt-4' />
                            <BreadCrumb title="Tickets List" pageTitle="Tickets" style={'mx-1'} />
                            <TicketsData />
                        </Container>
                    </div>
                </div>
            </div>
            <Footer footer_style={footerHeight > window.innerHeight ? 'App-footer-scroll' : ''} />
        </React.Fragment>

    );
}

export default SupportTicket;