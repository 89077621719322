import React, { useEffect, useRef, useState } from 'react';
import { Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { api } from '../../../config';
import { getAllBlogs } from '../../../slices/FrontView/blogs/thunk';
import Loader from '../../../Components/Common/Loader';
import Header from '../../../Layouts/FrontHeader';
import Footer from '../../../Layouts/FrontFooter';
import { clearBlogsByTitleSuccess } from '../../../slices/FrontView/blogs/reducer';

const AllBlogs = () => {

    const dispatch = useDispatch()
    const [Myblog, setMyblog] = useState([])

    const { allBlogs } = useSelector((state) => state.Blogs);
    const { cmsData } = useSelector((state) => state.CMSF);
    useEffect(() => {
        const result = allBlogs.filter(item => item.status === 'Approved');

        setMyblog(result)
    }, [allBlogs])

    useEffect(() => {
        dispatch(getAllBlogs())
        dispatch(clearBlogsByTitleSuccess())
    }, [dispatch])

    const myElementRef = useRef(null);
    const [footerHeight, setFooterHeight] = useState(700);
    useEffect(() => {
        if (myElementRef?.current) {
            const elementHeight = myElementRef.current.getBoundingClientRect().height;
            setFooterHeight(elementHeight)
        }
    });
    document.title = `${cmsData?.websiteTitle ? cmsData?.websiteTitle : '1000 Ai Sites'} | Blogs`;

    return (
        <div className='App'>
            <div ref={myElementRef} >
                <Header isactive={'active-blogs'} />
                <div className='sumitpage mb-10'>
                    <div className="px-4 pt-20 px-md-5 text-center text-lg-start">
                        <div className="container">
                            <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-10 md:gap-8">                                {
                                allBlogs?.length > 0 && allBlogs?.map((item, index) => {

                                    if (item.status === 'Approved') {
                                        let categoryItem = item?.categories?.join(', ')

                                        const BlogNameWithHyphens = item?.title?.replace(/[^\w\s]/gi, ' ').replace(/\s+/g, '-').replace(/-+/g, '-').replace(/^-+|-+$/g, '');
                                        return (
                                            <div key={index} className="col">

                                                <div className="card p-3" style={{ height: '515px' }}>
                                                    <Link
                                                        to={`/blog/${BlogNameWithHyphens}`} state={{ blogs: item }} className='Blog-link'>
                                                        <div className='card-img pb-3'  >
                                                            <img src={api.API_URL + '/' + item.blog_image} className="card-img-top" alt="..." />
                                                        </div>
                                                    </Link>
                                                    <div style={{ borderTopWidth: '1.5px', }}>
                                                        <Link to={`/blog/${BlogNameWithHyphens}`} state={{ blogs: item }} className='Blog-link'  >

                                                            <h5 className="text-lg font-bold line-clamp-2 text-ellipsis overflow-hidden" style={{ height: '65px', }}>
                                                                {item?.title}
                                                            </h5>
                                                        </Link>
                                                        <div className='py-1' style={{ borderTopWidth: '1.5px' }}>
                                                            <p className="line-clamp-2 text-start " style={{ height: '50px', }}>{item.shortDetail}</p>
                                                        </div>
                                                    </div>
                                                    <div style={{ borderTopWidth: '1.5px' }}>

                                                        <Link to={`/blog/${BlogNameWithHyphens}`} state={{ blogs: item }}  >
                                                            <div className="py-2 d-flex justify-content-between">
                                                                <p className="line-clamp-2 text-start"> {categoryItem}  </p>
                                                                <div className='btn btn-labeled btn-primary d-flex align-items-center '> Read More</div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>

                                            </div>
                                        )
                                    }
                                })
                            }
                            </div>
                        </div>
                    </div>
                </div >
            </div >
            <Footer footer_style={footerHeight > window.innerHeight ? 'App-footer-scroll' : ''} />

        </div >
    );
}

export default AllBlogs;