import { createSlice } from "@reduxjs/toolkit";
import { getAdminTicketsList, addNewAdminTicket, updateAdminTicket, deleteAdminTicket, getAdminSingleTicketsList } from './thunk';

export const initialState = {
    adminticketsList: []
};

const AdminTicketsSlice = createSlice({
    name: 'tickets',
    initialState,
    reducer: {},
    extraReducers: (builder) => {
        builder.addCase(getAdminTicketsList.fulfilled, (state, action) => {
            state.adminticketsList = action.payload.data;
        });

        builder.addCase(getAdminTicketsList.rejected, (state, action) => {
            state.error = action.payload.error || null;
        });

        builder.addCase(getAdminSingleTicketsList.fulfilled, (state, action) => {
            state.adminticketsList = action.payload.ticket;
        });

        builder.addCase(getAdminSingleTicketsList.rejected, (state, action) => {
            state.error = action.payload.error || null;
        });

        builder.addCase(addNewAdminTicket.fulfilled, (state, action) => {
            state.adminticketsList.push(action.payload.ticket);
        });

        builder.addCase(addNewAdminTicket.rejected, (state, action) => {
            state.error = action.payload.error || null;
        });

        builder.addCase(updateAdminTicket.fulfilled, (state, action) => {
            state.adminticketsList = state.adminticketsList.map(ticket =>
                ticket._id.toString() === action.payload.data._id.toString()
                    ? { ...ticket, ...action.payload.data }
                    : ticket
            );
        });

        builder.addCase(updateAdminTicket.rejected, (state, action) => {
            state.error = action.payload.error || null;
        });

        builder.addCase(deleteAdminTicket.fulfilled, (state, action) => {
            state.adminticketsList = state.adminticketsList.filter(
                ticket => ticket.Ticket_ID.toString() !== action.payload.ticket.toString()
            );
        });

        builder.addCase(deleteAdminTicket.rejected, (state, action) => {
            state.error = action.payload.error || null;
        });
    }
});

export default AdminTicketsSlice.reducer;