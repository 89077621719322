import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import * as moment from "moment";

// Import Images
import { SlideshowLightbox } from 'lightbox.js-react';
import 'lightbox.js-react/dist/index.css'

import {
    Col,
    Container,
    Row,
    Card,
    CardHeader,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Label,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    ModalFooter,
    Table,
    FormFeedback
} from "reactstrap";
import Select from "react-select";

import BreadCrumb from "../../../Components/Common/BreadCrumb";
import DeleteModal from "../../../Components/Common/DeleteModal";

//Import actions
// import {
//     addAdminTeamData as onAddAdminTeamData,
//     getAdminTeamData as onGetAdminTeamData,
//     updateAdminTeamData as onUpdateAdminTeamData,
// deleteAdminTeamData as onDeleteAdminTeamData,
// } from "../../../slices/thunks";
//redux
import { useDispatch, useSelector } from "react-redux";
import TableContainer from "../../../Components/Common/TableContainer";

import Loader from "../../../Components/Common/Loader";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import { AdminLoginInUserData, AdminUserActive, AdminUserSuspend, deletedataAdminUser, getAdminUserdata, updatedataAdminUser } from "../../../slices/thunks";

const Users = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const AdminUser = useSelector((state) => state.AdminUser.adminusersdetails);

    useEffect(() => {
        dispatch(getAdminUserdata());
    }, [dispatch,]);


    const [isEdit, setIsEdit] = useState(false);
    const [AdminUsersData, setAdminUsersData] = useState(null);
    const authAdmin = JSON.parse(sessionStorage.getItem("authAdmin"))
    //delete Conatct
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteModalMulti, setDeleteModalMulti] = useState(false);
    const [modal, setModal] = useState(false);


    useEffect(() => {
        setAdminUsersData(AdminUser)
    }, [AdminUser])

    useEffect(() => {
        if (!isEmpty(AdminUser)) {
            setAdminUsersData(AdminUser);
            setIsEdit(false);
        }
    }, [AdminUser]);

    const toggle = useCallback(() => {
        if (modal) {
            setModal(false);
            setIsEdit(false);
            setAdminUsersData(null)

        } else {
            setModal(true);
        }
    }, [modal]);



    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            _id: (AdminUsersData && AdminUsersData._id) || null,
            User_Name: (AdminUsersData && AdminUsersData.User_Name) || '',
            Email: (AdminUsersData && AdminUsersData.Email) || '',
            Status: (AdminUsersData && AdminUsersData.Status) || 'Pending',
        },
        validationSchema: Yup.object({
            User_Name: Yup.string().required("Please Enter Category Name"),
            Email: Yup.string().email().required("Please Enter Email"),
            Status: Yup.string().required("Please Select Status"),
        }),
        onSubmit: (values) => {

            const formData = new FormData();

            formData.append('id', values._id);
            formData.append('User_Name', values.User_Name);
            formData.append('Email', values.Email);
            formData.append('Status', values.Status);

            dispatch(updatedataAdminUser(formData))
            // setModal(false);
            validation.resetForm();
            setModal(false);
        },
    });

    // Update Data
    const handleContactClick = useCallback((arg) => {
        const contact = arg;
        setAdminUsersData({
            _id: contact._id,
            User_Name: contact.User_Name,
            Email: contact.Email,
            Status: contact.Status,
        });
        toggle();
        setIsEdit(true);
    }, [toggle]);



    // Checked All
    const checkedAll = useCallback(() => {
        const checkall = document.getElementById("checkBoxAll");
        const ele = document.querySelectorAll(".contactCheckBox");

        if (checkall.checked) {
            ele.forEach((ele) => {
                ele.checked = true;
            });
        } else {
            ele.forEach((ele) => {
                ele.checked = false;
            });
        }
        deleteCheckbox();
    }, []);

    // Delete Multiple
    const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
    const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

    const deleteMultiple = () => {
        const checkall = document.getElementById("checkBoxAll");
        selectedCheckBoxDelete.forEach((element) => {
            dispatch(deletedataAdminUser(element.value));
            setTimeout(() => { toast.clearWaitingQueue(); }, 3000);
        });
        setIsMultiDeleteButton(false);
        checkall.checked = false;
    };

    const deleteCheckbox = () => {
        const ele = document.querySelectorAll(".contactCheckBox:checked");
        ele.length > 0 ? setIsMultiDeleteButton(true) : setIsMultiDeleteButton(false);
        setSelectedCheckBoxDelete(ele);
    };

    // Delete Data
    const handleDeleteContact = () => {
        if (AdminUsersData) {
            dispatch(deletedataAdminUser(AdminUsersData._id));
            setDeleteModal(false);
        }
    };

    const onClickDelete = (data) => {
        setAdminUsersData(data)
        setDeleteModal(true);
    };


    const handleValidDate = date => {
        const date1 = moment(new Date(date)).format("DD MMM Y");
        return date1;
    };

    // Column
    const columns = useMemo(
        () => [
            {
                Header: <input type="checkbox" className="form-check-input" id="checkBoxAll" onClick={() => checkedAll()} />,
                Cell: (cellProps) => {
                    return <input type="checkbox" className="contactCheckBox form-check-input" value={cellProps.row.original._id} onChange={() => deleteCheckbox()} />;
                },
                id: '#',
            },
            {
                Header: "Date and Time",
                accessor: "createdAt",
                Cell: (cell) => (
                    <>
                        {handleValidDate(cell.row.original.createdAt)}
                    </>
                ),
            },
            {
                Header: "Email Address",
                accessor: "Email",
                filterable: true,
            },
            {
                Header: "Log In",
                Cell: (cellProps) => {
                    return (
                        <>
                            <button
                                onClick={() => {
                                    dispatch(AdminLoginInUserData({ adminId: authAdmin.data._id, userId: cellProps.row.original._id }, navigate))
                                }}
                                type="submit" className="btn btn-primary add-btn">
                                Log In
                            </button>
                        </>
                    );
                }
            },
            {
                Header: "List of Websites",
                accessor: "ToolCount",
                filterable: true,
                Cell: (cellProps) => (
                    <>
                        <div style={{ cursor: 'pointer' }} className="fw-bold d-flex text-secondary align-items-center">
                            <div onClick={() => {
                                const UsersData = cellProps.row.original;

                                navigate('/admin/submitted-sites', {
                                    state: UsersData,
                                })
                            }} className="flex-grow-1 ms-2 User_Name">
                                {cellProps.row.original?.ToolCount}
                            </div>
                        </div>
                    </>
                ),
            },
            {
                Header: "Last Login",
                accessor: "updatedAt",
                Cell: (cell) => (
                    <>
                        {handleValidDate(cell.row.original?.updatedAt)}
                    </>
                ),
            },
            {
                Header: "Status",
                accessor: "Status",
                Cell: (cell) => {
                    switch (cell.value) {
                        case "Active":
                            return <span className="badge text-uppercase badge-soft-success"> <i className="ri-checkbox-circle-line align-bottom"></i> Active </span>;
                        case "Suspended":
                            return <span className="badge text-uppercase badge-soft-danger"> <i className="ri-close-circle-line align-bottom"></i> Suspended </span>;
                        default:
                            return <span className="badge text-uppercase badge-soft-warning"> <i className="ri-time-line align-bottom"></i> Pending </span>;
                    }
                }
            },
            {
                Header: "Action",
                Cell: (cellProps) => {
                    const statusdata = cellProps.row.original?.Status;
                    const isPending = statusdata === "Pending";
                    const isActive = statusdata === "Active";
                    const isSuspended = statusdata === "Suspended";

                    return (
                        <ul className="list-inline hstack gap-2">
                            <li className="list-inline-item">
                                <UncontrolledDropdown>
                                    <DropdownToggle
                                        href=""
                                        className="btn btn-soft-secondary btn-sm dropdown"
                                        tag="button"
                                    >
                                        <i className="ri-more-fill align-middle"></i>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-end">
                                        {isPending && (
                                            <>
                                                <DropdownItem
                                                    className="dropdown-item edit-item-btn"
                                                    href=""
                                                    onClick={() => {
                                                        // Update the status to "Active"
                                                        const newStatus = statusdata === "Active" ? "Suspended" : "Active";
                                                        dispatch(AdminUserActive({ planId: cellProps.row.original?._id, newStatus }));
                                                    }}
                                                >
                                                    <i className="ri-checkbox-circle-line align-bottom me-2 text-muted"></i>{" "}
                                                    Active
                                                </DropdownItem>
                                                <DropdownItem
                                                    className="dropdown-item edit-item-btn"
                                                    href=""
                                                    onClick={() => {
                                                        // Update the status to "Suspended"
                                                        const newStatus = statusdata === "Active" ? "Suspended" : "Active";

                                                        dispatch(AdminUserSuspend({ planId: cellProps.row.original?._id, newStatus }));
                                                    }}
                                                >
                                                    <i className="ri-close-circle-line align-bottom me-2 text-muted"></i>{" "}
                                                    Suspended
                                                </DropdownItem>
                                            </>
                                        )}
                                        {isActive && (
                                            <DropdownItem
                                                className="dropdown-item edit-item-btn"
                                                href=""
                                                onClick={() => {
                                                    // Update the status to "Suspended"
                                                    const newStatus = statusdata === "Active" ? "Suspended" : "Active";
                                                    dispatch(AdminUserSuspend({ planId: cellProps.row.original?._id, newStatus }));
                                                }}
                                            >
                                                <i className="ri-close-circle-line align-bottom me-2 text-muted"></i>{" "}
                                                Suspended
                                            </DropdownItem>
                                        )}
                                        {isSuspended && (
                                            <DropdownItem
                                                className="dropdown-item edit-item-btn"
                                                href=""
                                                onClick={() => {
                                                    // Update the status to "Active"
                                                    const newStatus = statusdata === "Active" ? "Suspended" : "Active";
                                                    dispatch(AdminUserActive({ planId: cellProps.row.original?._id, newStatus }));
                                                }}
                                            >
                                                <i className="ri-checkbox-circle-line align-bottom me-2 text-muted"></i>{" "}
                                                Active
                                            </DropdownItem>
                                        )}
                                        <DropdownItem />
                                        <DropdownItem
                                            className="dropdown-item remove-item-btn"
                                            href=""
                                            onClick={() => {
                                                const contactData = cellProps.row.original;
                                                onClickDelete(contactData);
                                            }}
                                        >
                                            <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                                            Delete
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </li>
                        </ul>
                    );
                },
            },


        ],
        [handleContactClick, checkedAll]
    );

    document.title = "Admin Panel | Users";
    return (
        <React.Fragment>
            <div className="page-content">

                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={handleDeleteContact}
                    onCloseClick={() => setDeleteModal(false)}
                />
                <DeleteModal
                    show={deleteModalMulti}
                    onDeleteClick={() => {
                        deleteMultiple();
                        setDeleteModalMulti(false);
                    }}
                    onCloseClick={() => setDeleteModalMulti(false)}
                />
                <Container fluid>
                    <BreadCrumb title="Users" pageTitle="Dashboards" />
                    <Row>
                        <Col xxl={12}>
                            <Card id="TeamMembersList">
                                <CardBody>
                                    <div>
                                        {AdminUser?.length ? (
                                            <TableContainer
                                                columns={columns}
                                                data={(AdminUser || [])}
                                                isGlobalFilter={true}
                                                isAddUserList={false}
                                                customPageSize={8}
                                                className="custom-header-css"
                                                divClass="table-responsive table-card mb-3"
                                                tableClass="align-middle table-nowrap"
                                                theadClass="table-light"
                                                isContactsFilter={false}
                                                SearchPlaceholder='Search for Users...'
                                            />
                                        ) : <div style={{ color: "rgb(135, 138, 153)" }} className="text-center fs-18 fw-bold">User Not Available</div>
                                        }
                                    </div>
                                    <ToastContainer closeButton={false} limit={1} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment >
    );
};

export default Users;