import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';

//import images
import { useMemo } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getSubmittedSitesAllWebsite } from '../../../../slices/thunks';
import { api } from '../../../../config';

const OverviewTab = () => {

    const location = useLocation();
    const dispatch = useDispatch();

    const handleValidDate = date => {
        const date1 = moment(new Date(date)).format("DD MMM Y");
        return date1;
    };

    const { state } = location
    const SubmitSites = useSelector((state) => state.SubmitSites?.submittedsitesdetails);
    useEffect(() => {
        dispatch(getSubmittedSitesAllWebsite({ Allid: state._id }));
    }, [dispatch, state]);

    return (
        <React.Fragment>
            <Row>
                <Col lg={12}>
                    <div className="d-flex align-items-center mb-4">
                        <div className="flex-grow-1">
                            <h5 className="mb-0">User Detail</h5>
                        </div>
                    </div>
                </Col>
                <Col xl={12} lg={8}>
                    <Card>
                        <CardBody>
                            <div className="text-muted">
                                <div className="col-md mb-4">
                                    <Row className="align-items-center g-3">
                                        <div className="col-md">
                                            <div>
                                                <h4 className="fw-bold">{location?.state.User_Name}</h4>
                                                <div className="hstack gap-3 flex-wrap">
                                                    <div>Email : <span className="fw-medium">{location?.state.Email}</span></div>
                                                </div>
                                            </div>
                                        </div>
                                    </Row>
                                </div>

                                <div className="pt-3 border-top border-top-dashed mt-4">
                                    <Row>
                                        <Col lg={3} sm={6}>
                                            <div>
                                                <p className="mb-2 text-uppercase fw-medium fs-14">Date and Time :</p>
                                                <h5 className="fs-15 mb-0">{handleValidDate(location?.state?.createdAt)}</h5>
                                            </div>
                                        </Col>
                                        <Col lg={3} sm={6}>
                                            <div>
                                                <p className="mb-2 text-uppercase fw-medium fs-14">Last Login :</p>
                                                <h5 className="fs-15 mb-0">{handleValidDate(location?.state.updatedAt)}</h5>
                                            </div>
                                        </Col>
                                        <Col lg={3} sm={6}>
                                            <div>
                                                <p className="mb-2 text-uppercase fw-medium fs-14">Status :</p>
                                                {
                                                    location?.state.Status === "Active" ?
                                                        <div className="badge bg-success fs-12">Active</div>
                                                        :
                                                        location?.state.Status === "" ?
                                                            <div className="badge bg-danger fs-12">Suspended</div>
                                                            :
                                                            <div className="badge bg-warning fs-12">Pending</div>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </CardBody>

                    </Card>
                    <Row className="gy-2 mb-2" id="candidate-list">
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <div className="d-flex align-items-center">
                                        <h5 className="card-title flex-grow-1 mb-0">List of Websites</h5>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <div className="table-responsive table-card">
                                        <table className="table table-nowrap align-middle table-borderless mb-0">
                                            <thead className="table-light text-muted">
                                                <tr>
                                                    <th scope="col">Website Details</th>
                                                    <th scope="col">Short Description</th>
                                                    <th scope="col">Category</th>
                                                    <th scope="col">Submitted Date</th>
                                                    <th scope="col">Top 10</th>
                                                    <th scope="col">Approval Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {SubmitSites?.map((item, key) => (
                                                    <tr key={key}>
                                                        <td>
                                                            <div className="d-flex">
                                                                <div className="flex-shrink-0 avatar-md bg-light rounded p-1">
                                                                    <img
                                                                        src={api.API_URL + '/' + item?.Logo}
                                                                        alt=""
                                                                        className="img-fluid d-block"
                                                                    />
                                                                </div>
                                                                <div className="flex-grow-1 ms-3">
                                                                    <h5 className="fs-16">
                                                                        {item.Site_Name}
                                                                    </h5>
                                                                    {
                                                                        item?.Site_Link ?
                                                                            <a target='_blank' className="link-secondary" href={item.Site_Link}>
                                                                                {`${item.Site_Link.slice(0, 20)}...${item.Site_Link.slice(-5)}`}
                                                                            </a>
                                                                            :
                                                                            <div className="text-center">-</div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <p className="text-muted mb-0">
                                                                {item?.Short_Description.substring(0, 40) + '...'}
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <span className="badge badge-soft-primary fs-12 me-1" key={key}>
                                                                {item?.Category}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span className="badge badge-soft-success fs-12">{handleValidDate(item.Date_Submitted)}</span>
                                                        </td>
                                                        <td>
                                                            {
                                                                item?.TOP_10 === "true"
                                                                    ?
                                                                    <span className="badge text-uppercase badge-soft-success"><i class="ri-checkbox-circle-line align-bottom"></i> Yes </span>
                                                                    :
                                                                    <span className="badge text-uppercase badge-soft-danger"><i class="ri-close-line align-bottom"></i> No </span>
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                item?.Approval_Status === "Approved" ?
                                                                    <span className="badge text-uppercase badge-soft-success"><i class="ri-checkbox-circle-line align-bottom"></i> Approved </span> :
                                                                    item?.Approval_Status === "Rejected" ?
                                                                        <span className="badge text-uppercase badge-soft-danger"><i class="ri-close-circle-line align-bottom"></i> Rejected </span> :
                                                                        item.Approval_Status === "Review" ?
                                                                            <span className="badge text-uppercase badge-soft-info"><i class="ri-eye-line align-bottom"></i> Review </span> :
                                                                            <span className="badge text-uppercase badge-soft-warning"><i class="ri-time-line align-bottom"></i> Pending </span>
                                                            }
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default OverviewTab;