import React from 'react';
import { Container } from 'reactstrap';
import Section from './Section';


const PaymentHistoryOverview = () => {
    document.title="Admin Panel | Payment History Details Overview";

    return (
        <React.Fragment>
            <div className="page-content">                
                <Container fluid>                    
                <Section />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default PaymentHistoryOverview;