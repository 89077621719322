import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import {
    getUserTicket as getUserTicketApi,
    updateTask as updateTicketApi,
    deleteTask as deleteTicketApi,
    addUserTicket as addUserTicketApi,
    getTicketComment as getTicketCommentApi,
    addTicketComment as addTicketCommentApi
} from "../../../helpers/backend_helper";
import { addNewTicketSuccess, addTicketCommentsSuccess, deleteTicketSuccess, getTicketCommentsSuccess, getTicketsListSuccess, updateTicketSuccess } from "./reducer";




export const getUserTicketsList = (id) => async (dispatch) => {
    try {
        const response = await getUserTicketApi(id);
        if (response.success === true) {
            dispatch(getTicketsListSuccess(response.tickets))
        }
    } catch (error) {

        toast.error(error, { autoClose: 3000 });
    }
}

export const addNewUserTicket = (ticket) => async (dispatch) => {
    try {
        const response = await addUserTicketApi(ticket);
        if (response.success === true) {
            dispatch(addNewTicketSuccess(response?.ticket || {}))
            toast.success("Ticket Create Successfully", { autoClose: 3000 });
        }
        toast.success(response.message, { autoClose: 3000 });
    } catch (error) {
        toast.error(error, { autoClose: 3000 });
    }
}


export const getTicketComments = (id) => async (dispatch) => {
    try {
        const response = await getTicketCommentApi(id);
        if (response.success === true) {
            dispatch(getTicketCommentsSuccess(response?.data || []))
        }
    } catch (error) {
        toast.error(error, { autoClose: 3000 });

    }
}
export const addTicketComments = (id, data) => async (dispatch) => {
    try {
        const response = await addTicketCommentApi(id, data);
        if (response.success === true) {
            dispatch(addTicketCommentsSuccess(response?.data || {}))
        }
    } catch (error) {
        toast.error(error, { autoClose: 3000 });

    }
}

