import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    allBlogs: [],
    userAllBlogs: [],
    blogPlan: {},
    blogByTitle: {},
    isBlogLoading: false
};




const BlogsSlice = createSlice({
    name: "BlogsSlice",
    initialState,
    reducers: {
        getAllBlogsSuccess(state, action) {
            state.allBlogs = action.payload || {}
        },
        getBlogsByTitleSuccess(state, action) {
            state.blogByTitle = action.payload || {}
        },
        clearBlogsByTitleSuccess(state, action) {
            state.blogByTitle = {}
        },

        getUserAllBlogsSuccess(state, action) {
            state.userAllBlogs = action.payload || {}
        },
        addBlogLoaderStart(state, action) {
            state.isBlogLoading = true
        },
        addBlogLoaderStop(state, action) {
            state.isBlogLoading = false
        },

        addNewBlogSuccess(state, action) {
            let data = JSON.parse(sessionStorage.getItem('authUser'))?.data;
            if (action.payload?.user_ID === data?._id) {
                state.userAllBlogs = [...state.userAllBlogs, action?.payload];
            }
            state.allBlogs = [...state.allBlogs, action?.payload];
            state.isBlogLoading = false
        },

        updateBlogsSuccess(state, action) {
            state.allBlogs = state.allBlogs.map(item =>
                item._id.toString() === action.payload._id.toString()
                    ? { ...item, ...action.payload }
                    : item
            );
            state.userAllBlogs = state.userAllBlogs.map(item =>
                item._id.toString() === action.payload._id.toString()
                    ? { ...item, ...action.payload }
                    : item
            );
        },
        deleteBlogsSuccess(state, action) {
            state.allBlogs = state.allBlogs.filter(
                item => item._id.toString() !== action.payload.toString()
            );

            state.userAllBlogs = state.userAllBlogs.filter(
                item => item._id.toString() !== action.payload.toString()
            );
        },

        getBlogsPlanSuccess(state, action) {
            state.blogPlan = action.payload || {}
        },
    },

});


export const {
    getAllBlogsSuccess,
    getBlogsByTitleSuccess,
    getUserAllBlogsSuccess,
    addNewBlogSuccess,
    deleteBlogsSuccess,
    updateBlogsSuccess,
    getBlogsPlanSuccess,
    clearBlogsByTitleSuccess,
    addBlogLoaderStart,
    addBlogLoaderStop
} = BlogsSlice.actions

export default BlogsSlice.reducer;