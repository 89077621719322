import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert, } from 'reactstrap';

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";
import withRouter from "../../../Components/Common/withRouter";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions
import { loginUser, resetLoginFlag, getCMSdata } from "../../../slices/thunks";
import { api } from '../../../config';
import logoImg from "../../../assets/images/logo-dark.png";


const Login = (props) => {
    const dispatch = useDispatch();

    const { cmsdetails } = useSelector((state) => state.CMS);


    useEffect(() => {
        dispatch(getCMSdata());
    }, [dispatch]);


    const { user, errorMsg } = useSelector(state => ({
        // user: state.Account.user,
        errorMsg: state.Login.errorMsg,
    }));

    // console.log("user", user);
    const [userLogin, setUserLogin] = useState([]);
    const [passwordShow, setPasswordShow] = useState(false);

    useEffect(() => {
        if (user && user.user) {
            const updatedUserData = user.data.Email;
            const updatedUserPassword = user.data.confirm_password;
            setUserLogin({
                Email: updatedUserData,
                Password: updatedUserPassword
            });
        }
    }, [user]);

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            Email: userLogin.Email || '',
            Password: userLogin.Password || '',
        },
        validationSchema: Yup.object({
            Email: Yup.string().required("Please Enter Your Email"),
            Password: Yup.string().required("Please Enter Your Password"),
        }),
        onSubmit: (values) => {
            dispatch(loginUser(values, props.router.navigate));
        }
    });



    useEffect(() => {
        if (errorMsg) {
            setTimeout(() => {
                dispatch(resetLoginFlag());
            }, 3000);
        }
    }, [dispatch, errorMsg]);

    document.title = `${cmsdetails?.websiteTitle ? cmsdetails?.websiteTitle : '1000 Ai Sites'} | Admin Login`;
    return (
        <React.Fragment>
            <div className="auth-page-content">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="text-center mt-sm-5 mb-4 text-white-50">
                                <div>
                                    <Link to="/" className="d-inline-block auth-logo">
                                        <img src={cmsdetails?.websiteLogo && api.API_URL + '/' + cmsdetails?.websiteLogo || logoImg} alt="" loading="lazy" style={{ height: "50px", width: "200px" }} />
                                    </Link>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="mt-4">
                                <CardBody className="p-4">
                                    <div className="text-center mt-2">
                                        <h5 className="text-primary">Welcome Back !</h5>
                                        <p className="text-muted">Sign in to continue to 1000aisites.com</p>
                                    </div>
                                    {errorMsg && errorMsg ? (<Alert color="danger"> {errorMsg} </Alert>) : null}
                                    <div className="p-2 mt-4">
                                        <Form
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                validation.handleSubmit();
                                                return false;
                                            }}
                                            action="#">

                                            <div className="mb-3">
                                                <Label htmlFor="Email" className="form-label">Email</Label>
                                                <Input
                                                    name="Email"
                                                    className="form-control"
                                                    placeholder="Enter Email"
                                                    type="Email"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.Email || ""}
                                                    invalid={
                                                        validation.touched.Email && validation.errors.Email ? true : false
                                                    }
                                                />
                                                {validation.touched.Email && validation.errors.Email ? (
                                                    <FormFeedback type="invalid">{validation.errors.Email}</FormFeedback>
                                                ) : null}
                                            </div>

                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="password-input">Password</Label>
                                                <div className="position-relative auth-pass-inputgroup mb-3">
                                                    <Input
                                                        name="Password"
                                                        value={validation.values.Password || ""}
                                                        type={passwordShow ? "text" : "password"}
                                                        className="form-control pe-5"
                                                        placeholder="Enter Password"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        invalid={
                                                            validation.touched.Password && validation.errors.Password ? true : false
                                                        }
                                                    />
                                                    {validation.touched.Password && validation.errors.Password ? (
                                                        <FormFeedback type="invalid">{validation.errors.Password}</FormFeedback>
                                                    ) : null}
                                                    <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon" onClick={() => setPasswordShow(!passwordShow)}><i className="ri-eye-fill align-middle"></i></button>
                                                </div>
                                            </div>
                                            <div className="mt-4">
                                                <Button color="success" className="btn btn-success w-100" type="submit">
                                                    Sign In
                                                </Button>
                                            </div>
                                        </Form>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withRouter(Login);