import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import { ActiveAdminUsers, SuspendAdminUsers, deleteAdminUsers, getAdminUsers, getSingleUserAdminUsers, updateAdminUsers } from "../../../helpers/backend_helper";

export const getAdminUserdata = createAsyncThunk("adminuser/getAdminUserdata", async () => {
  try {
    const response = await getAdminUsers()
    return response;
  } catch (error) {
    return error;
  }
})

export const getSingleUserAdminUserdata = createAsyncThunk("adminuser/getSingleUserAdminUserdata", async (userId) => {
  try {
    const response = await getSingleUserAdminUsers(userId)
    return response;
  } catch (error) {
    return error;
  }
})

export const AdminUserActive = createAsyncThunk("adminuser/AdminUserActive", async ({ planId, newStatus }) => {
  try {
    const response = await ActiveAdminUsers(planId, newStatus);
    return response;
  } catch (error) {
    return error;
  }
});

export const AdminUserSuspend = createAsyncThunk("adminuser/AdminUserSuspend", async ({ planId, newStatus }) => {
  try {
    const response = await SuspendAdminUsers(planId, newStatus);
    return response;
  } catch (error) {
    return error;
  }
});

export const updatedataAdminUser = createAsyncThunk("adminuser/updatedataAdminUser", async (AdminUser) => {
  const Adminuser = AdminUser.get('id')
  try {
    const response = await updateAdminUsers(Adminuser, AdminUser)
    toast.success("Admin User Updated Successfully", { autoClose: 3000 });
    return response;
  } catch (error) {
    toast.error("Admin User Updated Failed", { autoClose: 3000 });
    return error;
  }
})

export const deletedataAdminUser = createAsyncThunk("adminuser/deletedataAdminUser", async (AdminUser) => {
  try {
    const response = deleteAdminUsers(AdminUser)
    console.log('response', response);
    toast.success("Admin User Deleted Successfully", { autoClose: 3000 });
    return { AdminUser, ...response };
  } catch (error) {
    toast.error("Admin User Deleted Failed", { autoClose: 3000 });
    return error;
  }
})