import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, FormFeedback, Input, Label, Row, UncontrolledDropdown } from 'reactstrap';

//SimpleBar
import SimpleBar from "simplebar-react";

//import images
import { Link, useLocation } from 'react-router-dom';
import TicketCodeExample from './TicketsDetailsCode';
import DeleteModal from '../../../../Components/Common/DeleteModal';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from "yup";
import { useFormik } from 'formik';
import { getAdminSingleTicketsCommentsList, addNewAdminTicketComments, updateAdminTicketComments, deleteAdminTicketComments, getAdminSingleTicketsList } from '../../../../slices/thunks';
import { isEmpty } from 'lodash';
import moment from 'moment';
import avatar1 from "../../../../assets/images/users/avatar-8.jpg";
import avatar2 from "../../../../assets/images/users/avatar-1.jpg";

// import { useQuill } from 'react-quilljs';

const TicketDescription = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const { state } = location;

    const AdminTickets = useSelector((state) => state.AdminTickets.adminticketsList);

    useEffect(() => {
        dispatch(getAdminSingleTicketsList(state?._id));
    }, [dispatch]);

    const AdminTicketsComment = useSelector((state) => state.AdminTicketsComment.adminticketscommentsList);
    const obj = JSON.parse(sessionStorage.getItem("authAdmin"));

    useEffect(() => {
        dispatch(getAdminSingleTicketsCommentsList(state?._id));
    }, [dispatch,]);

    const [isEdit, setIsEdit] = useState(false);
    const [SupportTicketis, setSupportTicketis] = useState(null);

    //delete Conatct
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteModalMulti, setDeleteModalMulti] = useState(false);
    const [modal, setModal] = useState(false);


    useEffect(() => {
        setSupportTicketis(AdminTicketsComment)
    }, [AdminTicketsComment])

    useEffect(() => {
        if (!isEmpty(AdminTicketsComment)) {
            setSupportTicketis(AdminTicketsComment);
            setIsEdit(false);
        }
    }, [AdminTicketsComment]);

    const toggle = useCallback(() => {
        if (modal) {
            setModal(false);
            setIsEdit(false);
            setSupportTicketis(null)
        } else {
            setModal(true);
        }
    }, [modal]);


    console.log('state', state);
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            _id: (SupportTicketis && SupportTicketis._id) || null,
            text: (SupportTicketis && SupportTicketis.text) || '',
        },
        validationSchema: Yup.object({
            text: Yup.string().required("Please Enter Comments"),
        }),
        onSubmit: (values) => {

            if (isEdit) {

                const formData = new FormData();
                formData.append('id', values._id);
                formData.append('text', values.text);

                dispatch(updateAdminTicketComments(formData))
            } else {
                const formData = new FormData();
                formData.append('id', state._id);
                formData.append('text', values.text);
                formData.append('admin', obj?.data?._id,);

                dispatch(addNewAdminTicketComments(formData));
            }
            validation.resetForm();
            setModal(false);
        },
    });

    // Update Data
    const handleContactClick = useCallback((arg) => {
        const contact = arg;
        setSupportTicketis({
            _id: contact._id,
            text: contact.text,
        });
        toggle();
        setIsEdit(true);
    }, [toggle]);

    // Delete Data
    const handleDeleteContact = () => {
        if (SupportTicketis) {
            dispatch(deleteAdminTicketComments(SupportTicketis._id));
            setDeleteModal(false);
        }
    };

    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteContact}
                onCloseClick={() => setDeleteModal(false)}
            />
            <DeleteModal
                show={deleteModalMulti}
                onDeleteClick={() => {
                    deleteMultiple();
                    setDeleteModalMulti(false);
                }}
                onCloseClick={() => setDeleteModalMulti(false)}
            />
            <Col xxl={9}>
                <Card>
                    <CardBody className="p-4">
                        <h6 className="fw-bold text-uppercase mb-3">Ticket Discripation</h6>
                        <p className="text-muted">{state?.Description}</p>
                    </CardBody>
                    <CardBody className="p-4">
                        <h5 className="card-title mb-4">Comments</h5>
                        {
                            AdminTicketsComment?.length ?
                                <SimpleBar style={{ height: "300px" }}>
                                    {
                                        AdminTicketsComment?.length !== 0 && AdminTicketsComment?.map((item, index) => {
                                            return (
                                                <div key={index} className="px-3 mx-n3">
                                                    {item?.user &&
                                                        <div className="d-flex m-2">
                                                            <div className="flex-shrink-0">
                                                                <img src={avatar1} alt="" className="avatar-xs rounded-circle" />
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <h5 className="fs-15">{AdminTickets?.User_ID?.Email?.split('@')[0]}{". "}<small className="text-muted">{moment(new Date(item?.createdAt)).format("DD MMM Y hh:mm")} </small></h5>
                                                                <p className="text-muted ">{item.text}</p>
                                                            </div>
                                                        </div>}

                                                    {item?.admin &&
                                                        <div className="d-flex ms-4 m-3 flex-grow-1">
                                                            <div className="flex-shrink-0">
                                                                <img src={avatar2} alt="" className="avatar-xs rounded-circle" />
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <h5 className="fs-15">{obj?.data?.Email?.split('@')[0]}{". "} <small className="text-muted">{moment(new Date(item?.createdAt)).format("DD MMM Y hh:mm")}</small></h5>
                                                                <p className="text-muted">{item.text}</p>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </SimpleBar>
                                : <div style={{ color: "rgb(135, 138, 153)" }} className="text-center fs-18 fw-bold">Comments Not Available</div>
                        }
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                            }}
                            className="mt-3"
                        >
                            <Row className="g-3">
                                <Col lg={12}>
                                    <Label htmlFor="text" className="form-label">Leave a Comments</Label>
                                    <Input
                                        className="form-control bg-light border-light"
                                        type='textarea'
                                        id="text"
                                        rows="3"
                                        name='text'
                                        placeholder="Enter comments"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.text || ""}
                                        invalid={
                                            validation.touched.text && validation.errors.text ? true : false
                                        }
                                    />

                                    {validation.touched.text && validation.errors.text ? (
                                        <FormFeedback type="invalid"><div>{validation.errors.text}</div></FormFeedback>
                                    ) : null}
                                </Col>
                                <Col lg={12} className="text-end">
                                    <button type='submit' className="btn btn-success">Post Comments</button>
                                </Col>
                            </Row>
                        </form>
                    </CardBody>

                </Card>
            </Col>
        </React.Fragment >
    );
};

export default TicketDescription;