import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import { getBlogPlan, updateBlogPlan } from "../../../helpers/backend_helper";

export const getBlogPlandata = createAsyncThunk("blogplan/getBlogPlandata", async () => {
  try {
    const response = await getBlogPlan();
    console.log('response ', response);
    return response;
  } catch (error) {
    return error;
  }
})

export const updatedataBlogPlan = createAsyncThunk("blogplan/updatedataBlogPlan", async (BlogPlan) => {
  const blogplan = BlogPlan.get('id')
  try {
    const response = await updateBlogPlan(blogplan, BlogPlan)
    console.log('response', response);
    toast.success("Blog Plan Updated Successfully", { autoClose: 3000 });
    return response;
  } catch (error) {
    toast.error("Blog Plan Updated Failed", { autoClose: 3000 });
    return error;
  }
})