import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import { AllWebsiteSubmittedUsers, deleteSubmittedUsers, getAllSubmittedSite, getApproveSubmitted, getBlogStatusActiveandDeactive, getRejectSubmitted, getSubmittedSiteTransaction, getSubmittedUsers, updateSubmittedUsers } from "../../../helpers/backend_helper";

export const getSubmittedSitesdata = createAsyncThunk("submittedsites/getSubmittedSitesdata", async () => {
  try {
    const response = await getSubmittedUsers();
    return response;
  } catch (error) {
    return error;
  }
})

export const getAllSubmittedSitesdata = createAsyncThunk("submittedsites/getAllSubmittedSitesdata", async () => {
  try {
    const response = await getAllSubmittedSite();
    return response;
  } catch (error) {
    return error;
  }
})

export const getSubmittedSitesAllWebsite = createAsyncThunk("submittedsites/getSubmittedSitesAllWebsite", async ({ Allid }) => {
  try {
    const response = await AllWebsiteSubmittedUsers(Allid);
    return response;
  } catch (error) {
    return error;
  }
});

export const updatedataSubmittedSites = createAsyncThunk("submittedsites/updatedataSubmittedSites", async (SubmittedSites) => {
  try {
    const response = await updateSubmittedUsers(SubmittedSites)
    console.log('updatedataSubmittedSites response', response);
    toast.success("Submitted Sites Updated Successfully", { autoClose: 3000 });
    return response;
  } catch (error) {
    toast.error("Submitted Sites Updated Failed", { autoClose: 3000 });
    return error;
  }
})

export const deletedataSubmittedSites = createAsyncThunk("submittedsites/deletedataSubmittedSites", async (SubmittedSites) => {
  try {
    const response = await deleteSubmittedUsers(SubmittedSites)
    toast.success("Submitted Sites Deleted Successfully", { autoClose: 3000 });
    return { SubmittedSites, ...response };
  } catch (error) {
    toast.error("Submitted Sites Deleted Failed", { autoClose: 3000 });
    return error;
  }
})

export const getSubmittedSitesTransaction = createAsyncThunk("submittedsites/getSubmittedSitesTransaction", async (id) => {
  try {
    const response = await getSubmittedSiteTransaction(id)

    return response;
  } catch (error) {
    toast.error("Submitted Sites Failed", { autoClose: 3000 });
    return error;
  }
})

export const getSiteStatusActiveandDeactiveData = createAsyncThunk("blog/getSiteStatusActiveandDeactiveData", async ({ id, newStatus }) => {
  console.log('id, status ', id, newStatus === 'Approved');


  try {
    const response = newStatus === 'Approved' ? await getApproveSubmitted(id) : await getRejectSubmitted(id);
    console.log('response', response);

    return response;
  } catch (error) {
    return error;
  }
});