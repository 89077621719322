import React, { useEffect } from 'react';
import { useState } from 'react';

//import Components
import { Card, CardBody, Col, Container, Form, FormFeedback, Input, Label, Row } from 'reactstrap';

// image
import ProfileImage from "../../../assets/images/logo-dark.svg";
import ProfileImage1 from "../../../assets/images/logo_favicon.svg";
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { addNewCMS, getCMSdata, updatedataCMS } from '../../../slices/thunks';
import { api } from '../../../config';
import MyEditor from '../../../Components/Common/MyEditor';

const CMS = () => {
    document.title = "Admin Panel | CMS";
    const dispatch = useDispatch();

    const [fieldvalue, setFieldValue] = useState(null);
    const [faviconvalue, setFaviconValue] = useState(null);

    const CMS = useSelector(state => state.CMS.cmsdetails);



    const [perimage, setPerImage] = useState("");
    const [perimage2, setPerImage2] = useState("");

    useEffect(() => {
        dispatch(getCMSdata());
    }, [dispatch]);

    useEffect(() => {
        if (CMS) {
            setPerImage(CMS.websiteLogo);
            setPerImage2(CMS.favicon);
        }
    }, [CMS]);

    const URL = /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i


    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed

        enableReinitialize: true,

        initialValues: {
            url: (CMS && CMS.url) || '',
            headerCode: (CMS && CMS.headerCode) || '',
            platformName: (CMS && CMS.platformName) || '',
            websiteTitle: (CMS && CMS.websiteTitle) || '',
            websiteKeywords: (CMS && CMS.websiteKeywords) || '',
            websiteDescription: (CMS && CMS.websiteDescription) || '',
            websiteLogo: (CMS && CMS.websiteLogo) || '',
            favicon: (CMS && CMS.favicon) || '',
            registerTitle: (CMS && CMS.register_title) || '',
            registerDescription: (CMS && CMS.register_Description) || '',
        },
        validationSchema: Yup.object({
            url: Yup.string().matches(URL, "Enter a valid url").required("Please Enter Valid Website URL"),
            headerCode: Yup.string().required("Please Enter Header Code"),
            platformName: Yup.string().required("Please Enter Name"),
            websiteTitle: Yup.string().required("Please Enter Title"),
            // websiteKeywords: Yup.string().required("Please Enter Keywords"),
            websiteDescription: Yup.string().required("Please Enter Description"),
        }),
        onSubmit: (values) => {
            const formData = new FormData();

            // Add key-value pairs to the FormData object
            formData.append('id', CMS._id);
            formData.append('url', values.url);
            formData.append('headerCode', values.headerCode);
            formData.append('platformName', values.platformName);
            formData.append('websiteTitle', values.websiteTitle);
            formData.append('websiteKeywords', values.websiteKeywords);
            formData.append('websiteDescription', values.websiteDescription);
            formData.append('register_title', values.registerTitle);
            formData.append('register_Description', values.registerDescription);
            formData.append('websiteLogo', !fieldvalue ? perimage : fieldvalue.image_file);
            formData.append('favicon', !faviconvalue ? perimage2 : faviconvalue.image_file);
            dispatch(updatedataCMS(formData));
        }
    });

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="CMS" pageTitle="Dashboards" />

                    <Row className='justify-content-center'>
                        <Col xxl={6}>
                            <Card >
                                <CardBody>
                                    <div className="live-preview">
                                        <Form
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                validation.handleSubmit();
                                                return false;
                                            }}
                                        >
                                            <Row>
                                                <Col className="text-center" lg={6}>
                                                    <Label htmlFor="Website Logo" className="form-label">Website Logo</Label>
                                                    <div className="text-center">
                                                        <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                                                            {
                                                                fieldvalue?.imageData ?
                                                                    <div className="avatar-xl p-1">
                                                                        <div className="avatar-title bg-light">
                                                                            <img src={fieldvalue.imageData} alt="user-profile" style={{ padding: "0" }} className="avatar-xl img-thumbnail user-profile-image" />
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    perimage ?
                                                                        <div className="avatar-xl p-1">
                                                                            <div className="avatar-title bg-light">
                                                                                <img src={api.API_URL + '/' + perimage} alt="user-profile" style={{ padding: "0" }} className="avatar-xl img-thumbnail user-profile-image" />
                                                                            </div>
                                                                        </div> :
                                                                        <div className="avatar-xl p-1">
                                                                            <div className="avatar-title bg-light">
                                                                                <img src={ProfileImage} alt="img" id="customer-user-profile" style={{ padding: "0" }} className="avatar-xl img-thumbnail user-profile-image" />
                                                                            </div>
                                                                        </div>

                                                                // ? <img src={perimage}
                                                                //     className="avatar-xl img-thumbnail user-profile-image"
                                                                //     alt="user-profile" /> : <img src={fieldvalue.imageData}
                                                                //         className="avatar-xl img-thumbnail user-profile-image"
                                                                //         alt="user-profile" />
                                                            }
                                                            <div className="avatar-xs p-0 profile-photo-edit">
                                                                <Input id="profile-img-file-input" name='websiteLogo' type="file"
                                                                    onChange={(event) => {
                                                                        const file = event.target.files[0];
                                                                        const reader = new FileReader();
                                                                        reader.onload = (event) => {
                                                                            const imageData = event.target.result;
                                                                            setFieldValue({ image_file: file, imageData: imageData });
                                                                        };
                                                                        reader.readAsDataURL(file);
                                                                    }}
                                                                    className="profile-img-file-input" />
                                                                <Label htmlFor="profile-img-file-input"
                                                                    className="profile-photo-edit avatar-xs">
                                                                    <span className="avatar-title bg-light border text-muted">
                                                                        <i className="ri-image-fill"></i>
                                                                    </span>
                                                                </Label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col className="text-center" lg={6}>
                                                    <Label htmlFor="Favicon" className="form-label">Favicon</Label>
                                                    <div className="text-center">
                                                        <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                                                            {
                                                                faviconvalue?.faviconData ?
                                                                    <div className="avatar-xl p-1">
                                                                        <div className="avatar-title bg-light">
                                                                            <img src={faviconvalue.faviconData} alt="img" id="favicon-img" style={{ padding: "0" }} className="avatar-xl img-thumbnail user-profile-image" />
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    perimage2 ?
                                                                        <div className="avatar-xl p-1">
                                                                            <div className="avatar-title bg-light">
                                                                                <img src={api.API_URL + '/' + perimage2} alt="img" id="customer-img" style={{ padding: "0" }} className="avatar-xl img-thumbnail user-profile-image" />
                                                                            </div>
                                                                        </div> :
                                                                        <div className="avatar-xl p-1">
                                                                            <div className="avatar-title bg-light">
                                                                                <img src={ProfileImage1} alt="img" id="customer-img" style={{ padding: "0" }} className="avatar-xl img-thumbnail user-profile-image" />
                                                                            </div>
                                                                        </div>

                                                                // ? <img src={perimage}
                                                                //     className="avatar-xl img-thumbnail user-profile-image"
                                                                //     alt="user-profile" /> : <img src={faviconvalue.faviconData}
                                                                //         className="avatar-xl img-thumbnail user-profile-image"
                                                                //         alt="user-profile" />
                                                            }
                                                            <div className="avatar-xs p-0 profile-photo-edit">
                                                                <Input id="profile-favicon-file-input" name='favicon' type="file"
                                                                    onChange={(event) => {
                                                                        const file = event.target.files[0];
                                                                        const reader = new FileReader();
                                                                        reader.onload = (event) => {
                                                                            const faviconData = event.target.result;
                                                                            setFaviconValue({ image_file: file, faviconData: faviconData });
                                                                        };
                                                                        reader.readAsDataURL(file);
                                                                    }}
                                                                    className="profile-img-file-input" />
                                                                <Label htmlFor="profile-favicon-file-input"
                                                                    className="profile-photo-edit avatar-xs">
                                                                    <span className="avatar-title bg-light border text-muted">
                                                                        <i className="ri-image-fill"></i>
                                                                    </span>
                                                                </Label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="url-field" className="form-label">Website URL</Label>
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter Website URL"
                                                            id="url-field"
                                                            name='url'
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values?.url || ""}
                                                            invalid={
                                                                validation.touched.url && validation.errors.url ? true : false
                                                            }
                                                        />
                                                        {validation.touched.url && validation.errors.url ? (
                                                            <FormFeedback type="invalid"><div>{validation.errors.url}</div></FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="phonenumberInput" className="form-label">Header Code</Label>
                                                        <Input
                                                            name='headerCode'
                                                            className="form-control"
                                                            id="headerCodeInput"
                                                            rows="3"
                                                            type='textarea'
                                                            placeholder="Enter Header Code"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.headerCode || ""}
                                                            invalid={validation.touched.headerCode && validation.errors.headerCode ? true : false}
                                                        />
                                                        {validation.touched.headerCode && validation.errors.headerCode ? (
                                                            <FormFeedback type="invalid"><div>{validation.errors.headerCode}</div></FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="emailidInput" className="form-label">Platform Name</Label>
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter Platform Name"
                                                            id="emailidInput"
                                                            name='platformName'
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.platformName || ""}
                                                            invalid={
                                                                validation.touched.platformName && validation.errors.platformName ? true : false
                                                            }
                                                        />
                                                        {validation.touched.platformName && validation.errors.platformName ? (
                                                            <FormFeedback type="invalid"><div>{validation.errors.platformName}</div></FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="address1ControlTextarea" className="form-label">Website Title</Label>
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter Website Title"
                                                            id="address1ControlTextarea"
                                                            name='websiteTitle'
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.websiteTitle || ""}
                                                            invalid={
                                                                validation.touched.websiteTitle && validation.errors.websiteTitle ? true : false
                                                            }
                                                        />
                                                        {validation.touched.websiteTitle && validation.errors.websiteTitle ? (
                                                            <FormFeedback type="invalid"><div>{validation.errors.websiteTitle}</div></FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="citynameInput" className="form-label">Website Keywords</Label>
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter Website Keywords"
                                                            id="citynameInput"
                                                            name='websiteKeywords'
                                                            required
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.websiteKeywords || ""}
                                                            invalid={
                                                                validation.touched.websiteKeywords && validation.errors.websiteKeywords ? true : false
                                                            }
                                                        />
                                                        {validation.touched.websiteKeywords && validation.errors.websiteKeywords ? (
                                                            <FormFeedback type="invalid"><div>{validation.errors.websiteKeywords}</div></FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="citynameInput" className="form-label">Website Description</Label>
                                                        <textarea name='websiteDescription' className="form-control"
                                                            id="exampleFormControlTextarea"
                                                            rows="3"
                                                            placeholder='Enter Website Description'
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.websiteDescription || ""}
                                                            invalid={
                                                                validation.touched.websiteDescription && validation.errors.websiteDescription ? true : false
                                                            }
                                                        ></textarea>
                                                        {validation.touched.websiteDescription && validation.errors.websiteDescription ? (
                                                            <FormFeedback type="invalid"><div>{validation.errors.websiteDescription}</div></FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="register-title-field" className="form-label">Register Contest Title</Label>
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter Register Contest Title"
                                                            id="register-title-field"
                                                            name='registerTitle'
                                                            required
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.registerTitle || ""}
                                                            invalid={
                                                                validation.touched.registerTitle && validation.errors.registerTitle ? true : false
                                                            }
                                                        />
                                                        {validation.touched.registerTitle && validation.errors.registerTitle ? (
                                                            <FormFeedback type="invalid"><div>{validation.errors.registerTitle}</div></FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col md={12}>
                                                    <MyEditor
                                                        label={'Register Contest Title'}
                                                        description={validation.values.registerDescription}
                                                        onchangeDescrition={(val) => validation.setFieldValue('registerDescription', val)}
                                                    />
                                                </Col>
                                                <Col md={12}>
                                                    <div className="text-end">
                                                        <button type="submit" className="btn btn-primary">Submit</button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
};

export default CMS;