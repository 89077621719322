import { createSlice } from "@reduxjs/toolkit";
import { getPlanAndPricingdata, addNewPlanAndPricing, updatedataPlanAndPricing, deletedataPlanAndPricing, ActiveAndDeactivedata } from './thunk';

export const initialState = {
  PlanAndPricingdetails: [],
  error: {}
};

const PlanAndPricingSlice = createSlice({
  name: "plan_and_pricing",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPlanAndPricingdata.fulfilled, (state, action) => {
      // console.log("state", state, "action", action);
      state.PlanAndPricingdetails = action.payload.data;
    });

    builder.addCase(getPlanAndPricingdata.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(addNewPlanAndPricing.fulfilled, (state, action) => {
      state.PlanAndPricingdetails.push(action.payload.data);
    });

    builder.addCase(addNewPlanAndPricing.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(updatedataPlanAndPricing.fulfilled, (state, action) => {
      state.PlanAndPricingdetails = state.PlanAndPricingdetails.map((PlainPricing) =>
        PlainPricing._id.toString() === action.payload.plan
          ._id.toString()
          ? {
            ...PlainPricing, ...action.payload.plan
          }
          : PlainPricing
      );
    });

    builder.addCase(updatedataPlanAndPricing.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(deletedataPlanAndPricing.fulfilled, (state, action) => {
      state.PlanAndPricingdetails = (state.PlanAndPricingdetails || []).filter((PlainPricing) => PlainPricing._id.toString() !== action.payload.PlanAndPricing.toString());
    });

    builder.addCase(deletedataPlanAndPricing.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(ActiveAndDeactivedata.fulfilled, (state, action) => {
      state.PlanAndPricingdetails = state.PlanAndPricingdetails.map((plan) =>
        plan._id.toString() === action.payload.plan._id.toString() ? { ...plan, ...action.payload.plan } : plan
      );
    });

    builder.addCase(ActiveAndDeactivedata.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });
  },
});

export default PlanAndPricingSlice.reducer;
