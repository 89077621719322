import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { useFormik } from 'formik';
import { object, string, mixed, array, date } from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from "lodash";

import { api } from '../../../config';
import Header from '../../../Layouts/FrontHeader';
import Footer from '../../../Layouts/FrontFooter';
import MyEditor from '../../../Components/Common/MyEditor';
import { addNewBlogs, updateNewBlogs } from '../../../slices/FrontView/blogs/thunk';
import { getCategorydata } from '../../../slices/thunks';
import website_background from "../../../assets/images/website_background.png";
import { addBlogLoaderStart } from '../../../slices/FrontView/blogs/reducer';



const AddBlog = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;


    const [blogData, setBlogData] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const [categoryList, setCategoryList] = useState([]);

    const userObj = JSON.parse(sessionStorage.getItem('authUser'))?.data
    const { categorydetails } = useSelector((state) => state.Category);
    const { cmsData } = useSelector((state) => state.CMSF);

    useEffect(() => {

        if (categorydetails) {
            const transformedArray = categorydetails.map(item => ({
                label: item.CategoryName,
                value: item.CategoryName,
                cat_icon: item.Category_fileIcon,
                cat_bgColor: item.Category_backgroundColor,
                Status: item.Status
            }));
            setCategoryList(transformedArray);
        }

    }, [categorydetails])


    useEffect(() => {
        if (state === null) {
            setBlogData(null)
        } else {
            setBlogData(state.blogs)
            setIsEdit(true)
        }
    }, [state])
    useEffect(() => {
        dispatch(getCategorydata());
    }, [dispatch]);

    const userSchema = object({
        title: string().required('Please Enter Blog Name'),
        shortDetail: string().required('Please Enter Short Description'),
        longDetail: string().required('Please Enter Long Description'),
        blog_image: mixed().required('Image is required'),
    }).shape({
        categories: array().min(1, 'Select at least one category'),
    })
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {

            title: (blogData && blogData.title) || '',
            blog_image: (blogData && blogData.blog_image) || '',
            shortDetail: (blogData && blogData.shortDetail) || '',
            longDetail: (blogData && blogData.longDetail) || '',
            categories: (blogData && blogData.categories) || [],

        },
        validationSchema: userSchema,
        onSubmit: (values,) => {

            if (isEdit) {

                const updateBlog = new FormData();
                updateBlog.append('id', blogData?._id);
                updateBlog.append('user_ID', userObj?._id);
                updateBlog.append('blog_image', values.blog_image);
                updateBlog.append('title', values.title);
                updateBlog.append('shortDetail', values.shortDetail);
                updateBlog.append('longDetail', values.longDetail);
                updateBlog.append('categories', values.categories);
                dispatch(updateNewBlogs(updateBlog, navigate));

            } else {
                const newBlog = new FormData();
                newBlog.append('user_ID', userObj?._id);
                newBlog.append('blog_image', values.blog_image);
                newBlog.append('title', values.title);
                newBlog.append('shortDetail', values.shortDetail);
                newBlog.append('longDetail', values.longDetail);
                newBlog.append('categories', values.categories);
                dispatch(addNewBlogs(newBlog, navigate));

            }
            dispatch(addBlogLoaderStart())
            formik.resetForm();
            navigate('/bloglist')
        },
    });

    const myElementRef = useRef(null);
    const [footerHeight, setFooterHeight] = useState(700);
    useEffect(() => {
        if (myElementRef?.current) {
            const elementHeight = myElementRef.current.getBoundingClientRect().height;
            setFooterHeight(elementHeight)
        }
    });
    document.title = `${cmsData?.websiteTitle ? cmsData?.websiteTitle : '1000 Ai Sites'} | ${isEdit ? 'Update Blog' : 'Add Blog'}`;

    const { values, touched, handleBlur, handleChange, handleSubmit, errors, setFieldValue } = formik;
    return (
        <div >
            <div ref={myElementRef}>
                <Header />

                <div className='sumitpage'>
                    <div className="px-4 pt-20 px-md-5 text-center text-lg-start" >
                        <section>

                            <div className="lg:px-4 py-5 px-md-5 text-center text-lg-start" style={{ backgroundColor: 'hsl(0, 0%, 96%)' }}>
                                <div className="container">
                                    <div className="row gx-lg-5 align-items-center justify-content-center">


                                        <div className="col-lg-8 mb-lg-0 ">
                                            <div className="card">
                                                <div className="card-body py-5 px-md-5">
                                                    <h3 className="text-center font-semibold mb-2"> Submit Your Blog Detils </h3>
                                                    {/* <p className='text-center' style={{ fontSize: '13px' }}>Submit Your Blog Today</p> */}
                                                    <form onSubmit={handleSubmit}>
                                                        <div>
                                                            <div className="row">

                                                                <div className="row">
                                                                    <div className="col-md-6 mb-4">
                                                                        <div className="form-outline">
                                                                            <p>Upload Blog Featured Image</p>
                                                                            <div className='blog-upload' >
                                                                                <img src={typeof values.blog_image === "string" ? isEmpty(values.blog_image) ? website_background : api.API_URL + '/' + values.blog_image : URL.createObjectURL(values?.blog_image)} width={"100%"} height={"100%"} style={{ borderRadius: '10px' }} />


                                                                                <div className="input--file">
                                                                                    <span>
                                                                                        <i className="bi bi-camera-fill"></i>
                                                                                    </span>
                                                                                    {/* <input name="file" type="file" value={values.file} onBlur={handleBlur} onChange={(event) => setFieldValue('file', event.currentTarget.files[0])} */}
                                                                                    <input
                                                                                        name="blog_image"
                                                                                        type="file"
                                                                                        accept="image/png, image/gif, image/jpeg, image/jpg"
                                                                                        onBlur={handleBlur}
                                                                                        onChange={(event) => {
                                                                                            const file = event.target.files[0];
                                                                                            const reader = new FileReader();
                                                                                            reader.onload = () => { setFieldValue('blog_image', file); };
                                                                                            reader.readAsDataURL(file);
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <p> Click to upload Logo (PNG, JPG and JPEG up to 2MB)</p>
                                                                            <div className='error'>
                                                                                <span >{errors.blog_image && touched.blog_image ? errors.blog_image : ''}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div className="form-outline mb-4 relative">

                                                                    <label className="form-label" htmlFor="form3Example3">Blog Title</label>
                                                                    <input id="url" className="form-control" type="text" name="title" placeholder="Enter the name of your Blog" value={values.title} onBlur={handleBlur} onChange={handleChange} />
                                                                    <div className='error'>
                                                                        <span >{errors.title && touched.title ? errors.title : ''}</span>
                                                                    </div>
                                                                </div>


                                                                <div className="form-outline mb-4 relative">

                                                                    <label htmlFor="shortDetail" className="form-label" >Blog Short Description </label>
                                                                    <input id="shortDetail" className="form-control" type="text" name="shortDetail" placeholder="Enter a brief one-liner about your Blog" value={values.shortDetail} onBlur={handleBlur} onChange={handleChange} />
                                                                    <div className='error'>
                                                                        <span >{errors.shortDetail && touched.shortDetail ? errors.shortDetail : ''}</span>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <MyEditor
                                                                        label={'Blog Long Description'}
                                                                        description={values.longDetail}
                                                                        onchangeDescrition={(value) => {
                                                                            setFieldValue('longDetail', value)
                                                                        }}

                                                                    />
                                                                    <div className="error">
                                                                        <span>{formik.errors.longDetail && formik.touched.longDetail ? formik.errors.longDetail : ''}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="form-outline mb-4 col-lg-12">
                                                                    <label className="form-label" htmlFor="form3Example4">Categories</label>

                                                                    <Select
                                                                        isMulti
                                                                        name="categories"
                                                                        options={categoryList}
                                                                        className="basic-multi-select"
                                                                        classNamePrefix="select"
                                                                        value={categoryList.filter(option => formik.values.categories.includes(option.value))}
                                                                        onBlur={handleBlur}
                                                                        onChange={(selectedOptions) => {
                                                                            const selectedValues = selectedOptions.map(option => option.value);
                                                                            setFieldValue('categories', selectedValues)
                                                                        }}

                                                                    />

                                                                    <div className='error'>
                                                                        <span >{errors.categories && touched.categories ? errors.categories : ''}</span>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <button type="submit" className="btn btn-primary btn-block mb-4 primary-btn">
                                                                {isEdit ? 'Update Blog' : 'Submit Blog  '}
                                                            </button>

                                                        </div>

                                                    </form>
                                                </div>
                                            </div>
                                            <div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/* Jumbotron */}

                        </section>
                    </div>
                </div>
                <Footer footer_style={footerHeight > window.innerHeight ? 'App-footer-scroll' : ''} />
            </div>
        </div>
    );
}

export default AddBlog;