import { createSlice } from "@reduxjs/toolkit";
import { getEditordata, addNewEditor, updatedataEditor, deletedataEditor, getSingleEditordata, pageSingleViewData, clearPageSingleViewData } from './thunk';

export const initialState = {
  editordetails: [],
  pageLinkData: null,
  error: {}
};

const editorSlice = createSlice({
  name: "editor",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEditordata.fulfilled, (state, action) => {
      // console.log("action", action);
      state.editordetails = action.payload.pages;
    });

    builder.addCase(getEditordata.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(getSingleEditordata.fulfilled, (state, action) => {
      // console.log("action", action);
      state.editordetails = action.payload.page;
    });

    builder.addCase(getSingleEditordata.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(addNewEditor.fulfilled, (state, action) => {
      state.editordetails.push(action.payload.page);
    });

    builder.addCase(addNewEditor.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(updatedataEditor.fulfilled, (state, action) => {
      state.editordetails = state.editordetails.map((Editors) =>
        Editors._id.toString() === action.payload.page
          ._id.toString()
          ? {
            ...Editors, ...action.payload.page
          }
          : Editors
      );
    });

    builder.addCase(updatedataEditor.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(deletedataEditor.fulfilled, (state, action) => {
      state.editordetails = (state.editordetails || []).filter((Editors) => Editors._id.toString() !== action.payload.Editor.toString());
    });

    builder.addCase(deletedataEditor.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(pageSingleViewData.fulfilled, (state, action) => {
      state.pageLinkData = action.payload?.page;
    });

    builder.addCase(pageSingleViewData.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });
    builder.addCase(clearPageSingleViewData.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(clearPageSingleViewData.fulfilled, (state, action) => {
      state.pageLinkData = null;
    });
  },
});

export default editorSlice.reducer;
