import React, { useEffect, useState, useCallback, useMemo } from "react";
import { isEmpty } from "lodash";

import {
    Col,
    Container,
    Row,
    Card,
    CardHeader,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Label,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    ModalFooter,
    Table,
    FormFeedback
} from "reactstrap";

import BreadCrumb from "../../../Components/Common/BreadCrumb";
import DeleteModal from "../../../Components/Common/DeleteModal";


//redux
import { useDispatch, useSelector } from "react-redux";
import TableContainer from "../../../Components/Common/TableContainer";

import Loader from "../../../Components/Common/Loader";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


// Images
import dummyImg from "../../../assets/images/users/user-dummy-img.jpg";
import avatar1 from '../../../assets/images/users/Profile Image.jpg';

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import { addNewCategory, deletedataCategory, getCategorydata, updatedataCategory } from "../../../slices/thunks";
import { api } from "../../../config";

const Category = () => {
    const dispatch = useDispatch();

    const Category = useSelector((state) => state.Category.categorydetails);

    useEffect(() => {
        dispatch(getCategorydata());
    }, [dispatch,]);

    const [isEdit, setIsEdit] = useState(false);
    const [Categoryis, setCategoryis] = useState(null);
    const [isImage, setIsImage] = useState(dummyImg);

    //delete Conatct
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteModalMulti, setDeleteModalMulti] = useState(false);
    const [modal, setModal] = useState(false);


    useEffect(() => {
        setCategoryis(Category)
    }, [Category])

    useEffect(() => {
        if (!isEmpty(Category)) {
            setCategoryis(Category);
            setIsEdit(false);
        }
    }, [Category]);

    const toggle = useCallback(() => {
        if (modal) {
            setModal(false);
            setIsEdit(false);
            setCategoryis(null)
        } else {
            setModal(true);
        }
        setIsImage(dummyImg);
    }, [modal]);

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            _id: (Categoryis && Categoryis._id) || null,
            CategoryName: (Categoryis && Categoryis.CategoryName) || '',
            Description: (Categoryis && Categoryis.Description) || '',
            CategoryIconName: (Categoryis && Categoryis.CategoryIconName) || '',
            Status: (Categoryis && Categoryis.Status) || 'Active',
            Category_backgroundColor: (Categoryis && Categoryis.Category_backgroundColor) || '#364574',
            Category_fileIcon: (Categoryis && Categoryis.Category_fileIcon) || ''
        },
        validationSchema: Yup.object({
            CategoryName: Yup.string().required("Please Enter Category Name"),
            Description: Yup.string().required("Please Enter Description"),
            CategoryIconName: Yup.string().required("Please Enter Icon Name"),
            Status: Yup.string().required("Please Select Status"),
            Category_backgroundColor: Yup.string().required("Please Select Category Background Color"),
        }).shape({
            Category_fileIcon: Yup.string().required("Please Enter Website Category File Icon")
        }),
        onSubmit: (values) => {

            if (isEdit) {

                const formData = new FormData();

                formData.append('id', values._id);
                formData.append('CategoryName', values.CategoryName);
                formData.append('Description', values.Description);
                formData.append('CategoryIconName', values.CategoryIconName);
                formData.append('Status', values.Status);
                formData.append('Category_backgroundColor', values.Category_backgroundColor);
                formData.append('Category_fileIcon', values.Category_fileIcon);

                dispatch(updatedataCategory(formData))
            } else {
                // console.log('values ::', values);
                const formData = new FormData();

                formData.append('CategoryName', values.CategoryName);
                formData.append('Description', values.Description);
                formData.append('CategoryIconName', values.CategoryIconName);
                formData.append('Status', values.Status);
                formData.append('Category_backgroundColor', values.Category_backgroundColor);
                formData.append('Category_fileIcon', values.Category_fileIcon);

                dispatch(addNewCategory(formData));
            }
            validation.resetForm();
            setIsImage(dummyImg);
            setModal(false);
        },
    });

    // Update Data
    const handleContactClick = useCallback((arg) => {
        const contact = arg;
        setCategoryis({
            _id: contact._id,
            CategoryName: contact.CategoryName,
            Description: contact.Description,
            Category_fileIcon: contact.Category_fileIcon,
            Category_backgroundColor: contact.Category_backgroundColor,
            CategoryIconName: contact.CategoryIconName,
            Status: contact.Status,
        });
        toggle();
        setIsEdit(true);
        setIsImage(api.API_URL + '/' + contact.Category_fileIcon);
    }, [toggle]);



    // Checked All
    const checkedAll = useCallback(() => {
        const checkall = document.getElementById("checkBoxAll");
        const ele = document.querySelectorAll(".contactCheckBox");

        if (checkall.checked) {
            ele.forEach((ele) => {
                ele.checked = true;
            });
        } else {
            ele.forEach((ele) => {
                ele.checked = false;
            });
        }
        deleteCheckbox();
    }, []);

    // Delete Multiple
    const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
    const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

    const deleteMultiple = () => {
        const checkall = document.getElementById("checkBoxAll");
        selectedCheckBoxDelete.forEach((element) => {
            dispatch(deletedataCategory(element.value));
            setTimeout(() => { toast.clearWaitingQueue(); }, 3000);
        });
        setIsMultiDeleteButton(false);
        checkall.checked = false;
    };

    const deleteCheckbox = () => {
        const ele = document.querySelectorAll(".contactCheckBox:checked");
        ele.length > 0 ? setIsMultiDeleteButton(true) : setIsMultiDeleteButton(false);
        setSelectedCheckBoxDelete(ele);
    };

    // Delete Data
    const handleDeleteContact = () => {
        if (Categoryis) {
            dispatch(deletedataCategory(Categoryis._id));
            setDeleteModal(false);
        }
    };

    const onClickDelete = (data) => {
        setCategoryis(data)
        setDeleteModal(true);
    };

    // Column
    const columns = useMemo(
        () => [
            {
                Header: <input type="checkbox" className="form-check-input" id="checkBoxAll" onClick={() => checkedAll()} />,
                Cell: (cellProps) => {
                    return <input type="checkbox" className="contactCheckBox form-check-input" value={cellProps.row.original._id} onChange={() => deleteCheckbox()} />;
                },
                id: '#',
            },
            {
                Header: "Category Name",
                accessor: "CategoryName",
                filterable: true,
            },
            {
                Header: "Category FileIcon",
                accessor: "Category_fileIcon",
                filterable: true,
                Cell: (cellProps) => {

                    return (
                        <>
                            {
                                cellProps.row.original?.Category_fileIcon ?
                                    <>
                                        <img
                                            src={api.API_URL + '/' + cellProps.row.original?.Category_fileIcon}
                                            alt=""
                                            // height="45"
                                            className="avatar-sm"
                                        />
                                    </>
                                    :
                                    <>
                                        <img
                                            src={avatar1}
                                            alt=""
                                            // height="45"
                                            className="avatar-sm"
                                        />
                                    </>
                            }
                        </>
                    )
                },
            },
            {
                Header: "Category Background Color",
                accessor: "Category_backgroundColor",
                filterable: true,
                Cell: (cellProps) => {
                    return (
                        <i className="ri-stop-fill align-middle" style={{ color: `${cellProps.value}`, fontSize: "40px" }}></i>
                    )
                }
            },
            {
                Header: "Category Icon Name",
                accessor: "CategoryIconName",
                filterable: true,
            },
            {
                Header: "Description",
                accessor: "Description",
                filterable: true,
                Cell: (cellProps) => {
                    return (
                        <div className="text-truncate">
                            {cellProps.value.length < 30
                                ? cellProps.value
                                : cellProps.value.substring(0, 30) + '...'}
                        </div>
                    )
                }
            },
            {
                Header: "Status",
                accessor: "Status",
                Cell: (cell) => {
                    switch (cell.value) {
                        case "Active":
                            return <span className="badge text-uppercase badge-soft-success"><i className="ri-checkbox-circle-line align-bottom"></i> Active </span>;
                        case "Inactive":
                            return <span className="badge text-uppercase badge-soft-danger"><i className="ri-close-circle-line align-bottom"></i> Inactive </span>;
                        default:
                            return <span className="badge text-uppercase badge-soft-warning">Default </span>;
                    }
                }
            },
            {
                Header: "Action",
                Cell: (cellProps) => {
                    return (
                        <ul className="list-inline hstack gap-2">

                            <li className="list-inline-item">
                                <UncontrolledDropdown>
                                    <DropdownToggle
                                        href=""
                                        className="btn btn-soft-secondary btn-sm dropdown"
                                        tag="button"
                                    >
                                        <i className="ri-more-fill align-middle"></i>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-end">

                                        <DropdownItem
                                            className="dropdown-item edit-item-btn"
                                            href=""
                                            onClick={() => { const contactData = cellProps.row.original; handleContactClick(contactData); }}
                                        >
                                            <i className="ri-pencil-fill align-middle me-2 text-muted"></i>
                                            Edit
                                        </DropdownItem>
                                        <DropdownItem
                                            className="dropdown-item remove-item-btn"
                                            href=""
                                            onClick={() => { const contactData = cellProps.row.original; onClickDelete(contactData); }}
                                        >
                                            <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>
                                            Delete
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </li>
                        </ul>
                    );
                },
            },
        ],
        [handleContactClick, checkedAll]
    );

    document.title = "Admin Panel | Category";
    return (
        <React.Fragment>
            <div className="page-content">

                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={handleDeleteContact}
                    onCloseClick={() => setDeleteModal(false)}
                />
                <DeleteModal
                    show={deleteModalMulti}
                    onDeleteClick={() => {
                        deleteMultiple();
                        setDeleteModalMulti(false);
                    }}
                    onCloseClick={() => setDeleteModalMulti(false)}
                />
                <Container fluid>
                    <BreadCrumb title="Category" pageTitle="Dashboards" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <div className="d-flex align-items-center flex-wrap gap-2">
                                        <div className="flex-grow-1">
                                            <button className="btn btn-primary add-btn" onClick={() => { setModal(true); }}>
                                                <i className="ri-add-fill me-1 align-bottom"></i>Add Category
                                            </button>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <div className="hstack text-nowrap gap-2">
                                                {isMultiDeleteButton && <button className="btn btn-secondary" onClick={() => setDeleteModalMulti(true)}><i className="ri-delete-bin-2-line"></i></button>}
                                            </div>
                                        </div>
                                    </div>
                                </CardHeader>
                            </Card>
                        </Col>
                        <Col xxl={12}>
                            <Card id="Category">
                                <CardBody>
                                    <div>
                                        {Category.length ? (
                                            <TableContainer
                                                columns={columns}
                                                data={(Category || [])}
                                                isGlobalFilter={true}
                                                isAddUserList={false}
                                                customPageSize={8}
                                                className="custom-header-css"
                                                divClass="table-responsive table-card mb-3"
                                                tableClass="align-middle table-nowrap"
                                                theadClass="table-light"
                                                isContactsFilter={false}
                                                SearchPlaceholder='Search for Category...'
                                            />
                                        ) : <div style={{ color: "rgb(135, 138, 153)" }} className="text-center fs-18 fw-bold">Category Not Available</div>
                                        }
                                    </div>
                                    <Modal id="showModal" backdrop={false} isOpen={modal} centered size="lg">
                                        <ModalHeader className="bg-soft-info p-3" toggle={toggle}>
                                            {!!isEdit ? "Edit Category" : "Add New Category"}
                                        </ModalHeader>

                                        <Form
                                            className="tablelist-form"
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                validation.handleSubmit();
                                                return false;
                                            }}
                                        >
                                            <ModalBody>
                                                <Input type="hidden" id="id-field" />
                                                <Row className="g-3">
                                                    <Col lg={12}>
                                                        <div className="text-center">
                                                            <div className="position-relative d-inline-block">
                                                                <div className="position-absolute  bottom-0 end-0">
                                                                    <Label htmlFor="Category_fileIcon" className="mb-0">
                                                                        <div className="avatar-xs cursor-pointer">
                                                                            <div className="avatar-title bg-light border rounded-circle text-muted">
                                                                                <i className="ri-image-fill"></i>
                                                                            </div>
                                                                        </div>
                                                                    </Label>
                                                                    <Input className="form-control d-none"
                                                                        id="Category_fileIcon" type="file"
                                                                        name="Category_fileIcon"

                                                                        invalid={!isEdit &&
                                                                            validation.touched.Category_fileIcon && validation.errors.Category_fileIcon ? true : false
                                                                        }
                                                                        onChange={(event) => {
                                                                            const file = event.target.files[0];
                                                                            const reader = new FileReader();
                                                                            reader.onload = (event) => {
                                                                                validation.setFieldValue('Category_fileIcon', file);
                                                                                setIsImage(event.target.result)
                                                                            };
                                                                            reader.readAsDataURL(file);
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="avatar-lg p-1">
                                                                    <div className="avatar-title bg-light rounded-circle">
                                                                        <img src={isImage} alt="img" id="customer-img" className="avatar-md rounded-circle object-cover img-thumbnail" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {validation.touched.Category_fileIcon && validation.errors.Category_fileIcon ? (
                                                                <FormFeedback type="invalid"><div>{validation.errors.Category_fileIcon}</div></FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <div>
                                                            <Label
                                                                htmlFor="CategoryName"
                                                                className="form-label"
                                                            >
                                                                Category Name
                                                            </Label>
                                                            <Input
                                                                name="CategoryName"
                                                                id="CategoryName"
                                                                className="form-control"
                                                                placeholder="Enter Category Name"
                                                                type="text"
                                                                validate={{
                                                                    required: { value: true },
                                                                }}
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.CategoryName || ""}
                                                                invalid={
                                                                    validation.touched.CategoryName && validation.errors.CategoryName ? true : false
                                                                }
                                                            />
                                                            {validation.touched.CategoryName && validation.errors.CategoryName ? (
                                                                <FormFeedback type="invalid"><div>{validation.errors.CategoryName}</div></FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>

                                                    <Col lg={6}>
                                                        <div>
                                                            <Label
                                                                htmlFor="CategoryIconName"
                                                                className="form-label"
                                                            >
                                                                Category Icon Name
                                                            </Label>

                                                            <Input
                                                                name="CategoryIconName"
                                                                id="CategoryIconName"
                                                                className="form-control"
                                                                placeholder="Enter Your Category Icon Name"
                                                                type="text"
                                                                validate={{
                                                                    required: { value: true },
                                                                }}
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.CategoryIconName || ""}
                                                                invalid={
                                                                    validation.touched.CategoryIconName && validation.errors.CategoryIconName ? true : false
                                                                }
                                                            />
                                                            {validation.touched.CategoryIconName && validation.errors.CategoryIconName ? (
                                                                <FormFeedback type="invalid"><div>{validation.errors.CategoryIconName}</div></FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>

                                                    <Col lg={6}>
                                                        <div>
                                                            <Label
                                                                htmlFor="Status"
                                                                className="form-label"
                                                            >
                                                                Status
                                                            </Label>

                                                            <Input
                                                                id="Status"
                                                                name="Status"
                                                                type="select"
                                                                className="form-select"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.Status || ""}
                                                            >
                                                                <option value="Active">Active</option>
                                                                <option value="Inactive">Inactive</option>
                                                            </Input>
                                                            {validation.touched.Status && validation.errors.Status ? (
                                                                <FormFeedback type="invalid"><div>{validation.errors.Status}</div></FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>

                                                    <Col lg={6}>
                                                        <div>
                                                            <Label
                                                                htmlFor="Category_backgroundColor"
                                                                className="form-label"
                                                            >
                                                                Category Background Color
                                                            </Label>

                                                            <Input
                                                                type="color"
                                                                className="form-control form-control-color w-100"
                                                                id="Category_backgroundColor"
                                                                name="Category_backgroundColor"
                                                                validate={{
                                                                    required: { value: true },
                                                                }}
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.Category_backgroundColor || ""}
                                                                invalid={
                                                                    validation.touched.Category_backgroundColor && validation.errors.Category_backgroundColor ? true : false
                                                                }
                                                            />
                                                            {validation.touched.Category_backgroundColor && validation.errors.Category_backgroundColor ? (
                                                                <FormFeedback type="invalid"><div>{validation.errors.Category_backgroundColor}</div></FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>

                                                    <Col lg={12}>
                                                        <div>
                                                            <Label
                                                                htmlFor="Description"
                                                                className="form-label"
                                                            >
                                                                Category Description
                                                            </Label>

                                                            <textarea
                                                                name="Description"
                                                                id="Description"
                                                                className="form-control"
                                                                placeholder="Enter Category Description"
                                                                type="text"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.Description || ""}
                                                                invalid={
                                                                    validation.touched.Description && validation.errors.Description ? true : false
                                                                }
                                                            ></textarea>
                                                            {validation.touched.Description && validation.errors.Description ? (
                                                                <FormFeedback type="invalid"><div>{validation.errors.Description}</div></FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </ModalBody>
                                            <ModalFooter>
                                                <div className="hstack gap-2 justify-content-end">
                                                    <button type="button" className="btn btn-light" onClick={() => { toggle(); setIsImage(dummyImg); setModal(false); setIsEdit(false) }} > Close </button>
                                                    <button type="submit" className="btn btn-success" id="add-btn" > {!!isEdit ? "Update" : "Add Category"} </button>
                                                </div>
                                            </ModalFooter>
                                        </Form>
                                    </Modal>
                                    <ToastContainer closeButton={false} limit={1} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment >
    );
};

export default Category;