import React, { useEffect } from 'react';
import { Container, Row } from 'reactstrap';

import Section from './Section';
import TicketDescription from './TicketDescription';
import TicketDetails from './TicketDetails';
import { useLocation } from 'react-router-dom';
import { getAdminSingleTicketsList } from '../../../../slices/thunks';
import { useDispatch, useSelector } from 'react-redux';

const TicketsDetails = () => {
    document.title = "Admin Panel | Support Tickets Details";
    const location = useLocation();
    const dispatch = useDispatch();
    const { state } = location;

    const AdminTickets = useSelector((state) => state.AdminTickets.adminticketsList);

    useEffect(() => {
        dispatch(getAdminSingleTicketsList(state?._id));
    }, [dispatch]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Section state={AdminTickets} />
                    </Row>
                    <Row>
                        <TicketDescription />
                        <TicketDetails state={AdminTickets} />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default TicketsDetails;