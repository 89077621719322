import { createSlice } from "@reduxjs/toolkit";
import { getAdminUserdata, updatedataAdminUser, deletedataAdminUser, AdminUserActive, AdminUserSuspend } from './thunk';

export const initialState = {
  adminusersdetails: [],
  error: {}
};

const AdminUserSlice = createSlice({
  name: "adminuser",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getAdminUserdata.fulfilled, (state, action) => {
      // console.log("action", action);
      state.adminusersdetails = action.payload.data;
    });

    builder.addCase(getAdminUserdata.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(updatedataAdminUser.fulfilled, (state, action) => {
      state.adminusersdetails = state.adminusersdetails.map((adminuser) =>
        adminuser._id.toString() === action.payload.user
          ._id.toString()
          ? {
            ...adminuser, ...action.payload.user
          }
          : adminuser
      );
    });

    builder.addCase(updatedataAdminUser.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(deletedataAdminUser.fulfilled, (state, action) => {
      state.adminusersdetails = (state.adminusersdetails || []).filter((adminuser) => adminuser._id.toString() !== action.payload.AdminUser.toString());
    });

    builder.addCase(deletedataAdminUser.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(AdminUserActive.fulfilled, (state, action) => {
      state.adminusersdetails = state.adminusersdetails.map((user) =>
        user._id.toString() === action.payload.user
          ._id.toString() ? { ...user, ...action.payload.user } : user
      );
    });

    builder.addCase(AdminUserActive.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(AdminUserSuspend.fulfilled, (state, action) => {
      state.adminusersdetails = state.adminusersdetails.map((user) =>
        user._id.toString() === action.payload.user
          ._id.toString() ? { ...user, ...action.payload.user } : user
      );
    });

    builder.addCase(AdminUserSuspend.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });
  },
});

export default AdminUserSlice.reducer;
