import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useFormik } from 'formik';
import { object, string, number, date, InferType, mixed } from 'yup';

import Header from '../../../Layouts/FrontHeader';
import Footer from '../../../Layouts/FrontFooter';
import { useDispatch, useSelector } from 'react-redux';
import { deleteRegisterWebsites, getWebsitePrice, registerNewWebsites } from '../../../slices/FrontView/submitSites/thunk';
import { Button, Spinner } from 'reactstrap';
import DOMPurify from 'dompurify';
import CryptoJS from "crypto-js";

const Register = () => {
    let navigate = useNavigate('')
    const dispatch = useDispatch();

    const authdata = JSON.parse(sessionStorage.getItem("authUser"))?.data;


    const { websitePlan } = useSelector((state) => state.SubmitSitesf)
    const { cmsData } = useSelector((state) => state.CMSF);

    let userSchema = object({
        email: string().email().required("Please  Enter Your Email Address"),
        website_name: string().required('Please Enter Website Link'),
        priceId: string().required('Please Select Any One Plan'),
    })

    useEffect(() => {
        dispatch(getWebsitePrice())
    }, [dispatch])


    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const encryptedDataEncoded = queryParams?.get('website');
        if (encryptedDataEncoded !== null) {
            const encryptedData = encryptedDataEncoded?.replace(/ /g, '+');

            const hashedText = CryptoJS.AES.decrypt(encryptedData, 'NODE');
            var originalText = (hashedText.toString(CryptoJS.enc.Utf8));
            const successUpdatePlans = JSON.parse(originalText);
            dispatch(deleteRegisterWebsites(successUpdatePlans, navigate))
        }
    }, [dispatch])




    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            website_name: '',
            email: (authdata && authdata?.Email) || '',
            priceId: websitePlan[0]?.plan_id || '',
            mode: websitePlan[0]?.mode || '',


        },
        validationSchema: userSchema,
        onSubmit: (values) => {


            const newObj = {
                Email: values.email,
                websitename: values.website_name,
                priceId: values.priceId,
                mode: values.mode,
            }
            dispatch(registerNewWebsites(newObj))
            formik.resetForm()
        },
    });
    const { values, touched, handleBlur, handleChange, handleSubmit, errors, setValues, setFieldValue } = formik

    const handleClick = (item) => {
        setFieldValue('priceId', item.plan_id)
        setFieldValue('mode', item.mode)
    };

    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
            return '<span className="' + className + '">' + (index + 1) + "</span>";
        },
    }

    const myElementRef = useRef(null);
    const [footerHeight, setFooterHeight] = useState(700);

    useEffect(() => {
        if (myElementRef?.current) {
            const elementHeight = myElementRef.current.getBoundingClientRect().height;
            setFooterHeight(elementHeight)
        }
    });
    document.title = `${cmsData?.websiteTitle ? cmsData?.websiteTitle : '1000 Ai Sites'} | Register`;

    return (
        <div>
            <div ref={myElementRef}>
                <Header isactive={'active-register'} />
                <div className='registerpage' >
                    <div className="px-4 py-20 px-md-5 text-center text-lg-start" >
                        <div className=" w-full mt-5 lg:grid lg:grid-cols-6">
                            <div className="lg:col-span-3  overflow-y-auto scrollbar-thin scrollbar-thumb-rounded-full scrollbar-thumb-gray-100 scrollbar-track-transparent">
                                <div className="w-full max-w-xl mx-auto flex items-center pb-4 px-3 card">
                                    <div className="w-full mx-auto">
                                        <a className="hidden" href="index.html">
                                            <img className="h-[24px] mx-auto mb-8" src="build/assets/logo-cf14bd45.svg" loading="lazy" />
                                        </a>
                                        <h2 className="text-center font-semibold mb-4">
                                            Submit Your Website
                                        </h2>
                                        <form method="post" className="space-y-3" data-turbo="false" onSubmit={handleSubmit}>
                                            <input type="hidden" name="_token" defaultValue="DnkPlbMts1ApRoYcbwJG8IkuXpX4ZmBEJOQHPfgV" autoComplete="off" />

                                            <div>
                                                <input id="website_name" className="form-control" type="text" name="website_name" onBlur={handleBlur} onChange={handleChange} value={values.website_name} placeholder="Enter Your Website Link" autofocus />
                                                <div className='error'>
                                                    <span >{errors.website_name && touched.website_name ? errors.website_name : ''}</span>
                                                </div>

                                            </div>

                                            <div>
                                                <input id="email" className="form-control" type="text" name="email" onBlur={handleBlur} onChange={handleChange} value={values.email} placeholder="Email address to get started" disabled={(authdata && authdata?.Email)} />
                                                <div className='error'>
                                                    <span >{errors.email && touched.email ? errors.email : ''}</span>

                                                </div>
                                            </div>


                                            <div className="p-4 border rounded-xl">
                                                <div className="mb-4">Price</div>
                                                <div data-controller="select">

                                                    {websitePlan.length > 0 && websitePlan?.map((item, index) => {
                                                        if (item?.status !== 'Inactive') {
                                                            return (

                                                                <div key={index} className="relative group mb-2" onClick={() => handleClick(item)}>
                                                                    {index === 0 &&
                                                                        <div className="absolute -top-2.5 left-4">
                                                                            <div className="h-5 px-2 rounded-full bg-purple-500 text-white text-xs flex items-center">
                                                                                Limited Time Offer
                                                                            </div>
                                                                        </div>
                                                                    }

                                                                    <label data-action="click->select#clicked">
                                                                        <input type="radio" name='plan' className="hidden peer" defaultChecked={index === 0 ? true : false} />
                                                                        <div className="cursor-pointer block p-4 rounded-xl border peer-checked:border-purple-500">
                                                                            <div className="flex space-x-1 items-baseline mb-0.5">
                                                                                <span className>${item?.salePrice} <span className="text-gray-400 line-through">${item?.originalPrice}</span></span>
                                                                                <span className="text-gray-500">/</span>
                                                                                <span className="text-gray-500">{item?.planName}</span>
                                                                            </div>
                                                                            {item?.description && <div className="text-xs text-gray-500">* {item?.description}.</div>}
                                                                        </div>
                                                                        <div className="absolute top-1/2 -translate-y-1/2 right-4 rounded-full border peer-checked:border-purple-500 w-5 h-5 flex items-center justify-center peer-checked:bg-purple-500">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="text-white w-4 h-4">
                                                                                <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                                                                            </svg>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            )
                                                        }

                                                    })}
                                                </div>
                                            </div>
                                            <div className="p-4 rounded-xl border Checklist">
                                                <h3 className="mb-2">Checklist</h3>
                                                <div className="space-y-1">
                                                    <div className="flex items-center space-x-2">
                                                        <div className="w-4 h-4 rounded-full bg-black text-white flex items-center justify-center">
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-3.5 h-3.5">
                                                                <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                                                            </svg>
                                                        </div>
                                                        <p className="text-sm text-white-500">Ensure your AI tool or site is fully functional and free of major bugs.</p>
                                                    </div>
                                                    <div className="flex items-center space-x-2">
                                                        <div className="w-4 h-4 rounded-full bg-black text-white flex items-center justify-center">
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-3.5 h-3.5">
                                                                <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                                                            </svg>
                                                        </div>
                                                        <p className="text-sm text-white-500">Ensure your AI tool delivers what it promises, offering real value to users.</p>
                                                    </div>
                                                    <div className="flex items-center space-x-2">
                                                        <div className="w-4 h-4 rounded-full bg-black text-white flex items-center justify-center">
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-3.5 h-3.5">
                                                                <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                                                            </svg>
                                                        </div>
                                                        <p className="text-sm text-white-500">Purely informational products not allowed.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="grid gap-2">
                                                <Button className="btn btn-primary w-full primary-btn" disabled={errors ? null : loading ? true : false} type='submit'>
                                                    {errors ? null : loading ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}

                                                    Continue
                                                </Button>

                                            </div>
                                        </form>
                                    </div>


                                </div>
                            </div>
                            <div className=" lg:block lg:col-span-3  overflow-y-auto scrollbar-thin scrollbar-thumb-rounded-full scrollbar-thumb-gray-100 scrollbar-track-transparent ">
                                <div className="flex items-center px-4 py-20  card">
                                    <div>
                                        <h1 className='font-bold text-center'>{cmsData?.register_title}</h1>
                                        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(cmsData?.register_Description || '') }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer footer_style={footerHeight > window.innerHeight ? 'App-footer-scroll' : ''} />
        </div>
    );
}

export default Register;