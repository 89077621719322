import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    ticketsUserList: [],
    ticketsUserCommnet: []
};




const TicketsSlice = createSlice({
    name: "TicketsSlice",
    initialState,
    reducers: {
        getTicketsListSuccess(state, action) {
            state.ticketsUserList = action.payload
        },

        getTicketCommentsSuccess(state, action) {
            state.ticketsUserCommnet = action.payload
        },

        addNewTicketSuccess(state, action) {
            state.ticketsUserList = [...state.ticketsUserList, action?.payload];
        },

        addTicketCommentsSuccess(state, action) {
            state.ticketsUserCommnet = [...state.ticketsUserCommnet, action?.payload];
        },

    },

});

export const {
    getTicketsListSuccess,
    addNewTicketSuccess,
    deleteTicketSuccess,
    updateTicketSuccess,
    getTicketCommentsSuccess,
    addTicketCommentsSuccess
} = TicketsSlice.actions

export default TicketsSlice.reducer;