import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import { deleteEditor, getEditor, getSingleEditor, getSinglePage, postEditor, updateEditor } from "../../../helpers/backend_helper";

export const getEditordata = createAsyncThunk("editor/getEditordata", async () => {
  try {
    const response = getEditor()
    return response;
  } catch (error) {
    return error;
  }
})

export const getSingleEditordata = createAsyncThunk("editor/getSingleEditordata", async ({ pagelink }) => {
  try {
    const response = getSingleEditor(pagelink)
    return response;
  } catch (error) {
    return error;
  }
})

export const addNewEditor = createAsyncThunk("editor/addNewEditor", async (Editor) => {
  try {
    const response = postEditor(Editor)
    toast.success("Editor Added Successfully", { autoClose: 3000 });
    return response;
  } catch (error) {
    toast.error("Editor Added Failed", { autoClose: 3000 });
    return error;
  }
})

export const updatedataEditor = createAsyncThunk("editor/updatedataEditor", async (Editor) => {
  const editor = Editor.get('id')
  try {
    const response = updateEditor(editor, Editor)
    toast.success("Editor Updated Successfully", { autoClose: 3000 });
    return response;
  } catch (error) {
    toast.error("Editor Updated Failed", { autoClose: 3000 });
    return error;
  }
})

export const deletedataEditor = createAsyncThunk("editor/deletedataEditor", async (Editor) => {
  try {
    const response = deleteEditor(Editor)
    toast.success("Editor Deleted Successfully", { autoClose: 3000 });
    return { Editor, ...response };
  } catch (error) {
    toast.error("Editor Deleted Failed", { autoClose: 3000 });
    return error;
  }
})


export const pageSingleViewData = createAsyncThunk("editor/pageSingleViewData", async (data) => {
  try {
    const response = await getSinglePage(data);
    if (response?.success === true) {
      return response;
    }
  } catch (error) {
    toast.error("Failed", { autoClose: 3000 });
    return error;
  }
})

export const clearPageSingleViewData = createAsyncThunk("editor/clearPageSingleViewData", async (data) => {
  try {

    return null;
  } catch (error) {
    toast.error("Failed", { autoClose: 3000 });
    return error;
  }
})