import DOMPurify from 'dompurify';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Link, useLocation } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';
import avatar1 from '../../../../assets/images/users/Placeholder_view_vector.svg.png';
import { getSingleTransactionData } from '../../../../slices/thunks';
import { api } from '../../../../config';

const OverviewTab = () => {
    const dispatch = useDispatch()
    const location = useLocation();
    const { state } = location;
    console.log("state: ", state);
    const Transaction = useSelector((state) => state.Transaction.transactiondetails);

    useEffect(() => {
        dispatch(getSingleTransactionData({ transactionId: state?._id }));
    }, [dispatch]);

    const handleValidDate = date => {
        const date1 = moment(new Date(date)).format("DD MMM Y");
        return date1;
    };

    return (
        <React.Fragment>
            <Row className='justify-content-center'>
                <Col xl={12} lg={8}>
                    <Card>
                        <CardBody>
                            <div className="text-muted">
                                <div className="col-md mb-4">
                                    <Row className="align-items-center g-3">
                                        <div className="col-md">
                                            <div>
                                                {/* <h5 className="fw-bold">{Transactiondata.website_ID.Site_Name}</h5> */}
                                                <p className="text-muted mb-0">{Transaction?.website_ID?.Submitted_By?.Email}</p>
                                            </div>
                                        </div>
                                    </Row>
                                </div>
                                <ul className="ps-0 m-0 vstack gap-2">
                                    <li style={{ listStyle: "none" }}><span style={{ color: "#878a99" }}> Website Submite Date : </span>{handleValidDate(Transaction?.current_period_start)}</li>
                                    <li style={{ listStyle: "none" }}><span style={{ color: "#878a99" }}>Subscription Start Date : </span>{handleValidDate(Transaction?.current_period_start)}</li>
                                    <li style={{ listStyle: "none" }}><span style={{ color: "#878a99" }}>Subscription Next Payment Date : </span>{
                                        Transaction?.current_period_end ?
                                            handleValidDate(Transaction?.current_period_end)
                                            : <>No Date found</>
                                    }</li>
                                    {/* <li style={{ listStyle: "none" }}><span style={{ color: "#878a99" }}>Next Payment Date: </span>1 Oct 2023</li> */}
                                </ul>
                                <div className="pt-3 border-top border-top-dashed mt-4">
                                    <Row>
                                        <Col lg={2} sm={6}>
                                            <div>
                                                <p className="mb-2 text-uppercase fw-medium fs-14">Transaction Date :</p>
                                                <span className="badge badge-soft-success fs-14">{handleValidDate(Transaction?.created_at)}</span>
                                            </div>
                                        </Col>
                                        <Col lg={2} sm={6}>
                                            <div>
                                                <p className="mb-2 text-uppercase fw-medium fs-14">Customer ID :</p>
                                                {
                                                    Transaction?.customer_id ?
                                                        <span className="mb-0 text-uppercase fw-bold fs-14">{Transaction?.customer_id}</span>
                                                        : <div>-</div>
                                                }
                                            </div>
                                        </Col>
                                        <Col lg={2} sm={6}>
                                            <div>
                                                <p className="mb-2 text-uppercase fw-medium fs-14">Payment ID :</p>
                                                {
                                                    Transaction?.payment_id ?
                                                        <span className="mb-0 text-uppercase fw-bold fs-14">{Transaction?.payment_id}</span>
                                                        : <div>-</div>
                                                }
                                            </div>
                                        </Col>
                                        <Col lg={2} sm={6}>
                                            <div>
                                                <p className="mb-2 text-uppercase fw-medium fs-14">Payment Status :</p>
                                                {
                                                    Transaction?.payment_status === "paid" || Transaction?.payment_status === "Paid" ? <span className="badge text-uppercase badge-soft-success"><i className="ri-checkbox-circle-line align-bottom"></i> Paid </span> :
                                                        Transaction?.payment_status === "canceled" ? <span className="badge text-uppercase badge-soft-danger"><i className="ri-close-circle-line align-bottom"></i> canceled </span> :
                                                            <span className="badge text-uppercase badge-soft-warning"><i className="ri-time-line align-bottom"></i> Pending </span>
                                                }
                                            </div>
                                        </Col>
                                        <Col lg={2} sm={6}>
                                            <div>
                                                <p className="mb-2 text-uppercase fw-medium fs-14">Subscription Status :</p>
                                                {
                                                    Transaction?.subscription_status === "Active" ? <span className="badge text-uppercase badge-soft-success"><i className="ri-checkbox-circle-line align-bottom"></i> Active </span> :
                                                        Transaction?.subscription_status === "canceled" ? <span className="badge text-uppercase badge-soft-danger"><i className="ri-close-circle-line align-bottom"></i> canceled </span> :
                                                            <span className="badge text-uppercase badge-soft-warning"><i className="ri-time-line align-bottom"></i> Pending </span>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </CardBody>

                    </Card>
                </Col>
            </Row>
            <Row className='justify-content-center'>
                <Col lg={12}>
                    <div className="d-flex align-items-center mb-4">
                        <div className="flex-grow-1">
                            <h5 className="mb-0">Website Details</h5>
                        </div>
                    </div>
                </Col>
                <Col xl={12} lg={12}>
                    <Card>
                        <CardBody>
                            <div className="d-flex align-items-center flex-wrap gap-2">
                                <div className="text-muted flex-grow-1">
                                    <div className="col-md">
                                        <Row className="align-items-center g-3">
                                            <div className="col-md-auto">
                                                <div>
                                                    <div className="bg-white rounded-circle">
                                                        {
                                                            Transaction?.website_ID?.Logo ?
                                                                <img src={api.API_URL + '/' + Transaction?.website_ID?.Logo} alt="" className="avatar-sm" />
                                                                :
                                                                <img src={avatar1} alt="" className="avatar-sm" />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md">
                                                <div>
                                                    <h4 className="fw-bold">{Transaction?.website_ID?.Site_Name}</h4>
                                                    <a href={Transaction?.website_ID?.Site_Link} target='_blank' className="link-secondary">{Transaction?.website_ID?.Site_Link}</a>
                                                </div>
                                            </div>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row className='justify-content-center'>
                <Col xl={6} lg={8}>
                    <Card>
                        <CardBody>
                            <div className="text-muted">
                                <ul className="ps-0 vstack gap-2">
                                    <li style={{ listStyle: "none" }}><span style={{ color: "#878a99" }}>Submitted By: </span>
                                        {
                                            Transaction?.website_ID?.Submitted_By?.Email ? Transaction?.website_ID?.Submitted_By?.Email.split("@")[0] : <>No Email found</>
                                        }
                                    </li>
                                    <li style={{ listStyle: "none" }}>
                                        <span style={{ color: "#878a99" }}>Top 10: </span>
                                        {
                                            Transaction?.website_ID?.TOP_10 === 'true' ? <span className="badge text-uppercase text-white bg-success fs-14"> Yes </span>
                                                : <span className="badge text-uppercase bg-danger text-white fs-14"> No </span>
                                        }
                                    </li>
                                    <li style={{ listStyle: "none" }}>
                                        <span style={{ color: "#878a99" }}>Payment Status: </span>
                                        {
                                            Transaction?.website_ID?.Payment_Status === 'paid' || Transaction?.website_ID?.Payment_Status === 'Paid' ? <span className="badge text-success text-uppercase fs-14 badge-soft-success"><i className="ri-checkbox-circle-line align-bottom"></i>  Paid </span>
                                                : Transaction?.website_ID?.Payment_Status === 'canceled' ? <span className="badge text-danger text-uppercase fs-14 badge-soft-danger"><i className="ri-close-circle-line align-bottom"></i>  Canceled </span>
                                                    : <span className="badge text-uppercase text-warning fs-14 badge-soft-warning"><i className="ri-time-line align-bottom"></i>  Pending </span>
                                        }
                                    </li>
                                    <li style={{ listStyle: "none" }}>
                                        <span style={{ color: "#878a99" }}>Approval Status: </span>
                                        {
                                            Transaction?.website_ID?.Approval_Status === 'Approved' ? <span className="badge text-success text-uppercase fs-14 badge-soft-success"><i className="ri-checkbox-circle-line align-bottom"></i> Approved </span>
                                                : Transaction?.website_ID?.Approval_Status === 'Rejected' ? <span className="badge text-danger text-uppercase fs-14 badge-soft-danger"><i className="ri-close-circle-line align-bottom"></i> Rejected </span>
                                                    : Transaction?.website_ID?.Approval_Status === 'Review' ? <span className="badge text-info text-uppercase fs-14 badge-soft-info"><i className="ri-eye-line align-bottom"></i> Review </span>
                                                        : <span className="badge text-warning text-uppercase fs-14 badge-soft-warning"><i className="ri-time-line align-bottom"></i> Pending </span>
                                        }
                                    </li>
                                    <li style={{ listStyle: "none" }}><span style={{ color: "#878a99" }}>Short Description: </span>{Transaction?.website_ID?.Short_Description ? Transaction?.website_ID?.Short_Description : <>No Description found</>}</li>
                                </ul>
                            </div>
                        </CardBody>

                    </Card>
                </Col>
                <Col xl={6} lg={8}>
                    <Card>
                        <CardBody>
                            <div className="text-muted">
                                <ul className="ps-0 vstack gap-2">
                                    <li style={{ listStyle: "none" }}>
                                        <span style={{ color: "#878a99" }}>Category: </span>
                                        {Transaction?.website_ID?.Category.map((item, index) => (
                                            <>
                                                <p key={index} className="badge me-1 mb-1 badge-soft-primary fs-14">
                                                    {item}
                                                </p>
                                                {(index + 1) % 5 === 0 && <br />}
                                            </>
                                        ))}
                                    </li>
                                    <li style={{ listStyle: "none" }}>
                                        <span style={{ color: "#878a99" }}>Subscription Type: </span>
                                        <p className="badge mb-0 badge-soft-secondary fs-14">{state?.plan_ID?.billingPeriod}</p>
                                    </li>
                                    <li style={{ listStyle: "none" }}>
                                        <span style={{ color: "#878a99" }}>Submitted Date: </span>
                                        <p className="badge mb-0 badge-soft-success fs-14">{handleValidDate(Transaction?.website_ID?.Date_Submitted)}</p>
                                    </li>
                                    <li style={{ listStyle: "none" }}>
                                        <span style={{ color: "#878a99" }}>Subscription Start Date: </span>
                                        <p className="badge mb-0 badge-soft-success fs-14">{handleValidDate(Transaction?.website_ID?.Subscription_Start_Date)}</p>
                                    </li>
                                    {Transaction?.website_ID?.Subscription_End_Date && <li style={{ listStyle: "none" }}>
                                        <span style={{ color: "#878a99" }}>Subscription Next Payment Date: </span>
                                        <p className="badge mb-0 badge-soft-danger fs-14">{handleValidDate(Transaction?.website_ID?.Subscription_End_Date)}</p>
                                    </li>}
                                </ul>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row className='justify-content-center'>
                <Col xxl={12}>
                    <Card>
                        <CardBody>
                            <div className="text-muted">
                                <ul className="ps-0 vstack gap-2">
                                    <li style={{ listStyle: "none" }}><span style={{ color: "#878a99" }}>Long Description: </span><div className='mt-2' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(Transaction?.website_ID?.Long_Description) }} /></li>
                                </ul>
                            </div>
                        </CardBody>

                    </Card>
                </Col>
            </Row>
            <Row className='justify-content-center'>
                <Col lg={12}>
                    <div className="d-flex align-items-center mb-4">
                        <div className="flex-grow-1">
                            <h5 className="mb-0">Plan Details</h5>
                        </div>
                    </div>
                </Col>
                <Col xl={12} lg={8}>
                    <Card>
                        <CardBody>
                            <div className="text-muted">
                                <div className="col-md mb-4">
                                    <Row className="align-items-center g-3">
                                        <div className="col-md">
                                            <div>
                                                {/* <h5 className="fw-bold">{Transaction.website_ID.Site_Name}</h5> */}
                                                <p className="text-muted mb-0">{Transaction?.plan_ID?.planName}</p>
                                            </div>
                                        </div>
                                    </Row>
                                </div>
                                <div className="pt-3 border-top border-top-dashed mt-4">
                                    <Row>
                                        <Col lg={2} sm={6}>
                                            <div>
                                                <p className="mb-2 text-uppercase fw-medium fs-14">Mode :</p>
                                                <span className="badge badge-soft-success fs-14">{Transaction?.plan_ID?.mode}</span>
                                            </div>
                                        </Col>
                                        <Col lg={2} sm={6}>
                                            <div>
                                                <p className="mb-2 text-uppercase fw-medium fs-14">Start Date :</p>
                                                <span className="badge badge-soft-success fs-14">{handleValidDate(Transaction?.plan_ID?.createdAt)}</span>
                                            </div>
                                        </Col>
                                        {/* <Col lg={2} sm={6}>
                                            <div>
                                                <p className="mb-2 text-uppercase fw-medium fs-14">End Date :</p>
                                                <span className="badge badge-soft-success fs-14">{handleValidDate(Transaction?.plan_ID?.updatedAt)}</span>
                                            </div>
                                        </Col> */}
                                        <Col lg={2} sm={6}>
                                            <div>
                                                <p className="mb-2 text-uppercase fw-medium fs-14">Original Price :</p>
                                                {
                                                    Transaction?.plan_ID?.originalPrice ?
                                                        < span className="fs-14">${Transaction?.plan_ID?.originalPrice}</span>
                                                        :
                                                        <span className="fs-14">$0</span>
                                                }
                                            </div>
                                        </Col>
                                        <Col lg={2} sm={6}>
                                            <div>
                                                <p className="mb-2 text-uppercase fw-medium fs-14">Sale Price :</p>
                                                {
                                                    Transaction?.plan_ID?.salePrice ?
                                                        < span className="fs-14">${Transaction?.plan_ID?.salePrice}</span>
                                                        :
                                                        <span className="fs-14">$0</span>
                                                }
                                            </div>
                                        </Col>
                                        <Col lg={2} sm={6}>
                                            <div>
                                                <p className="mb-2 text-uppercase fw-medium fs-14">Payment Status :</p>
                                                {
                                                    Transaction?.plan_ID?.status === "Active" ? <span className="badge text-uppercase badge-soft-success"><i className="ri-checkbox-circle-line align-bottom"></i> Active </span> :
                                                        Transaction?.plan_ID?.status === "Inactive" ? <span className="badge text-uppercase badge-soft-danger"><i className="ri-close-circle-line align-bottom"></i> Inactive </span> :
                                                            <span className="badge text-uppercase badge-soft-warning"><i className="ri-time-line align-bottom"></i> Default </span>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </CardBody>

                    </Card>
                </Col>
            </Row>
        </React.Fragment >
    );
};

export default OverviewTab;