import { createAsyncThunk } from "@reduxjs/toolkit";

//Include Both Helper File with needed methods
import { deleteAdminBlog, getBlog, getBlogStatusActiveandDeactive, postAdminBlog, updateAdminBlog } from "../../../helpers/backend_helper";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export const getBlogAllData = createAsyncThunk("blog/getBlogAllData", async () => {
  try {
    const response = await getBlog();
    return response;
  } catch (error) {
    return error;
  }
});

export const getBlogStatusActiveandDeactiveData = createAsyncThunk("blog/getBlogStatusActiveandDeactiveData", async ({ id, newStatus }) => {
  try {
    const response = await getBlogStatusActiveandDeactive(id, newStatus);
    return response;
  } catch (error) {
    return error;
  }
});

export const addNewBlog = createAsyncThunk("blog/addNewBlog", async (blog) => {
  try {
    // const navigate = useNavigate()
    const response = await postAdminBlog(blog)
    if (response.success === true) {
      // navigate('/admin/blog')
      toast.success("Blog Added Successfully", { autoClose: 3000 });
      return response;
    }
  } catch (error) {
    toast.error("Blog Added Failed", { autoClose: 3000 });
    return error;
  }
})

export const updatedataBlog = createAsyncThunk("blog/updatedataBlog", async (blog) => {
  const Blog = blog.get('id')
  try {
    const response = await updateAdminBlog(Blog, blog)
    toast.success("Blog Updated Successfully", { autoClose: 3000 });
    return response;
  } catch (error) {
    toast.error("Blog Updated Failed", { autoClose: 3000 });
    return error;
  }
})

export const deletedataBlog = createAsyncThunk("blog/deletedataBlog", async (blog) => {
  try {
    const response = await deleteAdminBlog(blog)
    toast.success("Blog Deleted Successfully", { autoClose: 3000 });
    return { blog, ...response };
  } catch (error) {
    toast.error("Blog Deleted Failed", { autoClose: 3000 });
    return error;
  }
})