import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { deleteAdminSupportTicketComments, getAdminSupportTicketComments, postAdminSupportTicketComments, updateAdminSupportTicketComments } from "../../../helpers/backend_helper";

//Include Both Helper File with needed methods

export const getAdminSingleTicketsCommentsList = createAsyncThunk("ticketscomments/getAdminSingleTicketsCommentsList", async (supportid) => {
    try {
        const response = getAdminSupportTicketComments(supportid);
        return response;
    } catch (error) {
        return error;
    }
});

export const addNewAdminTicketComments = createAsyncThunk("ticketscomments/addNewAdminTicketComments", async (ticketcomments) => {
    const ticketscomments = ticketcomments.get('id')
    try {
        const response = postAdminSupportTicketComments(ticketscomments, ticketcomments);
        const data = await response;
        if (data.success === true) {
            toast.success("Tickets comments Added Successfully", { autoClose: 3000 });
        } else {
            toast.error(data.message, { autoClose: 3000 });
        }
        return data;
    } catch (error) {
        return error;
    }
});

export const updateAdminTicketComments = createAsyncThunk("ticketscomments/updateAdminTicketComments", async (ticketcomments) => {
    const ticketscomments = ticketcomments.get('id')
    try {
        const response = updateAdminSupportTicketComments(ticketscomments, ticketcomments);
        const data = await response;
        toast.success("Tickets comments Updated Successfully", { autoClose: 3000 });
        return data;
    } catch (error) {
        toast.error("Tickets comments Updated Failed", { autoClose: 3000 });
        return error;
    }
});

export const deleteAdminTicketComments = createAsyncThunk("ticketscomments/deleteAdminTicketComments", async (ticketcomments) => {
    try {
        const response = deleteAdminSupportTicketComments(ticketcomments);
        toast.success("Tickets comments Delete Successfully", { autoClose: 3000 });
        return { ticketcomments, ...response };
    } catch (error) {
        toast.error("Tickets comments Delete Failed", { autoClose: 3000 });
        return error;
    }
});