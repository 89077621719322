import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import * as moment from "moment";

// Import Images
import { SlideshowLightbox } from 'lightbox.js-react';
import 'lightbox.js-react/dist/index.css'

import {
    Col,
    Container,
    Row,
    Card,
    CardHeader,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,

} from "reactstrap";

import BreadCrumb from "../../../Components/Common/BreadCrumb";

//redux
import { useDispatch, useSelector } from "react-redux";
import TableContainer from "../../../Components/Common/TableContainer";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { getSingleTransactionData, getTransactionData } from "../../../slices/Admin/Transaction/thunk";

const PaymentHistory = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const Transaction = useSelector((state) => state.Transaction.transactiondetails);

    useEffect(() => {
        dispatch(getTransactionData());
    }, [dispatch]);

    // Column
    const columns = useMemo(
        () => [
            {
                Header: "Transaction Date",
                accessor: "createdAt",
                filterable: true,
                Cell: (cell) => {
                    const formattedDate = moment(cell.value).format("D MMM YYYY");
                    return (
                        <div>
                            <div>{formattedDate}</div>
                        </div>
                    );
                },
            },
            {
                Header: "User Email",
                accessor: "Email",
                filterable: true,
                Cell: (cellProps) => {
                    const UserEmail = cellProps.row.original?.website_ID?.Submitted_By?.Email;
                    return (
                        <div>
                            <div>{UserEmail ? UserEmail : <>-</>}</div>
                        </div>
                    )
                }
            },
            {
                Header: "Website Name",
                accessor: "Site_Name",
                filterable: true,
                Cell: (cellProps) => {
                    const WebsiteName = cellProps.row.original?.website_ID?.Site_Name;
                    const WebsiteLinkName = cellProps.row.original?.website_ID?.Site_Link;
                    return (
                        <div>
                            <div style={{ cursor: 'pointer' }}
                                className="text-secondary fw-bold"
                                onClick={() => {
                                    const UsersData = cellProps.row.original;
                                    const Plan = UsersData?.plan_ID
                                    const newData = { ...UsersData?.website_ID, Plan }
                                    navigate('/admin/website-details-overview', {
                                        state: newData,
                                    });
                                }}
                            >
                                {WebsiteName ? WebsiteName : '-'}
                            </div>
                        </div >
                    )
                }
            },
            {
                Header: "Start Date",
                accessor: "current_period_start",
                filterable: true,
                Cell: (cell) => {
                    if (cell.value) {
                        const formattedDate = moment(cell.value).format("D MMM YYYY");
                        return (
                            <div>
                                <div>{formattedDate}</div>
                            </div>
                        );
                    } else {
                        return (
                            <div>-</div>
                        );
                    }
                },
            },
            {
                Header: "End Date",
                accessor: "current_period_end",
                filterable: true,
                Cell: (cell) => {
                    if (cell.value) {
                        const formattedDate = moment(cell.value).format("D MMM YYYY");
                        return (
                            <div>
                                <div>{formattedDate}</div>
                            </div>
                        );
                    } else {
                        return (
                            <div className="text-center">-</div>
                        );
                    }
                },
            },
            {
                Header: "Sale Price",
                accessor: "amount",
                filterable: true,
                Cell: (cellProps) => {
                    const amount = cellProps.value
                    return (
                        <>
                            {
                                amount ?
                                    < span className="fs-14">${amount}</span>
                                    :
                                    <span className="fs-14">$0</span>
                            }
                        </>
                    )
                }
            },
            {
                Header: "Billing Period",
                accessor: "billingPeriod",
                Cell: (cellProps) => {
                    const WebsiteName = cellProps.row.original?.plan_ID?.billingPeriod;

                    switch (WebsiteName) {

                        case "Daily":
                            return <span className="badge text-uppercase badge-soft-info"> Daily </span>;
                        case "Weekly":
                            return <span className="badge text-uppercase badge-soft-success"> Weekly </span>;
                        case "Monthly":
                            return <span className="badge text-uppercase badge-soft-secondary"> Monthly </span>;
                        case "Year":
                            return <span className="badge text-uppercase badge-soft-primary"> Yearly </span>;
                        default:
                            return <span className="badge text-uppercase badge-soft-warning"> One-Time </span>;
                    }
                }
            },
            {
                Header: "Status",
                accessor: "payment_status",
                Cell: (cell) => {
                    switch (cell.value) {
                        case "Active":
                            return <span className="badge text-uppercase badge-soft-success"><i className="ri-checkbox-circle-line align-bottom"></i>Active </span>;
                        case "Completed":
                        case "completed":
                            return <span className="badge text-uppercase badge-soft-success"><i className="ri-checkbox-circle-line align-bottom"></i> Completed </span>;
                        case "cancelled":
                            return <span className="badge text-uppercase badge-soft-danger"><i className="ri-close-circle-line align-bottom"></i> Cancelled </span>;
                        default:
                            return <span className="badge text-uppercase badge-soft-warning"><i className="ri-time-line align-bottom"></i> Pending </span>;
                    }
                }
            },
            {
                Header: "Subscription Id",
                accessor: "subscription_id",
                filterable: true,
                Cell: (cell) => {
                    return (
                        cell.value ?
                            <Link className="link-secondary"
                                to={`https://www.sandbox.paypal.com/billing/subscriptions/${cell.value}`}
                                target="_blank"
                            >
                                <div>  {cell.value}</div>
                            </Link>
                            :
                            <div className="text-center">-</div>
                    )
                }
            },
            {
                Header: "Payment ID ",
                filterable: true,
                Cell: (cell) => {
                    return (
                        <>
                            <Link className="link-secondary"
                                to={`https://www.sandbox.paypal.com/activity/payment/${cell.row.original.payment_id}`}
                                target="_blank"
                            >
                                <div>  {cell.row.original.payment_id}</div>
                            </Link>
                        </>

                    )
                }
            },
            {
                Header: "Action",
                Cell: (cellProps) => {
                    return (
                        <ul className="list-inline hstack gap-2">

                            <li className="list-inline-item">
                                <UncontrolledDropdown>
                                    <DropdownToggle
                                        href=""
                                        className="btn btn-soft-secondary btn-sm dropdown"
                                        tag="button"
                                    >
                                        <i className="ri-more-fill align-middle"></i>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-end">
                                        <DropdownItem
                                            className="dropdown-item edit-item-btn"
                                            onClick={() => {
                                                const UsersData = cellProps.row.original;
                                                const Plan = UsersData?.plan_ID
                                                const newData = { ...UsersData?.website_ID, Plan }
                                                navigate('/admin/website-details-overview', {
                                                    state: newData,
                                                });
                                            }}
                                        >
                                            <i className="ri-eye-fill align-bottom me-2 text-muted"></i>{" "}
                                            View Detail
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </li>
                        </ul>
                    );
                },
            },
        ],
    );

    document.title = "Admin Panel | Payment History";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Payment History" pageTitle="Dashboards" />
                    <Row>
                        <Col xxl={12}>
                            <Card id="TeamMembersList">
                                <CardBody>
                                    <div>
                                        {Transaction?.length ? (
                                            <TableContainer
                                                columns={columns}
                                                data={(Transaction || [])}
                                                isGlobalFilter={true}
                                                isAddUserList={false}
                                                customPageSize={8}
                                                className="custom-header-css"
                                                divClass="table-responsive table-card mb-3"
                                                tableClass="align-middle table-nowrap"
                                                theadClass="table-light"
                                                isContactsFilter={false}
                                                SearchPlaceholder='Search for Payment History...'
                                            />
                                        ) : <div style={{ color: "rgb(135, 138, 153)" }} className="text-center fs-18 fw-bold">Transaction Not Available</div>
                                        }
                                    </div>
                                    <ToastContainer closeButton={false} limit={1} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment >
    );
};

export default PaymentHistory;