import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import processImg from "../../../assets/images/payment-process.gif"
import CryptoJS from "crypto-js";
import axios from 'axios';

const PaymentProcess = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const encryptedDataEncoded = queryParams?.get('website');
        const blogData = decodeURIComponent(queryParams.get('blog'));

        let user = null;
        let token = null;


        if (blogData === 'null') {
            const encryptedData = encryptedDataEncoded?.replace(/ /g, '+');

            const hashedText = CryptoJS.AES.decrypt(encryptedData, 'NODE');
            var originalText = (hashedText.toString(CryptoJS.enc.Utf8));
            const successUpdatePlans = JSON.parse(originalText);

            user = successUpdatePlans?.user;
            token = successUpdatePlans?.user?.Token;

        } else {
            const hashedText = CryptoJS.AES.decrypt(blogData, 'NODE');
            var originalText = hashedText.toString(CryptoJS.enc.Utf8);
            const successUpdatePlans = JSON.parse(originalText);

            user = successUpdatePlans?.user;
            token = successUpdatePlans?.user?.Token;
        }

        sessionStorage.removeItem("authUser");
        sessionStorage.setItem("authUser", JSON.stringify({ data: user, token: token }));
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        setTimeout(() => {
            if (blogData === 'null') {
                navigate('/submitList')
            } else {
                navigate('/bloglist')
            }
            window.location.reload();
        }, 30000)
    }, [])



    return (
        <React.Fragment>
            <div className="auth-page-wrapper">
                <div className="auth-page-content">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <div className="d-inline-block auth-logo">

                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">
                                    <CardBody className="p-4 text-center">
                                        <div className="avatar-lg mx-auto mt-2">
                                            <div className="avatar-title bg-light text-success display-3 rounded-circle">
                                                {/* <i className="ri-checkbox-circle-fill"></i> */}
                                                <img src={processImg} alt="" />

                                            </div>
                                        </div>
                                        <div className="mt-4 pt-2">
                                            <h4>Transaction in progress </h4>
                                            <p className="text-muted mx-4">Please don't close this window</p>

                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>

            </div>
        </React.Fragment >
    );
};

export default PaymentProcess;