import { createSlice } from "@reduxjs/toolkit";
import { getCategorydata, addNewCategory, updatedataCategory, deletedataCategory } from './thunk';

export const initialState = {
  categorydetails: [],
  error: {}
};

const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCategorydata.fulfilled, (state, action) => {
      // console.log("state", state, "action", action);
      state.categorydetails = action.payload.data;
    });

    builder.addCase(getCategorydata.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(addNewCategory.fulfilled, (state, action) => {
      state.categorydetails.push(action.payload.category);
    });

    builder.addCase(addNewCategory.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(updatedataCategory.fulfilled, (state, action) => {
      state.categorydetails = state.categorydetails.map((Categories) =>
        Categories._id.toString() === action.payload.category
          ._id.toString()
          ? {
            ...Categories, ...action.payload.category
          }
          : Categories
      );
    });

    builder.addCase(updatedataCategory.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(deletedataCategory.fulfilled, (state, action) => {
      state.categorydetails = (state.categorydetails || []).filter((Categories) => Categories._id.toString() !== action.payload.Category.toString());
    });

    builder.addCase(deletedataCategory.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });
  },
});

export default categorySlice.reducer;
