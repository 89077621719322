import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import { getCMS, postCMS, updateCMS } from "../../../helpers/backend_helper";

export const getCMSdata = createAsyncThunk("cms/getCMSdata", async () => {
  try {
    const response = await getCMS();
    return response;
  } catch (error) {
    return error;
  }
});

export const addNewCMS = createAsyncThunk("cms/addNewCMS", async (CMS) => {
  try {
    const response = await postCMS(CMS)
    toast.success("CMS Added Successfully", { autoClose: 3000 });
    return response;
  } catch (error) {
    toast.error("CMS Added Failed", { autoClose: 3000 });
    return error;
  }
})

export const updatedataCMS = createAsyncThunk("cms/updatedataCMS", async (CMS) => {
  const cmsid = CMS.get('id')
  try {
    const response = await updateCMS(cmsid, CMS)
    toast.success("CMS Updated Successfully", { autoClose: 3000 });
    return response;
  } catch (error) {
    toast.error("CMS Updated Failed", { autoClose: 3000 });
    return error;
  }
})