import { createSlice } from "@reduxjs/toolkit";
import { getTransactionData, getSingleTransactionData } from './thunk';
import { isEmpty, isNaN } from "lodash";

export const initialState = {
  transactiondetails: [],
  error: {},
  websiteTodayRevenue: 0,
  websiteMonthlyRevenue: 0
};

const TransactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTransactionData.fulfilled, (state, action) => {
      state.transactiondetails = action.payload.data;
      let Monthlytotal = 0;
      let Todaytotal = 0;
      const today = new Date();

      action?.payload?.data?.map((item, index) => {

        if (new Date(item.createdAt).getMonth() + 1 === new Date().getMonth() + 1) {
          Monthlytotal += item?.plan_ID?.salePrice !== undefined ? item?.plan_ID?.salePrice : 0
        }
        if (new Date(item.createdAt).toDateString() === today.toDateString()) {
          Todaytotal += item?.plan_ID?.salePrice !== undefined ? item?.plan_ID?.salePrice : 0
        }
      })
      state.websiteTodayRevenue = Todaytotal;
      state.websiteMonthlyRevenue = Monthlytotal;
    });

    builder.addCase(getTransactionData.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(getSingleTransactionData.fulfilled, (state, action) => {
      state.transactiondetails = action.payload.data;
    });

    builder.addCase(getSingleTransactionData.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });
  },
});

export default TransactionSlice.reducer;
