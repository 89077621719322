import { combineReducers } from "redux";

// Front
import LayoutReducer from "./layouts/reducer";

// Authentication
import LoginReducer from "./Admin/auth/login/reducer";
import ProfileReducer from "./Admin/auth/profile/reducer";
import AdminLoginInUserSlice from "./Admin/AdminLoginInUser/reducer";

//TicketsList
import TicketsReducer from "./Admin/Tickets/reducer";

// CMS
import cmsSlice from "./Admin/Cms/reducer";

// Categories
import categoryReducer from "./Admin/Category/reducer";

// Categories
import PlanAndPricingSlice from "./Admin/PlanAndPricing/reducer";

// Admin User
import AdminUserSlice from "./Admin/AdminUsers/reducer";

// Submitted Sites
import SubmittedSitesSlice from "./Admin/SubmitedSites/reducer";

// Details And Product
import DetailsAndProductSlice from "./Admin/DetailsAndProduct/reducer";

// Editor
import editorSlice from "./Admin/Editor/reducer";

// Transaction
import TransactionSlice from "./Admin/Transaction/reducer";

// Blog
import BlogSlice from "./Admin/Blog/reducer";

// Blog Plan
import blogplanSlice from "./Admin/BlogPlan/reducer";

// Admin Support Ticket
import AdminTicketsSlice from "./Admin/Tickets/reducer";

// Admin Support Ticket
import AdminTicketsCommentSlice from "./Admin/TicketsComment/reducer";

import AuthReducer from "./FrontView/authentication/reducer";
import DashboardReducer from "./FrontView/dashboard/reducer";
import TicketsFReducer from "./FrontView/tickets/reducer";
import SubmitSitesReducer from "./FrontView/submitSites/reducer";
import CMSReducer from "./FrontView/cms/reducer";
import BlogsReducer from "./FrontView/blogs/reducer";
import PagesReducer from "./FrontView/dynamicPages/reducer";

const rootReducer = combineReducers({
    Layout: LayoutReducer,
    Login: LoginReducer,
    AdminLoginInUser: AdminLoginInUserSlice,
    Profile: ProfileReducer,
    Tickets: TicketsReducer,
    CMS: cmsSlice,
    Category: categoryReducer,
    PlanAndPricing: PlanAndPricingSlice,
    AdminUser: AdminUserSlice,
    SubmitSites: SubmittedSitesSlice,
    DetailandProduct: DetailsAndProductSlice,
    Editors: editorSlice,
    Transaction: TransactionSlice,
    Blog: BlogSlice,
    BlogPlan: blogplanSlice,
    AdminTickets: AdminTicketsSlice,
    AdminTicketsComment: AdminTicketsCommentSlice,

    ////
    Auth: AuthReducer,
    Dashboard: DashboardReducer,
    TicketsF: TicketsFReducer,
    SubmitSitesf: SubmitSitesReducer,
    CMSF: CMSReducer,
    Blogs: BlogsReducer,
    Pages: PagesReducer,
});

export default rootReducer;