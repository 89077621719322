import { createSlice } from "@reduxjs/toolkit";
import { updatedataCMS, addNewCMS, getCMSdata } from './thunk';

export const initialState = {
  cmsdetails: {},
  error: {}
};

const cmsSlice = createSlice({
  name: "cms",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCMSdata.fulfilled, (state, action) => {
      // console.log("action.payload", action.payload);
      state.cmsdetails = action.payload.data;
    });

    builder.addCase(getCMSdata.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(addNewCMS.fulfilled, (state, action) => {
      state.cmsdetails = action.payload.cms;
    });

    builder.addCase(addNewCMS.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(updatedataCMS.fulfilled, (state, action) => {
      state.cmsdetails = action.payload.cms
    });

    builder.addCase(updatedataCMS.rejected, (state, action) => {
      state.error = action.payload.error || null;
    });
  },
});

export default cmsSlice.reducer;