import React, { useEffect, useState } from 'react';
import CountUp from "react-countup";
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { getAdminUserdata, getAllSubmittedSitesdata, getBlogAllData, getSubmittedSitesdata, getTransactionData } from '../../../slices/thunks';
import { useDispatch, useSelector } from 'react-redux';

const Widgets = () => {
    const dispatch = useDispatch();
    const AdminUser = useSelector((state) => state.AdminUser.adminusersdetails);
    const SubmitSites = useSelector((state) => state.SubmitSites.allSubmittedSitesData);

    const { websiteTodayRevenue, websiteMonthlyRevenue, blogTodayRevenue, blogMonthRevenue } = useSelector(state => ({
        websiteTodayRevenue: state.Transaction.websiteTodayRevenue,
        websiteMonthlyRevenue: state.Transaction.websiteMonthlyRevenue,
        blogTodayRevenue: state.Blog.blogTodayRevenue,
        blogMonthRevenue: state.Blog.blogMonthRevenue
    }));


    useEffect(() => {
        dispatch(getAdminUserdata());
        dispatch(getAllSubmittedSitesdata());
        dispatch(getBlogAllData());
        dispatch(getTransactionData());
    }, [dispatch]);

    return (
        <React.Fragment>
            <Row>
                <Col xl={3} md={6}>
                    <Card className="card-animate bg-primary">
                        <CardBody>
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1 overflow-hidden">
                                    <p className="text-uppercase fw-bold text-white-50 text-truncate mb-0">Total Users</p>
                                </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-4">
                                <div>
                                    <h4 className="fs-22 fw-bold ff-secondary mb-4 text-white"><span className="counter-value">
                                        <CountUp
                                            start={0}
                                            end={AdminUser?.length}
                                            duration={1}
                                        />
                                    </span></h4>
                                    <Link to="/admin/users" className="text-decoration-underline text-white-50">View all Users</Link>
                                </div>
                                <div className="avatar-sm flex-shrink-0">
                                    <span className="avatar-title rounded fs-3 bg-soft-light">
                                        <i className="text-white las la-users"></i>
                                    </span>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col xl={3} md={6}>
                    <Card className="card-animate bg-secondary">
                        <CardBody>
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1 overflow-hidden">
                                    <p className="text-uppercase fw-bold text-white-50 text-truncate mb-0">Active Sites</p>
                                </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-4">
                                <div>
                                    <h4 className="fs-22 fw-bold ff-secondary mb-4 text-white"><span className="counter-value">
                                        <CountUp
                                            start={0}
                                            end={SubmitSites?.filter(site => site?.Approval_Status === 'Approved')?.length}
                                            duration={1}
                                        />
                                    </span></h4>
                                    <Link to="/admin/submitted-sites" className="text-decoration-underline text-white-50">View all Active Sites</Link>
                                </div>
                                <div className="avatar-sm flex-shrink-0">
                                    <span className="avatar-title rounded fs-3 bg-soft-light">
                                        <i className="text-white ri-checkbox-circle-line"></i>
                                    </span>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col xl={3} md={6}>
                    <Card className="card-animate bg-success">
                        <CardBody>
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1 overflow-hidden">
                                    <p className="text-uppercase fw-bold text-white-50 text-truncate mb-0">Today's revenue</p>
                                </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-4">
                                <div>
                                    <h4 className="fs-22 fw-bold ff-secondary mb-4 text-white">
                                        <span className="counter-value">
                                            $<CountUp
                                                start={0}
                                                end={websiteTodayRevenue + blogTodayRevenue}
                                                duration={1}
                                            />
                                        </span>
                                    </h4>
                                    <Link to="/admin/dashboard" className="text-decoration-underline text-white-50">View Today's Revenue</Link>
                                </div>
                                <div className="avatar-sm flex-shrink-0">
                                    <span className="avatar-title rounded fs-3 bg-soft-light">
                                        <i className="text-white ri-line-chart-line"></i>
                                    </span>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col xl={3} md={6}>
                    <Card className="card-animate bg-info">
                        <CardBody>
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1 overflow-hidden">
                                    <p className="text-uppercase fw-bold text-white-50 text-truncate mb-0">Monthly Revenue</p>
                                </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-4">
                                <div>
                                    <h4 className="fs-22 fw-bold ff-secondary mb-4 text-white">
                                        <span className="counter-value">
                                            $<CountUp
                                                start={0}
                                                end={websiteMonthlyRevenue + blogMonthRevenue}
                                                duration={1}
                                            />
                                        </span>
                                    </h4>
                                    <Link to="/admin/dashboard" className="text-decoration-underline text-white-50">View Monthly Revenue</Link>
                                </div>
                                <div className="avatar-sm flex-shrink-0">
                                    <span className="avatar-title rounded fs-3 bg-soft-light">
                                        <i className="text-white mdi mdi-calendar-month-outline"></i>
                                    </span>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default Widgets;