import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Form, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from '../../../Components/Common/TableContainer';
import { TicketsId, Title, CreateDate, Priority, Status } from "./TicketCol";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import { toast, ToastContainer } from 'react-toastify';
import Loader from "../../../Components/Common/Loader";
import DeleteModal from "../../../Components/Common/DeleteModal";
import { addNewUserTicket, getUserTicketsList } from '../../../slices/FrontView/tickets/thunk';





const TicketsData = () => {

    const dispatch = useDispatch();

    const [isEdit, setIsEdit] = useState(false);
    const [ticket, setTicket] = useState([]);

    // Delete Tickets
    const [deleteModal, setDeleteModal] = useState(false);
    const [modal, setModal] = useState(false);

    const { ticketsUserList } = useSelector((state) => state?.TicketsF);

    const obj = JSON.parse(sessionStorage.getItem('authUser'))?.data
    useEffect(() => {
        dispatch(getUserTicketsList(obj?._id))
    }, [dispatch])


    const toggle = useCallback(() => {
        if (modal) {
            setModal(false);
            setTicket(null);
        } else {
            setModal(true);
        }
    }, [modal]);

    // validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            id: '',
            Title: '',
            Description: '',
            Priority: 'Medium',
            // Status: '',
        },
        validationSchema: Yup.object({
            Title: Yup.string().required("Please Enter Title"),
            Description: Yup.string().required("Please Enter Description"),
            Priority: Yup.string().required("Please Select Priority")
        }).shape({
            // Status: Yup.string().required("Please Select Status"),
        }),
        onSubmit: (values) => {

            const newTicket = {

                User_ID: obj?._id,
                Title: values.Title,
                Description: values.Description,
                Priority: values.Priority,
                Status: 'New',
            };
            // save new ticket
            dispatch(addNewUserTicket(newTicket));
            validation.resetForm();

            toggle();
        },
    });

    // Delete Data
    const onClickDelete = (ticket) => {
        setTicket(ticket);
        setDeleteModal(true);
    };

    const handleDeleteTicket = () => {
        if (ticket) {
            // dispatch(deleteTicket(ticket._id));
            setDeleteModal(false);
        }
    };

    // Update Data
    const handleTicketsClick = useCallback((arg) => {
        const ticket = arg;

        setTicket({
            _id: ticket._id,
            id: ticket.id,
            Title: ticket.Title,
            due: ticket.due,
            status: ticket.status,
            Status: ticket.Status
        });

        setIsEdit(true);
        toggle();
    }, [toggle]);

    // useEffect(() => {
    //     setTicket(ticketsList);
    // }, [ticketsList]);

    // useEffect(() => {
    //     if (!isEmpty(ticketsList)) {
    //         setTicket(ticketsList);
    //         setIsEdit(false);
    //     }
    // }, [ticketsList]);

    // Add Data
    const handleTicketsClicks = () => {
        setTicket("");
        setIsEdit(false);
        toggle();
    };



    const handleEditEvent = (data) => {
        setModal(true)
    }


    // Checked All
    const checkedAll = useCallback(() => {
        const checkall = document.getElementById("checkBoxAll");
        const ele = document.querySelectorAll(".ticketCheckBox");

        if (checkall.checked) {
            ele.forEach((ele) => {
                ele.checked = true;
            });
        } else {
            ele.forEach((ele) => {
                ele.checked = false;
            });
        }
        deleteCheckbox();
    }, []);

    // Delete Multiple
    const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
    const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

    const deleteMultiple = () => {
        const checkall = document.getElementById("checkBoxAll");
        selectedCheckBoxDelete.forEach((element) => {
            // dispatch(deleteTicket(element.value));
            setTimeout(() => { toast.clearWaitingQueue(); }, 3000);
        });
        setIsMultiDeleteButton(false);
        checkall.checked = false;
    };

    const deleteCheckbox = () => {
        const ele = document.querySelectorAll(".ticketCheckBox:checked");
        ele.length > 0 ? setIsMultiDeleteButton(true) : setIsMultiDeleteButton(false);
        setSelectedCheckBoxDelete(ele);
    };

    const columns = useMemo(
        () => [
            {
                Header: <input type="checkbox" id="checkBoxAll" className='form-check-input' onClick={() => checkedAll()} />,
                Cell: (cellProps) => {
                    return <input type="checkbox" className="ticketCheckBox form-check-input" value={cellProps.row.original._id} onChange={() => deleteCheckbox()} />;
                },
                id: '#',
            },
            {
                Header: <div className='HederId'>ID</div>,
                accessor: "Ticket_ID",
                filterable: true,
                Cell: (cellProps) => {
                    return <TicketsId {...cellProps} />;
                },
            },
            {
                Header: "Title",
                accessor: "Title",
                filterable: true,
                Cell: (cellProps) => {
                    return <Title {...cellProps} />;
                },
            },

            {
                Header: "Create Date",
                accessor: "createdAt",
                filterable: true,
                Cell: (cellProps) => {
                    return <CreateDate {...cellProps} />;
                },
            },
            {
                Header: "Priority",
                accessor: "Priority",
                Cell: (cellProps) => {
                    return <Priority {...cellProps} />;
                },
            },
            {
                Header: "Status",
                accessor: "Status",
                Cell: (cellProps) => {
                    return <Status {...cellProps} />;
                },
            },

        ],
        [handleTicketsClick, checkedAll]
    );

    const dateFormat = () => {
        let d = new Date(),
            months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return ((d.getDate() + ' ' + months[d.getMonth()] + ', ' + d.getFullYear()).toString());
    };



    return (
        <React.Fragment>
            <Row className='height'>
                <DeleteModal

                    show={deleteModal}
                    onDeleteClick={handleDeleteTicket}
                    onCloseClick={() => setDeleteModal(false)}
                />
                <Col lg={12}>
                    <Card>
                        <CardHeader className="border-0">
                            <div className="d-flex align-items-center" style={{ justifyContent: "space-between" }}>
                                <div className="flex-shrink-0">
                                    <div className="d-flex flex-wrap gap-2 mt-2">
                                        <button className="btn primary-btn" onClick={() => { setIsEdit(false); toggle(); }}><i className="ri-add-line align-bottom"></i> Create Tickets</button>

                                    </div>
                                </div>

                            </div>
                        </CardHeader>
                        <CardBody className='pt-0'>
                            {ticketsUserList?.length ? (
                                <TableContainer
                                    columns={columns}
                                    data={(ticketsUserList || [])}
                                    customPageSize={8}
                                    className="custom-header-css"
                                    divClass="table-responsive table-card mb-3"
                                    tableClass="align-middle table-nowrap mb-0"
                                    theadClass="table-light"
                                    handleTicketClick={handleTicketsClicks}
                                />
                            ) : <div style={{ color: "rgb(135, 138, 153)" }} className="text-center fs-18 fw-bold">Ticket Not Available</div>
                            }
                            <ToastContainer closeButton={false} limit={1} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Modal isOpen={modal} backdrop={false} className='tablelist-1' centered size="lg">

                <Form className="tablelist-form" onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}>
                    <ModalHeader toggle={toggle} >
                        {!!isEdit ? "Edit Ticket" : "Add Ticket"}
                    </ModalHeader>
                    <ModalBody >
                        <Row className="g-3">
                            <Col lg={12}>
                                <div>
                                    <Label htmlFor="tasksTitle-field" className="form-label">Title</Label>
                                    <Input
                                        name="Title"
                                        id="tasksTitle-field"
                                        className="form-control"
                                        placeholder="Enter Title"
                                        type="text"
                                        validate={{ required: { value: true }, }}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.Title || ""}
                                        invalid={validation.touched.Title && validation.errors.Title ? true : false}
                                    />
                                    {validation.touched.Title && validation.errors.Title ? (
                                        <FormFeedback type="invalid">{validation.errors.Title}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg={12}>
                                <div>
                                    <Label htmlFor="tasksTitle-field" className="form-label">Description</Label>
                                    <Input
                                        type="textarea"
                                        name='Description'
                                        className="form-control"
                                        id="addaddress-textarea"
                                        placeholder="Please let us know The question You have and further details."
                                        rows="4"
                                        validate={{ required: { value: true }, }}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.Description || ""}
                                        invalid={validation.touched.Description && validation.errors.Description ? true : false}
                                    />
                                    {validation.touched.Description && validation.errors.Description ? (
                                        <FormFeedback type="invalid">{validation.errors.Description}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>

                            {/* <Col lg={6}>
                                <Label htmlFor="status-field" className="form-label">Status</Label>
                                <Input
                                    name="Status"
                                    type="select"
                                    className="form-select"
                                    id="status-field"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.Status || ""}
                                    invalid={validation.touched.Status && validation.errors.Status ? true : false}

                                >
                                    <option value="New">New</option>
                                    <option value="Inprogress">Inprogress</option>
                                    <option value="Closed">Closed</option>
                                    <option value="Completed">Completed</option>

                                </Input>
                                {validation.touched.Status && validation.errors.Status ? (
                                    <FormFeedback type="invalid">{validation.errors.Status}</FormFeedback>
                                ) : null}
                            </Col> */}
                            <Col lg={12}>
                                <Label htmlFor="priority-field" className="form-label">Priority</Label>
                                <Input
                                    name="Priority"
                                    type="select"
                                    className="form-select"
                                    id="priority-field"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.Priority || ""}
                                    invalid={validation.touched.Priority && validation.errors.Priority ? true : false}

                                >
                                    <option value="High">High</option>
                                    <option value="Medium">Medium</option>
                                    <option value="Low">Low</option>
                                </Input>
                                {validation.touched.Priority && validation.errors.Priority ? (
                                    <FormFeedback type="invalid">{validation.errors.Priority}</FormFeedback>
                                ) : null}
                            </Col>
                        </Row>

                    </ModalBody>
                    <div className="modal-footer">
                        <div className="hstack gap-2 justify-content-end">
                            <button onClick={() => { setModal(false); }} type="button" className="btn btn-light m-2 primary-btn" data-bs-dismiss="modal">Close</button>
                            <button type="submit" className="btn btn-success primary-btn" id="add-btn">{!!isEdit ? "Update" : "Add Ticket"}</button>
                        </div>
                    </div>
                </Form>
            </Modal>
        </React.Fragment>
    );
};

export default TicketsData;