import React from 'react';
import { Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';
import img from "../../../../assets/images/companies/img-4.png";
import moment from 'moment';

const Section = ({ state }) => {

    return (
        <React.Fragment>
            <Col lg={12}>
                <Card className="mt-n4 mx-n4 mb-n5">
                    <div className="bg-soft-warning">
                        <CardBody className="pb-4 mb-5">
                            <Row>
                                <div className="col-md">
                                    <Row className="align-items-center">
                                        <div className="col-md ms-3">
                                            <h4 className="fw-semibold" id="ticket-title">#{state?.Ticket_ID} - {state?.Title}</h4>
                                            <div className="hstack gap-3 flex-wrap">
                                                <div className="text-muted"><i className="ri-user-2-fill align-bottom me-1"></i> <span id="ticket-client">{state?.User_ID?.Email ? state?.User_ID?.Email : <>-</>}</span></div>
                                                <div className="vr"></div>
                                                <div className="text-muted">Create Date : <span className="fw-medium" id="create-date">{moment(state?.createdAt).format("D MMM YYYY")}</span></div>
                                                <div className="vr"></div>
                                                {
                                                    state.Priority === 'Medium' ? <span className="badge rounded-pill bg-success fs-12"> Medium </span>
                                                        : state.Priority === 'High' ? <span className="badge rounded-pill bg-danger fs-12"> High </span>
                                                            : <span className="badge rounded-pill bg-warning fs-12"> Low </span>
                                                }
                                                {
                                                    state.Status === 'Completed' ? <span className="badge rounded-pill bg-success fs-12"> Completed </span>
                                                        : state.Status === 'Closed' ? <span className="badge rounded-pill bg-danger fs-12"> Closed </span>
                                                            : state.Status === 'New' ? <span className="badge rounded-pill bg-info fs-12"> New </span>
                                                                : <span className="badge rounded-pill bg-warning fs-12"> Low </span>
                                                }
                                            </div>
                                        </div>
                                    </Row>
                                </div>
                            </Row>
                        </CardBody>
                    </div>
                </Card>
            </Col>
        </React.Fragment>
    );
};

export default Section;